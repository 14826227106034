import React, { useEffect, useContext, useState } from 'react';
import Page from '../../components/Page/Page';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import { Redirect, useHistory, Link } from 'react-router-dom';
import './financeing.css'
import { Card, Table, Modal, Button, Form, Input,InputNumber, Radio, Space, Select, DatePicker } from 'antd';
import moment from 'moment';
import Refinanciamiento from './Forms/Refinanciamiento';
import HardMoney from './Forms/HardMoney';
import LoanRequested from './LoanRequested';


const { TextArea } = Input;
const Financing = props => {
  const { state } = useContext(AuthContext);
  const [loantype,setLoantype] = useState(1);
  

  const onChange = (e) => {
    setLoantype(e.target.value);
  };
  if(state?.user?.usertype.includes('lender')){
    return <Redirect to="/dashboard/lender"/>;
  }else if(state?.user == null){
    return (
      <Page>
        <div className="maincontent fine"  id='l-requestform'>
          <div className='visit-anony-detail'>
          Debes ser miembro para acceder al portal de prestamistas.
          </div>
          <div className='visit-anony-cta'>
            <Link to="/pricing">Subscribete</Link>
            <Link to="/login?=/financing">Iniciar sesión</Link>
          </div>
          </div>
      </Page>
    )
  }
   return (
    <Page>
      <div className="maincontent fine"  id='l-requestform'>
          <div className='visit-dashboard'>
              <Link to="/dashboard/borrower">Visitar Portal de préstamo</Link>
          </div>
          <h2>Seleccionar préstamo</h2>
            <Radio.Group onChange={onChange} value={loantype} name="loantype">
              <Space direction="horizontal">
                <label></label>
                  <Radio value="Hard Money">Hard Money</Radio>
                  <Radio value="Refinanciamiento">Refinanciamiento</Radio>
              </Space>
            </Radio.Group>
        {loantype == 'Refinanciamiento'
        ? <Refinanciamiento loantype="Refinanciamiento"/>
        : loantype == 'Hard Money'
        ? <HardMoney loantype="Hard Money"/>
        :''}
      </div>
      <div className='dashboard-wrapper'>
        {state?.user?.id &&
          <LoanRequested id={state?.user?.id} hideLink = {true}/>}
      </div>
    </Page>
   );
};

export default Financing;
