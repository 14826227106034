import React, { useState, useContext,Suspense, lazy  } from 'react';
//import EditProfile from './EditProfile/EditProfile';
import profilebanner from '../../assets/images/bannerdefault.png'
import { NavLink } from 'react-router-dom';
import { Modal } from 'antd';
import { AuthContext } from '../.././App';
import API from '../.././utils/API';

import './userprofile.css'
import ProfileImage from '../../components/WidgetBar/ProfileImage';
const LazyHeavy = lazy(() => import('./EditProfile/EditProfile'));
const UserHeader = props => {
   const { state } = useContext(AuthContext);
   const [isUpload, setUpload] = useState(false)
   const [bannerImg, setbannerImg] = useState()
   const [userDetail, setUserDetail] = useState()
   React.useEffect(() => {
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
         const user = JSON.parse(localStorage.getItem('user'));
         fetchData(user.id)
      }
   },[]);
   const fetchData = (id) => {
      API.get(`/userinfo/?userid=${id}`).then(
         result => {
            const userDetail = result?.data?.userInfo
            setUserDetail(userDetail)
         }
      ).catch(err => {
         console.log(err);
      })
      userImage(id)

   }
   const userImage = (id) => {
      API.get(`/userinfo/getimage/?userid=${id}`).then(result => {
         setbannerImg(<img width="100%" height="420" src={result.data.base64} alt="" />)
         setUpload(true)
      }).catch(err => {
         console.log(err);
         return
      })
   }
   const [visible, setVisible] = useState(false);
   const showModal = () => {
      setVisible(true)
      document.body.classList.add('modalopen');

   }
   const handleCancel = () => {
      setVisible(false)
      document.body.classList.remove('modalopen');

   };
   const onSave = () => {
      setVisible(false)
      document.body.classList.remove('modalopen');
      window.location.reload()
   }
   const Capitalize = (str) => {
      if (str !== undefined && str !== null)
         return str.charAt(0).toUpperCase() + str.slice(1);
   }
   return (
      <div className="maincontent guestprofileview">    
         <div className="guestprofileinformation">
            {!isUpload ? <div className="coverimage"><img width="100%" height="420" src={profilebanner} alt=""/></div> :
               <div className="coverimage">{bannerImg}</div>}
            <div className="guestprofiledetails">
               <div className='userimagewrapper'><ProfileImage id={state?.user?.id} classtouse="userpict"/></div>
               <div className="username">{userDetail?.userid?.firstname ?<h2>{Capitalize(userDetail?.userid?.firstname)} {Capitalize(userDetail?.userid?.lastname)}</h2>:<h2>{Capitalize(state.user?.firstname)} {Capitalize(state.user?.lastname)}</h2>}</div>
               <div className="description">{Capitalize(props?.shortBio)}</div>
            </div>
         </div>
         <Modal
            visible={visible}
            okText="Submit"
            onCancel={handleCancel}
            centered="true"
            wrapClassName={'profileeditpop'}
            footer={null}
            maskClosable = {false}
         >
         <Suspense fallback={<div>Loading...</div>}>
            <LazyHeavy onopen={onSave}/>
         </Suspense>
         </Modal>
         <div className="guestprofiletabsview">
            <div className="guestprofiletabs">
               <div className="lefttab">
               <ul>
                     <li ><NavLink to={{
                        pathname: "/aboutuser"
                     }} >Acerca de</NavLink></li>
                  </ul>
               </div>
               <div className="righttab">
                  <ul>
                     <li onClick={showModal} className="message"><span className='editbutton'>Editar Perfil</span></li>
                  </ul>
               </div>
            </div>
         </div>
      </div>
   );
};

export default UserHeader;
