import React, {useContext} from 'react';
import styles from './Footer.module.css';
import { AuthContext } from '../../App';
import { NavLink, useLocation, matchPath } from 'react-router-dom';
import logo from '../../assets/images/ENLACANCHA-Logo-Largo.png';
import ios from '../../assets/images/ios.jpg';
import android from '../../assets/images/android.jpg';
const Footer = () => {
   const location = useLocation();
   const { state } = useContext(AuthContext);
   const match = matchPath(location.pathname, {
     path: ['/help-us', '/messenger'],
     exact: true,
     strict: false
   })

  const matchforum = matchPath(location.pathname, {
    path: ['/forums'],
    exact: true,
    strict: false
  })

   const isActive = {
      borderBottom: "1px solid #FFFFFF",
   };
   const headerhide = matchPath(location.search, {
      path: ['?forapp=1'],
      exact: true,
      strict: false
    })
  return (
    <footer id={(matchforum) ? "forumroot" : ""}className={ (match || headerhide)? styles.landing : styles.footer}>
      <div className={styles.footertoparea}>
         <div className={styles.footertop}>
            {(state?.user?.usertype.includes('lender') && state?.user?.sub == 'free' && state?.user?.level != 1) ? '' :
            <div className={styles.footerfirst+' '+ styles.footercolumn}>
               <h2 className={styles.blocktitle}>Explorar</h2>
               <ul>
                  <li><NavLink to="/education" activeStyle={isActive}>Educacion</NavLink></li>
                  <li><NavLink to="/network" activeStyle={isActive}>Conexiones</NavLink></li>
                  <li><NavLink to="/forums" activeStyle={isActive}>Forums</NavLink></li>
                  <li><NavLink to="/pricing" activeStyle={isActive}>Membresias</NavLink></li>
                  <li><NavLink to="/resources" activeStyle={isActive}>Recursos y Contratos</NavLink></li> 
                  <li><NavLink to="/sponsors" activeStyle={isActive}>Patrocinadores</NavLink></li> 
               </ul>
            </div>}
            <div className={styles.footerthird +' '+ styles.footercolumn}>
               <h2 className={styles.blocktitle}>Compañía</h2>
               <ul>
                  <li><NavLink to="/aboutus" activeStyle={isActive}>Acerca de nosotros</NavLink></li>
                  <li><NavLink to="/terms_and_conditions" activeStyle={isActive}>Teminos y Condiciones</NavLink></li>
                  <li><NavLink to="/privacy_policy" activeStyle={isActive}>Politica de Privacidad</NavLink></li>
                  <li><NavLink to="/contactus" activeStyle={isActive}>Contactanos</NavLink></li>
                  <li><NavLink to="/faq" activeStyle={isActive}>Preguntas Frequentes</NavLink></li>
               </ul>
            </div>
            <div className={styles.footerfourth+' '+ styles.footercolumn}>
               <h2 className={styles.blocktitle}>Redes</h2>
               <ul className={styles.sociallink}>
                  <li className={styles.facebook}><NavLink to={{ pathname: "https://www.facebook.com/profile.php?id=100087655726967&mibextid=LQQJ4d" }} target="_blank">Facebook</NavLink></li>
                  <li className={styles.instagram}><NavLink to={{ pathname: "https://www.instagram.com/enlacanchadebienesraices" }} target="_blank">Instagram</NavLink></li>
                  <li className={styles.youtube}><NavLink to={{ pathname: "https://www.youtube.com/channel/UCNAVtgmCbKDlvElREhY99Fg" }} target="_blank">Youtube</NavLink></li>

               </ul>
            </div>
         </div>
      </div>
      <div className={styles.footerappdownload}>
         <div className={styles.left}><img src={logo}/></div> 
         <div className={styles.middle}>
            <div className={styles.h}>LLÉVANOS <span>CONTIGO</span></div>
            <div className={styles.b}>Descarga nuestra aplicacion GRATIS</div>
         </div> 
         <div className={styles.right}>
            <div className={styles.text}>Disponible para iOS y ANDROID</div>
            <div>
               <a href="https://apps.apple.com/in/app/en-la-cancha/id6461210826"><img src={ios}/></a>
               <a href="https://play.google.com/store/apps/details?id=com.enlacancha"><img src={android}/></a>
            </div>
         </div>         
      </div>
      <div className={styles.footerbottom}>Derechos de Autor {new Date().getFullYear()}.En La Cancha.Todos los derechos son reservados</div>
    </footer>
  );
};

export default Footer;
