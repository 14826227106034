import React from 'react';
import API from '../../utils/API';
import './podcastdetail.css';
import PodcastImage from '../../components/PodcastData/PodcastImage';
import CategoryName from '../../components/CategoryName/CategoryName';
import PodcastFile from '../../components/PodcastData/PodcastFile';
import {Link } from 'react-router-dom';
import SocialShareBlock from '../../components/SocialShareBlock/SocialShareBlock';
const PodcastDetails = (props) => {
    const [blogData,setBlogData] = React.useState()
    const [podcastFile,setPodcastFile] = React.useState(undefined)
   React.useEffect(()=>{
    API.get('/podcasts/contents/node/' + props.pageid).then(result =>{
     setBlogData(result.data.podcasts[0])
     API.get(`/podcasts/getpodcast/?_id=${props.pageid}`).then(podcast => {
        setPodcastFile(<audio src = { `data:audio/mpeg;base64,${podcast?.data?.base64}`}></audio>)
     
     })

    })
   },[])
  return (
   <div className="podcastdetailspage">
      {blogData && <div className = "podcast" >
            <div className="breadcrumbwrapper">
               <div className="breadcrumb">
                  <ul>
                     <li><Link to='/education'>Educacion</Link></li>
                     <li><Link to="/education/podcasts">Podcasts</Link></li>
                     <li>{blogData.podcastname}</li>
                  </ul> 
               </div>
            </div>
            <div className = "podcastdetail" >
               <div className="imagebox"><PodcastImage id = {blogData?._id}/></div>
               <div className="courseshare">
                  <ul>
                     {/* <li className="bookmark"><a href="#">bookmark</a></li> */}
                     <li className="share">
                        <SocialShareBlock shareTitle={blogData?.podcastname} shareUrl={window.location.href} />
                     </li>
                  </ul>
               </div>
               <div className = "title">{blogData?.podcastname}</div>
               <div className = "description"><text>{blogData?.description}</text> </div>
               <div className="readtime">
                  <div  className="readview">
                     <label>Categoría: {" "}</label>
                     <strong>{blogData?.selectedCategory[0].name}</strong>
                  </div>
                  <div className="sharelink">
                     <ul>
                        {/* <li className="bookmark"><a href="#">bookmark</a></li>
                        <li className="share"><a href="#">share</a></li> */}
                     </ul>
                  </div>
               </div>
               <div className="audioplayer">
                  <PodcastFile id = {blogData._id}/>
               </div>
            </div>
      </div>}
   </div>
  );
};

export default PodcastDetails;