import React from 'react';
import Page from '../../components/Page/Page';
import styles from './AboutUs.module.css';
import aboutbanner from '../../assets/images/aboutbanner.svg';

import aboutimage1 from '../../assets/images/aboutimage1.jpg';
import aboutimage2 from '../../assets/images/aboutimage2.jpg';
import aboutimage3 from '../../assets/images/aboutimage3.jpg';

import CristhianHuamani from '../../assets/images/CristhianHuamani.svg';
import OswaldoGalarza from '../../assets/images/OswaldoGalarza.svg';
const AboutUs = (props) => {
  return (
    <Page>
      <div>     
      <div className={styles.maincontent, styles.aboutuspage}>
         
         <div className={styles.bannerarea}>
            <div className={styles.bannertitle}>
               <h2>En La Cancha brinda la oportunidad de explorar el mundo inmobiliario con múltiples características y beneficios</h2>
            </div>
            <div className={styles.imagebox}><img src={aboutbanner}  /></div>
         </div>
         
         <div className={styles.aboutusblock}>
            <div className={styles.aboutus}>
               <div className={styles.aboutleftbox}>
                  <h2 className={styles.blocktitle}>Quienes somos</h2>
                  <div className={styles.blockcontent}>
                     <p>En La Cancha es una plataforma de educación de bienes raíces dedicada a llevar a la comunidad latina en estados unidos hacia la libertad financiera mediante el poder de inversiones en bienes raíces.</p>
                     <p>En La Cancha fue fundada por Cristhian Huamani y Oswaldo Galarza, dos inversionistas de bienes raíces con una vision grande de educar y motivar a la comunidad hispana en estados unidos.</p>
                  </div>
               </div>
               <div className={styles.imagewrapper}>
                  <div className={styles.verticle}>
                     <div className={styles.imagebox1}><img src={aboutimage1} /></div>
                     <div className={styles.imagebox2}><img src={aboutimage2} /></div>
                  </div>
                  <div className={styles.horizontal}><img  src={aboutimage3} /></div>
               </div>
            </div>
         </div>

         <div className={styles.ourvisionwithmission}>
            <div className={styles.viewblockcontent}>
               <div className={styles.ourvisionblock}>
                  <div className={styles.ourvisionblockviewcontent}>
                     <h2 className={styles.blocktitle}>Nuestra Vision</h2>
                     <div className={styles.blockcontent}>
                        <p>llevar a la comunidad hispana en estados unidos hacia la libertad financiera mediante el poder de inversiones en bienes raíces.</p>
                     </div>
                  </div>
               </div>
               <div className={styles.ourmissionblock}>
                  <div className={styles.ourmissionblockkviewcontent}>
                     <h2 className={styles.blocktitle}>Nuestra Misión</h2>
                     <div className={styles.blockcontent}>
                        <p>Educando, motivando y creando una comunidad de inversionistas de bienes raíces hispanos en todo estados unidos.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>

         <div className={styles.aboutviewtestimonials}>
            <div className={styles.viewcontent}>
               <div className={styles.viewrow}>
                  <div className={styles.imagebox}>
                     <div className={styles.image}><img  src={CristhianHuamani}/></div>
                  </div>
                  <div className={styles.contentbox}>
                     <h2 className={styles.blocktitle}>Cristhian Huamani</h2>
                     <div className={styles.description}>
                     Cristhian Huamani es nativo de arequipa peru. emigro a estados unidos a la edad de 12 anos. como cualquier inmigrante tubo que luchar con los muchos impedimentos que un emigrante experimenta en estados unidos. a la edad de 23 anos se lanzo en el mundo de bienes raíces como agente de bienes raíces, especializiandoze en inversiones de bienes raíces. en su primer ano despues de aprender de las múltiples estrategias de inversión, el comenzó a crear su portafolio de inversión de bienes raíces con éxito. a los 28 anos Cristhian fundo la primera asociación de inversionistas de bienes raíces en maryland dc y virginia. Cristhian siempre tubo y continua a tener una vision de cambiar a la comunidad hispana. en estos días Cristhian disfruta educar y motivar a todos los hispanos En La Cancha. también disfruta viajar y explorar el mundo entero.
                     </div>
                  </div>
               </div>
               <div className={styles.viewrow}>
                  <div className={styles.imagebox}><div className={styles.image}><img src={OswaldoGalarza}/></div></div>
                  <div className={styles.contentbox}>
                     <h2 className={styles.blocktitle}>Oswaldo Galarza</h2>
                     <div className={styles.description}>
                     Oswaldo Galarza es nativo de ecuador. inmigro a estados unidos a los 22 anos. como cualquier inmigrante trabajo en construcción, limpiando picinas y limpiando mesas en restaurantes. a los 34 anos despues de experimentar con diferentes negocios, Oswaldo comenzo en el mundo de bienes raíces haciendo wholesaling y evolucionando a hacer flips y eventualmente creando un portafolio grande utilizando el método carrr. actualmente Oswaldo disfruta de la libertad financiera con su esposa e hijo. también disfruta de recorrer y explorar con su familia el mundo entero.
                     </div>
                  </div>
               </div>
            </div>
         </div>
      
      </div></div>
    </Page>
  );
};

export default AboutUs;
