import { Button, Modal, Input,message } from 'antd';
import React, { useContext, useState, useEffect,useRef } from 'react';
import API from '../../utils/API';
import { useHistory } from 'react-router-dom';
import { AuthContext } from '../../App';

const ConnectButtonProfile = (props) => {
    const { state } = useContext(AuthContext);
    const [friendState, setfriendState] = useState(null)
    const [visibletosubscribe, setVisibletosubscribe] = useState(false);
    const history = useHistory();

    const handleRedirectPricing =() => {
      setVisibletosubscribe(false);
      history.push('/pricing?=/user/pending-connections')
      document.body.classList.remove('modalopen');
    }
    const handleCancel = () => {
        setVisibletosubscribe(false);
        document.body.classList.remove('modalopen');
    };
  
    const getConnectionsStatus = async () => {
        if( props.forUserId !== state?.user?.id){
            try {
                const res = await API.get("/users/checkc/"+state?.user?.id+"/"+ props.forUserId);
                if(res?.data?.friend?.length){
                    setfriendState(res.data.friend);
                }else{
                  setfriendState(null);
                }
              } catch (err) {
                console.log(err);
              }
        }
    };
    useEffect(() => {
      getConnectionsStatus();
    }, [state?.user?.id, props.forUserId]);

    const remove = async (e, aid) => {
      API.post('/users/rcr',{
        sid:aid,
        rid: state?.user?.id
      }).then(res => {
        getConnectionsStatus();
        message.success({
          content: 'Conexión eliminada',
          className: 'custom-class',
        });
      }).catch(err => {
        console.log(err);
      });
    }

    const reject = (e, aid) => {
      API.post('/users/cr',{
        sid: aid,
        rid:state?.user?.id
      }).then(res =>{
        getConnectionsStatus();
        message.success({
          content: 'Solicitud de conexión rechazada',
          className: 'custom-class',
        });
      }).catch(err => {
        console.log(err);
      });
    }

    const cancel = (e, aid) => {
      API.post('/users/cr',{
        sid:state?.user?.id,
        rid: aid
      }).then(res =>{
        getConnectionsStatus();
        message.success({
          content: 'Solicitud de conexión cancelada',
          className: 'custom-class',
        });
      }).catch(err => {
        console.log(err);
      });
    }
    const sending = async (e) =>{
      e.preventDefault();
      if( props.forUserId !== state?.user?.id){
        API.get(`/users/scount/${state?.user?.id}`).then(u=>{
          if( state?.user?.level == 1 || u?.data?.user?.membershiprole == 'basic' || (u?.data?.user?.sentcount < 20 && u?.data?.user?.membershiprole == 'free')){
            sendingProgress();
          }else{
            setVisibletosubscribe(true);
            document.body.classList.add('modalopen');
          }
        });
      }
  }

  const sendingProgress = async () =>{
    if( props.forUserId !== state?.user?.id){
      try{
        const res = await API.post('/users/send', {
            sid: state?.user?.id,
            rid: props.forUserId
        });
        if(res?.data){
          getConnectionsStatus();
          message.success({
              content: 'Solicitud enviada con éxito',
              className: 'custom-class',
          });
          let receiverId = props.forUserId;
          /* props.socket.current.emit("sendDesktopNotification", {
            receiverId,
          });
          //trigger to notifications to devices
          var uid = state.user.id;
          API.post(
            `/users/fcm/notifications/web`,
            {
              "title": state?.user?.firstname +' '+state?.user?.lastname,
              "body": ' quiere ser tu conexión',
              "type":"request",
              "senderuid":uid,
              "receiverId": receiverId
            },
            {
              headers: {
                Authorization: `Bearer ${state.token}`,
              },
            }
          ) */
        }               
      } catch (err) {
        console.log(err);
      }
    }
  }
    const accept = async (e, aid) => {
      API.post('/users/acp',{
      sid:aid,
      rid: state?.user?.id
      }).then(res =>{
          getConnectionsStatus();
          message.success({
              content: 'Conexión aceptada',
              className: 'custom-class',
            });
            let receiverId = aid;
            /* props.socket.current.emit("sendDesktopNotification", {
              receiverId,
            });
            //trigger to notifications to devices
            var uid = state.user.id;
            API.post(
              `/users/fcm/notifications/web`,
              {
                "title": state?.user?.firstname +' '+state?.user?.lastname,
                "body": 'aceptó tu solicitud de conexion',
                "type":"accepted",
                "senderuid":uid,
                "receiverId": receiverId
              },
              {
                headers: {
                  Authorization: `Bearer ${state.token}`,
                },
              }
            ) */
      }).catch(err => {
        console.log(err);
      });
    }
    return (
        
        <>  {props.forUserId !== state?.user?.id
            ?
            friendState != null && friendState[0].requester == state?.user?.id && friendState[0].recipient == props.forUserId && friendState[0].status == 1
            ? <div className='connect-icon sent'>
              <div className="formaction">
                <button className="removebtn" onClick={(e)=>{cancel(e,props.forUserId)}}>Cancelar</button>
              </div>
            </div>
            : friendState != null && friendState[0].recipient == state?.user?.id  && friendState[0].requester == props.forUserId && friendState[0].status == 1
            ?  <div className='connect-icon received'>
                <div className="formaction">
                  <button className="acceptbtn" onClick={(e)=>{accept(e,props.forUserId)}}>Aceptar</button>
                  <button className="removebtn" onClick={(e)=>{reject(e,props.forUserId)}}>Cancelar</button>
                </div>
              </div>
            : friendState != null && friendState[0].requester == state?.user?.id && friendState[0].recipient == props.forUserId && friendState[0].status == 2
            ? <div className="formaction"><button className="removebtn" onClick={e=>{remove(e,props.forUserId)}}>Eliminar conexión</button></div>
            : friendState != null && friendState[0].recipient == state?.user?.id  && friendState[0].requester == props.forUserId && friendState[0].status == 2
            ? <div className="formaction"><button className="removebtn" onClick={e=>{remove(e,props.forUserId)}}>Eliminar conexión</button></div>
            : <div className="formaction"><button className='connect-icon' onClick={(e)=>{sending(e)}} title="Enviar">Conectate</button></div>
            :
            null}
            <Modal
            visible={visibletosubscribe}
            okText="Actualizar membresía"
            cancelText="Cancelar"
            onOk={handleRedirectPricing}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>Actualiza tu membresía para enviar más solicitudes de conexión, ya que alcanzaste el límite de solicitudes de conexiones</div>
         </Modal>
        </>
    );
};

export default ConnectButtonProfile;
