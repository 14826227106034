import React, { Component } from 'react';
import axios from 'axios';

class VimeoThumbnail extends Component {
   state = {
      imgdata: ''
   }
   componentDidMount() {
      axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(this?.props?.videoId)))
      .then(res => {
        const imageUrl = res.data.thumbnail_url.split('_');
          this.setState({imgdata:imageUrl[0]})
      })
   }
    render(){
         return (
               <div className={"imagebox player-wrapper"+(this?.state?.imgdata ? ' preview-loaded ': '')}>
                  {this.state.showPlayicon == true ? <span>play</span> :null}
                  <div> {this.state.imgdata && <img src={this.state.imgdata+'_350'} height="192px" width="352px" alt=""/>}</div>
               </div>            
         );      
   }

};

export default VimeoThumbnail;
