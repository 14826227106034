import React, { useEffect, useContext, useState } from 'react';
import './loaninterested.css';
import API from '../../utils/API';
import { AuthContext } from '../../App';


const LenderProfile = props => {
  const { state } = useContext(AuthContext);
  const [cname, setCname] = useState('')
  const [companyname, setCompanyname] = useState('')
  const [cemail, setCemail] = useState('')
  const [companyaddress, setCompanyaddress] = useState('')
  const [makeloan, setMakeloan] = useState('')
  const [officeemail, setOfficeemail] = useState('')
  const [officephone, setOfficephone] = useState('')
  const [telefono, setTelefono] = useState('')
  const [serviceprovidevalue, setServiceprovidevalue] = useState([])
  const [loantypevalue, setLoantypevalue] = useState([])
  const [minimumloanvalue, setMinimumloanvalue] = useState('')
  const [commission, setCommission] = useState('')




  useEffect(()=>{
    if(state?.user?.id){
      fetchdataInterested(state?.user?.id);
    }    
  },[state?.user?.id])

  function fetchdataInterested (id) {
    API.get('users/fetchtata/lender/info/'+id).then(result =>{
        setCname(result?.data?.ldata?.cname)
        setCompanyname(result?.data?.ldata?.companyname)
        setCemail(result?.data?.ldata?.cemail)
        setCompanyaddress(result?.data?.ldata?.companyaddress)
        setMakeloan(result?.data?.ldata?.makeloan)
        setOfficeemail(result?.data?.ldata?.officeemail)
        setOfficephone(result?.data?.ldata?.officephone)
        setTelefono(result?.data?.ldata?.telefono)
        setServiceprovidevalue(result?.data?.ldata?.serviceprovidevalue)
        setLoantypevalue(result?.data?.ldata?.loantypevalue)
        setMinimumloanvalue(result?.data?.ldata?.minimumloanvalue)
        setCommission(result?.data?.ldata?.commission)
    }).catch((e) =>{
      console.log('error show',e)
    })
  }
  if(state?.user?.usertype.includes('lender')){
      return (
        <div className='dashboard-wrapper'>
          <div className='loaninterestedblock'>
          <div className='lender-profile-view'>
            <div className="lender-profile-item">
                <span>Nombre y apellido de persona de contacto: </span>
                <span>{cname}</span>
            </div>
            <div className="lender-profile-item">
                <span>Nombre de la compañía: </span>
                <span>{companyname}</span>
            </div>
            <div className="lender-profile-item">
                <span>Telefono: </span>
                <span>{telefono}</span>
            </div>
            <div className="lender-profile-item">
                <span>Email: </span>
                <span>{cemail}</span>
            </div>
            <div className="lender-profile-item">
                <span>Direccion de su  compañía: </span>
                <span>{companyaddress}</span>
            </div>
            <div className="lender-profile-item">
                <span>Numero telefonico de oficina: </span>
                <span>{officephone}</span>
            </div>
            <div className="lender-profile-item">
                <span>Email de oficina: </span>
                <span>{officeemail}</span>
            </div>
            <div className="lender-profile-item">
                <span>¿Estados en los  que provee sus servicios?: </span>
                <span>{serviceprovidevalue.join(', ')}</span>
            </div>
            {makeloan && <div className="lender-profile-item">
                <span>Realiza prestamos con Tax ID?: </span>
                <span>{makeloan}</span>
            </div>}
            <div className="lender-profile-item">
                <span>Tipos de prestamos</span>
                <span>{loantypevalue.join(', ')}</span>
            </div>
            <div className="lender-profile-item">
                <span>Cual es el prestamo minimo que puede originar: </span>
                <span>{minimumloanvalue}</span>
            </div>
            <div className="lender-profile-item">
                <span>Comision: </span>
                <span>{commission} %</span>
            </div>
          </div>
        </div>
        </div>
      );
  }else{
    return(
      <div className='dashboard-wrapper'>
        <div className='loaninterestedblock'>
          If you want to join the Lender Community, Please contact us. 
        </div>
      </div>
    )
  }
  }
  export default LenderProfile;