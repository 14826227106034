import React, {  useContext } from 'react';
import './googlelogin.css'
import { AuthContext } from '../../App';
import { Modal } from 'antd';
import API from '../../utils/API';
import { GoogleLogin } from 'react-google-login';
import { useHistory, useLocation} from 'react-router-dom';

const GoogleLoginPage = props => {

  const { dispatch } = useContext(AuthContext);
  const location = useLocation();
const history = useHistory();

const responseGoogle = (response) => {
   if(response.profileObj){
    API.post('/users/sociallogin', {
      email: response.profileObj.email.toLowerCase().trim(),
      firstname: response.profileObj.givenName,
      lastname: response.profileObj.familyName,
      username: response.profileObj.email.toLowerCase().trim(), //assign email as username
      password: response.googleId,
      platform: "Google"
    })
      .then((result) => {
        dispatch({
          type: 'LOGIN',
          payload: {
            token: result.data.token,
            user: result.data.user,
          },
        });
        if (location.search != '') {
          let redirectpath = location.search;
          history.push(redirectpath.replace('?=', '')+location.hash);
         }else {
          history.push('/dashboard');
         }
         
      })
      .catch(e => {
        Modal.error({
          title: 'Registro fallido',
        
        });
      });
    }

  }
 //clientId="1077948181292-24k3gl0v28g64usuiuvh12kmflcpu5iv.apps.googleusercontent.com"
  return (
    <div className="googlepage">
        <GoogleLogin

    clientId="439865587566-ctc689r6tb2u2fg8rtbin66hs82ikdv4.apps.googleusercontent.com"
    render={renderProps => (
        <a onClick={renderProps.onClick} >Continua con Google</a>
    )}
  
    onSuccess={responseGoogle}
    onFailure={responseGoogle}
    cookiePolicy={'single_host_origin'}
  />
    </div>
  );
};

export default GoogleLoginPage;