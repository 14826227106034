import React, { useState } from 'react';
import Page from '../../components/Page/Page';
/* import styles from './Register.module.css'; */
import { Modal } from 'antd';
import API from '../../utils/API';
import './css/Comingsoon.css';
import RegisterForm from '../Register/RegisterForm';


/* import { AuthContext } from '../../App';
import { useHistory } from 'react-router-dom'; */


const Comingsoon = props => {
  return (
    <Page>
      <div className="maincontent commingsoonpage">
        <div className="signupwrapper">
         <div className="signupintroduction">
            <h1>En La Cancha</h1>
            <h2>Próximamente, en breve, pronto...</h2>
            <h3>¡¡¡Sí!!! Por favor, preinscríbase para el lanzamiento.</h3>
         </div>
          <RegisterForm/>
        </div>
      </div>
    </Page>
  );
};

export default Comingsoon;
