import React from 'react';
import './booklist.css';
import { Carousel } from "antd";
import API from '../../utils/API';
import { Link} from 'react-router-dom'
import BookImage from '../../components/BooksData/BookImage';
const BooksListing = (props) => {
   const [books,setBooks] = React.useState([])
   const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
             breakpoint: 1119,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1,
             }
         },
         {
             breakpoint: 767,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         },
         {
             breakpoint: 480,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         }
      ]
   }
   React.useEffect(()=> {
      API.get(`/books/`).then(result=>{
         setBooks(result.data.books.sort((a, b)=> {
  
            // equal items sort equally
            if (a.bookOrder === b.bookOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.bookOrder === null) {
                return 1;
            }
            else if (b.bookOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
                return a.bookOrder < b.bookOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else { 
                return a.bookOrder < b.bookOrder ? 1 : -1;
            }
        
          }))
      }).catch(err => {
         console.log(err);
      })
   },[])
   const titlecase = (word) => {
      if(word !== undefined){
         return  word.split(' ')
         .map(w => w[0]?.toUpperCase() + w?.substr(1).toLowerCase())
         .join(' ')
   }
}
  return (
     <div>
      {books.length ? <div className="viewbooklist">
         <div className="viewbook">
            <h2>Libros</h2>
            <div className="viewcontent">
               <Carousel {...settings} >
                  {books.map((book,index) => <Link to={book.pathalias.alias} title={book.bookname} className="viewrow" key={index}>
                     <div className="bookcontent">
                        <div className="imagebox"><BookImage id ={book._id}/></div>
                        <div className="content">
                       {String(book.bookname).length > 71?<h2 className="title">{String(book.bookname).substring(0,70)}...</h2>:<h2 className="title">{String(book.bookname)}</h2>}
                           {book.artistname&&<div className="category">{titlecase(book.artistname.substring(0, 15).trim())}</div>}
                           {book.description &&<div className="description">{book.description.substring(0, 120)}</div>}
                           <div className="sharelink">
                              <ul>
                                 {/* <li className="bookmark"><a href="#">bookmark</a></li>
                                 <li className="share"><a href="#">share</a></li> */}
                              </ul>
                              </div>
                           </div>
                        </div>
                     {/*<div className = "categoryname" ><CategoryName id = {book?.selectedCategory[0]}/></div>*/}
                  </Link>)
                } 
                 
               </Carousel>
            </div>
            <div className="viewall"><Link to="/education/books">Ver todo</Link></div>
         </div>
      </div> : null}</div>
   );
};

export default BooksListing;
