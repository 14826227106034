import React from 'react';
import Page from '../../components/Page/Page';
import styles from './Education.module.css';
import EducationListing from './EducationListing';
import Podcast from '../Podcasts/PodcastsListing';
import BlogsListing from '../Blogs/BlogsListing';
import BookListing from '../Books/BooksListing';
import { Link } from 'react-router-dom';
import TipOfTheWeekListing from '../TipOfTheWeek/TipOfTheWeekListing';
import WebinarListing from '../Webinar/WebinarListing';

const Education = (props) => {
  return (
      <Page>
         <div className = {styles.vieweducationfilter}>
            <div className={styles.vieweducationfilter}>
               <div className={styles.vieweducationcontent}>
                  <div className={styles.searchtabs}>
                     <ul>
                     <li><Link to="/education/videocourses">ENTRENAMIENTOS</Link></li>
                     <li><Link to="/education/webinar">WEBINARS</Link></li>
                     <li><Link to="/education/tipoftheweek">Jugadas en la cancha</Link></li>
                        <li><Link to="/education/podcasts">Podcasts</Link></li>
                        <li><Link to="/education/blogs">Blogs</Link></li>
                        <li><Link to="/education/books">Libros</Link></li>
                     </ul>
                  </div>
               </div>
            </div>
            <EducationListing/>
            <WebinarListing/>
            <TipOfTheWeekListing />
            <Podcast/>
            <BlogsListing/>
            <BookListing/>
         </div>
      </Page>
  );
};

export default Education;
