import React, { useEffect, useContext, useState } from 'react';
import API from '../../../../utils/API';
import { Modal, Button, Form, Input,InputNumber, Select, DatePicker } from 'antd';


const { TextArea } = Input;
const RefinanciamientoStep2 = props => {
  const [loantype, setLoantype] = useState('');
  const [stateList,setStateList] = useState([]);
  const [statelistoptiondone,setStateListOptiondone] = useState(false);
  const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
  const incomeOption = [
    {
      value: '$20,000-$50,000',
      label: '$20,000-$50,000',
    },
    {
      value: '$50,000-$70,000',
      label: '$50,000-$70,000',
    },
    {
      value: '$70,000-$100,000',
      label: '$70,000-$100,000',
    },
    {
      value: '$100,000 o mas',
      label: '$100,000 o mas',
    },
  ]
  const savingOption = [
    {
      value: 'Menos de 10,000',
      label: 'Menos de 10,000',
    },
    {
      value: '$10,000 - $15,000',
      label: '$10,000 - $15,000',
    },
    {
      value: '$15,000 - $20,000',
      label: '$15,000 - $20,000',
    },
    {
      value: '$20,000 +',
      label: '$20,000 +',
    },
  ]
  const onNext = () => {
    props.form.validateFields(["companyname", "taxoption", "employmenttype", "income", "credit", "saving"]).then(() =>{
      var value = props.form.getFieldsValue()
      props.formdata.companyname = value.companyname;
      props.formdata.taxoption = value.taxoption;
      props.formdata.employmenttype = value.employmenttype;
      props.formdata.income = value.income;
      props.formdata.credit = value.credit;
      props.formdata.saving = value.saving;
      props.next()
    })
  }
  const onPrev = () => {
    props.prev()
  }
   return (
    <>
        <Form.Item
          label="Nombre de compania"
          name="companyname"
          className="form-item"
          rules={[
            {
              required: true,
              validator: (_, value) => {
                if(typeof(value) == "undefined" || !value){
                return Promise.reject(new Error('Entra nombre de compania'));
                }
                return Promise.resolve();
              },
            },
          ]}
        >
          <Input  placeholder="Entra nombre de compania" className="form-text" />
        </Form.Item>
        <Form.Item
            label="Tienes tax id o social? "
            name="taxoption"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
                options={[
                {
                  value: 'Tax ID',
                  label: 'Tax ID',
                },
                {
                  value: 'Seguro Social',
                  label: 'Seguro Social',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="¿ Cual describe mejor su empleo ?"
            name="employmenttype"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={[
                {
                  value: 'Empleado (W2)',
                  label: 'Empleado (W2)',
                },
                {
                  value: 'Dueño de su propio negocio ( 1099)',
                  label: 'Dueño de su propio negocio ( 1099)',
                },
                {
                  value: 'Ambos',
                  label: 'Ambos'
                }
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Ingresos"
            name="income"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={incomeOption}
            />
          </Form.Item>
          <Form.Item
            label="Puntuación de crédito"
            name="credit"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={[
                {
                  value: 'No se',
                  label: 'No se',
                },
                {
                  value: '500-620',
                  label: '500-620',
                },
                {
                  value: '620-700',
                  label: '620-700',
                },
                {
                  value: '700+',
                  label: '700+',
                }
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Ahorros"
            name="saving"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={savingOption}
            />
          </Form.Item>
          <Form.Item className="form-action">
            <Button className="prevstep" onClick={() => onPrev()}>Atrás</Button>
            <Button className="nextstep" type="primary" onClick={() => onNext()}>Siguiente</Button>
          </Form.Item>
      </>
   );
};

export default RefinanciamientoStep2;
