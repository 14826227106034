import React, { Component, useRef } from 'react';
import {  Form, Input, Button, InputNumber, Radio, Select } from 'antd';
import API from '../../utils/API';
const { TextArea } = Input;

class NewEnquiry extends Component {

  constructor(props) {
    super()
    this.props = props;
    this.state = {
      userdata: props?.userdata,
      loading: false,
      error: false,
      fullname: '',
      email: '',
      phonenumber: '',
      message: undefined,
    }
  }
  componentDidMount () {

  }
  makeAutocompleteOff = () => {
    const el = document.getElementsByClassName("ant-select-selection-search-input");
    for (let i = 0; i < el.length; i++) {
      el[i].setAttribute("autocomplete", "registration-select");
    }
  }
  onFinish = values => {
    this.setState({loading:true})
    if(typeof values.fullname === "undefined" || typeof values.email === "undefined" || typeof values.message === "undefined"){
      this.setState({error: true,loading:false})
    }else{
      values.dealid = this.props.dealid
      var dealEnquiryData = {
        values: values
      };
      API.post('/deals/enquiry/add', dealEnquiryData, {
        headers: { Authorization: `Bearer ${this.state?.userdata?.token}` },
      })
      .then(result => {
        if(result?.data?.msg === "done"){
          this.props.getModalResponse(false)
        }
      })
    }
  };

  render(){
    return (
      <Form onFinish = {this.onFinish} className='newpropertform'>
        <div className="header"><h2>Pregunta</h2></div>
        {this.state.error && <div className='error'>All * marked field are required.</div>}
        <div className='form-label'>Entra tu nombre completo<span className='red'>*</span></div>
        <Form.Item name="fullname">
            <Input placeholder="Entra tu nombre completo" className='inputbox' onFocus={(e) =>{
                      this.makeAutocompleteOff();
                    }}/>
        </Form.Item>
        <div className='form-label'>Entra tu correo electronico<span className='red'>*</span></div>
        <Form.Item name="email">
            <Input placeholder="Ingrese su ID de correo electrónico" className='inputbox' onFocus={(e) =>{
                      this.makeAutocompleteOff();
                    }}/>
        </Form.Item>
        <div className='form-label'>Envia Mensaje<span className='red'>*</span></div>
        <Form.Item name="message">
            <TextArea rows={4} placeholder="Escribe tu mensaje" className='inputbox'/>
        </Form.Item>

        <Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="submit"
                loading={this.state.loading}
            >
                Enviar
            </Button>
        </Form.Item>
        
      </Form>
    );
  }
};
export default NewEnquiry;
