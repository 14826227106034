import React, {  useState } from 'react';
import { Modal, Skeleton } from 'antd';
import API from '../../utils/API';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import RoleName from '../../components/RolesName/RolesName';
import Moment from 'moment';
import { Link } from 'react-router-dom';
import './css/dashboardforums.css';
import './css/dashboardglobal.css';
import noavtar from '../../assets/no-avatar.png';
import Linkify from 'react-linkify';

const ForumsViewBlock = () => {

  const [topicdata, setTopicData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  
  React.useEffect(()=>{
      API.get('/topics/all/user')
      .then(result => {      
      if(result.data){
         setTopicData(result.data);
         setLoading(false);
      }
      })
      .catch(e => {
      Modal.error({
         title: 'Ocurrió un error',
         content: e.message,
      });
      });
   },[]);
  return (
    <div className="forumsviewblock viewblockcontent">
               <h2 className="blocktitle">Forums</h2>
               <div className="viewcontent">
               {loading ? (
            <div className="loading">
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
            </div>
          ) : (
                topicdata.map((topic, index) => {
                  
                   if(topic.author != null){     
                     return <div className="viewrow"  key={topic.shortid}>
                        <div className="userinformation">
                           <div className="userinfo">
                              <div className="userpic">
                                 <Link to={topic.author?.pathalias?.alias}>
                                    <ProfileImage id={topic.author._id}/>
                                 </Link>
                              </div>
                              <div className="username">
                                 <div className="title"><h2><Link to={topic.author?.pathalias?.alias}>{topic.author.firstname} {topic.author.lastname}</Link></h2></div>
                                 <div className="category">{topic.posts[0]?.author?.userinfoid?.roles && <RoleName id = {topic.posts[0].author?.userinfoid?.roles}/>}</div>
                              </div>
                           </div>
                           <div className="createdby">
                              <div className="created">
                                 <label>Publicado por:</label>
                                 <span className="authername">{topic.author.firstname} {topic.author.lastname}</span>
                              </div>
                              <div className="date">{Moment(topic.createdAt).format('MMM D, YYYY')}</div>
                           </div>
                        </div>
                        <div className="description">
                        <div className="title"><Link to={"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)}>{topic.title}</Link></div>
                           <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >{topic.posts[0]?.message}</Linkify>
                        </div>
                     </div>
                   }
                  })
               )}
               </div>
               <div className="viewall"><Link to="/forums">Ver todo</Link></div>
            </div>
  );
};

export default ForumsViewBlock;
