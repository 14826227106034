import React from 'react';
import { useLocation, matchPath } from 'react-router-dom';
import styles from './Page.module.css';
import RedlineNotification from '../RedLine/RedlineNotification';

const Page = props => {
  const location = useLocation();
  const match = matchPath(location.pathname, {
    path: ['/help-us'],
    exact: true,
    strict: false
  })
  const headerhide = matchPath(location.search, {
    path: ['?forapp=1'],
    exact: true,
    strict: false
  })
  return (
    <div className={(match || headerhide)? styles.PageLanding : styles.Page } >
      <div className={styles.MainLayout}>
        <RedlineNotification/>
        {props.children}
      </div>
    </div>
  );
};

export default Page;
