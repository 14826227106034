import React, { Component} from "react";
import {  Button,Modal, Form, Input } from "antd";
import API from '../../utils/API';
import Page from "../../components/Page/Page";
import { Redirect } from 'react-router';
import Realtor from '../../assets/images/Realtor-bro.svg';
import Endpoint from '../../assets/images/Endpoint-rafiki.svg';
import Freepikcoin from '../../assets/images/freepikcoin.svg';
import './login.css';

class ForgetModalForm extends Component {
  token = this.props.match.params.token;
  state = {
    validate: 0,
    name: '',
    token: this.props.match.params.token,
  }
  componentDidMount(){
    this.getValidate()
  }
  getValidate(){
    API.get('/users/token/'+this.state.token)
    .then(result => {
  
      if(result.data != 'null') {
  
        this.setState({name:result.data.firstname+' '+result.data.lastname })
      }
    })
    .catch(e => {
      this.setState({token:'0'});
      Modal.error({
        title: 'Enlace expirado',
        centered: "true"
      });
    });
  }
  onFinishRequest = values => {
    API.post('/users/password/reset', {
      password: values.password,
      token: this.state.token
    })
    .then(result => {
      if(result.data != 'null'){
        this.setState({token:0})
        Modal.success({
          content: "Contraseña Establecida",
        });
      }
    })
    .catch(e => {
      Modal.error({
        title: 'Contraseña Establecida',
        content: e.response.data.msg,
      });
    });
  };
  render(){
    if(this.state.token == "0"){
      return <Redirect to='/login'/>; 
    }
    return (
      <Page>
        <div className="signinpage fpass">
          <div className="signupwrapper">
            <div className="signupintroduction">
              <h3>Bienvenido {this.state.name+' '}to,</h3>
              <h1>En La Cancha</h1>
              <div className="realtorimagebox">
                  <div className="realtor1"><img width="180" src={Realtor} /></div>
                  <div className="realtor2"><img width="480" src={Endpoint} /></div>
                  <div className="realtor3"><img width="170" src={Freepikcoin}/></div>
              </div>
            </div>

            <div className="registration-form-wrap">
              <div className="registration-form">
                <h2>Establecida la contraseña</h2>
                <Form
                  name="Iniciar sesión"
                  onFinish={this.onFinishRequest}
                  layout="vertical"
                >
                <Form.Item
                    label="Nueva contraseña"
                    name="password"
                    className="formitem"
                    rules={[
                      {
                        required: true,
                        message: 'La contraseña debe contener letras y numeros con un limite de 8 caracteres',
                        min: 8,
                      },
                    ]}
                  >
                    <Input.Password  placeholder="Introduzca una nueva contraseña" className="formtext"/>
                  </Form.Item>
                  <Form.Item
                    name="confirm"
                    label="Ingrese y confirme la nueva contraseña"
                    dependencies={['password']}
                    hasFeedback
                    rules={[
                      {
                        required: true,
                        message: '¡Confirme su nueva contraseña!',
                      },
                      ({ getFieldValue }) => ({
                        validator(_, value) {
                          if (!value || getFieldValue('password') === value) {
                            return Promise.resolve();
                          }
                            return Promise.reject(new Error('¡Las dos contraseñas que ingresó no coinciden!'));
                          },
                        }),
                      ]}
                  >
                  <Input.Password placeholder="Ingrese y confirme la nueva contraseña"/>
                  </Form.Item>
                  <Form.Item
                    className="form-action"
                  >
                  <Button
                      type="primary"
                      htmlType="submit"
                      className="form-submit"
                    >
                   Guardar e iniciar sesión
                    </Button>
                  </Form.Item>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </Page>
    );
  }
};

export default ForgetModalForm;
