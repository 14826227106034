import React, { useState, useEffect } from 'react';
import API from '../../utils/API';
import ProfileImage from '../WidgetBar/ProfileImage';
import { Link } from 'react-router-dom';
import convertDate from '../../utils/convertDate';
import noavtar from '../../assets/no-avatar.png';


const ConnectionNotificationItem = (props) => {
  const [alias,setAlias] = useState('');
  const fetchData = (id) => {
    API.get('/users/'+id).then(async result => {
      setAlias(result.data.user.pathalias.alias);
    }).catch(err=> {
      console.log(err);
    })
   }

   useEffect(()=> {  
      fetchData(props?.notifyitem?.author._id)     
   },[props?.notifyitem?.author._id])

  return (
    <>
    <Link to={alias} onClick={() => window.location.href = alias}><div>
              <span className={"picbox "+ (props.notifyitem?.notificationtype)}><ProfileImage id={props.notifyitem?.author._id}/></span>
                <span>
                  <div>
                    <strong className="name-s">{props.notifyitem?.author?.firstname} {props.notifyitem?.author?.lastname}</strong>
                    {props.notifyitem?.notificationtype == 'accepted'
                    ? 
                      <span> aceptó tu solicitud de conexion</span>
                    : null
                    }
                    {props.notifyitem?.notificationtype == 'request'
                      ? 
                        <span> quiere ser tu conexión</span>
                      : null
                    }
                  </div>
                  <div className="timelog">{convertDate(props.notifyitem.createdAt)}</div>
                </span>
              </div>
              </Link>
      
    </>
  );
};

export default ConnectionNotificationItem;
