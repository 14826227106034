import React from 'react';
/* import { NavLink, useLocation, matchPath } from 'react-router-dom'; */

import './redline.css';
const RedlineNotification = (props) => {
    /* const location = useLocation();
    const sep = location.pathname.split("/")
    const match = matchPath(sep[1], {
        path: ['deals'],
        exact: true,
        strict: false
    }) */
    //use class hide-marquee to hide
    return (
        <div className='red-wrapper'>
            <marquee>Es su responsabilidad verificar y realizar sus propias diligencias.</marquee>
             {/* <marquee>MASTERCLASS - Charlotte Nc - Mayo 11 - Tickets - <a href="http://inversionistasusa.com" target="_blank">CLICK AQUI</a></marquee> */}
        </div>
    )
}

export default RedlineNotification;