import React, { useState, useContext,useEffect } from 'react';
import styles from './AddPostForm.module.css';
import {  Input, Button } from 'antd';
import { AuthContext } from '../../App';
import { Link } from 'react-router-dom';
import { Mention, MentionsInput } from 'react-mentions'
import merge from 'lodash/merge';
import API from '../../utils/API';

// antd
const { TextArea } = Input;

const AddPostForm = props => {
  const [message, setMessage] = useState('');
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const [showbutton, setShowButton] = useState(false);
  const [buttonDisable, setButtonDisable] = useState(true);
  const [allUserList,setAllUserList] = useState([])
  useEffect(() => {
    fetchTagData();
  }, []);

  const fetchTagData = () => {
    API.get('/users/tagged/fetchtata')
    .then(result => {
      let userList = [...allUserList]
      result?.data?.users.map(u =>{
        let fullName=  u?.firstname +' '+u?.lastname;
        let ids = u?._id;
        let exists = userList.some(el => el.id === ids);
        if(!exists) {
          userList.push({id: ids, display: fullName})
        }
      })
      //update list with post's user list
      props.usersList.map((u,index) =>{
        let exists = userList.some(el => el.id === u?.id);
        if(!exists) {
          userList.push({id: u?.id, display: u?.display})
        }
        if(index+1 == props.usersList?.length){
          setAllUserList(userList)
        }
      })
    })
    .catch(e => {
     console.log(e.message)
    });
  };
  const mentionStyle = {backgroundColor: "#fff"}
  let customStyle = merge({}, mentionStyle, {
    input: {
      height: 80,
      overflow: "auto",
    },
    highlighter: {
      height: 80,
      overflow: "hidden",
      boxSizing: "border-box",
    },
    control: {
      backgroundColor: '#fff',
      fontSize: 14,
      fontWeight: 'normal',
    },
  
    '&multiLine': {
      control: {
        fontFamily: 'monospace',
        minHeight: 63,
      },
      highlighter: {
        padding: 9,
        border: '1px solid transparent',
      },
      input: {
        padding: 9,
        border: '1px solid transparent',
      },
    },
  
    '&singleLine': {
      display: 'inline-block',
      width: 180,
  
      highlighter: {
        padding: 1,
        border: '2px inset transparent',
      },
      input: {
        padding: 1,
        border: '2px inset',
      },
    },
  
    suggestions: {
      list: {
        backgroundColor: 'white',
        border: '1px solid rgba(0,0,0,0.15)',
        fontSize: 14,
      },
      item: {
        padding: '5px 15px',
        borderBottom: '1px solid rgba(0,0,0,0.15)',
        '&focused': {
          backgroundColor: '#cee4e5',
        },
      },
    },
  });
  if (state.isAuthenticated) {
    return (
      <div className='comment-form'>
          <MentionsInput style={customStyle}
					placeholder="Escribe un comentario. Utilice @ para mencionar a alguien"
					value={message}
					onChange={e => {
            setMessage(e.target.value);
            if(e.target.value.trim().length > 0){
              setButtonDisable(false);
              setShowButton(true);
            }else{
              setButtonDisable(true);
            }
          }}
          onFocus ={ e => {
            setShowButton(true)
          }}
          onBlur = {
            e =>{
              if(message.trim().length == 0) setShowButton(false)
            }
          }
					a11ySuggestionsListLabel={'Suggested mentions'}
				>
          <Mention
            trigger="@"
            data={allUserList}
            markup='@@@____id__^^____display__@@@^^^'
          />
				</MentionsInput>
           {showbutton ? <Button type="primary" disabled = {buttonDisable} onClick={(e) => {
            setShowButton(false);
            setButtonDisable(true);
              props.postReply(e, message, setMessage, loading, setLoading, props.topic, props.detailPageUrl, props.receiverId);
            }}>Post</Button> : ''}
      </div>
    );
  }

  return (
    <div className={styles.AddPost}>
      <div className={styles.AuthMessage}>
        Debes iniciar sesión para responder a este tema.
        <br />
        <Link to="/login">Iniciar sesión</Link> | <Link to="/pricing">Suscríbete</Link>
      </div>
    </div>
  );
};

export default AddPostForm;
