import React from "react";
import Page from "../../components/Page/Page";
import { Link } from "react-router-dom";
import { Carousel, Modal } from "antd";
import "./booklist.css";
import "./AllBooks.css";
import API from "../../utils/API";
import BookImage from "../../components/BooksData/BookImage";

const AllBooks = (props) => {
  const [books, setBooks] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [active, setActive] = React.useState();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const titlecase = (word) => {
    if (word !== undefined) {
      return word
        .split(" ")
        .map((w) => w[0]?.toUpperCase() + w?.substr(1).toLowerCase())
        .join(" ");
    }
  };
  const onCatChange = (catId) => {
    setActive(catId);
    const arr = [];
    arr.push(catId);
    API.get("/books/bycategory/" + arr)
      .then((result) => {
        setBooks(
          result.data.books.sort((a, b) => {
            // equal items sort equally
            if (a.bookOrder === b.bookOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.bookOrder === null) {
              return 1;
            } else if (b.bookOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.bookOrder < b.bookOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.bookOrder < b.bookOrder ? 1 : -1;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  React.useEffect(() => {
    API.get(`/books/`)
      .then((result) => {
        setBooks(
          result.data.books.sort((a, b) => {
            // equal items sort equally
            if (a.bookOrder === b.bookOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.bookOrder === null) {
              return 1;
            } else if (b.bookOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.bookOrder < b.bookOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.bookOrder < b.bookOrder ? 1 : -1;
            }
          })
        );
        API.get("/categories/getcategories")
          .then((resultc) => {
            const arr = [];
            resultc.data.category.map((name) => {
              for (let i = 0; i < result.data.books.length; i++) {
                if (result.data.books[i].selectedCategory[0] !== undefined) {
                  if (result.data.books[i].selectedCategory[0]._id == name._id) {
                    const cat = name.name;
                    const _id = name._id;

                    arr.push({ name: cat, _id: _id });
                  }
                }
              }
            });
            const filteredArr = arr.reduce((acc, current) => {
              const x = acc.find((item) => item._id === current._id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setCategory(filteredArr);
          })
          .catch((e) => {
            Modal.error({
              title: "Ocurrió un error",
              content: e.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  return (
    <Page>
      <div className="allbookspage maincontent">
        <div className="vieweducationfilter">
          <div className="vieweducationcontent">
            {/* {state.isAuthenticated && (
                     <div className="userpic"><ProfileImage id={state.user.id}/></div>
                  )}
                  <div className="searchbox">
                     <Form>
                        <Form.Item
                           name="name"
                           className="form-item search-filter"
                        >
                           <Input className="formtext" placeholder="Búsqueda por nombre, palabras clave" />
                        </Form.Item>
                        <Form.Item className="form-action"> 
                           <Button type="primary" htmlType="submit" className="form-submit"> 
                           Submit
                           </Button>
                        </Form.Item>
                     </Form>
                  </div> */}
            <div className="searchtabs">
              <ul>
                <li>
                  <Link to="/education/videocourses">ENTRENAMIENTOS</Link>
                </li>
                <li>
                  <Link to="/education/webinar">WEBINARS</Link>
                </li>
                <li>
                  <Link to="/education/tipoftheweek">Jugadas en la cancha</Link>
                </li>
            

                <li>
                  <Link to="/education/podcasts">Podcasts</Link>
                </li>
                <li>
                  <Link to="/education/blogs">Blogs</Link>
                </li>
                <li className="active">
                  <Link to="/education/books">Libros</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="educationlistfilterslider">
          <div className="viewcontent">
            <Carousel {...settings}>
              {category.map((catName) => (
                <div className="viewrow">
                  <a
                    style={{ color: active == catName._id ? "black" : "" }}
                    onClick={() => onCatChange(catName._id)}
                  >
                    {catName.name}
                  </a>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="breadcrumbwrapper">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/education">Educacion</Link>
              </li>
              <li>Libros</li>
            </ul>
          </div>
        </div>
        <div className="viewbooklist allbookslist">
          <div className="viewbook">
            {books.length ? (
              <div className="viewcontent">
                {books.map((book) => (
                  <Link to={book.pathalias.alias} className="viewrow">
                    <div className="bookcontent">
                      <div className="imagebox">
                        <BookImage id={book._id} />
                      </div>
                      <div className="content">
                        {String(book.bookname).length >71?<h2 className="title">
                          <Link to={book.pathalias.alias} title={book.title}>
                            {book.bookname.substring(0, 70)}...
                          </Link>
                        </h2>:<h2 className="title">
                          <Link to={book.pathalias.alias} title={book.title}>
                            {book.bookname}
                          </Link>
                        </h2>}
                        {book.artistname && (
                          <div className="category">
                            {titlecase(book.artistname.substring(0, 25).trim())}
                          </div>
                        )}
                        {book.description && (
                          <div className="description">
                            {book.description.substring(0, 170).trim()}
                          </div>
                        )}
                        {/*<div className="sharelink">
                              <ul>
                                 <li className="bookmark"><a href="#">bookmark</a></li>
                                 <li className="share"><a href="#">share</a></li> 
                              </ul>
                              </div>
                              */}
                      </div>
                    </div>
                    {/*<div className = "categoryname" ><CategoryName id = {book?.selectedCategory[0]}/></div>*/}
                  </Link>
                ))}
              </div>
            ) : (
              <div className="catnotfound">
                ¡¡No se han encontrado resultados!!
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
export default AllBooks;
