import React from 'react';
import image01 from '../../assets/images/image1.svg';
import image02 from '../../assets/images/image002.svg';
import image03 from '../../assets/images/image3.svg';
/* import image04 from '../../assets/images/image4.svg'; */
import { Link } from 'react-router-dom';
import './EducationBlock.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const EducationBlock = props => {
  return (
    <div className="vieweducationlisting">
    <div className="viewrow">
       <div className="viewcontent">
          <div className="body">
            <div className="description">
               <h2>EDUCATE EN LA CANCHA</h2>
               Aprendiendo las estrategias de inversión En La Cancha vas a poder identificar e invertir en oportunidades de inversión en tu mercado
            </div>
            <div className="joinbutton"><Link to="/education" className="join">APRENDE LAS ESTRATEGIAS</Link></div>
          </div>
          <div className="imagebox">
            <LazyLoadImage
              alt="article"
              effect="blur"
              src={image01}
             />
            </div>
       </div>
    </div>
    <div className="viewrow">
       <div className="viewcontent">
          <div className="body">
             <div className="description">
             <h2>Construye Tu Equipo</h2>
             Unete a la primera comunidad nacional de inversionistas de bienes raíces  donde vas a poder construir tu equipo de expertos para que tengas éxito en la cancha
             </div>
             <div className="joinbutton"><Link to="/network" className="join">CONSTRUYE TU EQUIPO</Link></div>
          </div>
          <div className="imagebox">
            <LazyLoadImage
              alt="article"
              effect="blur"
              src={image02}
             />
            </div>
       </div>
    </div>
    <div className="viewrow">
       <div className="viewcontent">
          <div className="body">
             <div className="description">
             <h2>OBTEN RESPUESTAS A TODAS TUS PREGUNTAS</h2>
             Haz todas tus preguntas a inversionistas activamente metiendo goles En La Cancha
             </div>
             <div className="joinbutton"><Link to="/forums" className="join">PREGUNTA AHORA</Link></div>
          </div>
          <div className="imagebox">
            <LazyLoadImage
              alt="article"
              effect="blur"
              src={image03}
             />
            </div>
       </div>
    </div>
    {/* <div className="viewrow">
       <div className="viewcontent">
          <div className="body">
             <div className="description">
             <h2>Mujeres empoderadas</h2>
             Únete a la comunidad de mujeres inversionistas de bienes raíces a nivel nacional y aprende de emprendedoras de bienes raíces en la cancha
             </div>
             <div className="comming">Próximamente prrronto...</div>
          </div>
          <div className="imagebox">
            <LazyLoadImage
              alt="article"
              effect="blur"
              src={image04}
            />
            </div>
       </div>
    </div> */}
 </div>
  );
};

export default EducationBlock;
