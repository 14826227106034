import React, { useEffect, useState } from 'react';
import './loaninterested.css';
import { Card, Table, Modal, Button, Form, Input,InputNumber, Divider } from 'antd';
import API from '../../utils/API';
import { NavLink } from 'react-router-dom/cjs/react-router-dom';
import Moment from  'moment'
//const { TextArea } = Input;

const LoanInterested = props => {
  const [tabledata, setTabledata] = useState([])
  const [tabledatanewloan, setTabledataNewloan] = useState([])
  //const [loading, setLoading] = useState(false);
  const [showInterested, setShowInterested] = useState(false)
  const [showRequested, setShowRequested] = useState(true)
  //const [showLenderifno, setShowLenderIfno] = useState(false)
  //const [cname, setCname] = useState('')
  //const [companyname, setCompanyname] = useState('')
  //const [cemail, setCemail] = useState('')
  //const [companyaddress, setCompanyaddress] = useState('')
  //const [makeloan, setMakeloan] = useState('')
  //const [officeemail, setOfficeemail] = useState('')
  //const [officephone, setOfficephone] = useState('')
  //const [telefono, setTelefono] = useState('')
  //const [serviceprovidevalue, setServiceprovidevalue] = useState([])
  //const [loantypevalue, setLoantypevalue] = useState([])
  //const [minimumloanvalue, setMinimumloanvalue] = useState('')
  const [commission, setCommission] = useState('')
  const [open,setOpen] = useState(false);
  const [form] = Form.useForm();
  const [activeData, setActiveData] = useState({})
  const columns = [
    {
      title: "Detalles del cliente",
      dataIndex: 'client',
      key: 'client',
    },
    {
      title: 'Estado del préstamo',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date'
    },
    {
      title: 'Transacción cerrada?',
      dataIndex: 'action',
      key: 'action',
    },
    {
      title: 'Detalles',
      'dataIndex': 'detail',
      key: 'detail',
    }
  ];
  /* const onChangeTransaction = (s, id,index, lrnid) => {
    API.post('users/l/transact',{transact: s, i:index,id:id, lid:props?.id, lrnid:lrnid})
    .then(res =>{
      fetchdataInterested();
      console.log(res)
    })
    .catch()
  }; */
  const onFinish = value => {
    console.log(value);
    API.post('users/l/transact',{transact: activeData.status, i:activeData.index,id:activeData.linterestedid, lid:props?.id, lrnid:activeData.lrnid, loanamount: value.approveamount, commission:commission})
    .then(res =>{
      setActiveData({})
      setOpen(false)
      fetchdataInterested();
      form.resetFields();
      console.log(res)
    })
    .catch()
  }
  /* const lenderInfoClick = () => {
    setShowLenderIfno(true)
    setShowInterested(false)
    setShowRequested(false)
  } */
  const interestedClick = () =>{
    setShowInterested(true)
    setShowRequested(false)
    //setShowLenderIfno(false)
  }
  const requestedClick = () => {
    setShowRequested(true)
    setShowInterested(false)
    //setShowLenderIfno(false)
    
  }
  const onUpdateHandle = (status,linterestedid, index, lrnid  ) =>{
    setActiveData({status,linterestedid, index, lid:props?.id, lrnid})
    setOpen(true)
  }
  const onModalClose =() =>{
    setActiveData({})
    form.resetFields();
    setOpen(false)
    
  }

  useEffect(()=>{
    fetchdataInterested();
  },[props?.id])

  function fetchdataInterested () {
    API.get('users/fetchtata/l/i/'+props?.id).then(result =>{
        //setCname(result?.data?.ldata?.cname)
        //setCompanyname(result?.data?.ldata?.companyname)
        //setCemail(result?.data?.ldata?.cemail)
        //setCompanyaddress(result?.data?.ldata?.companyaddress)
        //setMakeloan(result?.data?.ldata?.makeloan)
        //setOfficeemail(result?.data?.ldata?.officeemail)
        //setOfficephone(result?.data?.ldata?.officephone)
        //setTelefono(result?.data?.ldata?.telefono)
        //setServiceprovidevalue(result?.data?.ldata?.serviceprovidevalue)
        //setLoantypevalue(result?.data?.ldata?.loantypevalue)
        //setMinimumloanvalue(result?.data?.ldata?.minimumloanvalue)
        setCommission(result?.data?.ldata?.commission)
      let d = [];
      let newRequest = [];
      result?.data?.ldata?.interestedLoan.sort(function(a,b){
        return new Date(b.lrnid.createdAt) - new Date(a.lrnid.createdAt);
      });
      result?.data?.ldata?.interestedLoan?.map((lr,i) =>{
        d.push({
          key: i,
          client: <><div>Nombre: {lr?.lrnid?.fullname}</div>
          <div>Correó electrónico: {lr?.lrnid?.author?.email}</div>
          <div>Teléfono: {lr?.lrnid?.telephonenumber}</div>
          <div>Nombre de la compañía: {lr?.lrnid?.companyname}</div></>,
          status: <span className='text-cap'>{lr?.lrnid?.status == 'new' ? 'Nuevo' : lr?.lrnid?.status == 'active' ? 'Activo': lr?.lrnid?.status == 'close'? 'Cerrado': ''}</span>,
          date: <span className='text-cap'>{Moment(lr?.lrnid?.createdAt).format('DD-MMM-YYYY')}</span>,
          action: <>
            <div><strong>{lr?.transaction.charAt(0).toUpperCase() + lr?.transaction.slice(1)}</strong>
            {lr?.loanamount && <div>${lr?.loanamount} approved at {lr?.commission}% Comision</div>}
            </div>
            {(lr?.transaction == 'no' && lr?.lrnid?.status == 'active') && <>
              <Button onClick={()=> onUpdateHandle('Si',lr._id,i, lr.lrnid._id)}>Actualizar</Button></>}
            <Divider type="vertical" />
            </>,
            detail: <NavLink to={lr.pageurl} className="request-btn">Ver detalles</NavLink>
        });
        if(result.data.ldata.interestedLoan.length == i+1){
          //setLoading(true);
          setTabledata(d);
        }
      })
      //GET new loan request
      result?.data?.ldata?.invitedLoan.sort(function(a,b){
        return new Date(b.lrnid.createdAt) - new Date(a.lrnid.createdAt);
      });
      result?.data?.ldata?.invitedLoan?.map((lr,i) =>{
        if(lr?.lrnid?.status == 'new'){
          newRequest.push({
            key: i,
            client: <><div>Nombre: {lr?.lrnid?.fullname}</div></>,
            status: <span className="text-cap">{lr?.lrnid?.status == 'new' ? 'Nuevo' : lr?.lrnid?.status == 'active' ? 'Activo': lr?.lrnid?.status == 'close'? 'Cerrado': ''}</span>,
            date:<span className="text-cap">{Moment(lr?.lrnid?.createdAt).format('DD-MMM-YYYY')}</span>,
            action: <>
              <div><strong>{lr?.transaction.charAt(0).toUpperCase() + lr?.transaction.slice(1)}</strong>
              </div>
              </>,
            detail: <NavLink to={lr.pageurl} className="request-btn">Ver detalles</NavLink>
          });
        }
        
        if(result.data.ldata.invitedLoan.length == i+1){
          //setLoading(true);
          setTabledataNewloan(newRequest);
        }
      })
    }).catch((e) =>{
      console.log('error show',e)
    })
  }
      return (
        <div className='loaninterestedblock'>
          <div className='button-switcher'>
            <Button className={showRequested && 'active' } onClick={() => requestedClick()}>Nueva solicitud</Button>
            <Button className={showInterested && 'active' } onClick={() => interestedClick()}>Solicitudes Aceptadas</Button>
            {/* <span className='move-to-right'><Button className={showLenderifno && 'active' } onClick={() => lenderInfoClick()}>Lender Info</Button></span> */}
          </div>
          {showRequested &&
            <Card title="Nueva solicitud">
            <Table 
              dataSource={tabledatanewloan}
              columns={columns}
              className='responsive-table'
              pagination={{
                position: ['none', 'bottomCenter'],
                pageSize:10,
              }}
              />;
            </Card>
          }
          {showInterested && <Card title="Cliente que has aceptado">
          <Table 
            dataSource={tabledata}
            columns={columns}
            className='responsive-table'
            pagination={{
              position: ['none', 'bottomCenter'],
              pageSize:10,
            }}
          />;
          </Card>}
          {/* {showLenderifno && <div className='lender-profile-view'>
            <div className="lender-profile-item">
                <span>Nombre y apellido de persona de contacto: </span>
                <span>{cname}</span>
            </div>
            <div className="lender-profile-item">
                <span>Nombre de la compañía: </span>
                <span>{companyname}</span>
            </div>
            <div className="lender-profile-item">
                <span>Telefono: </span>
                <span>{telefono}</span>
            </div>
            <div className="lender-profile-item">
                <span>Email: </span>
                <span>{cemail}</span>
            </div>
            <div className="lender-profile-item">
                <span>Direccion de su  compañía: </span>
                <span>{companyaddress}</span>
            </div>
            <div className="lender-profile-item">
                <span>Numero telefonico de oficina: </span>
                <span>{officephone}</span>
            </div>
            <div className="lender-profile-item">
                <span>Email de oficina: </span>
                <span>{officeemail}</span>
            </div>
            <div className="lender-profile-item">
                <span>¿Estados en los  que provee sus servicios?: </span>
                <span>{serviceprovidevalue.join(', ')}</span>
            </div>
            {makeloan && <div className="lender-profile-item">
                <span>Realiza prestamos con Tax ID?: </span>
                <span>{makeloan}</span>
            </div>}
            <div className="lender-profile-item">
                <span>Tipos de prestamos</span>
                <span>{loantypevalue.join(', ')}</span>
            </div>
            <div className="lender-profile-item">
                <span>Cual es el prestamo minimo que puede originar: </span>
                <span>{minimumloanvalue}</span>
            </div>
            <div className="lender-profile-item">
                <span>Comision: </span>
                <span>{commission} %</span>
            </div>
          </div>} */}
          <Modal
            visible={open}
            footer={null}
            onCancel={(e)=>onModalClose()}
            destroyOnClose={true}>
              <Form
                form={form}
                layout="vertical"
                name="approvedload"
                onFinish={onFinish}
              >
              <Form.Item
                  label="Monto de prestamo"
                  name="approveamount"
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if(typeof(value) == "undefined" || !value){
                        return Promise.reject(new Error('Enter Loan amount'));
                        }
                        return Promise.resolve();
                      },
                    },
                  ]}
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                  />
                </Form.Item>
                <Form.Item className="form-action">
                <Button
                    type="primary"
                    htmlType="submit"
                    className="form-submit"
                  >
                  Guardar
                  </Button>
                </Form.Item>
                </Form>
          </Modal>
        </div>
      );
  }
  export default LoanInterested;