import React, { useState, useEffect } from 'react';
import {Skeleton} from 'antd';
import API from '../../utils/API';
import noavtar from '../../assets/no-avatar.png';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const ProfileImage = props => {

  const [loadings, setLoadings] = useState(true);
  const [profileImage, setProfileImg] = useState('');

  useEffect( ()=> {
    let isMounted = true;
    if(props.id){
        API.get(`/userinfo/getimageprofile/?userid=${props.id}`).then(result => {
          if(result?.data?.base64 && result?.data?.mstype == 'basic'){
            if(isMounted){
              setProfileImg(
                <><LazyLoadImage
                alt="profile"
                effect="blur"
                height="60px"
                width="60px"
                className={"img " +(props.classtouse? props.classtouse : '')+(result?.data?.mstype == 'basic' ? ' pro ': '')}
                src={result?.data?.base64}
               />
               <div className='pro-icon'></div></>
              )
              setLoadings(false);
            }
          }else if(result?.data?.base64){
            if(isMounted){
              setProfileImg(
                <LazyLoadImage
                alt="profile"
                effect="blur"
                height="60px"
                width="60px"
                className={"img " +(props.classtouse? props.classtouse : '')}
                src={result?.data?.base64}
               />
              )
              setLoadings(false);
            }
          } else if(result?.data?.mstype == 'basic'){
            if(isMounted){
              setProfileImg(<><img  alt="" className={"img "+(props.classtouse? props.classtouse : '')+(result?.data?.mstype == 'basic' ? ' pro ': '')} src={noavtar} />
              <div className='pro-icon'></div></>);
              setLoadings(false);
            }
          }else {
            if(isMounted){
              setProfileImg(<img  alt="" className={"img "+(props.classtouse? props.classtouse : '')} src={noavtar} />);
              setLoadings(false);
            }
          }
      }).catch(err => {
        setProfileImg(<img  alt="" className="img" src={noavtar} />);
        setLoadings(false);
          return
      })
    }
   return () => { isMounted = false }; 
  },[props.id]);

  return (
    <div>
      {loadings ? (
        <Skeleton.Avatar className="skeletonk" active avatar shape="circle"/>
      ) : (
        <div>{profileImage}</div>
      )}
    </div>
    
    
    
  );
};

export default ProfileImage;
