import React from 'react';
import './blogslist.css';
import API from '../../utils/API';
import { Carousel } from "antd";
import {Link} from 'react-router-dom'
import BlogImage from '../../components/BlogData/BlogImage';
const BlogsListing = (props) => {
   const [blogs,setBlogs] = React.useState([])
   const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
             breakpoint: 1119,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1,
             }
         },
         {
             breakpoint: 767,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         },
         {
             breakpoint: 480,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         }
      ]
   }
   React.useEffect(()=> {
      API.get(`/blogs/`).then(result=>{
  
      setBlogs(result.data.blogs.sort( (a, b)=> {
  
         // equal items sort equally
         if (a.blogOrder === b.blogOrder) {
             return 0;
         }
         // nulls sort after anything else
         else if (a.blogOrder === null) {
             return 1;
         }
         else if (b.blogOrder === null) {
             return -1;
         }
         // otherwise, if we're ascending, lowest sorts first
         else if (true) {
             return a.blogOrder < b.blogOrder ? -1 : 1;
         }
         // if descending, highest sorts first
         else { 
             return a.blogOrder < b.blogOrder ? 1 : -1;
         }
     
       }))
  
      })
     },[])
     const titlecase = (word) => {
      if(word !== undefined){
  return  word.split(' ')
  .map(w => w[0]?.toUpperCase() + w?.substr(1).toLowerCase())
  .join(' ')
  }
  }
  return (
     <div>
    {blogs.length ? <div className="viewblogslist">
         <div className="viewblogs">
            <h2>Blogs</h2>
            <div className="viewcontent">
               <Carousel {...settings} >
                 {blogs.map((blog, index) => <Link to={blog.pathalias.alias}  title={blog.title} className="viewrow" key={index}>
               
                     <div className="imagebox"><BlogImage id = {blog._id}/></div>
                     {String(blog.blogname).length > 71?<h2 className="title">{String(blog.blogname).substring(0,70)}...</h2>:<h2 className="title">{String(blog.blogname)}</h2>}
                     {/* <div className="category">{blog.artistname}</div>
                     <div className = "categoryname" ><CategoryName id = {blog?.selectedCategory[0]}/></div>
                     <div className="readtime">70
                       
                        <div className="sharelink">
                           <ul>
                              <li className="bookmark"><a href="#">bookmark</a></li>
                              <li className="share"><a href="#">share</a></li> 
                           </ul>
                        </div>
                     </div>
                     */}
                  </Link>)}
                
               </Carousel>
            </div>
            <div className="viewall"><Link to="/education/blogs">Ver todo</Link></div>
       
         </div>
      </div> : null}</div>
  );
};

export default BlogsListing;
