import React, { useState } from "react";
import API from "../../utils/API";
import { Link, useHistory } from "react-router-dom";
import "../Blogs/blogdetail.css";
import CategoryName from "../../components/CategoryName/CategoryName";
import SocialShareBlock from "../../components/SocialShareBlock/SocialShareBlock";
import "./tipoftheweekdetails.css";
import "../Education/education.css";
import ReactPlayer from "react-player";
import esLocale from "moment/locale/es";
import moment from 'moment'
import {Modal,Button} from 'antd';
import axios from 'axios';

const TipOfTheWeekDetails = (props) => {
  const [blogData, setBlogData] = React.useState();
  const [dataList,setDataList] = useState([])
  const [category,setCategory] = useState()
  const [index,setIndex] = useState(0)
  const [free,setFree] = useState()
  const history = useHistory()
  const [hidesubscribemodal,sethidesubscrubemodal] = useState(false)
  const [redirectUrl,setRedirectUrl] = useState(false)
 const [imgdata,setImgData] = useState([])
 const [imagePreview,setImagePriview] = useState({})
  React.useEffect(() => {
    moment.locale('es', [esLocale]);
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let id = JSON.parse(localStorage.getItem('user')).id;
        API.get('users/'+id).then(result=>{
          if(JSON.parse(localStorage.getItem('user')).level != 1){
            if(result.data != null && result.data.user.membershiprole != 'undefined'){
            if( result.data.user.membershiprole == 'free'){
                setFree('free')
                history.push('/education/tipoftheweek')
                sethidesubscrubemodal(true)
            }
            }
          } else{
              setFree('paid')
              sethidesubscrubemodal(false)
          }
      }).catch(e =>{
        console.log(e.message);
      });
   } else{
     history.push('/login')
      setRedirectUrl(true)
   }
    API.get("/tipoftheweek/contents/node/" + props.pageid).then((result) => {
      setBlogData(result.data.tipoftheweek[0]);
      createMarkup(result.data.tipoftheweek[0].description);
      setCategory(<CategoryName id={result.data.tipoftheweek[0]?.selectedCategory[0]._id}/>)
      API.get('/tipoftheweek/contents/limited')
      .then(resulto => {
        const datalists= resulto.data.tipoftheweek;  
       const index = datalists.findIndex( (element) => element?._id === result?.data?.tipoftheweek[0]?._id);
       setIndex(index)
         setDataList(datalists)
        const iddata = [];
        var that = this;
        let Vimeo = require('vimeo').Vimeo;
        //let client = new Vimeo("{client_id}", "{client_secret}", "{access_token}");
        let client = new Vimeo("721e84fa5be48b9b62e753ae08747a2f6f8d748d", "InC25cSCXjI9NdWvPPvjBX+akYs+qqny30FrRVyiJUPo2KjePXENmNEapS8gj3CVnO5SeOCjTetCiSNWW7GOgm8N99t8GtyaLaTHI0A4bLdDKYE6Dj4/w1yNqKtmdkxc", "36273e305fdd9a218b7b54380e2a1025");
        datalists.map((id,index,arr)=> {
          /* client.request({
            method: 'GET',
            path: '/videos/'+Number(id.videoURL)
            }, function (name, embed) {
              iddata[index] = embed?.pictures?.base_link;
              if(arr.length - 1 === index){
                  setImgData(iddata) 
              }
            }) */
          axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(id.videoURL)))
          .then(res => {
            const imageUrl = res.data.thumbnail_url.split('_');
            iddata[index] = imageUrl[0];
            if(arr.length - 1 === index){
              setImgData(iddata);            
            }
          })
        })
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    });
  }, []);

 const handleRedirect =  () =>{
    
  sethidesubscrubemodal(false)
    if(localStorage.getItem('token')){
      let path = '/pricing?='+blogData.pathalias.alias;
      history.push(path);
    } else{
    let path = '/login?='+blogData.pathalias.alias;
    history.push(path);
    }
  }
  const handleCancel = () => {
    sethidesubscrubemodal(false);
    this.props.history.push('/education')
    document.body.classList.remove('modalopen');
  };
  const cancleRedirect = () =>{
    history.push('/education')
    sethidesubscrubemodal(false)
    setRedirectUrl(false)
  }
  const createMarkup = (data) => {
    return { __html: data };
  };
const onSelectTip = (id) => {
  
   setBlogData(undefined)
   setCategory(undefined)
   API.get("/tipoftheweek/contents/node/" + id).then((result) => {

     setBlogData(result.data.tipoftheweek[0]);
     createMarkup(result.data.tipoftheweek[0].description);
     setCategory(<CategoryName id={result?.data?.tipoftheweek[0]?.selectedCategory[0]._id}/>)
     const index = dataList.findIndex( (element) => element?._id === result?.data?.tipoftheweek[0]?._id);
 
     setIndex(index)
   });
}
const onNext = (id) => {
   setBlogData([])
   setCategory(undefined)
   API.get("/tipoftheweek/contents/node/" + id).then((result) => {

     setBlogData(result.data.tipoftheweek[0]);
     createMarkup(result.data.tipoftheweek[0].description);
     setCategory(<CategoryName id={result.data.tipoftheweek[0]?.selectedCategory[0]._id}/>)

   });
}
  return (
    <div className="blogsdetailspage tipofweekdetails">
      {blogData && (
        <div className="blogsdetail">
         <div className="breadcrumbwrapper">
            <div className="breadcrumb">
               <ul style={{textAlign:'center',alignItems:'center',justifyContent:'center',margin:0}} >
                  <li><Link to='/education'>Educacion</Link></li>
                  <li><Link to="/education/tipoftheweek">Jugadas en la cancha</Link></li>
               </ul>
            </div>
            <div className="tipofweektitle">
              <div className="tipofweektitleinner">
                <div className="title">{blogData.title}</div>
             
              </div>
            </div>
         </div>
          <div className="tipofweekdetailcontent">
            <div className="blogsdetailcontent preview">
              <div className="tipvideo">
                <ReactPlayer
                  url={"https://vimeo.com/" + blogData.videoURL}
                  width="100%"
                  height="558px"
                  playing
                  light={true}
                  controls={true}
                />
              </div>
              {blogData?.publishedDate&& <div className="created" style={{marginTop:5}} >Publicado en {moment(blogData?.publishedDate).format('MMM D, YYYY')}</div>}
              {blogData?.artistname&& <div className="created" style={{marginTop:5}}>Publicado por: {String(blogData?.artistname).charAt(0).toUpperCase() + String(blogData?.artistname).slice(1)}</div>}
              <div className="courseshare">
                <ul>
                  <li className="share">
                    <SocialShareBlock
                      shareTitle={blogData.title}
                      shareUrl={window.location.href}
                    />
                  </li>
                </ul>
              </div>
              <div className="categoriesbox">
                <div className="categories">
                  <label>Categoría: </label>
                  <strong>
                   {category}
                  </strong>
                </div>
              </div>
              {blogData?.description&&<div className="title">
              Descripcion de la Jugadas en la cancha
              </div>}
              <div
                className="description"
                dangerouslySetInnerHTML={{ __html: blogData.description }}
              ></div>
              <div className="createdby">
                <div className="sharelink">
                  <ul>
                    {/* <li className="bookmark"><a href="#">bookmark</a></li>
                     <li className="share"><a href="#">share</a></li> */}
                  </ul>
                </div>
              </div>
              <Modal
         visible={hidesubscribemodal}
         okText="Actualizar membresía"
         onOk={handleRedirect}
         onCancel={cancleRedirect}
         cancelText="Cancelar"
         keyboard="false"
         centered="true"
         closable={false}
      >
         <div>Debe actualizar su membresía para ver los detalles de la Jugadas en la cancha</div>
      </Modal>
      <Modal
           visible={redirectUrl}
           okText="Iniciar sesión"
           onOk={handleRedirect}
           onCancel={handleCancel}
           cancelText="Suscríbete"
           keyboard="false"
           centered="true"
           closable={false}
        >
           <div className="emptymessage" >Debes iniciar sesión para ver este video</div>
        </Modal>
            </div>
            <div className="thumbnailpager">
              <div className="thumbnailpagerinner">
                {dataList.map( (tipdata,i) =>  <Link className={tipdata._id == blogData._id ? 'active' : ''} onClick={()=> onSelectTip(tipdata._id)} to={tipdata.pathalias.alias} > <div className="thumbnailcontent" style={{backgroundColor:blogData._id == tipdata._id?'#EBEBEB':''}}>
                  <div className="leftcontent">
                  {<div>{imgdata[i] && <img src={imgdata[i]+'_110x90'} />}</div>}
                  </div>
                  <div className="rightcontent"><Link to={tipdata.pathalias.alias} title={tipdata.title}>{String(tipdata.title).length > 71?<text>{String(tipdata.title).substring(0,70)}...</text>:<text>{String(tipdata.title)}</text>}</Link>{tipdata?.publishedDate&&<div className="created" >{moment(tipdata?.publishedDate).format('MMM D, YYYY')}</div>}{tipdata?.artistname&&<div className="createdname" >{String(tipdata?.artistname).charAt(0).toUpperCase() + String(tipdata?.artistname).slice(1)}</div>}  </div>
              
                </div>  </Link>)}
                {1 !== dataList.length&&<div className="viewall"><Link to="/education/tipoftheweek">Ver todo</Link></div>}
              </div>
            </div>
            
          </div>
          {dataList.length > 0 &&<div className="actionbutton"><div className="actionbuttoninner">
          {index > 0&&<div className="button previous"> <Link onClick={()=> onSelectTip(dataList[index-1]?._id)} to={dataList[index+1]?.pathalias?.alias} ><Button type="primary">Jugada anterior</Button></Link></div>}
          {index + 1 !== dataList.length&&<div className="button next"> <Link onClick={()=> onSelectTip(dataList[index+1]?._id)} to={dataList[index+1]?.pathalias?.alias} ><Button type="primary">Siguiente Jugada</Button></Link></div>}
          </div></div>}
        </div>
      )}
    </div>
  );
};

export default TipOfTheWeekDetails;
