import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import ForumsViewBlock from './ForumsViewBlock';
import EducationViewBlock from './EducationViewBlock';
import BooksViewBlock from './BooksViewBlock';
import PodcastsViewBlock from './PodcastsViewBlock';
import BlogsViewBlock from './BlogsViewBlock';
import noavtar from '../../assets/no-avatar.jpg';
import API from '../../utils/API';
import Page from '../../components/Page/Page';
import './Dashboard.module.css';
import './css/dashboardverify.css';
import VerifySuccessMail from '../../components/VerifySuccessMail/VerifySuccessMail';
import Joyride, {  EVENTS, STATUS } from 'react-joyride';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import {Form, Input, Button, Modal } from 'antd';
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import LoanRequested from '../Borrower/LoanRequested';
import LoanInterested from '../Lender/LoanInterested';
const Dashboard = () => {
  const { state } = useContext(AuthContext);
  const [path,setPath] = useState()
  const step = [
    {
      target: '.educationtour',
      content: 'Aquí es donde puedes ver todos los videos, podcasts, blogs y libros relacionados con tus preferencias.',
      disableBeacon: true,


    },
    {
      target: '.networktour',
      content: 'Aquí es donde puedes ver a todas las personas en En La Cancha, y puedes enviar solicitudes de conexión.',
    },
    {
      target: '.forumtour',
      content: '¡Aquí es donde puedes ver todas las publicados en En La Cancha y también puedes publicar!',
    },
    {
      target: '.pricingtour',
      content: 'Aquí es donde puedes verlos tipos de membresias y disfrutar de los servicios premium.',
    },
    {
      target: '.userprofiletour',
      content: 'Aquí es donde puedes ver tu perfil y también realizar cambios cuando lo desees',

    },
    // {
    //   target: '.messagetour',
    //   content: 'Aquí es donde puedes ver todos los mensajes recibidos y enviados a tus conexiones',

    // },
    // {
    //   target: '.notificationtour',
    //   content: 'Aquí es donde puedes ver todas las notificaciones.',

    // },

    {
      target: '.dashboardtour',
      content: 'Aquí es donde puedes navegar En La Cancha',

    },



  ]
  const [steps, setSteps] = useState(step)
  const [hideTourBtn, setHideTourBtn] = useState(true)
  const [seeTour, setSeeTour] = useState(false)
  const [loadings, setLoadings] = useState(true);
  const [profileImage, setProfileImg] = useState('');
  const [visiblePhone, setvisiblePhone] = useState(false);
  const [phonenumber, setPhoneNumber] = useState('')
  const [name,setName] = useState();
  const [form] = Form.useForm();
  const seeTourHandle = () => {
    setSeeTour(true)
  }

  React.useEffect(() => {
    let isMounted = true;
    if (localStorage.getItem('token') && localStorage.getItem('user') && isMounted) {
      const user = JSON.parse(localStorage.getItem('user'));
      if(user?.id){
        API.post('/userinfo/updateuserinfoid', {
          id: user.id
        },
        ).catch(err => {
          console.log(err);
        })
        API.get('/users/' + user.id).then(result => {
          setPath(result?.data.user.pathalias);
          if(!result?.data?.user?.userinfoid?.phonenumber){
            setvisiblePhone(true);
          }
          if(result?.data?.user?.pathalias?.pagetype == undefined){
            API.post(`/users/addpath/`,{id:user.id}).then(res => {
              console.log(res);
             }).catch(err => {
               console.log(err);
             })
          }
          setName(result.data.user.firstname)
          if (result.data.user.istourdone) {
            setHideTourBtn(false)
          }
        }).catch(err => {
          console.log(err);
        })
      }
    }
    return () => { isMounted = false };
  }, [state])
  if(state?.user?.usertype.includes('lender') && state?.user?.sub == 'free' && state?.user?.level != 1){
    return <Redirect to="/dashboard/lender"/>;
  }
  /* if(state?.user?.usertype.includes('lender')){
    return <Redirect to="/lender/dashboard"/>;
  } */
  const handleJoyrideCallback = data => {
    const { action, index, status, type } = data;

    if ([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND].includes(type)) {
      // Update state to advance the tour

    }
    else if ([STATUS.FINISHED, STATUS.SKIPPED].includes(status)) {
      // Need to set our running state to false, so we can restart if we click start again.
      API.post('/users/tourdone', {
        id: state.user.id

      },
      ).catch(err => {
        console.log(err);
      })

      setHideTourBtn(false)
    }
  }
  const onCancel =() => {
    setvisiblePhone(false);
    form.resetFields();
  }
  const onFinishRequest = values => {   
   API.post('/userinfo/managephone',
    {id: state.user.id, phonenumber: phonenumber})
    .then(() => {
      setvisiblePhone(false);
      form.resetFields();
    })
    .catch(err => {
      console.log(err);
    })
  };
  const handlePhoneOnChange = (value, data, event, formattedValue) => {
    if(formattedValue.length > 10){
      setPhoneNumber(formattedValue);
    }else {
      setPhoneNumber('');
    }
  }
  return (
    <Page>
      {seeTour && <Joyride
        steps={steps}
        continuous={true}
        showSkipButton={true}
        callback={handleJoyrideCallback}
        styles={{
        options: {
          primaryColor: '#0072BB',      
          width: 600,
          hieght:200,
          zIndex: 1000,
        }
      }}
      locale={{
        last: "Ok, lo tengo",
        back: "Atras",
        next: "Siguiente",
        skip:"Saltar"
        
      }}
          />}
         <div className="maincontent dashboardpage rightside1">
            <div className="viewuserinformation">
              <div className="viewcontent">
                  <div className="viewrow">
                    <div className="userpic"><ProfileImage id={state?.user?.id}/></div>
                    <div className="username">
                      {state?.isAuthenticated && (
                        <h2>Bienvenido {name},</h2>
                      )}
                      </div>
                  </div>
                 {hideTourBtn && <div onClick = {seeTourHandle} className="taketourcontent"><a href="#">Haz un Tour en En La Cancha</a></div>}
          </div>
        </div>
        {/* phonenumber update */}
      <Modal
        visible={visiblePhone}
        okText="Enviar"
         wrapClassName={'forgetpopup'}
        centered="true"
        closable={false}
        footer={null}
      >
        <Form
          form={form}
          name="phonenumbermanage"
          onFinish={onFinishRequest}
          layout="vertical"
        >
          <p>¿Quieres recibir notificación de nuestros eventos, vivos y entrenamientos?
Actualiza tu información de contacto agregando aquí tu número  telefónico.</p>
          <PhoneInput
            country={'us'}
            value={phonenumber}
            enableSearch={true}
            onChange={handlePhoneOnChange}
          />
          <div className="modal-buttons">
            <Button
              htmlType="button"
              className="form-submit"
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="form-submit"
              disabled = {phonenumber.length ? false : true}
            >
              Enviar
            </Button>
          </div>

        </Form>
      </Modal>
        <VerifySuccessMail />
        {/* <Link to={path?.alias} ><div>click</div></Link> */}

        {/* verifymessage */}
        {/* <div className="verifymessage doyoucomplete">
              <div className="viewcontent">
                  <div className="viewrow">
                    <p>¿Quieres completar tu perfil?</p>
                    <div className="formaction">
                        <a href="#" className="yes">Si</a>
                        <a href="#" className="dolater">Hazlo mas tarde</a>
                    </div>
                  </div>
              </div>
            </div> */}

            {/* verifymessage closed */}
            <div className="maindiv dashboardwrapper container">
              
                {/* {(state?.user?.sub != 'free' || state?.user?.level == 1 ) &&  */}
                  <div className="midcontent">
                    <ForumsViewBlock />
                    <EducationViewBlock />
                    <PodcastsViewBlock />
                    <BlogsViewBlock />
                  {/* <NetworksViewBlock /> */}
                    <BooksViewBlock />
                    {/* {(!state?.user?.usertype.includes('lender') && state?.user?.id) &&
                    <LoanRequested id={state?.user?.id}/>} */}
                  </div>
                {/* } */}
                {/* {state?.user?.usertype.includes('lender') && <LoanInterested id={state?.user?.id}/>} */}
              {/*<div className="rightsidebar">
                 <AroundCornerViewBlock /> 
              </div>*/}
            </div>
          </div>
    </Page>

  );
};

export default Dashboard;
