import React, {Component, useState} from 'react';
import Page from '../../components/Page/Page';
import API from '../../utils/API';
import {Modal} from 'antd';
/* import styles from './TermsCondition.module.css'; */

class TermsCondition extends Component {

  state ={
    pagedata: [],
    alias : this.props.match.url
  }
  componentDidMount() {
    this.getData()
  };
  getData(){
    API.get('/pages/nodecontent'+this.state.alias)
    .then(result => {
      const basicpage= result.data.basicpages;
     this.setState({ pagedata:basicpage });
    })
    .catch(e => {
      Modal.error({
        title: 'Ocurrió un error',
        content: e.message,
      });
    });
  }

  render(){
    return (
      <Page>
        <div className="maincontent termsandconditions">
          <h1>{this.state.pagedata.title}</h1>
          <div dangerouslySetInnerHTML={ { __html:this.state.pagedata.body}} ></div>
        </div>
      </Page>
    );
  }
};

export default TermsCondition;
