import React from "react";
import Page from "../../components/Page/Page";
import { Link } from "react-router-dom";
import { Carousel, Modal } from "antd";
import "./podcasts.css";
import "./Allpodcasts.css";
import PodcastImage from "../../components/PodcastData/PodcastImage";
import API from "../../utils/API";

const AllPodcasts = (props) => {
  const [podcasts, setPodcasts] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [active, setActive] = React.useState();
  React.useEffect(() => {
    API.get(`/podcasts/`)
      .then((result) => {
        setPodcasts(
          result.data.podcasts.sort((a, b) => {
            // equal items sort equally
            if (a.podcastOrder === b.podcastOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.podcastOrder === null) {
              return 1;
            } else if (b.podcastOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.podcastOrder < b.podcastOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.podcastOrder < b.podcastOrder ? 1 : -1;
            }
          })
        );
        API.get("/categories/getcategories")
          .then((results) => {
            const arr = [];
            results.data.category.map((name) => {
              for (let i = 0; i < result.data.podcasts.length; i++) {
                if (result.data.podcasts[i].selectedCategory[0] !== undefined) {
                  if (result.data.podcasts[i].selectedCategory[0]._id == name._id) {
                    const cat = name.name;
                    const _id = name._id;
                    arr.push({ name: cat, _id: _id });
                  }
                }
              }
            });
            const filteredArr = arr.reduce((acc, current) => {
              const x = acc.find((item) => item._id === current._id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setCategory(filteredArr);
          })
          .catch((e) => {
            Modal.error({
              title: "Ocurrió un error",
              content: e.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onCatChange = (catId) => {
    setActive(catId);
    const arr = [];
    arr.push(catId);
    API.get("/podcasts/bycategory/" + arr)
      .then((result) => {
        setPodcasts(
          result.data.podcasts.sort((a, b) => {
            // equal items sort equally
            if (a.podcastOrder === b.podcastOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.podcastOrder === null) {
              return 1;
            } else if (b.podcastOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.podcastOrder < b.podcastOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.podcastOrder < b.podcastOrder ? 1 : -1;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });

  };

  return (
    <Page>
      <div className="allpodcastspage maincontent">
        <div className="vieweducationfilter">
          <div className="vieweducationcontent">
            {/* {state.isAuthenticated && (
                     <div className="userpic"><ProfileImage id={state.user.id}/></div>
                  )}
                  <div className="searchbox">
                     <Form>
                        <Form.Item
                           name="name"
                           className="form-item search-filter"
                        >
                           <Input className="formtext" placeholder="Búsqueda por nombre, palabras clave" />
                        </Form.Item>
                        <Form.Item className="form-action"> 
                           <Button type="primary" htmlType="submit" className="form-submit"> 
                           Submit
                           </Button>
                        </Form.Item>
                     </Form>
                  </div> */}
            <div className="searchtabs">
              <ul>
                <li>
                  <Link to="/education/videocourses">ENTRENAMIENTOS</Link>
                </li>
                <li>
                  <Link to="/education/webinar">WEBINARS</Link>
                </li>
                <li>
                  <Link to="/education/tipoftheweek">Jugadas en la cancha</Link>
                </li>

                <li className="active">
                  <Link to="/education/podcasts">Podcasts</Link>
                </li>
                <li>
                  <Link to="/education/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/education/books">Libros</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="educationlistfilterslider">
          <div className="viewcontent">
            <Carousel {...settings}>
              {category.map((catName) => (
                <div className="viewrow">
                  <a
                    style={{ color: active == catName._id ? "black" : "" }}
                    onClick={() => onCatChange(catName._id)}
                  >
                    {catName.name}
                  </a>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="breadcrumbwrapper">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/education">Educacion</Link>
              </li>
              <li>Podcasts</li>
            </ul>
          </div>
        </div>
        <div className="viewpodcastslist allpodcastslist">
          <div className="viewpodcasts">
            {podcasts.length ? (
              <div className="viewcontent">
                {podcasts.map((podcast) => (
                  <div className="viewrow">
                    <Link className="podscastlink" to={podcast.pathalias.alias}>
                      <div className="imagebox">
                        <PodcastImage id={podcast._id} />
                      </div>
                      
                      {String(podcast.podcastname).length > 71? <h2 className="title">
                        <Link
                          to={podcast.pathalias.alias}
                          title={podcast.title}
                        >
                          {String(podcast.podcastname).substring(0,70)}...
                        </Link>
                      </h2>:<h2 className="title">
                        <Link
                          to={podcast.pathalias.alias}
                          title={podcast.title}
                        >
                          {podcast.podcastname}
                        </Link>
                      </h2>}
                    </Link>
                  </div>
                ))}
              </div>
            ) : (
              <div className="catnotfound">
                ¡¡No se han encontrado resultados!!
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
export default AllPodcasts;
