import React, { useEffect, useContext, useState } from 'react';
import API from '../../../utils/API';
import { AuthContext } from '../../../App';
import { Redirect, useHistory } from 'react-router-dom';
import './../financeing.css'
import { Modal, Form, Input,InputNumber, Radio, Space, Select } from 'antd';
import HardMoneyStep1 from './HardMoneySteps/HardMoneyStep1';
import HardMoneyStep2 from './HardMoneySteps/HardMoneyStep2';
import HardMoneyStep3 from './HardMoneySteps/HardMoneyStep3';
import HardMoneyStep4 from './HardMoneySteps/HardMoneyStep4';
const { TextArea } = Input;
const libraries = ["places"];
const HardMoney = props => {
  const [stepForm] = Form.useForm();
  const [current, setCurrent] = React.useState(0);
  const { state } = useContext(AuthContext);
  //const [loantype, setLoantype] = useState('');
  //const [stateList,setStateList] = useState([]);
  //const [selectedItems, setSelectedItems] = useState([]);
  //const [statelistoptiondone,setStateListOptiondone] = useState(false);
  //const [serviceprovidevalue, setServiceProvideValue] = useState({});
  //const [cuantosShow, setCuantosShow] = useState(false)
  //const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
  const history = useHistory();
  //const [options, setOptions] = useState([]);
  //const [valuepropertyaddress, setValuepropertyaddress] = useState(null)
  //const [phonenumber, setPhoneNumber] = useState('')
  const [formdata, setFormdata] = useState({})
  /* const optionsLang = [
    {value: 'Español',label: 'Español'},
    {value: 'English', label: 'English'}
  ];
  const incomeOption = [
    {
      value: '$20,000-$50,000',
      label: '$20,000-$50,000',
    },
    {
      value: '$50,000-$70,000',
      label: '$50,000-$70,000',
    },
    {
      value: '$70,000-$100,000',
      label: '$70,000-$100,000',
    },
    {
      value: '$100,000 +',
      label: '$100,000 +',
    },
  ];
  const savingOption = [
    {
      value: '$30,000-$40,000',
      label: '$30,000-$40,000',
    },
    {
      value: '$40,000-$50,000',
      label: '$40,000-$50,000',
    },
    {
      value: '$50,000- $100,000',
      label: '$50,000- $100,000',
    },
    {
      value: '$100,000 o mas',
      label: '$100,000 o mas',
    },
  ] */
  
  const onFinish = value =>{
    console.log('valuee: ',value)
    //setLoantype(props.loantype)
    API.post('users/loan/r/add',{
      author: state?.user?.id,
      authorname:state?.user?.firstname,
      loantype:props.loantype,
      fullname: formdata.fullname ? formdata.fullname.trim(): '',
      companyname:formdata?.companyname ? formdata?.companyname?.trim() : '',
      telephonenumber: formdata?.telephonenumber.trim(),
      stateprovideservice: value.stateprovideservice,
      taxoption: formdata?.taxoption,
      employmenttype: formdata?.employmenttype,
      credit: formdata?.credit,
      income: formdata?.income,
      saving: value.saving,
      usedhardmoney: value.usedhardmoney,
      activehardmoney: formdata.activehardmoney,
      contractorready: value.contractorready,
      project: formdata?.project,
      dealundercontract: value.dealundercontract,
      propertyaddress: formdata.propertyaddress ? formdata.propertyaddress.trim() : '',
      propertyvalue: value.propertyvalue,
      renovacioncost: value.renovacioncost,
      afterrepairecost: value.afterrepairecost,
      langcontact: formdata?.langcontact,
      acceptterm: value.acceptterm,
    }).then(result =>{
      history.push('/dashboard/borrower');
      Modal.success({
        content: 'Se envió su solicitud de préstamos con éxito. Un prestamista se contactará con usted',
      });
      form.resetFields();
      //setLoantype();
    })
  }
   /* useEffect(()=>{
    async function getStateList () {
      await API.get('/location/details/state').then(async result => {
        setStateList(result?.data?.statelist)
        let temp = [];
        result?.data?.statelist.map((s)=>{
          temp.push({
            value: s.name,
            label: s.name,
          });
        })
        setOptions(temp);
        setStateListOptiondone(true);
       }).catch(err => {
         console.log(err);
       })
    }
    getStateList();
  },[statelistoptiondone]) */

 if(state?.user?.usertype.includes('lender')){
    return <Redirect to="/dashboard"/>;
  }
  const next = () => {
    window.scrollTo({
      top: 0
    });
    setCurrent(current+1)
  }
  const prev = () => {
    window.scrollTo({
      top: 0
    });
    setCurrent(current-1);
  };
  const steps = [
    {
      step: 1,
      title: "INFORMACION GENERAL",
      content: <HardMoneyStep1 next={next} prev={prev} form = {stepForm} formdata={formdata}/>
    },
    {
      step: 2,
      title: "INFORMACION FINANCIERA",
      content: <HardMoneyStep2 next={next} prev={prev} form = {stepForm} formdata={formdata}/>
    },
    {
        step: 3,
        title: "INFORMACION DE EXPERIENCIA",
        content: <HardMoneyStep3 next={next} prev={prev} form = {stepForm} formdata={formdata} cc={formdata?.activehardmoney}/>
    },
    {
        step: 4,
        title: "INFORMACION DE LA PROPIEDAD",
        content: <HardMoneyStep4 prev={prev} formdata={formdata}/>
    }
  ];
   return (
    <Form onFinish = {onFinish} form = {stepForm} layout="vertical"
    name="">
      <div className='step-wrapper'>
        <h2>{steps[current]?.title}</h2>
        <div id="l-requestforms">
          {steps[current]?.content}
        </div>
        
      </div>
    </Form>
   );
};

export default HardMoney;
