import React, { useState, useEffect } from 'react';
import {Skeleton} from 'antd';
import API from '../../utils/API';
import AudioPlayer from 'react-h5-audio-player';
import 'react-h5-audio-player/lib/styles.css';

const PodcastFile = props => {
  const [loadings, setLoadings] = useState(true);
  const [audioFile, setAuido] = useState('');
  useEffect( ()=> { 
    if(props.id){
        API.get(`/podcasts/getpodcast/?_id=${props.id}`).then(result => {
          if(result?.data?.base64){
            setAuido(<AudioPlayer
            autoPlay={false}
            src={result?.data?.base64}
          />)
           setLoadings(false);
          }
          
      }).catch(err => {
        setAuido(<div></div>);
        setLoadings(false);
          return
      })
    }
  },[props.id]);

  return (
    <div>
      {loadings ? (
        <Skeleton.Button style = {{width:"600px"}} className="skeletonk" active avatar shape="square"/>
      ) : (
        <div>{audioFile}</div>
      )}
    </div>
    
    
    
  );
};

export default PodcastFile;
