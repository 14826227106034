import React, { Component } from 'react';
import API from '../../utils/API';
import {Modal} from 'antd';
import { Link } from 'react-router-dom';
import Moment from 'moment';
import { Carousel } from "antd";
import './education.css';
import axios from 'axios';
import EducationListingItem from './EducationListingItem';

class EducationListing extends Component {
   state ={
      datalists: [],
      fetchdone:false,
      imgdata:[], 
    }
    settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
             breakpoint: 1119,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1,
             }
         },
         {
             breakpoint: 767,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         },
         {
             breakpoint: 480,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         }
      ]
   }
    componentDidMount() {
      
      this.getData()
    };
    
    getData() {
      var that = this;
      API.get('/courses/contents')
      .then(result => {
        const datalists= result.data.courses.sort((a, b) => {
  
         // equal items sort equally
         if (a.courseOrder === b.courseOrder) {
             return 0;
         }
         // nulls sort after anything else
         else if (a.courseOrder === null) {
             return 1;
         }
         else if (b.courseOrder === null) {
             return -1;
         }
         // otherwise, if we're ascending, lowest sorts first
         else if (true) {
             return a.courseOrder < b.courseOrder ? -1 : 1;
         }
         // if descending, highest sorts first
         else { 
             return a.courseOrder < b.courseOrder ? 1 : -1;
         }
     
       });
        this.setState({datalists})
        /* const iddata = []; */
        //let Vimeo = require('vimeo').Vimeo;
         //let client = new Vimeo("{client_id}", "{client_secret}", "{access_token}");
         //let client = new Vimeo("721e84fa5be48b9b62e753ae08747a2f6f8d748d", "InC25cSCXjI9NdWvPPvjBX+akYs+qqny30FrRVyiJUPo2KjePXENmNEapS8gj3CVnO5SeOCjTetCiSNWW7GOgm8N99t8GtyaLaTHI0A4bLdDKYE6Dj4/w1yNqKtmdkxc", "36273e305fdd9a218b7b54380e2a1025");
        /*  Promise.all(datalists.map( async(id,index,arr)=> { */
         /* client.request({
            method: 'GET',
            path: '/videos/'+Number(id.videos[0].videoURL)
            }, function (name, embed) {
               iddata[index] = embed?.pictures?.base_link;
               if(arr.length - 1 === index){
                  that.setState({imgdata:iddata});
               }
            }) */
        /* await axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(id.videos[0].videoURL)))
        .then(res => {
          const imageUrl = res.data.thumbnail_url.split('_');
          iddata[index] = imageUrl[0];     
          if(arr.length - 1 === index){
            this.setState({imgdata:iddata})
          }
        }) */
      /* })) */
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
    render(){
       /* if(this.state.datalists.length){ */
         return (
            <div className="viewvideocourselist">
               <div className="viewvideocourse">
                  <h2>ENTRENAMIENTOS</h2>
                  <div className="viewcontent">
                     {/* <Carousel {...this.settings} >                  
                        { this.state.datalists.map((datalist, index) =>
                           <EducationListingItem datalistitem={datalist} key={index}/>
                        )}
                    </Carousel> */}
                     <Link to={"/education/beginners"} className="viewrow">
                        <div className="imagebox player-wrapper basic">
                           <div> </div>
                        </div>
                        <div className="titlebox">
                           <h2>Comenzando tu educación desde cero</h2>
                        </div>
                     </Link>
                     <Link to={"/education/intermediate"} className="viewrow">
                        <div className="imagebox player-wrapper inter">
                           <div> </div>
                        </div>
                        <div className="titlebox">
                           <h2>Continuando con tu educación</h2>
                        </div>
                     </Link>
                     <Link to={"/education/expert"} className="viewrow">
                        <div className="imagebox player-wrapper expert">
                           <div> </div>
                        </div>
                        <div className="titlebox">
                           <h2>Avanzando en tu educación</h2>
                        </div>
                     </Link> 
                  </div>
                  <div className="viewall"><Link to="/education/videocourses">Ver todo</Link></div>
               
               </div>
            </div>
          );
       /*}else{
          return(
             <div></div>
          );
       } */
      
   }

};

export default EducationListing;
