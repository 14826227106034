import React, { Component, useRef } from 'react';
import {  Form, Input, Button, InputNumber, Radio, Select } from 'antd';
import API from '../../utils/API';
import {useDropzone} from 'react-dropzone'
import axios from 'axios';
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
const { TextArea } = Input;
const { Option } = Select;
const   DropzoneFunctional = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'images/png':[],
      'images/jpg':[],
      'images/gif':[]
    },
    maxFiles: 20
  });
  return (
    <div
      {...getRootProps()}
      style={{
        border: isDragActive ? '2px dashed #000' : '2px dashed #eee',
        padding: '20px',
        marginBottom: '20px'
      }}
    >
      {isDragActive ? (
        <p className="dropzone">Drop your image files here</p>
      ) : (
        <><p className="dropzone">Drag and drop some image files here, or click to select files</p>
        <em>(Only *.jpeg and *.png images will be accepted)</em></>
      )}
      <input {...getInputProps()} />
    </div>
  );
};
class UpdateProperty extends Component {
  thumb = {
    display: 'inline-flex',
    borderRadius: 2,
    border: '1px solid #eaeaea',
    marginBottom: 8,
    marginRight: 8,
    width: 100,
    height: 'auto',
    padding: 4,
    boxSizing: 'border-box',
    position: "relative"
  };
  
  thumbInner = {
    display: 'flex',
    minWidth: 0,
    overflow: 'hidden'
  };
  
  imgstyle = {
    display: 'block',
    width: '100%',
    height: '100%'
  };
  constructor(props) {
    super()
    this.props = props;
    this.state = {
      files : [],
      fileRejectionsError: [],
      availableOptions: ['Rent', 'Lease', 'Sale'],
      availablefor: [],
      userdata: props?.userdata,
      loading: false,
      error: false,
      zipcode: '',
      statename: '',
      cityname: '',
      stateList: [],
      cityList:[],
      body: undefined,
      dealid:'',
    }
  }
  componentDidMount () {
    this.handleEditorChange = this.handleEditorChange.bind(this);
    console.log('this.props: ',this.props)
    this.setState({
      availablefor: this.props.dealsdata.availablefor[0],
      body: this?.props?.dealsdata?.body,
      dealid: this?.props?.dealsdata?.dealid
    })
    API.get('/location/details/state').then(async result => {
      this.setState({stateList: result?.data?.statelist}, function(){
        console.log('aaa state',this.state.stateList)
        let dstate = this.props?.dealsdata?.state;
        let selectedtext = dstate.substring(0,dstate.indexOf("(")).trim();
        result?.data?.statelist.map(async(result) => {
          if(result.name == selectedtext){
            API.get('/location/details/city/US/'+result.isoCode).then(async result => {
              console.log('result.data.citylist: ',result.data.citylist)
              const city = result.data.citylist;
              this.setState({cityList:city})
            })
          }
        })
      })
     }).catch(err => {
       console.log(err);
     })
  }
  makeAutocompleteOff = () => {
    const el = document.getElementsByClassName("ant-select-selection-search-input");
    for (let i = 0; i < el.length; i++) {
      el[i].setAttribute("autocomplete", "registration-select");
    }
  }
  onFinish = values => {
    this.setState({loading:true})
    values.body = this.state.body;
    if(typeof values.title === "undefined" || typeof values.address1 === "undefined" || typeof values.state === "undefined" || typeof values.city === "undefined" || typeof values.zipcode === "undefined" || typeof values.bathrooms === "undefined" || typeof values.bedroom === "undefined" || typeof values.footage === "undefined" || typeof values.lotsize === "undefined" || typeof values.price === "undefined" || typeof values.type === "undefined" || typeof values.yearbuild === "undefined" || this.state.availablefor.length == 0 || (this.state.files.length == 0 && this.props.dealsdata.photo.length == 0) || typeof values.body === "undefined" ){
      this.setState({error: true,loading:false})
    }else{
      var dealData = {
        author: this.props.dealsdata?.author?._id,
        values: values,
        photos: this.state.files,
        availablefor: this.state.availablefor,
        dealid: this.state.dealid,
      };
      API.post('/deals/update', dealData, {
        headers: { Authorization: `Bearer ${this.state?.userdata?.token}` },
      })
      .then(result => {
        if(result.data.msg === "done"){
          this.props.getDealDetail()
          this.props.getModalResponse(false)
        }
      })
    }

  };

  handleDrop = (acceptedFiles, fileRejections) => {
    const firstFile = acceptedFiles[0];
    this.setState({fileRejectionsError:fileRejections});
    
    if (firstFile) {
      //const reader = new FileReader();
      let filelist = []
      if(this.state.files.length > 0){
        filelist = this.state.files
      }
      acceptedFiles.map(async(file, index) => {
        let base64String = await readFile(file)
        filelist.push({
          name:file.name,
          preview: URL.createObjectURL(file),
          base64Image: base64String
        })
        if(index+1 == acceptedFiles.length){
          console.log('filelist: ',filelist)
          this.setState({files:filelist}, function(){
            console.log('files',this.state.files)
          })
          
        }
      })
    }
  };
  onChange = (e) => {
    this.setState({availablefor:e.target.value }, function(){
      console.log('avaialblefor', this.state.availablefor)
    })
    console.log('checked = ', e);
  };
  handlezipcode = (e) => {
    axios.get(`https://api.zippopotam.us/us/${String(e.target.value)}`).then(data=> {
      console.log('data: ',data)
      if(data){
          console.log('set statte')
          this.setState({statename:data?.data?.places[0]['state']+" ("+data?.data?.places[0]['state abbreviation']+")",
          cityname:data?.data?.places[0]['place name']})
          
        } else{
          console.log('Empty set statte')
          this.setState({statename:'', cityname: ''})
        }
    }).catch(err=> {
      console.log('error state empty')
      this.setState({statename:'', cityname: ''})
    })
  }
  handleStateChange = (e) => {
      let selectedtext = e.substring(0,e.indexOf("(")).trim();
      console.log('selectedtext: ',selectedtext)
      this.state.stateList.map(async(result) => {
        if(result.name == selectedtext){
          API.get('/location/details/city/US/'+result.isoCode).then(async result => {
            console.log('result.data.citylist: ',result.data.citylist)
            const city = result.data.citylist;
            this.setState({cityList:city})
          })
        }
      })
  }
  handleEditorChange = (event, editor) => {
    const data = editor.getData();
    this.setState({ body: data });
  }
  removeFile = file => () => {
    const newFiles = this.state.files;
    newFiles.splice(newFiles.indexOf(file), 1)
    this.setState({files: newFiles})
  }
  render(){
    const files = this.state.files.map(file => (
      <div style={this.thumb} key={file.name} className='image-preview'>
        <div style={this.thumbInner}>
          <img
            src={file.preview}
            style={this.imgstyle}
            onLoad={() => { URL.revokeObjectURL(file.preview) }}
          />
          <button onClick={this.removeFile(file)} className='delete-icon'></button>
        </div>
      </div>
    ))
    const oldFile = this?.props?.dealsdata?.photo.map(file => (
      <div style={this.thumb} key={file.name}>
        <div style={this.thumbInner}>
          <img
            src={file.location}
            style={this.imgstyle}
            onLoad={() => { URL.revokeObjectURL(file.location) }}
          />
        </div>
      </div>
    ))
    return (
    
      <Form onFinish = {this.onFinish} className='newpropertform'
      initialValues={{
        title: this?.props?.dealsdata?.title,
        address1: this?.props?.dealsdata?.address1,
        address2: this?.props?.dealsdata?.address2,
        state: this?.props?.dealsdata?.state,
        city: this?.props?.dealsdata?.city,
        zipcode: this?.props?.dealsdata?.zipcode,
        price:this?.props?.dealsdata?.price,
        type: this?.props?.dealsdata?.type,
        footage: this?.props?.dealsdata?.footage,
        //parking: this?.props?.dealsdata?.parking,
        yearbuild: this?.props?.dealsdata?.yearbuild,
        lotsize: this?.props?.dealsdata?.lotsize,
        bedroom: this?.props?.dealsdata?.bedroom,
        bathrooms: this?.props?.dealsdata?.bathrooms,
        //halfbathrooms: this?.props?.dealsdata?.halfbathrooms,
        avr: this?.props?.dealsdata?.avr,
        //basement: this?.props?.dealsdata?.basement,
        //hoa: this?.props?.dealsdata?.hoa,
        renovationestimate: this?.props?.dealsdata?.renovationestimate,
      }}>
        <div className="header"><h2>Update Property</h2></div>
        {this.state.error && <div className='error'>All * marked field are required.</div>}
        <div className='form-label'>Title<span className='red'>*</span></div>
        <Form.Item name="title">
            <Input placeholder="Enter Title"/>
        </Form.Item>
        <div className='address-wrapper'>
            <div className='form-label'>Property Address<span className='red'>*</span></div>
            <Form.Item name="address1">
                <Input placeholder="Address Line 1"/>
            </Form.Item>
            <Form.Item name="address2">
                <Input placeholder="Address Line 2"/>
            </Form.Item>
            <div className='state-city-zip'>
                <Form.Item name="state">
                    <Select className="formtext"  
                    onChange={(e)=>{ this.handleStateChange(e)
                    }}
                    placeholder = {"Estado *"}
                    bordered = {false}
                    showSearch
                    onFocus={(e) =>{
                      this.makeAutocompleteOff();
                    }}
                    >
                    {this.state.stateList.map ( res => <Option  key = {Math.random()} iso = {res.isoCode} value= {res.name + (' ('+res.isoCode.toUpperCase()+')')}>{res.name} ({res.isoCode})</Option>)}
                  </Select>
                </Form.Item>
                <Form.Item name="city">
                   {/*  <Input placeholder="city"/> */}
                    <Select 
                      bordered = {false}
                      showSearch
                      className='formtext'
                      placeholder = {'Ciudad *'}
                      onFocus={(e) =>{
                        this.makeAutocompleteOff();
                      }} 
                    >
                    {this.state.cityList?.length ?
                    this.state.cityList?.map ( res => <Option  key = {Math.random()}  value= {res.name}>{res.name}</Option>)
                    : ''}
                    </Select>
                </Form.Item>
                <Form.Item name="zipcode">
                    {/* <Input placeholder="Zipcode"/> */}
                <Input 
                onChange = {this.handlezipcode} 
                className="formtext"
                autoComplete='stop'
                placeholder = "Zipcode"
                />
              </Form.Item>
              
            </div>
        </div>
        <div className='form-label'>Property Available<span className='red'>*</span></div>
        <div className='available-wrapper'>
            <Radio.Group onChange={this.onChange} value={this.state.availablefor}>
              <Radio value="Yes">Yes</Radio>
              <Radio value="No">No</Radio>
            </Radio.Group>
        </div>
        <div className='form-label'>Property Price<span className='red'>*</span></div>
        <div className='price-wrapper'>
          <Form.Item name="price">
            <InputNumber
              min={0}
              style={{
                width: '100%',
                borderRadius:'6px',
              }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <div className='form-label'>Renovation Estimate<span className='red'>*</span></div>
        <div className='price-wrapper'>
          <Form.Item name="renovationestimate">
              <InputNumber
                min={0}
                style={{
                  width: '100%',
                  borderRadius:'6px',
                }}
                formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
          </Form.Item>
        </div>
        <div className='form-label'>AVR<span className='red'>*</span></div>
        <div className='price-wrapper'>
          <Form.Item name="avr">
            <InputNumber
              min={0}
              style={{
                width: '100%',
                borderRadius:'6px',
              }}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            />
          </Form.Item>
        </div>
        <div className='form-label'>Property Description<span className='red'>*</span></div>
        <div className='desc-wrapper'>
            <Form.Item name="footage" className='price-wrapper'>
                <InputNumber
                min={0}
                style={{
                  width: '100%',
                  borderRadius:'6px',
                }}
              placeholder="Sq.Footage"
            />
            </Form.Item>
            <span>sq.ft</span>
            <Form.Item name="type">
                <Input placeholder="Type"/>
            </Form.Item>
            {/* <Form.Item name="parking">
                <Input placeholder="Parking"/>
            </Form.Item> */}
            <Form.Item name="yearbuild">
                <InputNumber
                  min={1}
                  style={{
                    width: '100%',
                    borderRadius:'6px',
                  }}
                  placeholder='Year Build'
                />
            </Form.Item>
            <Form.Item name="lotsize">
                <Input placeholder="Lot Size"/>
            </Form.Item>
            <Form.Item name="bedroom">
                <InputNumber
                  min={1}
                  style={{
                    width: '100%',
                    borderRadius:'6px',
                  }}
                  placeholder='Bedrooms'
                />
            </Form.Item>
            <Form.Item name="bathrooms">
                <InputNumber
                  min={1}
                  style={{
                    width: '100%',
                    borderRadius:'6px',
                  }}
                  placeholder='Bathrooms'
                />
            </Form.Item>
            {/* <Form.Item name="halfbathrooms">
                <InputNumber
                  min={1}
                  style={{
                    width: '100%',
                    borderRadius:'6px',
                  }}
                  placeholder='Half Bathrooms'
                />
            </Form.Item>
            <Form.Item name="basement">
                <Input placeholder="Basement"/>
            </Form.Item>
            <Form.Item name="hoa">
                <InputNumber 
                min={1}
                style={{
                  width: '100%',
                  borderRadius:'6px',
                }}
                placeholder="HoA"/>
            </Form.Item> */}
            {/* <Form.Item name="renovationestimate">
                <Input placeholder="Renovation Estimate"/>
            </Form.Item> */}
        </div>
        
        <div className='property-details'>
        <div className='form-label'>Property Details<span className='red'>*</span></div>
          <Form.Item>
            <CKEditor
              editor={ClassicEditor}
              onChange={this.handleEditorChange}
              data={this?.props?.dealsdata?.body}
              name="body"
              config={{
                  placeholder:
                      "Start typing Key Takeaways note here...",
                  toolbar: [
                      "|",
                      "Bold",
                      "Italic",
                      "Link",
                      "NumberedList",
                      "BulletedList",
                      "|",
                      "BlockQuote",
                      "InsertTable",
                      "Undo",
                      "Redo",
                  ],
              }}
            />
          </Form.Item>
        </div>
        <div className='upload-wrapper'>
          <DropzoneFunctional onDrop={this.handleDrop}/>
          <aside>
              <ul>{files}</ul>
          </aside>
          <p className='max-msg'>(Max 20 photos)<span className='red'>*</span></p>
          <aside>
              <ul>{oldFile}</ul>
          </aside>
        </div>

        <Form.Item>
            <Button
                type="primary"
                htmlType="submit"
                className="submit"
                loading={this.state.loading}
            >
                Submit
            </Button>
        </Form.Item>
        
      </Form>
    );
  }
};
function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}
export default UpdateProperty;
