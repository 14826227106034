import React, { useState, useEffect, useContext } from 'react';
import styles from './newtopic.css';
import {  Form, Input, Button, Modal,Radio, Collapse, Checkbox } from 'antd';
import { LeftOutlined } from '@ant-design/icons';
import API from '../../utils/API';
import { useHistory, Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import RoleName from '../../components/RolesName/RolesName';
import {useDropzone} from 'react-dropzone'
const { TextArea } = Input;
const { Panel } = Collapse;
const   DropzoneFunctional = ({ onDrop }) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/jpeg': [],
      'images/png':[],
      'images/jpg':[],
      'images/gif':[]
    },
    maxFiles: 4
  });
  return (
    <div
      {...getRootProps()}
      style={{
        border: isDragActive ? '2px dashed #000' : '2px dashed #eee',
        padding: '20px',
        marginBottom: '20px'
      }}
    >
      {isDragActive ? (
        <p className="dropzone">Drop your image files here</p>
      ) : (
        <><p className="dropzone">Drag and drop some image files here, or click to select files</p>
        <em>(Only *.jpeg and *.png images will be accepted)</em></>
      )}
      <input {...getInputProps()} />
    </div>
  );
};
const thumb = {
  display: 'inline-flex',
  borderRadius: 2,
  border: '1px solid #eaeaea',
  marginBottom: 8,
  marginRight: 8,
  width: 100,
  height: 'auto',
  padding: 4,
  boxSizing: 'border-box',
  position: 'relative'
};

const thumbInner = {
  display: 'flex',
  minWidth: 0,
  overflow: 'hidden'
};

const imgstyle = {
  display: 'block',
  width: '100%',
  height: '100%'
};
const NewTopic = props =>  {
  const [stepForm] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [subcategory, setSubcategory] = useState(null);
  const history = useHistory();
  const { state } = useContext(AuthContext);
  const [current, setCurrent] = React.useState(0);
  const[title, setTitle] = useState("");
  const[message, setMessage] = useState("");
  const [selectCategory,setSelectCategory] = React.useState([]);
  const [primaryRole, setRole] = useState(null);
  const [communityrule, setcommunityrule] = useState(0);
  const [avatarStatus, setavatarStatus] = useState(null);
  const [files, setFiles] = useState([]);
  const [fileRejectionsError, setfileRejectionsError] = useState('');
  React.useEffect(()=>{
    let isMounted = true; 
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let id = JSON.parse(localStorage.getItem('user')).id;
      API.get('users/'+id).then(result=>{
        if(result.data != null){
          if(result.data.user.avatar != null){
            setavatarStatus(true);
          }
          if(result.data.user.userinfoid != null){
            if(result.data.user.userinfoid.roles != null){
              setRole(result.data.user.userinfoid?.roles);
            }
          }
          if(result.data.user.communityrule != null){
            setcommunityrule(result.data.user.communityrule);
          }
        }
      }).catch(e =>{
        console.log(e.message);
      });
    }
    API.get('/categories/getcategories/subcategory')
    .then(result => {
      setSelectCategory(result.data.category)
     })
    .catch(e => {
      Modal.error({
        title: 'Ocurrió un error',
        content: e.message,
      });
    });
    return () => { isMounted = false }; 
  },[])


  const onFinish = values => {
    console.log('onFinish')
    setLoading(true);
    var topicData = {
      author: state.user.id,
      subcategory: subcategory._id,
      title: title,
      message: message,
      photos: files,
    };
    if(!communityrule){
      topicData = {
        author: state.user.id,
        subcategory: subcategory._id,
        title: title,
        message: message,
        accepteddata: values,
        photos: files,
      }
    }
    console.log('topicData: ',topicData)
    API.post('/topics/add', topicData, {
      headers: { Authorization: `Bearer ${state.token}` },
    })
      .then(result => {
        setTitle("");
        setMessage("");
        setSubcategory(null);
        props.getModalResponse(false);
      })
      .catch(e => {
        setLoading(false);
        Modal.error({
          title: 'No se pudo publicar un tema nuevo',
          content: e.message,
        });
      });
  };
  const next = () => {
    setCurrent(current + 1);
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  const FirstStep = () => {
    const [showerrorTitle,setError] = React.useState(false);

    const onNext = () => {      
      const value = stepForm.getFieldsValue();
      if(value.title == undefined || value.title == '' || value.title.trim() == '' || value.title.trim().length < 5) {
        setError(true);
        return;
      }
      else{
        setTitle(value.title.trim());
        setMessage(value?.message?.trim());
        next();
      }
    }
    const onNextPost = () => {
      const value = stepForm.getFieldsValue();
        if(value.title == undefined || value.title == '' || value.title.trim() == '' || value.title.trim().length < 5 ) {
          setError(true);
          return;
        }else{
          setTitle(value.title.trim());
          setMessage(value?.message?.trim());
          setCurrent(current + 2);
        }
     
    }

    const onTopicFinish = () => {
      setLoading(true);
      const value = stepForm.getFieldsValue();
      if(value.title == undefined || value.title == '' || value.title.trim() == '' || value.title.trim().length < 5) {
        setError(true);
      }else{
        setTitle(value.title.trim());
        setMessage(value?.message?.trim());
        var topicData = {
          author: state.user.id,
          subcategory: subcategory._id,
          title: value.title.trim(),
          message: value?.message?.trim(),
          photos: files,
        };
        console.log('topicData 2',topicData)
        API.post('/topics/add', topicData, {
          headers: { Authorization: `Bearer ${state.token}` },
        })
          .then(result => {
            setTitle("");
            setMessage("");
            setSubcategory(null);
            props.getModalResponse(false);
          })
          .catch(e => {
            setLoading(false);
            Modal.error({
              title: 'No se pudo publicar un tema nuevo',
              content: e.message,
            });
          });
      }
    };
    const handleDrop = (acceptedFiles, fileRejections) => {
      var filecount = acceptedFiles.length + files.length;
      if(fileRejections.length > 0 || filecount > 4) {
        setfileRejectionsError('You can upload Max 4 photos');
        return;
      }
      // Function to convert file to base64
      const convertToBase64 = (file) => {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();
          reader.readAsDataURL(file); // Read the file as a Data URL (base64)
          reader.onload = () => resolve(reader.result);
          reader.onerror = (error) => reject(error);
        });
      };
    
      // Process each accepted file to include base64Image
      const processFiles = async () => {
        const newFiles = await Promise.all(
          acceptedFiles.map(async (file) => {
            const base64Image = await convertToBase64(file);
            return {
              name: file.name,
              preview: URL.createObjectURL(file),
              file, // keeping the actual file for potential further use
              base64Image, // adding base64 string
            };
          })
        );
    
        // Update state while keeping previously added files
        setFiles((prevFiles) => [...prevFiles, ...newFiles]);
      };
    
      // Execute the processing of files
      processFiles();
    };
    
    const removeFile =  (file) => {
      const newFiles = files;
      setFiles([]);
      newFiles.splice(newFiles.indexOf(file), 1)
      setFiles((prevFiles) => [...prevFiles, ...newFiles]);
    }
    // Function to render the file previews
   const renderFilePreviews = () => {
    return (
      <ul>
        {files.map((file) => (
          <div style={thumb} key={file.name} className="image-preview">
            <div style={thumbInner}>
              <img
                src={file.preview}
                style={imgstyle}
                alt={file.name}
              />
              <Button
                type="primary"
                htmlType="button"
                onClick={() => removeFile(file)}
                className="delete-icon"
              >
                Remove
              </Button>
            </div>
          </div>
        ))}
      </ul>
    );
  };
    return <div>
      <div className="header"><h2>Crea una Publicacion</h2></div>
      
      <div className="userinfomation">
        <div className="userpic">
          <ProfileImage id={state.user.id}/>
        </div>
        <div className="username">
          <div className="name">{state.user.firstname} {state.user.lastname}</div>
          <div className="description-box">
            <span className="description">

            {primaryRole != null ? <RoleName id = {primaryRole}/> : null}

            </span>
            
            {subcategory && (
              <span className="category">{subcategory.name}</span>
            )}
          </div>
        </div>        
      </div>
      <div className="forumpostformwrapper">
      
      <Form.Item
          name="title"
          label=" "
          rules={[
            {
              required: true,
              message: 'El título debe tener al menos 5 caracteres',
              min: 5,
            },
          ]}
        >
          <Input placeholder="Entra tu pregunta aqui"/>
        </Form.Item>
        {showerrorTitle &&<div className = "error">*  Por favor ingrese el pregunta.</div>}
        <Form.Item
          name="message"
        >
          <TextArea placeholder="Entra detalles de tu pregunta aqui" rows={24}/>
        </Form.Item>
        {state.user.level == 1 &&
          <div className='upload-wrapper'>
            {files.length < 4 && <DropzoneFunctional onDrop={handleDrop}/>}
            <aside>
            {renderFilePreviews()}
            </aside>
            {fileRejectionsError != '' && 
              <p className='error'>{fileRejectionsError}</p>
            }
            <p className='max-msg'>(Max 4 photos)<span className='red'>*</span></p>
          </div>
        }
        {(subcategory && communityrule) 
        ?
                  <Form.Item>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={onTopicFinish}
                    disabled={loading}
                  >
                   Publicar
                  </Button>
                </Form.Item>
        : null }
        {(subcategory && !communityrule) && (
                  <Form.Item>
                  <Button
                    type="primary"
                    htmlType="button"
                    onClick={onNextPost}
                  >
                   Publicar
                  </Button>
                </Form.Item>
        )}
        {!subcategory && (
          <Form.Item
            className="formaction"
          >
            <Button
              type="primary"
              htmlType="button"
              onClick={onNext}
            >
              Seleciona categoria
            </Button>
          </Form.Item>
        )}
        </div>
    </div>
  }
  const SecondStep = () => {
    const handleOnChange = e => {
      e.preventDefault();
      const subcat = e.target.value;
      const sid = e.target.id;
      API.get(`/subcategories/info?sid=${sid}`).then(result => {
        if(result.data != 'null'){
          setSubcategory(result.data);
        }

      });
      prev();
    }
    return <div>
        <div className="header"><h2>Seleciona categoria</h2></div>
          <Button
            className="back-button"
            onClick={prev}
            htmlType="button"
          >
            <LeftOutlined/>
          </Button>
          <div className="forumpostformwrapper">
            <div className="category-list">
              <Collapse accordion>
              {selectCategory.map((cat, index) => {
                if(cat.subcategories.length > 0){
                      return <Panel header={cat.name} key={index}>
                        <Radio.Group name="radiogroup" value = {cat.name+(index)} >
                          {cat.subcategories.map((subcat, index) =>{
                              return <Radio onChange = {handleOnChange} id= {subcat.shortid} value = {subcat.name} className="radio-item" >{subcat.name}</Radio>
                            }
                          )}
                        </Radio.Group>
                      </Panel>
                }
                })
              }
              </Collapse>
            </div>
        </div>
    </div>
  }
  const LastStep = () => {
    const editPost = () => {
      setCurrent(0);
    }
    return <div>
      <div className="header thankyou"><h2>Gracias por participar en los foros de En La Cancha</h2></div>
      <div className="forumpostformwrapper thankyoumessage">
        <div className="intro-message">
          <p>Hola {state.user.firstname.charAt(0).toUpperCase() + state.user.firstname.slice(1)},</p>
          <p>¡Estamos muy emocionados de que estés haciendo tu primera publicación en el foro En La Cancha!
              Sin embargo, antes de que puedas enviar tu publicación, necesitamos que aceptes seguir
              algunas reglas. Nuestras estrictas pautas ayudan a proteger a nuestros miembros del spam.</p>
        </div>
        <div className="intro-message-option">
        <Form.Item
          name="Sin solicitud de mentor"
          valuePropName="checked"
          className="agreement"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Por favor acepta')),
            },
          ]}
        >
        <Checkbox
        style={{ fontSize:'12px' }}
        >Sin solicitud de mentor</Checkbox>
        </Form.Item>

        <Form.Item
          name="Sin pedir ni ofrecer ofertas"
          valuePropName="checked"
          className="agreement"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Por favor acepta')),
            },
          ]}
        >
        <Checkbox
        style={{ fontSize:'12px' }}
        >Sin pedir ni ofrecer ofertas</Checkbox>
        </Form.Item>

        <Form.Item
          name="Ninguna otra forma de publicidad o solicitud"
          valuePropName="checked"
          className="agreement"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Por favor acepta')),
            },
          ]}
        >
        <Checkbox
        style={{ fontSize:'12px' }}
        >Ninguna otra forma de publicidad o solicitud</Checkbox>
        </Form.Item>

        <Form.Item
          name="Sin correo electrónico ni número de teléfono"
          valuePropName="checked"
          className="agreement"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Por favor acepta')),
            },
          ]}
        >
        <Checkbox
        style={{ fontSize:'12px' }}
        >Sin correo electrónico ni número de teléfono</Checkbox>
        </Form.Item>

        <Form.Item
          name="Sin carga o solicitud de prestamista"
          valuePropName="checked"
          className="agreement"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Por favor acepta')),
            },
          ]}
        >
        <Checkbox
        style={{ fontSize:'12px' }}
        >Sin carga o solicitud de prestamista</Checkbox>
        </Form.Item>

        </div>
        <div className="package-message">Para publicar cualquiera de los contenidos anteriores, simplemente regístrese en En La Cancha
          Cuenta de membresía básica o comercial para publicar anuncios en Marketplace.</div>
        <div className="agree-message"><strong>Al marcar a continuación, acepta seguir nuestras <Link to='/rules' target="_blank">reglas</Link>  sobre esta publicación y todas las publicaciones futuras.</strong></div>
        <Form.Item
          name="Community Rule"
          valuePropName="checked"
          className="agreement"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Por favor acepta')),
            },
          ]}
        >
        <Checkbox
        style={{ fontSize:'12px' }}
        >Prometo no pedir mentor, pedir u ofrecer ofertas, incluir contacto información, o solicitar un préstamo o prestamista en los Foros de En La Cancha.</Checkbox>
        </Form.Item>
      </div>
        <Form.Item
          className="thankyou formaction"
        >
            <Button
              type="primary"
              htmlType="button"
              onClick={editPost}
              className="edit"
              style={{marginRight:'10px'}}
              
            >
              Editar Publicaciones
            </Button>
            <Button
            type="primary"
            htmlType="submit"
            className="submit"
          >
            Enviar
          </Button>

        </Form.Item>

    </div>
  }
  
  const steps = [
    {
      step: 1,
      title: "¿Cuál es tu función principal en bienes raices?",
      content: <FirstStep/>
    },
    {
      step: 2,
      title: "¿Cuáles son tus intereses en bienes raices?",
      content: <SecondStep/>
    },
    {
        step: 3,
        title: "¿Donde estas localizado?",
        content: <LastStep/>
    },
  ];
  return (
      <Form onFinish = {onFinish} form = {stepForm}>
        <div className="steps-content">{steps[current].content}</div>
      </Form>
  );
};

function readFile(file) {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.addEventListener('load', () => resolve(reader.result), false)
    reader.readAsDataURL(file)
  })
}
export default NewTopic;
