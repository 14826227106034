import React, { useEffect, useContext, useState } from 'react';
import API from '../../../../utils/API';
import { Modal, Button, Form, Input,InputNumber, Select, DatePicker } from 'antd';


const { TextArea } = Input;
const RefinanciamientoStep3 = props => {
  const [loantype, setLoantype] = useState('');
  const [stateList,setStateList] = useState([]);
  const [statelistoptiondone,setStateListOptiondone] = useState(false);
  const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
 
  const handleActiveinvestChange = (value) => {
    if(value == 'Si'){
      setCuantosShow(true);
    }else{
      props.formdata.activeinvest = value;
      setCuantosShow(false);
    }
  };
  const onNext = () => {
    var value = props.form.getFieldsValue()
    props.formdata.project = value.project;
    props.formdata.activeinvest = value.activeinvest;
    props.formdata.cuantos = value.cuantos;
    props.next()
    console.log(props.form.getFieldsValue(['activeinvest']))
  }
  const onPrev = () => {
    props.prev()
  }
   return (
    <>
          <Form.Item
            label="¿ Cuantos proyectos a hecho?"
            name="project"
            >
              <Select
              options={[
                {
                  value: 'Ninguno',
                  label: 'Ninguno',
                },
                {
                  value: '1-3',
                  label: '1-3',
                },
                {
                  value: '3-5',
                  label: '3-5',
                },
                {
                  value: '5-10',
                  label: '5-10',
                },
                {
                  value: '10+',
                  label: '10+',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="¿ Tiene prestamos de inversion activos?"
            name="activeinvest"
            >
              <Select
              onChange={handleActiveinvestChange}
              options={[
                {
                  value: 'Si',
                  label: 'Si',
                },
                {
                  value: 'No',
                  label: 'No',
                },
              ]}
            />
          </Form.Item>
          {(cuantosShow == true || props.cc == 'Si') && <Form.Item
            label="Cuantos"
            name="cuantos"
          >
            <InputNumber
              min={0}
              formatter={(value) => `${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Entra cuantos'
            />
          </Form.Item>}
          <Form.Item className="form-action">
            <Button className="prevstep" onClick={() => onPrev()}>Atrás</Button>
            <Button className="nextstep" type="primary" onClick={() => onNext()}>Siguiente</Button>
          </Form.Item>
      </>
   );
};

export default RefinanciamientoStep3;
