import { Button, Modal, Input,message } from 'antd';
import React, { useContext, useState, useEffect,useRef } from 'react';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import { useHistory } from 'react-router-dom';

const ConnectButton = (props) => {
    const { state } = useContext(AuthContext);
    const [friendState, setfriendState] = useState(null)
    const [visibletosubscribe, setVisibletosubscribe] = useState(false);
    const history = useHistory();

    const handleRedirectPricing =() => {
      setVisibletosubscribe(false);
      history.push('/pricing?=/network')
      document.body.classList.remove('modalopen');
    }
    const handleCancel = () => {
        setVisibletosubscribe(false);
        document.body.classList.remove('modalopen');
    };

    const getConnectionsStatus = async () => {
        if( props.forUserId !== state?.user?.id){
            try {
                const res = await API.get("/users/checkc/"+state?.user?.id+"/"+ props.forUserId);
                if(res?.data?.friend?.length){
                    setfriendState(res.data.friend);
                }
              } catch (err) {
                console.log(err);
              }
        }
    };
    useEffect(() => {
      getConnectionsStatus();
    }, [state?.user?.id, props.forUserId]);

    React.useEffect(() => {
      props?.socket?.current?.on("getDesktopNotification", (data) => {
        getConnectionsStatus();
      });
    }, [props?.socket]);

    const sending = async (e) =>{
        e.preventDefault();
        if( props.forUserId !== state?.user?.id){
          API.get(`/users/scount/${state?.user?.id}`).then(u=>{
            console.log(u?.data?.user?.sentcount)
            if( state?.user?.level == 1 || u?.data?.user?.membershiprole == 'basic' || (u?.data?.user?.sentcount < 20 && u?.data?.user?.membershiprole == 'free')){
              sendingProgress();
            }else{
              setVisibletosubscribe(true);
              document.body.classList.add('modalopen');
            }
          });
        }
    }

    const sendingProgress = async () =>{
      if( props.forUserId !== state?.user?.id){
        try{
          const res = await API.post('/users/send', {
              sid: state?.user?.id,
              rid: props.forUserId
          });
          if(res?.data){
            getConnectionsStatus();
            message.success({
                content: 'Solicitud enviada con éxito',
                className: 'custom-class',
            });
            //let receiverId = props.forUserId;
            /* props.socket.current.emit("sendDesktopNotification", {
              receiverId,
            });
            //trigger to notifications to devices
            var uid = state.user.id;
            API.post(
              `/users/fcm/notifications/web`,
              {
                "title": state?.user?.firstname +' '+state?.user?.lastname,
                "body": 'quiere ser tu conexión',
                "type":"request",
                "senderuid":uid,
                "receiverId": receiverId
              },
              {
                headers: {
                  Authorization: `Bearer ${state.token}`,
                },
              }
            ) */
          }               
        } catch (err) {
          console.log(err);
        }
      }
    }

    const accept = async (e, aid) => {
        API.post('/users/acp',{
          sid:aid,
          rid: state?.user?.id
        }).then(res =>{
            getConnectionsStatus();
            message.success({
                content: 'Conexión aceptada',
                className: 'custom-class',
              });
              let receiverId = aid;
              /* props.socket.current.emit("sendDesktopNotification", {
                receiverId,
              });
              //trigger to notifications to devices
              var uid = state?.user?.firstname +' '+state?.user?.lastname;
              API.post(
                `/users/fcm/notifications/web`,
                {
                  "title": state.user.id,
                  "body": 'aceptó tu solicitud de conexion',
                  "type":"accepted",
                  "senderuid":uid,
                  "receiverId": receiverId
                },
                {
                  headers: {
                    Authorization: `Bearer ${state.token}`,
                  },
                }
              ) */
        }).catch(err => {
          console.log(err);
        });
    }
    return (
        
        <>{props.forUserId !== state?.user?.id
          ?
            friendState != null && friendState[0].requester == state?.user?.id && friendState[0].recipient == props.forUserId && friendState[0].status == 1
            ? <div className='connect-icon sent' title="Ya esta enviado">Solicitud enviada</div>
            : friendState != null && friendState[0].recipient == state?.user?.id  && friendState[0].requester == props.forUserId && friendState[0].status == 1
            ?  <div className='connect-icon received' title="Accept Request" onClick={(e)=>{accept(e,props.forUserId)}}>Request received</div>
            : friendState != null && friendState[0].requester == state?.user?.id && friendState[0].recipient == props.forUserId && friendState[0].status == 2
            ? <div className='connect-icon connected' title="Conectado">Conectado</div>
            : friendState != null && friendState[0].recipient == state?.user?.id  && friendState[0].requester == props.forUserId && friendState[0].status == 2
            ? <div className='connect-icon connected' title="Conectado">Conectado</div>
            : <div className='connect-icon' onClick={(e)=>{sending(e)}} title="Enviar">Connect</div>
            :null}
           <Modal
            visible={visibletosubscribe}
            okText="Actualizar membresía"
            cancelText="Cancelar"
            onOk={handleRedirectPricing}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>Actualiza tu membresía para enviar más solicitudes de conexión, ya que alcanzaste el límite de solicitudes de conexiones</div>
         </Modal> 
        </>
    );
};

export default ConnectButton;
