import React, {useContext, useState, useEffect, useRef,Suspense} from 'react';
import { Skeleton } from 'antd';
/* import Conversation from '../../components/Conversations/Conversation'; */
import Message from '../../components/Message/Message';
import Page from '../../components/Page/Page';
import './messenger.css';
import { AuthContext } from '../../App';
import API from '../../utils/API';
import io from "socket.io-client";
import ConversationHead from '../../components/Conversations/ConversationHead';
import ChatOnline from  '../../components/ChatOnline/ChatOnline';
import { useLocation } from 'react-router-dom';

const Conversation = React.lazy(() => import("../../components/Conversations/Conversation"));

const Messenger = (props) => {
    const [conversations, setConversations] = useState([]);
    const [friends, setFriends] = useState([]);
    const [messages, setMessages] = useState([])
    const [currentChat, setCurrentChat] = useState(null);
    const [newMessage, setNewMessage] = useState("");
    const [arrivalMessage, setArrivalMessage] = useState(null);
    const [onlineUsers, setOnlineUsers] = useState([]);
    const socket = useRef();
    const { state } = useContext(AuthContext);
    const scrollRef = useRef();
    const location = useLocation();
    const [activeClass, setActiveClass] =useState(null);
    const markRead = async (c, readby) =>{
        setCurrentChat(c);
        const resread = await API.post("/conversations/mread", {
            c:c._id,
            b:readby,
        });
    }
    useEffect(() => {
        let currentURL = window.location.href;

        if(currentURL.includes("comunidad.enlacancha")) {
            socket.current = io("wss://comunidad.enlacancha.us");
        }else if(currentURL.includes("enlacancha")) {
            socket.current = io("wss://enlacancha.us");
        }else if(currentURL.includes("hurekadev")) {
            socket.current = io("wss://elc.hurekadev.info");
        }else if (currentURL.includes("localhost")) {
            socket.current = io("ws://localhost:8000");
        }
        
        socket.current.on("getMessage", (data) => {
            setArrivalMessage({
              sender: data.senderId,
              text: data.text,
              receiverId: data.receiverId,
              createdAt: Date.now(),
            });
          });
    }, []);

    useEffect(() => {
        const getFriends = async () => {
            try {
                const res = await API.get("/users/f/"+state?.user?.id);
                if(res.data?.friend?.friends){
                    setFriends(res.data.friend.friends);
                }
            }catch(err){
                console.log(err);
            }
        };
        getFriends();
      }, [state?.user?.id]);
    
    useEffect(() => {
        arrivalMessage &&
          currentChat?.members.includes(arrivalMessage.sender) && currentChat?.members.includes(arrivalMessage.receiverId) &&
          setMessages((prev) => [...prev, arrivalMessage]);
      }, [arrivalMessage, currentChat]);

    useEffect(()=>{
       //socket.current.emit("addUser", state?.user?.id);
       socket.current.on("getUsers", users => {
        setOnlineUsers(users);
       })
    }, [state?.user?.id])

    useEffect(() => {
        const getConversations = async () => {
          try {
            const res = await API.get("/conversations/"+state?.user?.id);
            setConversations(res.data);
          } catch (err) {
            console.log(err);
          }
        };
        getConversations();
      }, [state?.user?.id]);
    //get messages
    useEffect(() => {
        const getMessages = async () => {
          try {
            const res = await API.get("/messages/" + currentChat?._id);
            setMessages(res.data);
          } catch (err) {
            console.log(err);
          }
        };
        getMessages();
      }, [currentChat]);

    const handleSubmit = async (e) => {
        if(e.keyCode == 13 && e.shiftKey == false && newMessage.trim().length > 0) {
            const message = {
                sender: state?.user?.id,
                text: newMessage,
                conversationId: currentChat._id,
            };

            e.preventDefault();

            const receiverId = currentChat.members.find(
                (member) => member !== state?.user?.id
            );
        
            socket.current.emit("sendMessage", {
                senderId:state?.user?.id,
                receiverId,
                text: newMessage,
            });
            try {
                const res = await API.post("/messages", message);
                setMessages([...messages, res.data]);
                let newMessageTemp = newMessage;
                setNewMessage("");
                const resread = await API.post("/conversations/unread", {
                    id:currentChat._id,
                    receiverId:receiverId,
                });
                socket.current.emit("sendMessageNotification", {
                    receiverId,
                });
                //trigger to notifications to devices
                var uid = state.user.id+"_"+currentChat._id;
                API.post(
                `/users/fcm/notifications/web`,
                {
                    "title": state?.user?.firstname +' '+state?.user?.lastname,
                    "body": newMessageTemp,
                    "type":"messages",
                    "senderuid":uid,
                    "receiverId": receiverId
                },
                {
                    headers: {
                    Authorization: `Bearer ${state.token}`,
                    },
                }
                )
            } catch (err) {
                console.log(err);
            }
        }
    }
    const handleSubmitButton = async (e) => {
        e.preventDefault();
        if(newMessage.trim().length <= 0){
            return;
        }
        const message = {
            sender: state?.user?.id,
            text: newMessage,
            conversationId: currentChat._id,
        };
        const receiverId = currentChat.members.find(
            (member) => member !== state?.user?.id
        );
        socket.current.emit("sendMessage", {
            senderId:state?.user?.id,
            receiverId,
            text: newMessage,
        });
        try {
            const res = await API.post("/messages", message);
            setMessages([...messages, res.data]);
            let newMessageTemp = newMessage;
            setNewMessage("");
            const resread = await API.post("/conversations/unread", {
                id:currentChat._id,
                receiverId:receiverId,
            });
            socket.current.emit("sendMessageNotification", {
                receiverId,
            });
            //trigger to notifications to devices
            var uid = state.user.id+"_"+currentChat._id;
            API.post(
            `/users/fcm/notifications/web`,
            {
                "title": state?.user?.firstname +' '+state?.user?.lastname,
                "body": newMessageTemp,
                "type":"messages",
                "senderuid":uid,
                "receiverId": receiverId
            },
            {
                headers: {
                Authorization: `Bearer ${state.token}`,
                },
            }
            )
        } catch (err) {
            console.log(err);
        }
    }
    //scrollbottom of chat
    useEffect(() => {
        scrollRef.current?.scrollIntoView({ behavior: "smooth", block: 'nearest', inline: 'start' });
        if(location?.search && !currentChat){
            const getCurrentChat = async () => {
                const chatId = location.search.replace('?chat=',"");
                const res = await API.get("/conversations/con/" +chatId);
                setCurrentChat(res.data);
            }
            getCurrentChat();
        }
      }, [messages,currentChat]);

    return (
        <Page>
            <div className="maincontent messengerpage fullpage">
                <div className="messenger">
                    <div className="chatMenu">
                        <div className="chatMenuWrapper">
                            {/* <input placeholder="Search for friends" className="chatMenuInput" /> */}
                            <h2>Conversaciones</h2>
                            {conversations.map((c, index) => (
                                <Suspense fallback={<Skeleton avatar paragraph={{ rows: 2 }} />} key={index}>
                                    <div className="chatMenuItem" onClick={() => markRead(c,state?.user?.id)}>
                                        <Conversation conversation={c} currentUser={state}  onlineUsers={onlineUsers}/>
                                    </div>
                                </Suspense>                              
                            ))}
                        </div>
                    </div>
                    <div className="chatBox">
                        <div className="chatBoxWrapper">
                            {
                                currentChat?
                            <>
                                <ConversationHead convesationhead={currentChat} currentUser={state}/>
                                <div className="chatBoxTop">
                                    
                                    {messages.map((m, index)=>{
                                       return <div ref={scrollRef} key={index}>
                                         <Message message={m} own={m.sender === state.user.id} key={index}/>
                                        </div>
                                    })}
                                   
                                </div>
                                <div className="chatBoxBottom">
                                    <textarea
                                        className="chatMessageInput"
                                        placeholder="Escribe algo..."
                                        onChange={(e) => setNewMessage(e.target.value)}
                                        value={newMessage}
                                        onKeyDown={(e) => {
                                            handleSubmit(e);
                                          }}
                                    ></textarea>
                                    <button className="chatSubmitButton" onClick={handleSubmitButton}>
                                        Enviar
                                    </button>
                                </div>
                            </>
                                : <span className="noConversationText">Abra una conversación para iniciar un chat</span>}
                        </div>
                    </div>
                    {/* <div className="chatOnline">
                        <div className="chatOnlineWrapper">
                            <h2>Mi red</h2>
                            {friends.map((f) => (
                                <Suspense fallback={<Skeleton avatar paragraph={{ rows: 2 }} />}>
                                    <div className="chatOnlineMenuItem">
                                        <ChatOnline
                                            onlineUsers={onlineUsers}
                                            currentId={state}
                                            friends={f}
                                            setCurrentChat={setCurrentChat}
                                        />
                                    </div>
                                </Suspense>                              
                            ))}
                            
                        </div>
                    </div> */}

                </div>
            </div>
        </Page>
    )
}

export default Messenger;