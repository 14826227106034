import React, { useContext, useState} from 'react';
import {Modal } from 'antd';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
/* import reducer from '../../utils/reducer'; */
/* import API from '../../utils/API'; */
import Page from '../../components/Page/Page';
import EducationBlock from '../../components/EducationBlock/EducationBlock';
import TestimonialFront from '../../components/TestimonialFront/TestimonialFront';
import styles from './Front.module.css';
import bannerImg from '../../assets/images/BannerImage.jpeg';
import API from '../../utils/API';
import { useHistory } from 'react-router-dom';
import ReactPlayer from "react-player";

const Home = (props) => {
  const { state } = useContext(AuthContext);
  const [visible, setModalState ] = useState(true);
  const [dealerOnboard, setDealerOnboard ] = useState(true);
  const [confirmLoading, setConfirmLoading] = React.useState(false);
  const [modalText, setModalText] = React.useState('Estamos muy emocionados de que vas a meterte a la cancha de bienes raices! Para que puedas acceder a todos los benficios de tu cuenta.');
  const [modalTextDealerOnboard, setModalTextDealerOnboard] = React.useState('Agradecemos sinceramente su interés en formar parte del portal de Tratos.');
  const token = props.match.params.token;
  const { dispatch } = useContext(AuthContext);
  const history = useHistory();
  let allparam = props.match.url.split('/');
  let openDealer = false;
  let openEmailVerify = false;
  if(allparam[1] === 'activation' && allparam[2] === 'emailconfirm' && allparam[3] === 'dealer' && allparam[4]=== 'reset' && token){
    openDealer = true
  }else if(allparam[1] === 'activation' && allparam[2] === 'emailconfirm' && token){
    openEmailVerify = true
  }
  if(state.isAuthenticated){
    return <Redirect to="/dashboard"/>;
  }
  window.onbeforeunload = function() {
    localStorage.clear();
  }
  const verifyEmail = () => {
    setModalText('Validación de correo electrónico...');
    setConfirmLoading(true);
    API.post('/users/authentication/activate', {
      token: token,
    })
      .then((response) => {
        setModalState(false);
        setConfirmLoading(false);
        return API.post('/users/login', {
          email: response.data.data.email,
          password: response.data.data.password,
        });
      })
      .then(result => {
        /* After PreLauch Code */
        dispatch({
          type: 'LOGIN',
          payload: {
            token: result.data.token,
            user: result.data.user,
          },
        });
        history.push('/help-us');   
        Modal.success({
          content: '¡Su correo electrónico ha sido verificado correctamente!',
        });
        /* After PreLauch Code End */
      })
      .catch(e => {
        setModalState(false);
        setConfirmLoading(false);
        Modal.error({
          title: e.response.data.msg,
        });
      });
  };
  const onboardDealer = () => {
    setConfirmLoading(true);
    API.post('/users/authentication/activate/dealer', {
      token: token,
    })
    .then(result => {
      console.log(result)
      setDealerOnboard(false)
      setConfirmLoading(false);
      dispatch({
        type: 'LOGIN',
        payload: {
          token: result.data.token,
          user: result.data.user,
        },
      });
      history.push('/change-password');
    })
    .catch(e => {
      setDealerOnboard(false)
      setConfirmLoading(false);
      console.log(e)
      Modal.error({
        title: e?.response?.data?.msg,
      });
    });
  };
  return (

    <Page>
      <div className={styles.bannerarea}
        style={{backgroundImage: "url(" + bannerImg + ")"}}
      >
        <div className={styles.bannercontent}>
          {/* <div className={styles.shortdescription}>
          Los hispanos en estados unidos no solo somos trabajadores de construcción, limpieza o restaurante, también somos inversionistas de bienes raíces y juntos llegaremos a la libertad financiera.</div> */}
        </div>
      </div>
      <EducationBlock/>
      <TestimonialFront/>

      {openEmailVerify && 
        <Modal
        visible={visible}
        type="success"
        onOk={verifyEmail}
        okText="Verificado"
        keyboard="false"
        centered="true"
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        closable={false}
        confirmLoading={confirmLoading}
      >
          <p>{modalText}</p>
        </Modal>
        }
        {openDealer && <Modal
        visible={dealerOnboard}
        type="success"
        onOk={onboardDealer}
        okText="Continue"
        keyboard="false"
        centered="true"
        cancelButtonProps={{
          style: {
            display: "none",
          },
        }}
        closable={false}
        confirmLoading={confirmLoading}
      >
          <p>{modalTextDealerOnboard}</p>
        </Modal>}
    </Page>
  );
};

export default Home;
