import React, { useContext,useState }  from "react";
import { Form, Input ,Radio ,Checkbox ,Button,Modal,message,Select} from "antd";
import { AuthContext } from '../../App';
import { useHistory } from 'react-router-dom';
import API from '../../utils/API';
import RafikiImage from '../../assets/rafiki.png';
import Curious from '../../assets/Curious.png';
import Address from '../../assets/Address.png';
import Aroundworld from '../../assets/Aroundworld.png';
import axios from "axios";

function MyStepForm() {
  const [stepForm] = Form.useForm();
  var zip = /^[0-9]+$/;
  const history = useHistory();
  const { state } = useContext(AuthContext);
  const [interest,setInterest] = React.useState([]);
  const [selectInterest,setSelectInterest] = React.useState([])
  const [category,setCategory] = React.useState(undefined);
  const [currentCity,setCurrentCity] = React.useState("")
  const [currentstate,setCurrentState] = React.useState("")
  const [currentZipcode,setCurrentZipcode] = React.useState("")
  const [selectCategory,setSelectCategory] = React.useState([])
  const [current, setCurrent] = React.useState(0);
  React.useEffect(()=>{
    API.get('/roles/')
    .then(result => {
     const arr = []
     result.data.roles.map(name => {
      const cat = name.name
      const _id = name._id
     
      arr.push({name:cat,_id:_id})
      
     })
    setSelectCategory(arr)
  
 
    })
    .catch(e => {
      Modal.error({
        title: 'Ocurrió un error',
        content: e.message,
      });
    });

    API.get('/interests/')
    
    .then(result => {
      const arr = []
      
      result.data.interets.map(name => {
        const cat = name.name
        const _id = name._id
       
        arr.push({name:cat,_id:_id})
        
      })
       
      setSelectInterest(arr)
    
      
    
    })
    .catch(e => {
      Modal.error({
        title: 'Ocurrió un error',
        content: e.message,
      });
    });
   
  
  },[])

  const next = () => {
    var clientKey = "egFQ8a65VvfiEyrGVjdesL1XTPPtrv9oFa6Cb8Lo1FgqJzXZ0H0uqOCzdue4OfPp";
    var zipcode = '20001'
    axios.get("https://www.zipcodeapi.com/rest/"+clientKey+"/info.json/" + zipcode + "/radians",{
     
      headers: {
        "access-control-allow-origin" : "*",
        "Content-type": "application/json; charset=UTF-8"
      }}).then(result => {
      

    })
    window.scrollTo({
      top: 0
    });
    setCurrent(current + 1);
  };

  const prev = () => {
    window.scrollTo({
      top: 0
    });
    setCurrent(current - 1);
  };
  const onStart = (city,pstate,zipcode) => {
      
      const data = {
        primarycategory:category,
          interests:interest,
          currentlocation:{city:currentCity,state:currentstate,zipcode:currentZipcode},
          primaryinvestmentlocation:{city:city,state:pstate,zipcode:zipcode},
         
      }
    
      API.post('/userinfo/adduserinfo', {
        roles:category,
          interests:interest,
          currentlocation:{city:currentCity,state:currentstate,zipcode:currentZipcode},
          primaryinvestmentlocation:{city:city,state:pstate,zipcode:zipcode},
          userid:state.user.id,
          firstname:state.user.firstname,
          lastname:state.user.lastname
         
      }, 
      )
        .then(() => {

         
        
        message.success('tu perfil ha sido actualizado exitosamente', 4);
       history.push('/dashboard');
       
        })
        .catch(e => {
         
         console.log(e);
       });
    
  }

  const Step1Form = () => {
      const [showerror,setError] = React.useState(false)
      const handleOnChange = e => {
        e.preventDefault();
        
      const cat = e.target.value
    
      setCategory(cat)

      }
      const onNext = () => {
          window.scrollTo({
            top: 0
          });
          if(category !== undefined){
            
          next()
        } else {
            setError(true)
        }
      }
    return (
        <div className="contentarea step1Form">
            <div className="leftcontent">
                <div className="helpuswrapper">
                    <div className="question">
                        <h2>¿Cuál es tu role principal en bienes raices?</h2>
                        {/* <p><b>nota:</b> También puedes seleccionar la función secundaria en su perfil</p> */}
                    </div>
                    <div className="radioboxitem"   >
                        
                    <Radio.Group onChange = {handleOnChange} name="radiogroup" value = {category} >
                           {selectCategory.map(res => <Radio value = {res._id} >{res.name}</Radio>)}
                    </Radio.Group>
                   
                    </div>
                    {showerror &&<div className = "error">* Seleccione el rol principal</div>}
                    <div className="actionbutton">
                        <Button className="nextstep" type="primary" onClick={onNext}>Siguiente</Button>
                    </div>
                </div>
            </div>
            <div className="rightcontent">
               <div className=""><img src={RafikiImage} /></div>
            </div>
        </div>
    );
  };

  const Step2Form = () => {
    const [showerror,setError] = React.useState(false)
    const [size,isSize] = React.useState(false)
    const onChange = (value) => {
        setInterest(value)
         }
         const onNext = () => {
            window.scrollTo({
              top: 0
            });
            if(interest.length !== 0){
            if(interest.length <4){
                next()
            } else {
                isSize(true)
            }
           
          } else {
              setError(true)
          }
        }
    return (
        <div className="contentarea step2Form">
            <div className="leftcontent">
                <div className="helpuswrapper">
                    <div className="question">
                        <h2>¿Cuáles son tus intereses en bienes raices?</h2>
                        <p><b>nota:</b> Puedes seleccionar cualquiera de estos, hasta 3 intereses</p>
                    </div>
                    <div className = "checkboxitem" >
                    <Form.Item
                    name = "interests">
                                    <Checkbox.Group  name="realestateinvesting" onChange = {onChange}>
                         {selectInterest.map(int =><Checkbox value={int._id}>{int.name}</Checkbox>)}
                      
                    </Checkbox.Group></Form.Item>


                    </div>
                    {showerror &&<div className = "error">* Por favor seleccione intereses</div>}
                    {size &&<div className = "error">* Seleccione hasta 3 intereses</div>}
                    <div className="actionbutton">
                        <Button className="prevstep" onClick={() => prev()}>Previo</Button>
                        <Button className="nextstep" type="primary" onClick={onNext}>Siguiente</Button>
                    </div>
                </div>
            </div>
            <div className="rightcontent">
                <div className=""><img src={Curious} /></div>
            </div>
        </div>
    );
  };

  const Step3Form = () => {
  
          
    const onNext = () => {
       const value = stepForm.getFieldsValue()
      
        if(value.currentcity !== ""  && value.currentstate !== "" && value.currentcityzipcode !== ""){
            if(value.currentcity !== undefined  && value.currentstate !== undefined && value.currentcityzipcode !== undefined){
         
            setCurrentState(value.currentstate)
            setCurrentCity(value.currentcity)
            setCurrentZipcode(value.currentcityzipcode)
          next()
      } }
    }
    
       const onhandlezipcode = (e) => {

        axios.get(`https://api.zippopotam.us/us/${String(e.target.value)}`).then(data=> {
          if(data){
          stepForm.setFieldsValue({currentstate:data?.data?.places[0]['state']+" ("+data?.data?.places[0]['state abbreviation']+")"})
           stepForm.setFieldsValue({currentcity:data?.data?.places[0]['place name']})
            } else{
              stepForm.setFieldsValue({currentstate:''})
              stepForm.setFieldsValue({currentcity:''})
            }
        }).catch(err=> {
          stepForm.setFieldsValue({currentstate:''})
          stepForm.setFieldsValue({currentcity:''})
        })
       }
       
    return (
        <div className="contentarea step4Form">
            <div className="leftcontent">
                <div className="helpuswrapper">
                    <div className="question">
                    <h2>¿Donde estas localizado?</h2>
                        <p><b>nota:</b> Según tu código postal; el estado y la ciudad serán seleccionados</p>

                    </div>
                    <div className="locations">
                    <Form.Item
                            name="currentcityzipcode"
                            className="formitem"
                            rules={[
                                {
                                  required: true,
                                  //message: 'Entra tu primer nombre',
                                  validator: (_, value) => {
                                    if(typeof(value) == "undefined" || !value){
                                     return Promise.reject(new Error('Por favor ingrese el código postal'));
                                    }else if(zip.test(value)){
                                       return Promise.resolve();
                                     }
                                     return Promise.reject(new Error('Ingrese un código postal válido'));
                                   },
                                },
                              ]}
                        >
                            <Input 
                            onChange = {onhandlezipcode}  
                            placeholder="Por favor ingrese tu código postal *" 
                            className="formtext"
                            autoComplete="stop"
                            />
                        </Form.Item>
                    <Form.Item
                            name="currentstate"
                            className="formitem"
                            rules = {[
                                {
                                    required:true,
                                    message:"Este espacio es obligatorio"
                                }
                            ]}
                        >
                            <Input placeholder="Por favor ingresa tu estado *" className="formtext" />
                        </Form.Item>
                        <Form.Item
                            name="currentcity"
                            className="formitem"
                            rules = {[
                                {
                                    required:true,
                                    message:"Este espacio es obligatorio"
                                }
                            ]}
                        >
                            <Input placeholder="Por favor ingresa tu ciudad *" className="formtext"/>
                        </Form.Item>
                    </div>
                   
                    <div className="actionbutton">
                        <Button className="prevstep" onClick={() => prev()}>Previo</Button>
                        <Button  onClick = {onNext} type="primary" htmlType="submit">Siguiente</Button>
                    </div>
                </div>
            </div>
            <div className="rightcontent">
                <div className=""><img src={Address} /></div>
            </div>
        </div>
    );
  };

  const Step4Form = () => {

 
    const [showerror,setError] = React.useState(false)
    const onChange = (e) => {
     const status = e.target.checked;
   
     if(status){

        stepForm.setFieldsValue({state:currentstate})
        
       stepForm.setFieldsValue({city:currentCity})
    
       stepForm.setFieldsValue({cityzipcode:currentZipcode})
     
      
     } else {
    
       
        stepForm.setFieldsValue({state:""})
        stepForm.setFieldsValue({city:""})
        stepForm.setFieldsValue({cityzipcode:""})
        setError(false)
     
     }
    
    }
 
    const onNext =  () => {
        window.scrollTo({
          top: 0
        });
        var value = stepForm.getFieldsValue()
         
         if((value.city !== "" && value.state !== "" && value.cityzipcode !== "" ) ){
            if(value.city !== undefined && value.state !== undefined && value.cityzipcode !== undefined) {
              
          
           onStart(value.city,value.state,value.cityzipcode)
       
     }}}
     const onhandlezipcode = (e) => {
      axios.get(`https://api.zippopotam.us/us/${String(e.target.value)}`).then(data=> {
        if(data){
        stepForm.setFieldsValue({state:data?.data?.places[0]['state']+" ("+data?.data?.places[0]['state abbreviation']+")"})
         stepForm.setFieldsValue({city:data?.data?.places[0]['place name']})
          } else{
            stepForm.setFieldsValue({state:''})
            stepForm.setFieldsValue({city:''})
          }
      }).catch(err=> {
        stepForm.setFieldsValue({state:''})
        stepForm.setFieldsValue({city:''})
      })
    }
        
    return (
        <div className="contentarea step4Form">
            <div className="leftcontent">
                <div className="helpuswrapper">
                    <div className="question">
                        <h2>¿Cuál es tu ubicación principal de inversión?</h2>
                        <p><b>nota:</b> Los campos marcados con <span className="requiredsymbol">*</span> son obligatorios. Puedes agregar una locazacion de inversión secundaria en su perfil</p>
                    </div>
                    <div className="mylocations"><Checkbox  onClick = {onChange}>La misma que mi localizacion</Checkbox></div>
                    <div className="locations">
                    <Form.Item
                            name="cityzipcode"
                            className="formitem"
                            rules={[
                                {
                                  required: true,
                                  //message: 'Entra tu primer nombre',
                                  validator: (_, value) => {
                                    if(typeof(value) == "undefined" || !value){
                                     return Promise.reject(new Error('Por favor ingrese el código postal'));
                                    }else if(zip.test(value)){
                                       return Promise.resolve();
                                     }
                                     return Promise.reject(new Error('Ingrese un código postal válido'));
                                   },
                                },
                              ]}
                        >
                            <Input 
                            onChange = {onhandlezipcode}  
                            placeholder="Por favor ingrese tu código postal *" 
                            className="formtext"
                            autoComplete="stop"/>
                        </Form.Item>
                        <Form.Item
                            name="state"
                            className="formitem"
                            rules = {[
                                {
                                    required:true,
                                    message:"Este espacio es obligatorio"
                                }
                            ]}
                        >
                            <Input    placeholder="Por favor ingresa tu estado *" className="formtext"/>
                        </Form.Item>
                        <Form.Item
                            name="city"
                            className="formitem"
                            rules = {[
                                {
                                    required:true,
                                    message:"Este espacio es obligatorio"
                                }
                            ]}
                        >
                            <Input  placeholder="Por favor ingresa tu ciudad" className="formtext"/>
                        </Form.Item>
                      
                    </div>
                    
                    <div className="actionbutton">
                        <Button className="prevstep" onClick={() => prev()}>Previo</Button>
                        <Button onClick = {onNext} type="primary" htmlType="submit">Comienza</Button>
                    </div>
                </div>
            </div>
            <div className="rightcontent">
                <div className=""><img src={Aroundworld} /></div>
            </div>
        </div>
    );
  };

  const onFinish = (fieldsValue) => {
  };

  const steps = [
    {
      step: 1,
      title: "¿Cuál es tu función principal en bienes raices?",
      content: <Step1Form />
    },
    {
      step: 2,
      title: "¿Cuáles son tus intereses en bienes raices?",
      content: <Step2Form />
    },
    {
        step: 3,
        title: "¿Donde estas localizado?",
        content: <Step3Form />
    },
    {
        step: 4,
        title: "What is your Primary Investment Location ?",
        content: <Step4Form />
    }
  ];
  return (
      <Form onFinish = {onFinish} form = {stepForm}>
        <div className="steps-content">{steps[current].content}</div>
        </Form>
  );
}
export { MyStepForm };