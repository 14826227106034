import React, { useState, useEffect, useContext } from 'react';
import Page from '../../components/Page/Page';
import API from '../../utils/API';
import { Link, useHistory } from 'react-router-dom';
import noavtar from '../../assets/no-avatar.png';
import './ConnectionNetwork.css'
import { AuthContext } from '../../App';
import {Skeleton, message} from 'antd';
import convertDate from '../../utils/convertDate';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import Rolename from '../../components/RolesName/RolesName';
import MessageButton from '../../components/WidgetBar/MessageButton';
import ConnectionsNav from './ConnectionsNav';

const Connections = props => {
    
  const { state } = useContext(AuthContext);
  const [friends, setFriends] = useState([]);
  const [isLoading, setisLoading] = useState(true);
  const [newSearchText, setNewSearchText] = useState('');
  const queryParams = props.location.search;
  const search = new URLSearchParams(queryParams).get('search');
  const history = useHistory();

  useEffect(() => {
    if(search !== null){
      setNewSearchText(search);
    }
    getConnections();
  }, [state?.user?.id]);

  const getConnections = async () => {
    if( state?.user?.id){
        try {
            const res = await API.get("/users/f/"+state?.user?.id);
            if(res?.data?.friend?.friends?.length){
              setFriends(res.data.friend.friends);
            }else{
              setFriends([]);
            }
            setisLoading(false);
          } catch (err) {
            console.log(err);
          }
    }
  };

  const remove = async (e, aid) => {
    API.post('/users/rcr',{
      sid:aid,
      rid: state?.user?.id
    }).then(res =>{
      getConnections();
      message.success({
        content: 'Conexión eliminada',
        className: 'custom-class',
      });
    }).catch(err => {
      console.log(err);
    });
  }
  const handelSearchSubmit = async (e) =>{
    e.preventDefault();
    history.push(`/user/connections?search=${newSearchText}`);
    getConnections();
  }
  return (
    <Page>     
      <div className="maincontent connections">
        <div className="connectionstabs">
          <div className="connectionsearch">
            <div className="searchbox">
                {state.isAuthenticated && <div className='userpicbox'>
                  <ProfileImage id={state?.user?.id}/>
                </div>}
              <div className="formbox">
                  <div className="form-item search-filter">
                    <input 
                    type="text"
                    name="search" 
                    className="formtext"
                    placeholder="Búsqueda por nombre, ciudad, estado, código postal"
                    onChange={(e) => setNewSearchText(e.target.value)}
                    value={newSearchText != null ? newSearchText : ''}
                    />
                    </div>
                  <div className="form-action"><input type="button" className="form-submit" value="Submit" onClick={(e)=>handelSearchSubmit(e)}/></div>
              </div>
            </div>
          </div>
          <div className="connectionlink">
            <ConnectionsNav connection="active"/>
          </div>
        </div>
        <div className="connectionsview">
          <div className="maintitle"><h2>Mi red</h2></div>
          {isLoading == true
            ? <div className='loading'>
                <div className="view-row">
                  <Skeleton avatar active={true} paragraph={{ rows: 4 }} />
                </div>
                <div className="view-row">
                  <Skeleton avatar active={true} paragraph={{ rows: 4 }} />
                </div>
                <div className="view-row">
                  <Skeleton avatar active={true} paragraph={{ rows: 4 }} />
                </div>
            </div>
            : friends != null & friends.length > 0 ?
            <div className="connectionscontent">
            {friends.map((item, index) => {
              let fullname = item?.connecteduser?.firstname + ' ' + item?.connecteduser?.lastname;
              fullname = fullname.toLowerCase();
              if (fullname.includes(newSearchText?.toLowerCase())){
                return <div className="view-row">
                  <div className="userpic">
                    {item?.connecteduser?.pathalias?.alias
                    ?
                    <Link to={item?.connecteduser?.pathalias?.alias}>
                      <ProfileImage id={item?.connecteduser?._id} classtouse="connectImg"/>
                    </Link>
                    :<ProfileImage id={item?.connecteduser?._id} classtouse="connectImg"/>
                    }
                  </div>
                  <div className="content">
                    {item?.connecteduser?.pathalias?.alias
                      ?
                      <div className="name"><Link to={item?.connecteduser?.pathalias?.alias}>
                        {item?.connecteduser?.firstname} {item?.connecteduser?.lastname}
                      </Link></div>
                      : <div className="name">{item?.connecteduser?.firstname} {item?.connecteduser?.lastname}</div>
                    }

                    <div className="category">{item?.connecteduser?.userinfoid?.roles && <Rolename id = {item?.connecteduser?.userinfoid?.roles}/>}</div>
                    <div className="connected">Connected over {convertDate(item?.connecteddate)}</div>
                    <div className="formaction">
                      <button className="removebtn" onClick={e=>{remove(e,item?.connecteduser._id)}}>Eliminar conexión</button>
                      <MessageButton forUserId={item?.connecteduser?._id}/>
                    </div>
                  </div>
                </div>
              }
              })
            }
            </div>
               : <div className='no-result'>Construye su red</div>
            }
          </div>
      </div>
    </Page>
  );
};

export default Connections;
