import React, { useContext, useState ,useEffect} from 'react';
import API from '../../utils/API';
import Moment from 'moment'
const LenderDetail = props => {
const [loading, setLoading] = useState(false)
const [lenderdata, setLenderdata] = useState([])

  useEffect(()=>{
    let isMounted = true;
    API.get('/users/fetchtata/basic/l/i/'+props.lid+'/'+props.lrnid).then(result => {
      if (isMounted){
        setLenderdata(result?.data?.ldata);
        setLoading(true)
      }
    })
    return () => { isMounted = false };
  },[props.lid])

    return (
    <>
    {loading && 
    <div className='l-items'>
      <div className='l-items-left'>
        <div className="l-item l-name"><span>Nombre:</span><span>{lenderdata?.cname} </span></div>
        <div className='l-iteml officeemail'><span>Correó electrónico:</span><span>{lenderdata?.officeemail}</span></div>
        <div className="l-iteml-comp-address"><span>Teléfono:</span><span> {lenderdata?.officephone}</span></div>
      </div>
      <div className='l-items-right'>
        <span class="transation-status">{lenderdata.interestedLoan[0]?.transaction}</span>
        {lenderdata.interestedLoan[0]?.loanamount && 
          <>
            <div>
              <span>Monto de prestamo: </span>
              <span>${lenderdata?.interestedLoan[0]?.loanamount}</span>
            </div>
            <div className='transactiondata text-cap'>
              <span>Aprobado en:</span>
              <span>{Moment(lenderdata?.interestedLoan[0]?.transactiondate).format('DD-MMM-YYYY')}</span>
            </div>
          </>        
        }
      </div>

    </div>}
    </>
    )
};

export default LenderDetail;
