import React, { useState } from 'react';
import { Carousel, Modal, Spin } from "antd";
import ReactPlayer from "react-player";
import './TestimonialFront.css';
import axios from 'axios';

const TestimonialFront = props => {
   const [visible, setVisible] = useState(false);
   const [videoid, setVideoid] = useState(null);
   const [loading, setLoading] = useState(true);
   const [imageLoading, setImageLoading] = useState(true);
   /* const [thumburl, seturl] = React.useState([]); */
   const [imgdata, setImgdata] = useState([]);

   let videoList = [752944200, 752941163, 752941882, 752942729];
   //, 597636557, 597638862, 597640490, 597644012, 597645718

   const showModal =(id) => {
      setVisible(true);
      setVideoid(id);
      document.body.classList.add('modalopen');
   }
   const handleCancel = () => {
      setVisible(false);
      setVideoid(null);
      setLoading(true);
      document.body.classList.remove('modalopen');
   };
   React.useEffect(() => {
      //var that = this;
      //let Vimeo = require('vimeo').Vimeo;
      //let client = new Vimeo("{client_id}", "{client_secret}", "{access_token}");
      //let client = new Vimeo("721e84fa5be48b9b62e753ae08747a2f6f8d748d", "InC25cSCXjI9NdWvPPvjBX+akYs+qqny30FrRVyiJUPo2KjePXENmNEapS8gj3CVnO5SeOCjTetCiSNWW7GOgm8N99t8GtyaLaTHI0A4bLdDKYE6Dj4/w1yNqKtmdkxc", "36273e305fdd9a218b7b54380e2a1025");
      const iddata = [];
      Promise.all(videoList.map((videoid, index, arr) => (
         /* client.request({
            method: 'GET',
            path: '/videos/'+Number(videoid)
            }, function (name, embed) {
               iddata[index] = embed?.pictures?.base_link;
               if(arr.length - 1 === index){
                  setImageLoading(false);
                  setImgdata(iddata);
               }
            }) */
         axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(videoid))
         .then(res => {
           const imageUrl = res.data.thumbnail_url.split('_');
           iddata[index] = imageUrl[0];
          
           if(arr.length - 1 === index){
            setImageLoading(false);
            setImgdata(iddata);
           }
         })
      )))
   }, []);
  
   const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
             breakpoint: 1119,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1,
             }
         },
         {
             breakpoint: 767,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         },
         {
             breakpoint: 480,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         }
      ]
   }

   return (
   <div className="testinomialsliderwrap">
      <div className="viewtestinomialslider">
         <h2 className="blocktitle">Testimonios</h2>
         <div className="viewcontent">
            {!imageLoading ?               
               <Carousel {...settings} >
               {videoList.map((vimeoid, index) =>
                  <div className="viewrow" key={index}>
                     <div className="imagebox player-wrapper">
                        <div className="playbutton"><span onClick={() => showModal(vimeoid)}>play</span></div>
                        <div className="react-player" id={vimeoid}>
                           {imgdata[index] ? <img src={imgdata[index]+'_396x266'} height="266" width="396" /> :  <div className="spinner-box"><Spin/></div>}
                           
                        </div>
                     </div>
                  </div>
                  )}
            </Carousel>
            :  <Carousel {...settings} >
            {videoList.map((vimeoid, index) =>
               <div className="viewrow" key={index}>
                  <div className="imagebox player-wrapper">
                     <div className="playbutton"><span onClick={() => showModal(vimeoid)}>play</span></div>
                     <div className="react-player" id={vimeoid}>
                        <div className="spinner-box"><Spin/></div>
                     </div>
                  </div>
               </div>
               )}
         </Carousel>
            }

         </div>
         <Modal
            visible={visible}
            okText="Submit"
            onCancel={handleCancel}
            centered="true"
            wrapClassName={'testimonialspopup'}
            keyboard={false}
            maskClosable={false}
            footer={null}
            >
               {loading && <div className="spinner-box"><Spin tip="Cargando..."/></div>}
               <div className="testimonialsvideo">
                  <ReactPlayer
                     url={'https://vimeo.com/'+(videoid)}
                     width="100%"
                     height="480px"
                     playing={true}
                     controls={true}
                     onReady={()=> {setLoading(false)}}
                  />
               </div>
         </Modal>
      </div>
   </div>
  );
};

export default TestimonialFront;
