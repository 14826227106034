import React, {useState} from 'react';
import './categoryfilter.css';
import { Link } from 'react-router-dom';
import API from '../../utils/API';
import {Modal} from 'antd';

const Forumcategory = props => {

   const [category, setcategory] = useState([]);
   React.useEffect( ()=> {
      let isMounted = true;
      API.get('/categories/getcategories/subcategory')
      .then(result => {
       const arr = []
       result.data.category.map(name => {
          const cat = name.name
          const _id = name._id
          const subcat = name.subcategories
          const topcategory = (name.topcategory ? true : false)
  
        arr.push({name:cat,_id:_id, topcategory:topcategory,subcat:subcat })
       })
       if(isMounted){
         setcategory(arr);
       }
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
      return () => { isMounted = false }; 
    },['category']);

   return (
      <div className="forumscategory categoryfilter">
         <div className="heading"><h2 className="blocktitle">Categoría principal del foro</h2></div>
         {category.length ?
               (<ul className="maincat-list">
                  <li> <Link to="/forums">See All Categories</Link></li>
               {category.map((catname, index) => {
                  return <ul key={index}>
                           {catname.topcategory && (<li key={index}>
                           <Link to={'/forums/'+(catname.name).replace(/ /g, "-")}>{catname.name}</Link></li>)}
                           {catname.subcat.length ? (
                            
                              catname.subcat.map((item, key) => {
                               if(item.topcategory){
                                  return <li key={key}>
                                  <Link to={"/forums/"+(item.name).replace(/[^a-zA-Z]/g,"-")+"/"+item.shortid+'?sid='+item.shortid}>{item.name}</Link>
                                 </li>
                               }
                              })
                          
                           )
                           : null }
                         </ul>
               })}
               </ul> )
               : null 
            }
         {/* {subcatModal(subcatData)} */}
      </div>
  );
};

export default Forumcategory;
