import React, { useState } from 'react';
import API from '../../utils/API';
import {Skeleton, Modal} from 'antd';
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player";


import './css/dashboardglobal.css';
import './css/dashboardeducation.css'

const EducationViewBlock = () => {
  const [datalists, setData] = React.useState([]);
  const [loading, setLoading] = useState(true);
  
  React.useEffect(()=>{
      API.get('/courses/contents/user')
      .then(result => {      
      if(result.data){
         setData(result.data.courses.sort((a, b) => {
  
            // equal items sort equally
            if (a.courseOrder === b.courseOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.courseOrder === null) {
                return 1;
            }
            else if (b.courseOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
                return a.courseOrder < b.courseOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else { 
                return a.courseOrder < b.courseOrder ? 1 : -1;
            }
        
          }));
         setLoading(false);
      }
      })
      .catch(e => {
         Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
         });
      });
   },[]);
  return (
   <div className="educationviewblock viewblockcontent">
      <h2 className="blocktitle">Educacion</h2>
      <div className="viewcontent">
      {loading ? (
            <div className="loading">
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
            </div>
          ) : (
            datalists.map((datalist, index) => {
                  return <div className="viewrow" key={index}>
                     <div className="videoinformation">
                        <div className="videobox">
                           <Link to={datalist.pathalias.alias}><span>play</span></Link>
                           <ReactPlayer
                              url={'https://vimeo.com/' + datalist.videos[0].videoURL}
                              width="100%"
                              height="60px"
                           />
                        </div>
                        {/* <div className="createdby">
                           <div className="created">
                              <label>publicado por:</label>
                              <span className="authername">Jane Cooper</span>
                           </div>
                           <div className="date">May 13, 2021</div>
                        </div> */}
                     </div>
                     <div className="title"><h2><Link to={datalist.pathalias.alias} title={datalist.title}>{datalist.title}</Link></h2></div>
                     <div className="description">
                     <div dangerouslySetInnerHTML={ { __html:datalist?.body?.replace(/<[^>]+>/g, '')}} ></div>
                     </div>
                  </div>
                  })
          )}
      </div>
      <div className="viewall"><Link to="/education/videocourses">Ver todo</Link></div>
   </div>
  );
};

export default EducationViewBlock;
