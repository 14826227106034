import React, { useState, useEffect } from 'react';
import API from '../../utils/API';


const RolesName = props => {
  const [loadings, setLoadings] = useState(true);
  const [categoryName, setCategoryName] = useState('');

  useEffect( ()=> {
    if(props.id){
        API.get(`/roles/id?rolesid=${props.id}`).then(res => {
            setCategoryName(res?.data?.roles?.name)         
            setLoadings(false);
          }).catch(err => {
            setLoadings(false);
            console.log(err);
          })
    }  
  },[props.id]);

  return (
    <div>
   
        {categoryName}
    </div>
    
    
    
  );
};

export default RolesName;