import React, { useEffect, useRef, useState } from 'react';
import './loanrequested.css';
import {NavLink,Link} from 'react-router-dom';
import { Card, Table, Modal, Button, Form, Input,InputNumber } from 'antd';
import API from '../../utils/API';
import Moment from 'moment'
const { TextArea } = Input;
const columns = [
    {
      title: 'Title',
      dataIndex: 'title',
    },
    {
      title: 'Status',
      dataIndex: 'status',
    },
    {
      title: 'Amount',
      dataIndex: 'amount',
    },
  ];
const LoanRequested = props => {
  const [tabledata, setTabledata] = useState([])
  const [loading, setLoading] = useState(false);
  const columns = [
    {
      title: 'Direccion',
      dataIndex: 'address',
      key: 'address',
      className: 'address-col'
    },
    {
      title: 'Tipo de prestamo',
      dataIndex: 'loantype',
      key: 'loantype',
    },
    {
      title: 'Estado',
      dataIndex: 'status',
      key: 'status',
    },
    {
      title: 'Fecha',
      dataIndex: 'date',
      key: 'date',
    },
    {
      title: 'Detalles',
      dataIndex: 'detail',
      key: 'detail',
    },
  ];
  useEffect(()=>{
    API.get('users/fetchtata/l/r/'+props?.id).then(result =>{
      let d = [];
      result?.data?.ldata?.map((lr,i) =>{
        d.push({
          key: i,
          loantype: lr?.loantype?.replace(/_/g, ' '),
          status: <span className="text-cap">{lr.status == 'new' ? 'Nuevo' : lr.status == 'active' ? 'Activo': lr.status == 'close'? 'Cerrado': ''}</span>,
          date: <span className="text-cap">{Moment(lr.requestDate).format('DD-MMM-YYYY')}</span>,
          address: lr?.propertyaddress,
          detail: <Link to={lr?.pageurl} className="request-btn">Ver detalles</Link>
        });
        if(result.data.ldata.length == i+1){
          setLoading(true);
          setTabledata(d);
        }

      })
    })
  },[loading])

      return (
        <div className='loanrequestedblock'> 
                    
          <Card title="Préstamo Requerido" extra={props?.hideLink ? '' :<NavLink to="/financing" className="request-btn">Solicitar un nuevo préstamo</NavLink>} >
          <Table 
            dataSource={tabledata}
            columns={columns}
            className='responsive-table'
            pagination={{
              position: ['none', 'bottomCenter'],
              pageSize:10,
            }}
            />;
          </Card>
        {/* <ModalForm open={state.open} onCancel={this.hideLoanModal} /> */}
        </div>
      );
  }
  export default LoanRequested;