import React, { useContext, useState, useRef } from 'react';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import API from '../../utils/API';
import {Button, Modal} from 'antd';
import { AuthContext } from '../../App';
import NewDeal from './NewProperty.js';
import Page from '../../components/Page/Page';
import './css/deals.css';
const Deals = props => {

  const { state } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [allDeals, setAllDeals] = useState([])
  const [newSearchText, setNewSearchText] = useState('');
  const queryParams = props?.location?.search;
  const search = new URLSearchParams(queryParams).get('search');
  React.useEffect(() => {
    setNewSearchText(search)
    fetchdeals(search);
  }, [search])

  function fetchdeals(search){
    console.log('newSearchText: ',search)
    if(search == null){
      API.get(`deals/contents`).then(result => {
        if(result?.data?.deals){
          setAllDeals(result.data.deals)
        }
      })
    }else{
      API.get(`deals/contents?search=${search.trim()}`).then(result => {
        if(result?.data?.deals){
          setAllDeals(result.data.deals)
        }
      })
    }

  }
    const addProperty = () => {
      setVisible(true)
    }
    const handleCancel = () => {
      setVisible(false)
    };
    const getModalResponse = (visiblecheck) =>{
      fetchdeals();
      setVisible(visiblecheck)
    }
    const handelSearchSubmit = (e) =>{
      if(e.keyCode == 13 && (newSearchText == null || newSearchText.trim().length <= 0)){
        return
      }
      fetchdeals(newSearchText);
    }

  return (
    <Page>
      <div className={"maincontent dealerpage"}>
        <div className="maindiv">
          <div className="deals-midcontent">
            <div className='membersearchbox dealersearchbox'>
              <div className='membersearch'>
              <h1> TRATOS </h1>
                <div className="searchbox" >
                  {state.isAuthenticated &&  <div className='userpicbox'>
                    <ProfileImage id={state?.user?.id}/>
                  </div>}
                  <div className="formbox">
                    <div className="form-item search-filter">
                      <input 
                      type="text"
                      name="search" 
                      className="formtext"
                      placeholder="Search by City, State, Zipcode"
                      onChange={(e) => setNewSearchText(e.target.value)}
                        value={newSearchText != null ? newSearchText : ''}
                      />
                      </div>
                    <div className="form-action"><input type="button" className="form-submit" value="Submit" onClick={(e)=> handelSearchSubmit(e)}/></div>
                  </div>
                  {state?.user?.usertype?.includes('dealer') &&
                    <div className='add-property'>
                      <Button onClick={()=> addProperty()}>AGREGAR PROPIEDAD</Button>
                    </div>
                  }
                </div>
              </div>
            </div>

            <div className='deals-wrapper'>
              <div className='deals-content deals-landing'>
                {allDeals.map((d, key) =>{
                  return <div className='deals-content-row' key={key}>
                    <a href={'deals/'+d.dealid+'/'+d.title.replace(/[^a-zA-Z]/g,"-")}><div className='img-wrapper'><img src={d.photo[0]?.location} /></div>
                    <div className='deals-content-overlay'>
                        <div className='deals-content-overlay-content'>
                          <div className='deals-content-top'>
                              <div className='address'>
                                {d?.address1}
                                <div>{d?.address2}, {d?.state}, {d?.zipcode}</div>
                              </div>
                              <div className='price'>${d?.price.toLocaleString()}</div>
                          </div>
                          <ul className='deals-content-bottom'>
                            <li>Area-<span>{d?.footage}</span>Sq.Ft</li>
                            <li>Bed-<span>{d?.bedroom}</span></li>
                            <li>Bath-<span>{d?.bathrooms}</span></li>
                          </ul>
                        </div>
                    </div></a>
                  </div>
                })}
              </div>
            </div>
          </div>
          <Modal
            visible={visible}
            onCancel={handleCancel}
            centered="true"
            wrapClassName={'addproperty'}
            footer={null}
            keyboard={false}
            maskClosable={false}
            width={1000}
          >
            <NewDeal getModalResponse={getModalResponse} userdata={state}/>
          </Modal>
        </div>
      </div>
    </Page>    
  );
};

export default Deals;
