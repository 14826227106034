import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Form, Input, Modal, Checkbox } from 'antd';
import API from '../../utils/API';
import './signup.css';
import GoogleLoginPage from '../../components/GoogleLogin/GoogleLoginPage';
import FacebookLoginPage from '../../components/FacebookLogin/FacebookLoginPage';

const RegisterForm = props => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisibile] = useState(false);
  const re = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,50}$/;
  const alpha = /^(?=.*[A-Za-z ])[a-zA-Z ]{1,50}$/;
  const emailcheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [form] = Form.useForm();
  const handleModal = (e) =>{
    setVisibile(false);
  }
  const onFinish = values => {
    setLoading(true);
    API.post('/users/register', {
      email: values.email.toLowerCase().trim(),
      firstname: values?.firstname?.trim(),
      lastname: values?.lastname?.trim(),
      username: values.email.toLowerCase().trim(), //assign email as username
      password: values.password,
    })
      .then(() => {
        form.resetFields();
        setVisibile(true);
        setLoading(false);
        /* Modal.info({
          title: 'verificacion de email',
          content: 'Se ha enviado un correo de verificación a su correo electrónico. Por favor verifique para disfrutar Nuestros servicios',
        }); */
        //props.form.resetFields();
      })
      .catch(e => {
        setLoading(false);
        Modal.error({
          title: 'Registro fallido',
          content: e.response.data.msg,
        });
      });
  };

  return (
          <div className="registration-form-wrap">
            <div className="registration-form">
              <h2>Registracion Gratuita</h2>
              <div className="helptext">Vamos a empezar tu cuenta</div>
              <Form
                form={form}
                name="signup"
                layout="vertical"
                onFinish={onFinish}
                size = {'default'}
              >
                <Form.Item
                  label="Primer Nombre"
                  name="firstname"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      //message: 'Entra tu primer nombre',
                      validator: (_, value) => {
                        if(typeof(value) == "undefined" || !value){
                         return Promise.reject(new Error('Entra tu primer nombre'));
                        }else if(alpha.test(value.trim())){
                           return Promise.resolve();
                         }
                         return Promise.reject(new Error('Solo se permite el alfabeto'));
                       },
                    },
                  ]}
                >
                  <Input  placeholder="Entra tu primer nombre" className="form-text" />
                </Form.Item>
                <Form.Item
                  label="Apellido"
                  name="lastname"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      //message: 'Entra tu apellido',
                      validator: (_, value) => {
                       if(typeof(value) == "undefined" || !value){
                        return Promise.reject(new Error('Entra tu apellido'));
                       }else if(alpha.test(value.trim())){
                          return Promise.resolve();
                        }
                        return Promise.reject(new Error('Solo se permite el alfabeto'));
                      }
                    },
                  ]}
                >
                  <Input  placeholder="Entra tu apellido" className="form-text" />
                </Form.Item>
                <Form.Item
                  label="Correo Electronico"
                  name="email"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      //message: 'Por favor introduzca un correo electrónico válido',
                      //type: 'email',
                      validator: (_, value) => {
                        if(typeof(value) == "undefined" || !value){
                         return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                        }else if(emailcheck.test(value.trim())){
                           return Promise.resolve();
                         }
                         return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                       }
                    },
                  ]}
                >
                  <Input  placeholder="Entra tu correo electronico" className="form-text" />
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      //message: 'La contraseña debe contener letras y numeros con un limite de 8 caracteres',
                      validator: (_, value) => {
                        if(typeof(value) == 'undefined' || !value){
                          return Promise.reject(new Error('La contraseña debe contener letras y numeros con un limite de 8 caracteres'));
                        }else if (!value || re.test(value)) {
                          return Promise.resolve();
                        }
                          return Promise.reject(new Error('La contraseña debe contener letras y numeros con un limite de 8 caracteres'));
                        },
                    },
                  ]}
                >
                  <Input.Password  placeholder="Entra tu contraseña" className="form-text" />
                </Form.Item>
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  className="form-item term-and-condition"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Acepte los términos y la política de privacidad anteriores.')),
                    },
                  ]}
                >
                <Checkbox
                style={{ fontSize:'12px' }}
                >
                  Registrandote a En la Cancha tu estas de acuerdo con la <Link to="/privacy_policy" target="_blank">Política de privacidad</Link>, <Link to="/terms_and_conditions" target="_blank">Términos y condiciones</Link>
                </Checkbox>
                </Form.Item>
                <Form.Item
                  style={{textAlign: 'center' }} 
                  className="form-action"           
                >
                  <input type="submit" disabled={loading} value="Registrate" className="form-submit"></input>
                </Form.Item>
              </Form>
              <div className="form-divider"><span>O</span></div>
              <div className="social-auth-login">
                <div  className="google"><GoogleLoginPage/></div>
                <div className="facebook"><FacebookLoginPage/></div>
              </div>
              <div className="already-account">Ya tengo una cuenta?<Link to="/login">Iniciar sesión</Link></div>
            </div>
            <Modal
              visible= {visible}
              onOk={handleModal}
              keyboard="false"
              centered="true"
              cancelButtonProps={{
                style: {
                  display: "none",
                },
              }}
              closable={false}
            >
              <p>Se ha enviado un correo de verificación a su correo electrónico. Por favor verifique para disfrutar
Nuestros servicios.</p>
            </Modal>
          </div>
  );
};

export default RegisterForm;
