import React, { useContext, useState ,useEffect} from 'react';
import { AuthContext } from '../../App';
import API from '../../utils/API';
import './css/ldetailpage.css';
import { Button, Radio, Space} from 'antd';
import { Redirect } from 'react-router-dom';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { Link } from 'react-router-dom/cjs/react-router-dom';
import Moment from 'moment'
import LenderDetail from './LenderDetail';
const LoanDetailpage = props => {
  const { state } = useContext(AuthContext);
  //const [interest, setInterest] = useState(1);
  const [intereststatus, setIntereststatus] = useState();
  const [loanstatus, setLoanstatus] = useState('');
  const [bid,setBid] = useState('');
  const [lrnid, setLrnid] =useState('');
  const [lid, setLid] =useState('');
  const [activeinvest,setActiveinvest] = useState('')
  const [companyname,setCompanyname] = useState('')
  const [credit,setCredit] = useState('')
  const [cuantos,setCuantos] = useState('')
  const [currentdebt,setCurrentdebt] = useState('')
  const [currentrent,setCurrentrent] = useState('')
  const [employmenttype,setEmploymenttype] = useState('')
  const [fullname,setFullname] = useState('')
  const [income,setIncome] = useState('')
  const [isrented,setIsrented] = useState('')
  const [langcontact,setLangcontact] = useState([])
  const [project,setProject] = useState('')
  const [propertyaddress,setPropertyaddress] = useState('')
  const [propertypurchased,setPropertypurchased] = useState('')
  const [propertypurchasedprice,setPropertypurchasedprice] = useState('')
  const [propertytax, setPropertytax] = useState('')
  const [propertyvalue,setPropertyvalue] = useState('')
  const [renovacioncost,setRenovacioncost] = useState('')
  const [afterrepairecost,setAfterrepairecost] = useState('')
  const [saving,setSaving] = useState('')
  const [stateprovideservice,setStateprovideservice] = useState([])
  const [taxoption,setTaxoption] = useState('')
  const [telephonenumber,setTelephonenumber] = useState('')
  const [loantype,setLoanType] = useState('')
  const [interestedLenders, setInterestedLenders] = useState([])
  const [loancreated, setLoancreated] = useState('')
  const [hideSave, setHideSave] = useState('')
  const history = useHistory();
  const [usedhardmoney, setUsedhardmoney] = useState('');
  const [activehardmoney, setActivehardmoney] = useState('');
  const [contractorready, setContractorready] = useState('');
  const [dealundercontract, setDealundercontract] = useState('');
  useEffect(()=>{
    let isMounted = true;
    API.get('/users/fetchtata/lr/d'+props.tokenid).then(result => {
      if (isMounted)
      setLoanType(result?.data?.lrdata?.lrd?.loantype)
      setActiveinvest(result?.data?.lrdata?.lrd?.activeinvest)
      setCompanyname(result?.data?.lrdata?.lrd?.companyname)
      setCredit(result?.data?.lrdata?.lrd?.credit)
      setCuantos(result?.data?.lrdata?.lrd?.cuantos)
      setCurrentdebt(result?.data?.lrdata?.lrd?.currentdebt)
      setCurrentrent(result?.data?.lrdata?.lrd?.currentrent)
      setEmploymenttype(result?.data?.lrdata?.lrd?.employmenttype)
      setFullname(result?.data?.lrdata?.lrd?.fullname)
      setIncome(result?.data?.lrdata?.lrd?.income)
      setIsrented(result?.data?.lrdata?.lrd?.isrented)
      setLangcontact(result?.data?.lrdata?.lrd?.langcontact)
      setProject(result?.data?.lrdata?.lrd?.project)
      setPropertyaddress(result?.data?.lrdata?.lrd?.propertyaddress)
      setPropertypurchased(result?.data?.lrdata?.lrd?.propertypurchased)
      setPropertypurchasedprice(result?.data?.lrdata?.lrd?.propertypurchasedprice)
      setPropertytax(result?.data?.lrdata?.lrd?.propertytax)
      setPropertyvalue(result?.data?.lrdata?.lrd?.propertyvalue)
      setRenovacioncost(result?.data?.lrdata?.lrd?.renovacioncost)
      setAfterrepairecost(result?.data?.lrdata?.lrd?.afterrepairecost)
      setSaving(result?.data?.lrdata?.lrd?.saving)
      setStateprovideservice(result?.data?.lrdata?.lrd?.stateprovideservice)
      setTaxoption(result?.data?.lrdata?.lrd?.taxoption)
      setTelephonenumber(result?.data?.lrdata?.lrd?.telephonenumber)
      setUsedhardmoney(result?.data?.lrdata?.lrd?.usedhardmoney)
      setActivehardmoney(result?.data?.lrdata?.lrd.activehardmoney)
      setContractorready(result?.data?.lrdata?.lrd.contractorready)
      setDealundercontract(result?.data?.lrdata?.lrd?.dealundercontract)
      setBid(result?.data?.lrdata?.lrd?.author)
      setLrnid(result?.data?.lrdata?.lrnid)
      setLid(result?.data?.lrdata?.lid)
      if(result?.data?.lrdata?.alreadyinterested != ''){
        setIntereststatus('interested')
        setHideSave(true)
      }
      setLoanstatus(result?.data?.lrdata?.lrd?.status)
      setLoancreated(result?.data?.lrdata?.lrd?.createdAt)
      setInterestedLenders(result?.data?.lrdata?.lrd?.interested)
    }).catch(err => {
      console.log(err);
    })
    return () => { isMounted = false };
  },[props.tokenid])
  
  if(!state.isAuthenticated){
    return <Redirect to={"/login?=/loan/view/"+(props.tokenid)}/>;
  }
  const onChange = (e) => {
    setIntereststatus(e.target.value);
  };
  const saveLoan = () => {
    API.post('/users/linfo/setinterested',{
      lrnid,
      lid: state?.user?.id,
      interest: 'interested',
    }).then(() =>{
      history.push('/dashboard');
    })
    .catch(e =>{

    })
  }
  return (
    <div className="maincontent detail-page-wrapper">
      <div className="breadcrumbwrapper">
          <div className="breadcrumb">
            <ul>
                <li><Link to="/financing">Financiamiento</Link></li>
                <li>Detalle del préstamo</li>
            </ul>
          </div>
      </div>
      <div className='detail-page'>
        {companyname && <div className='company-name d-item'>
          <span>Nombre de compania : </span><span>{companyname}</span>
        </div>}
        {/* {telephonenumber && <div className='tele-phone d-item'>
          <span>Número telefónico : </span><span>{telephonenumber}</span>
        </div>} */}
        {
          loantype=="Refinanciamiento" 
          ? (stateprovideservice.length > 0 && <div className='service-provide d-item'>
              <span>¿Estados en los  que provee sus servicios? : </span><span>{stateprovideservice.join(', ')}</span>
            </div>)
          :loantype=="Hard Money"
          ? (stateprovideservice.length > 0 && <div className='service-provide d-item'>
              <span>En que estado esta el proyecto? : </span><span>{stateprovideservice.join(', ')}</span>
              </div>)
          :''
        }
        {taxoption && <div className='tax-option d-item'>
          <span>Tienes tax id o social? : </span><span>{taxoption}</span>
        </div>}
        {employmenttype && <div className='emp-type d-item'>
          <span>¿ Cual describe mejor su empleo ? : </span><span>{employmenttype}</span>
        </div>}
        {credit && <div className='cr d-item'>
          <span>Puntuación de crédito : </span><span>{credit}</span>
        </div>}
        {income && <div className='income d-item'>
          <span>Ingresos : </span><span>{income}</span>
        </div>}
        {loantype == "Refinanciamiento" 
        ?(saving && <div className='saving d-item'>
          <span>Ahorros : </span><span>{saving}</span>
        </div>)
        :loantype == "Hard Money"
        ? (saving && <div className='saving d-item'>
          <span>¿ Con cuanto Capital cuenta? : </span><span>{saving}</span>
        </div>)
        :''}
        {usedhardmoney && <div className='usedhardmoney d-item'>
          <span>¿Utilizo hard money lenders anteriormente?</span><span>{usedhardmoney}</span>
        </div>}
        
        {activehardmoney && <div className='activehardmoney d-item'>
        <span>¿ Tiene prestamos hard money activos?</span><span>{activehardmoney}</span>
        </div>}
        
        {contractorready && <div className='contractorready d-item'>
        <span>¿ Tiene un contratista listo?</span><span>{contractorready}</span>
        </div>}
        {dealundercontract && <div className="dealundercontract d-item">
          <span>Tienes un propiedad bajo contrato?</span><span>{dealundercontract}</span>
        </div>}
        {project && <div className='project d-item'>
          <span>¿ Cuantos proyectos a hecho? : </span><span>{project}</span>
        </div>}
        {activeinvest && <div className='investment d-item'>
          <span>¿ Tiene prestamos de inversion activos? : </span><span>{activeinvest}</span>
        </div>}
        {activeinvest == 'Si' ? <div className='quant-tos d-item'>
          <span>Cuantos : </span><span>{cuantos}</span>
        </div>
        :''}
        {propertyaddress && <div className='address d-item'>
          <span>Cual es la direccion de la propiedad? : </span><span>{propertyaddress}</span>
        </div>}
       
        {loantype == "Refinanciamiento" 
        ?( propertyvalue && <div className='pvalue d-item'>
          <span>¿Cuál es la valorización de la propiedad? : </span><span>$ {propertyvalue}</span>
          </div>)
        :loantype == "Hard Money"
        ? ( propertyvalue && <div className='pvalue d-item'>
          <span>Cual es el precio de compra? : </span><span>$ {propertyvalue}</span>
          </div>)
        :''}
        {propertytax && <div className='ptax d-item'>
          <span>¿Cuáles son los impuestos de la propiedad? : </span><span>$ {propertytax}</span>
        </div>}
        {isrented && <div className='isrented d-item'>
          <span>Esta rentado? : </span><span>{isrented}</span>
        </div>}
        {isrented == 'Si' ? <div className='c-rent d-item'>
          <span>Cual es la renta actual? : </span><span>$ {currentrent}</span>
        </div>
        :''}
        {propertypurchased && <div className='purchased-date d-item'>
          <span>¿Cuándo se compró la propiedad? : </span><span className="text-cap">{Moment(propertypurchased).format('DD-MMM-YYYY')}</span>
        </div>}
        {propertypurchasedprice && <div className='purchased-price d-item'>
          <span>Precio de compra? : </span><span>$ {propertypurchasedprice}</span>
        </div>}
        
        {loantype == "Refinanciamiento" 
        ?( renovacioncost && <div className='renovation d-item'>
          <span>Costo de renovacion : </span><span>$ {renovacioncost}</span>
          </div>)
        :loantype == "Hard Money"
        ? ( renovacioncost && <div className='renovation d-item'>
            <span>Cual es el costo de renovacion? : </span><span>$ {renovacioncost}</span>
          </div>)
        :''}
        {afterrepairecost && <div className='afterrepairecost d-item'>
            <span>¿Cuál es tu estimado de valorización después de los arreglos? : </span><span>$ {afterrepairecost}</span>
          </div>}
        {currentdebt && <div className='c-debt d-item'>
          <span>Deuda actual? : </span><span>$ {currentdebt}</span>
        </div>}
        <div className='fullname d-item'>
          <span>¿En que idioma quiere ser contactado? : </span><span>{langcontact.join(', ')}</span>
        </div>
        {state?.user?.id == lid &&
          <div className='lr-action-wrapper'>
            <div className='lrque'><h2>¿Quieres trabajar con este cliente ?</h2></div>
            {(intereststatus == "interested" && hideSave == true) && 
              <div className='b-details'>
                <div className='b-name'><span>Name: </span> {fullname}</div>
                <div className='b-email'><span>Email: </span>{bid?.email}</div>
                <div className='b-phone'><span>Phone: </span>{telephonenumber}</div>
              </div>
            }
            <Radio.Group onChange={onChange} value={intereststatus} name="interest">
                {hideSave == false ?
                  <Space direction="vertical">
                    <Radio value="interested">Si</Radio>
                    {/* <Radio value="not-interested">No</Radio> */}
                  </Space>
                :
                  <Space direction="vertical">
                    <Radio value="interested" disabled>Si</Radio>
                    {/* <Radio value="not-interested" disabled>No</Radio> */}
                  </Space>
                }
            </Radio.Group>
            {(hideSave == false) && 
              <div className='action-wrapper'>
                <Button className='btn' onClick={saveLoan}>Save</Button>
              </div>
            }
          </div>
        }
      </div>
      <div className='loan-status-box'>
        <div className={"loan-status " +loanstatus}>Estado del préstamo: <span>{loanstatus == 'new' ? 'Nuevo' : loanstatus == 'active' ? 'Activo': loanstatus == 'close'? 'Cerrado': ''}</span></div>
        <div className="loan-post">Fecha de publicación: <span>
        <span className="date text-cap">{Moment(loancreated).format('DD-MMM-YYYY')}</span>
        </span></div>
        </div>
      {(interestedLenders.length && lid == 0) > 0 && 
        <div className='interested-lenders-wrapper'>
            <div className='l-items-head'>
              <div className='l-items-left'>Detalles del prestamista</div>
              <div className='l-items-right'>Transacción Cerrada?</div>
            </div>
          {interestedLenders.map((lend, index) =>{
            return <LenderDetail lid={lend?.interesteduser._id} lrnid={lrnid} />
          })}
        </div>
      }
    </div>
  );
};

export default LoanDetailpage;
