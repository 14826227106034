import React, { useState,useRef } from 'react';
import profilebanner from '../../assets/images/bannerdefault.png'
import profileimage from "../../assets/images/userdefault.png"
import { AuthContext } from '../.././App';
import API from '../.././utils/API';
import { Modal} from 'antd';
import Signuppopup from '../../components/Signuppopup/Signuppopup';
import MessageButton from '../../components/WidgetBar/MessageButton';
import ConnectButtonProfile from '../../components/WidgetBar/ConnectButtonProfile';
import io from "socket.io-client";
import ProfileImage from '../../components/WidgetBar/ProfileImage';

const GuestUserHeader = props => {
   const [visible, setVisible] = useState(false);
   const [interest, setInterest] = useState([])
   const socket = useRef();

   React.useEffect(() => {
      let currentURL = window.location.href;
  
      if(currentURL.includes("comunidad.enlacancha")) {
         socket.current = io("wss://comunidad.enlacancha.us");
       }else if(currentURL.includes("enlacancha")) {
          socket.current = io("wss://enlacancha.us");
      }else if(currentURL.includes("hurekadev")) {
          socket.current = io("wss://elc.hurekadev.info");
      }else if (currentURL.includes("localhost")) {
          socket.current = io("ws://localhost:8000");
      }
    }, []);
   const handleCancel = () => {
      setVisible(false)
      document.body.classList.remove('modalopen');
   };

   const [{ alt, src }, setImg] = useState({
      src: profilebanner,
     
   });
   const [isUpload, setUpload] = useState(false)
   const [profileImg, setProfileImg] = useState();
   const [bannerImg, setbannerImg] = useState();
   const [isProfileUpload, setProfileUpload] = useState(false)
   const [userDetail, setUserDetail] = useState()
   const [mstype, setmstype] = useState('');
   React.useEffect(() => {
         fetchData(props.id)
   }, []);
   const fetchData = (id) => {
      API.get('/users/'+ id).then(
         result => {
            const userDetail = result?.data.user
            setUserDetail(userDetail)
            if (userDetail?.interests.length !== 0) {
               const arr = []
               result?.data?.userInfo?.interests.map(res => {
                  API.get(`/interests/id/?interestid=${res}`).then(res => {
                     arr.push(res?.data?.interets?.name)
                  }).catch(err => {
                     console.log(err);
                  })
               })
               setInterest(arr)
            }
         }
      ).catch(err => {
         console.log(err);
      })
      userImage(id)

   }
   const userImage = (id) => {
      API.get(`/userinfo/getimageprofile/?userid=${id}`).then(result => {

         setProfileImg(<img  alt={userDetail?.firstname} className={"img "+(result?.data?.mstype == 'basic' ? ' pro ': '')} src={result?.data?.base64} />)
         setmstype(result?.data?.mstype);
         setProfileUpload(true)

      }).catch(err => {

         return
      })
      API.get(`/userinfo/getimage/?userid=${id}`).then(result => {
         setbannerImg(<img width="100%" height="420" alt={userDetail?.firstname} src={result.data.base64} />)
         setUpload(true)

      }).catch(err => {
         console.log(err);
         return
      })
   }

   const { state } = React.useContext(AuthContext);

   const Capitalize = (str) => {
      if (str !== undefined && str !== null)
         return str.charAt(0).toUpperCase() + str.slice(1);
   }

   return (

      <div className="maincontent guestprofileview guestuser-header">

         <div className="guestprofileinformation"> 
            {!isUpload ? <div className="coverimage"><img width="100%" height="420" src={src} /></div> :
               <div className="coverimage">{bannerImg}</div>}
            <div className="guestprofiledetails">
               <div className='userimagewrapper'><ProfileImage id={props?.id} classtouse="userpict"/></div>
               <div className="username"><h2>{Capitalize(userDetail?.firstname)} {Capitalize(userDetail?.lastname)}</h2></div>
               <div className="description">{Capitalize(userDetail?.userinfoid?.shortbio)}</div>
            </div>
         </div>
         
         <div className="guestprofiletabsview">
            <div className="guestprofiletabs">
               <div className="lefttab">
                  <ul>
                  {/* <li ><NavLink to={{
                        pathname: "/guestuser/aboutuser", aboutProps:{
                           id:props.id
                          }

                     }} style={props.btn}  >Acerca de</NavLink></li> */}
                      {/* <li ><NavLink to={{
                        pathname: "/aboutguestuser", aboutProps:{
                           id:props.id
                          }

                     }} style={props.btn}  >Acerca de</NavLink></li> */}
                     {/* <li><NavLink to="/guestuser" >Connections<span className="count">1000</span></NavLink> </li>
                     <li><NavLink to="/guestuser" >Followers<span className="count">1000</span></NavLink> </li>
                     <li><NavLink to="/guestuser" >Following<span className="count">1000</span></NavLink> </li> */}
                  </ul>
               </div>
               <div className="righttab">
                  
                     {/* <li className="connect"><NavLink to="/guestuser" onClick={showModal}>Conectate</NavLink></li>
                     <li className="message"><NavLink to="/guestuser" onClick={showModal}>Envia Mensaje</NavLink></li>
                     <li className="report">
                        <span><div className="dot">dot</div></span>
                        <ul className="dropdown">
                           <li><a href="#">Reportar perfil</a></li>
                        </ul>
                     </li> */}
                      {state.isAuthenticated && (
                         <ul>
                           <li><ConnectButtonProfile forUserId={props.id} socket={socket}/></li>
                           <li><MessageButton forUserId={props.id}/></li>
                         </ul>
                      )}
               </div>
            </div>
         </div>
         <Modal
            visible={visible}
            okText="Submit"
            onCancel={handleCancel}
            centered="true"
            wrapClassName={'registrationpopup'}
            footer={null}
        >
            <Signuppopup/>
        </Modal>
      </div>
   );
};

export default GuestUserHeader;
