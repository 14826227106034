import React, { useEffect, useContext, useState } from 'react';
import API from '../../../../utils/API';
import { Modal, Button, Form, Input,InputNumber, Select, DatePicker, Checkbox } from 'antd';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
const libraries = ["places"];

const { TextArea } = Input;
const HardMoneyStep4 = props => {
  const [loantype, setLoantype] = useState('');
  const [stateList,setStateList] = useState([]);
  /* const [statelistoptiondone,setStateListOptiondone] = useState(false); */
  const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
  const [valuepropertyaddress, setValuepropertyaddress] = useState(null)
  const [options, setOptions] = useState([]);
  const [searchboxRef, setSearchboxRef] = useState(null)
  const handleIsrentedChange = (value) => {
    if(value == 'Si'){
      setIsrentedShow(true);
    }else{
      setIsrentedShow(false)
    }
  };
  const savingOption = [
    {
      value: '$30,000-$40,000',
      label: '$30,000-$40,000',
    },
    {
      value: '$40,000-$50,000',
      label: '$40,000-$50,000',
    },
    {
      value: '$50,000- $100,000',
      label: '$50,000- $100,000',
    },
    {
      value: '$100,000 o mas',
      label: '$100,000 o mas',
    },
  ]
  const onPrev = () => {
    props.prev()
  }
  const onSearchBoxMounted = ref => {
    setSearchboxRef(ref);
  };
  const changeSearch = (event) => {
    if(event.target.value != '') {
      props.formdata.propertyaddress = event.target.value;
    }
  }
  const onPlacesChanged = () => {
    const places = searchboxRef.getPlaces();
    if(places.length){
      if(places[0]?.business_status){
        props.formdata.propertyaddress = places[0].name+', '+places[0].formatted_address
      }else{
        props.formdata.propertyaddress = places[0].formatted_address
      }
    }
  };
   return (
    <>
          <Form.Item
            label="¿ Con cuanto Capital cuenta?"
            name="saving"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={savingOption}
            />
          </Form.Item>
          <Form.Item
            label="¿Utilizo hard money lenders anteriormente?"
            name="usedhardmoney"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={[
                {
                  value: 'Si',
                  label: 'Si',
                },
                {
                  value: 'No',
                  label: 'No',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="¿ Tiene un contratista listo?"
            name="contractorready"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={[
                {
                  value: 'Si',
                  label: 'Si',
                },
                {
                  value: 'No',
                  label: 'No',
                },
                {
                  value: 'Yo soy contratista',
                  label: 'Yo soy contratista',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Tienes un propiedad bajo contrato?"
            name="dealundercontract"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
              options={[
                {
                  value: 'Si',
                  label: 'Si',
                },
                {
                  value: 'No',
                  label: 'No',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Cual es la direccion de la propiedad?"
            name="propertyaddress"
            className='addressfield'
          >
            {/* <Input  placeholder='Entra direccion de la propiedad'/> */}
            <LoadScript
              googleMapsApiKey="AIzaSyAxVHisD2iH7zCBu2qLhsIUPX2VOUFaWpA"
              libraries = {libraries}
            >
              <StandaloneSearchBox
                onLoad={onSearchBoxMounted}
                onPlacesChanged={onPlacesChanged}
              >
                
                <input
                  type="text"
                  className='text-input ant-input'
                  placeholder="Dirección de Propiedad"
                  name="modalInputAdd"
                  label="Address"
                  onBlur={changeSearch} 
                  onChange={changeSearch}
                />
            </StandaloneSearchBox>
          </LoadScript>
          </Form.Item>
          <Form.Item
            label="Cual es el precio de compra?"
            name="propertyvalue"
          >
            <InputNumber
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Entra valorización de la propiedad'
            />
          </Form.Item>
          <Form.Item
            label="Cual es el costo de renovacion?"
            name="renovacioncost"
          >
            <InputNumber 
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Entra costo de renovacion'/>
          </Form.Item>
          <Form.Item
            label="¿Cuál es tu estimado de valorización después de los arreglos?"
            name="afterrepairecost"
          >
            <InputNumber 
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              />
          </Form.Item>
          <Form.Item
          name="acceptterm"
          valuePropName="checked"
          className="term-accept"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Aceptar los términos anteriores')),
            },
          ]}
          >
            <Checkbox>
              Haz click aquí  para aceptar ser contactado por los prestamistas asociados
            </Checkbox>
          </Form.Item>
          <Form.Item className="form-action">
            <Button className="prevstep" onClick={() => onPrev()}>Atrás</Button>
            <Button
                type="primary"
                htmlType="submit"
                className="form-submit"
              >
              Enviar
              </Button>
          </Form.Item>
      </>
   );
};

export default HardMoneyStep4;
