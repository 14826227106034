import React, { useState } from 'react';
import { Modal, Button } from 'antd';
import {FacebookShareButton, FacebookIcon, TwitterShareButton, TwitterIcon, WhatsappShareButton, WhatsappIcon} from "react-share";


const SocialShareBlock = props => {
  const [hidesocialShareModal, sethidesocialShareModal] = useState(false);
  const shareTitle = props.shareTitle;

  const hideModal = () => {
    sethidesocialShareModal(false);
  }

  function copyLink() {
    navigator.clipboard.writeText(props.shareUrl)
    let secondsToGo = 1;
    const modal = Modal.success({
      title: 'Link copiado al portapapeles',
      okButtonProps : { style: { display: 'none' } }, 
    });
    const timer = setInterval(() => {
      secondsToGo -= 1;
    }, 1000);
    setTimeout(() => {
      clearInterval(timer);
      modal.destroy();
    }, secondsToGo * 1000);
  }
  return (
    <div className="share">
      <div className="share-icon" onClick={() => {sethidesocialShareModal(true)}}>Compartir</div>
      <Modal
        visible={hidesocialShareModal}
        footer={null}
        wrapClassName="social-share-wrapper"
        onCancel={hideModal}
        >
          <div className="share-title">Compartir</div>
          <div className="social-share">
          <FacebookShareButton 
              url={props.shareUrl}
              quote={props.shareTitle}
              className='facebook-share'>
              <FacebookIcon size={48} round={true}/>
              <span>Facebook</span>
          </FacebookShareButton>
          <TwitterShareButton
          url={props.shareUrl}
          title={props.shareTitle}
          className="twitter"
          >
          <TwitterIcon size={48} round={true}/>
          <span>Twitter</span>
          </TwitterShareButton>
          <WhatsappShareButton
          url={props.shareUrl}
          title={shareTitle}
          separator=":: "
          className='whatsapp'
          >
          <WhatsappIcon size={48} round={true}/>
          <span>Whatsapp</span>
          </WhatsappShareButton>
          </div>
          <div className="copy-div">
              <div className="copy-text">{props.shareUrl}</div>
              <div className="copy-button"><Button onClick={copyLink}>Copiar</Button></div>
          </div>
        </Modal>
    </div>
  );
};

export default SocialShareBlock;
