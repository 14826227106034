import React, { useState, useContext } from 'react';
import Page from '../../components/Page/Page';
import {Skeleton} from 'antd';
import TopicList from '../../components/TopicList/TopicList';
import NewForum from '../../pages/NewTopic/NewTopic';
import { Button, Modal } from 'antd';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import noavtar from '../../assets/no-avatar.png';


// antd
const Subcategory = props => {
  const { state } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [contentLoading, setContentLoading] = useState(true);
  const [info, setInfo] = useState({ name: '', description: '' });
  const queryParams = props.location.search;
  const shortid = new URLSearchParams(queryParams).get('sid');
  const subcat = props.match.params.subcat;
  const [topiclimit, settopiclimit] = useState(0);
  const [membershiprole, setmembershiprole] = useState('free');
  const [username, setusername] = useState();
  const [mainclass, setMainClass] = useState('subcat-topic-warapper');
  const [showActive, setShowActive] = useState(0);

  React.useEffect(() => {
    if(state?.isAuthenticated){
      
      API.get("/users/counter/"+state?.user?.id).then((c) =>{
        if(c?.data?.connected){
          setShowActive(c?.data?.connected);
          setMainClass('subcat-topic-warapper subcat-both-sidebar');
        }else{
          setMainClass('subcat-topic-warapper subcat-left-sidebar');
        }
      });
    }
    return () => {
      return {
        mainclass
      }
    }
  }, [state])

  React.useEffect( ()=> {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let id = JSON.parse(localStorage.getItem('user')).id;
      API.get('users/'+id).then(result=>{
        if(result.data != null){
          setusername(result.data.user.firstname);
          if(result.data.user.topiclimit != null || result.data.user.topiclimit != 'undefined'){
            settopiclimit(result.data.user.topiclimit);
          }
          if(result.data.user.membershiprole != 'undefined'){
            setmembershiprole(result.data.user.membershiprole);
          }
        }
      }).catch(e =>{
        console.log(e.message);
      });
    }
  },['username']);

  const openAddPost = () => {
    if(state.user.level == 1){
      setVisible(true);
      document.body.classList.add('modalopen');
    }else if(topiclimit >= 3 && membershiprole=='free'){
      Modal.success({
        content: 'Has alcanzado el límite semanal para publicar temas en el foro.',
      });
    }else if(topiclimit >= 5 && membershiprole=='basic'){
      Modal.success({
        content: 'Has alcanzado el límite diario para publicar temas en el foro.',
      });
    }else{
      setVisible(true);
      document.body.classList.add('modalopen');
    }
    
  }
  const handleCancel = () => {
    setVisible(false)
    document.body.classList.remove('modalopen');
  };
  const getModalResponse = (visiblecheck) =>{
    setVisible(visiblecheck);
    document.body.classList.remove('modalopen');
    Modal.success({
      content: 'Gracias por enviar el tema del foro',
      onOk: () =>  reloadCurrent()
    });
  }

  const reloadCurrent= () =>{
    window.location.reload();
  }
  return (
    <Page>
      <div className={mainclass}>
          <div
            className="skeleton-container"
            style={{ display: contentLoading ? 'block' : 'none' }}
          >
            <div className='subcat-topiclist'>
              <Skeleton active />
              <Skeleton className="skeleton" active />
              <Skeleton className="skeleton" active />
            </div>
          </div>
          <div
            className="topicListContainer"
            style={{ display: contentLoading ? 'none' : 'block' }}
          >
          <div className="content-top-sub">
            {state.isAuthenticated ? (
              <div>
                <div className="forumssearchbox">
                  <div className="userpic"><ProfileImage id={state.user.id}/></div>
                  <div className="searcharea">
                    <Button
                      className="addpost"
                      onClick={openAddPost}
                    >
                      Cual es tu pregunta {username ? username.charAt(0).toUpperCase() +username.slice(1) : state.user.firstname.charAt(0).toUpperCase()+state.user.firstname.slice(1)}?
                    </Button>
                    <Modal
                      visible={visible}
                      okText="Enviar"
                      onCancel={handleCancel}
                      centered="true"
                      wrapClassName={'addforumspost'}
                      footer={null}
                      keyboard={false}
                      maskClosable={false}
                    >
                      <NewForum getModalResponse={getModalResponse}/>
                    </Modal>
                  </div>
                </div>
                <div className="s-title">{info.name}</div>
                </div>
              ):
              <div className="s-title public">{info.name}</div>
              }
              
            </div>
            <TopicList
                sid={shortid}
                contentLoading={contentLoading}
                setContentLoading={setContentLoading}
                setInfo={setInfo}
                subcat={subcat}
              />
          </div>
        </div>
    </Page>
  );
};

export default Subcategory;
