import React, { useState, useEffect, useContext, useRef } from 'react';
import { Skeleton, Modal, Empty } from 'antd';

import API from '../../utils/API';
import io from "socket.io-client";

import { AuthContext } from '../../App';
import ForumSubcategoryContent from '../ForumBlock/ForumSubcategoryContent';
import Forumcategory from '../../components/LeftSidebar/Forumcategory';
import { useHistory } from 'react-router-dom';
import ChatBlock from '../../components/RightSidebar/ChatBlock';
import SliderChatBlock from '../../components/RightSidebar/SliderChatBlock';

// antd

const TopicList = props => {
  const { contentLoading, setContentLoading, setInfo } = props;
  const [topics, setTopics] = useState([]);
  const [loading, setLoading] = useState(true);
  const history = useHistory();
  const [mainclass, setMainClass] = useState('anonymousforum');
  const [mainclass1, setMainClass1] = useState('subcat-topiclist');
  const [showActive, setShowActive] = useState(0);
  const [pages, setPages] = useState({
    currentPage: null,
    totalPages: null,
  });
  const { state } = useContext(AuthContext);
  const socket = useRef();
  React.useEffect(() => {
    if(state?.isAuthenticated){
      setMainClass1('midcontent');
      API.get("/users/counter/"+state?.user?.id).then((c) =>{
        if(c?.data?.connected){
          setShowActive(c?.data?.connected);
          setMainClass('maincontent forums bothsidebar');
        }else{
          setMainClass("maincontent forums sidebarleft")
        }
      });
    }
    return () => {
      return {
        mainclass,
        mainclass1
      }
    }
  }, [state])

    
  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
  }, [state]);

  // Initial load
  useEffect(() => {
    API.get(`/subcategories/topics?sid=${props.sid}`)
      .then(result => {
        if(result.data.topics.length > 0){
          setTopics(result.data.topics);
          setInfo({
            name: result.data.name,
            description: result.data.description,
          });
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages * 10,
          });
          setContentLoading(false);
          setLoading(false);
        }else{
          history.push('/forums');
        }
        
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  }, [props.sid, setContentLoading, setInfo]);

  // const fetchPage = page => {
  //   setLoading(true);

  //   API.get(`/subcategories/topics?sid=${props.sid}&page=${page}`)
  //     .then(result => {
  //       setTopics(result.data.topics);
  //       setPages({
  //         currentPage: result.data.currentPage,
  //         totalPages: result.data.totalPages * 10,
  //       });
  //       setLoading(false);
  //     })
  //     .catch(e => {
  //       Modal.error({
  //         title: 'Ocurrió un error',
  //         content: e.message,
  //       });
  //     });
  // };

  return (
      <div className={"forum " + (mainclass)}>
        <div className="maindiv">
          {(state.isAuthenticated && showActive != 0)
          ? <div className="sliderchatblock"><SliderChatBlock socket={socket}/></div>
            : null
          }
          <div className="leftsidebar">
            {state.isAuthenticated && (
            <div className="categoryfilterwrapper">
              {/* <SearchBlock/> */}
              {/* <Discussionblock/> */}
              <Forumcategory/>
            </div>
            )}
            {!state.isAuthenticated && (
            <div className="categoryfilterwrapper">
              <Forumcategory/>
            </div>
            )}

          </div>
          <div className={mainclass1}>
            <div className="forumspostscontent catforumspostscontent">
              <div className="forumsposts">
                {loading ? (
                  <div className="skeleton">
                      <Skeleton active />
                      <Skeleton active />
                      <Skeleton active />
                  </div>
                ) : topics.length > 0 ? (
                  topics.map(topic => {
                    if(topic.author != null){
                      return (
                        <div key={topic._id} className="catviewrow">
                          <ForumSubcategoryContent subcategorytopic={topic._id}/>
                        </div>
                      );
                    }
                    
                  })
                ) : (
                  <div>
                    <div colSpan={3}>
                      <Empty
                        description="el tema no ha sido encontrado"
                        className="notopics"
                      />
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
          {(state.isAuthenticated && showActive != 0)
          ? <div className="rightsidebar chatwrapper">
              <ChatBlock socket={socket} key={Date.now()}/>
            </div>
            : null
          }
        </div>
      </div>
  );
};

export default TopicList;
