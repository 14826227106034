import React from 'react';
import API from '../../utils/API';
import { useHistory } from 'react-router-dom'
import SponsorImage from '../../components/SponsorsData/SponsorImage';
import SponsorBannerImage from '../../components/SponsorsData/SponsorBannerImage';
import './sponsordetail.css';
const SponsorDetails = (props) => {
    const [blogData,setBlogData] = React.useState()
    const history = useHistory();
   React.useEffect(()=>{
    API.get('/sponsors/contents/node/' + props.pageid).then(result =>{
     setBlogData(result.data.sponsors[0]) 
    }).catch(error => {
      console.log(error);
    })
  
   },[])
  return (
      <div className="sponsordetailwrapper">
        <div className="backbutton">
          <button onClick={() => { history.goBack();}}>Go back</button>
        </div>
        <div className = "sponsordetailpage">
          <div className='banner-image'>
            <div className='banner-content '>
            <h1 className = "title">{blogData?.sponsorname}</h1>
            </div>
            <SponsorBannerImage id = {blogData?._id}/>
          </div>
          {blogData && <div className = "sponsordetail">
            <div className = "sponsordetailcontent" >
              <div className = "imagebox"><SponsorImage id = {blogData?._id}/></div>
              <div className = "contentbox">
                {blogData?.artistname ? <div className = "auther">por <text>{blogData?.artistname}</text> </div> : ''}
                <div className = "description"><text>{blogData?.description}</text> </div>
              </div>
            </div>
            {blogData?.sponsorurl&&<div className="contact-section">
            <div className='label'>Contact us</div>
            <a href={blogData.sponsorurl} target="_blank" rel="noreferrer noopener">
            View Website
            </a>  
            </div>}
          </div>}
        </div>
      </div>
  );
};
export default SponsorDetails;