import React from "react";
import Page from "../../components/Page/Page";
import { Link, useLocation, matchPath } from "react-router-dom";
import { Carousel,Skeleton,Modal } from "antd";
import "./sponsorlist.css";
import "./AllSponsors.css";
import API from "../../utils/API";
import SponsorImage from "../../components/SponsorsData/SponsorImage";
import sponsorbannerimage from "../../assets/images/sponsors.png";
import { AutoComplete } from 'antd';
const AllSponsors = (props) => {
  const [sponsors, setSponsors] = React.useState([]);
  const [filtersponsors, setFilterSponsors] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [active, setActive] = React.useState();
  const [noresult, setNoresult] = React.useState();
  const [stateList, setStateList] = React.useState([]);
  const [cityList, setCityList] = React.useState([]);
  const [selectedState, setSelectedState] = React.useState('');
  const [selectedCity, setSelectedCity] = React.useState('');
  const location = useLocation();
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const headerhide = matchPath(location.search, {
    path: ['?forapp=1'],
    exact: true,
    strict: false
  })

  const onCatChange = (catId) => {
    setActive(catId);
    const arr = [];
    arr.push(catId);
    API.get("/sponsors/bycategory/" + arr)
      .then((result) => {
        setSponsors(
          result.data.sponsors.sort((a, b) => {
            // equal items sort equally
            if (a.sponsorOrder === b.sponsorOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.sponsorOrder === null) {
              return 1;
            } else if (b.sponsorOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.sponsorOrder < b.sponsorOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.sponsorOrder < b.sponsorOrder ? 1 : -1;
            }
          })
        );
        setFilterSponsors(
          result.data.sponsors.sort((a, b) => {
            // equal items sort equally
            if (a.sponsorOrder === b.sponsorOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.sponsorOrder === null) {
              return 1;
            } else if (b.sponsorOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.sponsorOrder < b.sponsorOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.sponsorOrder < b.sponsorOrder ? 1 : -1;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };
  React.useEffect(() => {
    API.get(`/sponsors/`)
      .then((result) => {
        if(result?.data?.sponsors?.length){
          setSponsors(
            result.data.sponsors.sort((a, b) => {
              // equal items sort equally
              if (a.sponsorOrder === b.sponsorOrder) {
                return 0;
              }
              // nulls sort after anything else
              else if (a.sponsorOrder === null) {
                return 1;
              } else if (b.sponsorOrder === null) {
                return -1;
              }
              // otherwise, if we're ascending, lowest sorts first
              else if (true) {
                return a.sponsorOrder < b.sponsorOrder ? -1 : 1;
              }
              // if descending, highest sorts first
              else {
                return a.sponsorOrder < b.sponsorOrder ? 1 : -1;
              }
            })
          );
          setFilterSponsors(
            result.data.sponsors.sort((a, b) => {
              // equal items sort equally
              if (a.sponsorOrder === b.sponsorOrder) {
                return 0;
              }
              // nulls sort after anything else
              else if (a.sponsorOrder === null) {
                return 1;
              } else if (b.sponsorOrder === null) {
                return -1;
              }
              // otherwise, if we're ascending, lowest sorts first
              else if (true) {
                return a.sponsorOrder < b.sponsorOrder ? -1 : 1;
              }
              // if descending, highest sorts first
              else {
                return a.sponsorOrder < b.sponsorOrder ? 1 : -1;
              }
            })
          );
        }else{
          setNoresult(true);
        }
        
        API.get("/categories/getcategories")
          .then((resultc) => {
            const arr = [];
            resultc.data.category.map((name) => {
              for (let i = 0; i < result.data.sponsors.length; i++) {
                if (result.data.sponsors[i].selectedCategory[0] !== undefined) {
                  if (result.data.sponsors[i].selectedCategory[0] == name._id) {
                    const cat = name.name;
                    const _id = name._id;

                    arr.push({ name: cat, _id: _id });
                  }
                }
              }
            });
            const filteredArr = arr.reduce((acc, current) => {
              const x = acc.find((item) => item._id === current._id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setCategory(filteredArr);
          })
          .catch((e) => {
            Modal.error({
              title: "Ocurrió un error",
              content: e.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
      API.get('/location/details/state').then(async result => {
        setStateList(result?.data?.statelist)
       }).catch(err => {
         console.log(err);
       })
  }, []);
  const handleStateChange = (event) => {
    const { name, value } = event.target;
    console.log('value: ',value)
    setSelectedState(value);
    let match = value.match(/\((.*?)\)/);
    setCityList([])
    if(match){
      let stateCode = match[1]
      API.get('/location/details/city/US/'+stateCode).then(async result => {
        setCityList(result.data.citylist);
      }).catch(err => {
        console.log(err);
      })
    }
  }
  const handleCityChange = (value) => {
    // Set the selected city value to the state
    setSelectedCity(value);
    console.log('Selected City:', value);
  };

  const handleSearch = (event) => {
    event.preventDefault();
    if(selectedState != '' && selectedCity != '') {
      const filteredSponsors = sponsors.filter(sponsor => 
        sponsor.selectedState === selectedState && sponsor.selectedCity === selectedCity
      );
      setFilterSponsors(filteredSponsors);
      setNoresult(true);
    } else if (selectedState != '') {
      const filteredSponsors = sponsors.filter(sponsor => 
        sponsor.selectedState === selectedState
      );
      setFilterSponsors(filteredSponsors);
      setNoresult(true);
    } else if(selectedCity != '') {
      const filteredSponsors = sponsors.filter(sponsor => 
        sponsor.selectedCity === selectedCity
      );
      setFilterSponsors(filteredSponsors);
      setNoresult(true);
    } else {
      setFilterSponsors(sponsors);
      setNoresult(true);
    }

  }
  return (
    <Page>
      <div className="allsponsorspage maincontent">
        <div className="viewsponsorlist allsponsorslist">
          <div className="banner-sponsor">
            <div className="banner-sponsor-content">
              <h2 className="banner-sponsor-title">Sponsors</h2>
              <div className="banner-sponsor-sub">VERIFICA Y REALIZA <br/>TUS PROPIAS DILIGENCIAS</div>
            </div>
            <img src={sponsorbannerimage}/>
          </div>

          <div className="educationlistfilterslider">
            <div className="viewcontent">
              <Carousel {...settings}>
                {category.map((catName) => (
                  <div className="viewrow">
                    <a
                      onClick={() => onCatChange(catName._id)}
                      className={active == catName._id ? "active" : ""}
                    >
                      {catName.name}
                    </a>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
        <div className="sponsorsearch">
          <div className="form-title">Filtra tu busqueda</div>
          <div className="formbox">
            <form onSubmit={handleSearch}>
              <div className="form-item search-filter">
                <div className="item"><label>Estado</label>
                <select id="state-city-select" className="custom-select" onChange={handleStateChange} name="selectedState">
                  <option value="" key="0">Select State</option>
                  {stateList.map ( (res, key) => <option  key = {key} iso = {res.isoCode} value= {res.name + (' ('+res.isoCode.toUpperCase()+')')}>{res.name} ({res.isoCode})</option>)}
                </select>
                </div>
                <div className="item city-wrapper">
                  <label>Ciudad</label>
                  <AutoComplete
                    options={cityList.map((c) => ({ value: c.name }))}
                    onChange={handleCityChange}
                    placeholder="Select City"
                    filterOption={(inputValue, option) =>
                      option.value.toLowerCase().includes(inputValue.toLowerCase())
                    }
                  />
                </div>
              </div>
              <div className="form-action"><input type="submit" className="form-submit" value="Submit"/></div>
            </form>
          </div>
        </div>
        <div className="viewsponsor">
          {filtersponsors.length ? (
            <div className="viewcontent">
              {filtersponsors.map((sponsor) => (
                <Link to={(sponsor.pathalias.alias+((headerhide) ?'?forapp=1':''))} className="viewrow">
                  <div className="sponsorcontent">
                    <div className="content">
                      {String(sponsor.sponsorname).length >71?<h2 className="title">
                        <Link to={(sponsor.pathalias.alias+((headerhide) ?'?forapp=1':''))} title={sponsor.title}>
                          {sponsor.sponsorname.substring(0, 70)}...
                        </Link>
                      </h2>:<h2 className="title">
                        <Link to={(sponsor.pathalias.alias+((headerhide) ?'?forapp=1':''))} title={sponsor.title}>
                          {sponsor.sponsorname}
                        </Link>
                      </h2>}
                    </div>
                    <div className="imagebox">
                      <SponsorImage id={sponsor._id} />
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          ) : noresult == true ?
          <div className="catnotfound">
              <h3>No Sponsors</h3>
            </div>
          :(
            <div className="catnotfound">
              <Skeleton/>
            </div>
          )}
        </div>
        </div>
      </div>
    </Page>
  );
};
export default AllSponsors;
