import React, { useState, useContext } from 'react';
import Page from '../../components/Page/Page';
import {Form, Input, Button, Modal } from 'antd';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import { useHistory, Link, useLocation} from 'react-router-dom';
import { Redirect } from 'react-router';
import Realtor from '../../assets/images/Realtor-bro.svg';
import Endpoint from '../../assets/images/Endpoint-rafiki.svg';
import Freepikcoin from '../../assets/images/freepikcoin.svg';
/* import GoogleLoginPage from '../../components/GoogleLogin/GoogleLoginPage'; */
import './login.css';
/* import FacebookLoginPage from '../../components/FacebookLogin/FacebookLoginPage'; */

const Login = (props) => {
  const [loading, setLoading] = useState(false);
  const [visible, setVisible] = useState(false);
  const [visiblePhone, setvisiblePhone] = useState(false);
  
  
 /*  const[visiblePrelauch, setVisiblePrelauch] = useState(false); */
  const [VisibleEmailConfirm, setVisibleEmailConfirm] = useState(false);
  const [visibleBlock, setvisibleBlock] = useState(false);
  const { state } = useContext(AuthContext);
  const { dispatch } = useContext(AuthContext);
  const [form] = Form.useForm();
  const history = useHistory();
  const location = useLocation();
  const [userEmail, setUserEmail] = useState('');
  const [userPass, setPass] = useState('');
  const emailcheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  if(state.isAuthenticated){
    return <Redirect to="/dashboard"/>;
  }
  const onFinish = values => {
    setLoading(true);
    API.post('/users/login', {
      email: values.email.toLowerCase().trim(),
      password: values.password,
    })
      .then(result => {
          if(result.data.user!='null'){
           if(result.data.user.status == '0'){
              setvisibleBlock(true);
           }else if(result.data.user.confirm == '0' || result.data.user.confirm == null){
            setVisibleEmailConfirm(true);
            setUserEmail(values.email.toLowerCase());
            setPass(values.password);
           }else{
           dispatch({
            type: 'LOGIN',
            payload: {
              token: result.data.token,
              user: result.data.user,
            },
          });
          API.get('/users/' + result.data.user.id).then(udata => {
            if(udata?.data?.user?.userinfoid?.phonenumber){
              setvisiblePhone(true);
            }
          }).catch(err => {
            console.log(err);
          })
          if (location.search != '') {
            let redirectpath = location.search;
            history.push(redirectpath.replace('?=', '')+location.hash);
           }else {
            history.push('/dashboard');
           }
           
          }
        }
      })
      .catch(e => {
        setLoading(false);
        Modal.error({
          title: 'Error de inicio de sesion',
          content: e?.response?.data?.msg,
        });
      });
  };
  
  const openForgetModal =() => {
    setVisible(true);
  }

  const onCancel =() => {
    setVisible(false);
    form.resetFields();
  }
  const onOkVerification =() => {
    API.post('/users/resending/verificationlink', {
      email: userEmail,
      password: userPass,
    }).then(result =>{
      setVisibleEmailConfirm(false);
      history.push('/login');
      Modal.success({
        title: 'Se ha enviado un enlace de verificación a la identificación de correo electrónico registrada',
        centered: "true"
      })
    })
    .catch( e =>{
      setVisibleEmailConfirm(false);
      history.push('/login');
      Modal.success({
        title: 'Algo salió mal. Inténtalo después de algún tiempo.',
        centered: "true",
      });
    });  
  }
  const onOkBlock  =() => {
    //setVisiblePrelauch(false);
    history.push('/contactus');
  }
  const onFinishRequest = values => {
    API.post('/users/pass/reset', {
      email: values.femail.toLowerCase().trim(),
    })
    .then(result => {
      setLoading(false);
      form.resetFields();
      setVisible(false);
        Modal.success({
          title: 'Se ha enviado una instrucción de restablecimiento de contraseña a la identificación de correo electrónico registrada',
          centered: "true",
        });

    })
    .catch(e => {
      setLoading(false);
      Modal.error({
        title: 'El correo electrónico no existe en nuestro sistema',
        centered: "true",
      });
    });
  };

  return (
    <Page>
      <div className="signinpage">
        <div className="signupwrapper">
          <div className="signupintroduction">
            <h3>Bienvenidos a la,</h3>
            <h1>En La Cancha</h1>
            <div className="realtorimagebox">
                <div className="realtor1"><img width="180" src={Realtor} /></div>
                <div className="realtor2"><img width="480" src={Endpoint} /></div>
                <div className="realtor3"><img width="170" src={Freepikcoin}/></div>
            </div>
          </div>

          <div className="registration-form-wrap">
            <div className="registration-form">
              <h2>Iniciar sesión</h2>
              <Form
                name="login"
                onFinish={onFinish}
              >
                <Form.Item
                  label="Correo Electronico"
                  name="email"
                  className="form-item email"
                  /* rules={[
                    {
                      required: true,
                      message: 'Por favor introduzca una dirección de correo electrónico válida',
                      type: 'email',
                    },
                  ]} */
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if(typeof(value) == "undefined" || !value){
                         return Promise.reject(new Error('Por favor introduzca una dirección de correo electrónico válida'));
                        }else if(emailcheck.test(value.trim())){
                           return Promise.resolve();
                         }
                         return Promise.reject(new Error('Por favor introduzca una dirección de correo electrónico válida'));
                       }
                    },
                  ]}
                >
                  <Input disabled={loading} className="form-text" placeholder="Entra tu correo electronico"/>
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  className="form-item password"
                  rules={[
                    {
                      required: true,
                      message: 'La contraseña debe contener letras y numeros con un limite de 8 caracteres',
                      min: 8,
                    },
                  ]}
                >
                  <Input.Password disabled={loading} className="form-text" placeholder="Entra tu contraseña"/>
                  
                </Form.Item>
                <div className="forgotpassword" onClick={openForgetModal}>Olvidaste tu contraseña?</div>
                <Form.Item
                  className="form-action"
                >
                  <Button
                    type="primary"
                    htmlType="submit"
                    className="form-submit"
                    disabled={loading}
                  >
                    Iniciar Sesión
                  </Button>
                </Form.Item>
                {/* <div className="form-divider"><span>O</span></div> */}
                  {/* <div className="social-auth-login">
                     <div className="google"><GoogleLoginPage/></div>
                     <div className="facebook"><FacebookLoginPage/></div>
                  </div> */}
                  <div className="already-account">No tienes cuenta?<Link to="/pricing"> Subscribete</Link></div>
              </Form>
            </div>
         </div>
         
        </div>
      </div>
      {/* Forget Password */}
      <Modal
        visible={visible}
        okText="Enviar"
        onCancel={onCancel}
        wrapClassName={'forgetpopup'}
        centered="true"
        closable={false}
        footer={null}
      >
        <Form
          form={form}
          name="forgetpassword"
          onFinish={onFinishRequest}
          layout="vertical"
        >
          <Form.Item
            label="Correo Electronico"
            name="femail"
            className="form-item email"
            /* rules={[
              {
                required: true,
                message: 'Por favor introduzca una dirección de correo electrónico válida',
                type: 'email',
              },
            ]} */
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if(typeof(value) == "undefined" || !value){
                   return Promise.reject(new Error('Por favor introduzca una dirección de correo electrónico válida'));
                  }else if(emailcheck.test(value.trim())){
                     return Promise.resolve();
                   }
                   return Promise.reject(new Error('Por favor introduzca una dirección de correo electrónico válida'));
                 }
              },
            ]}
          >
            <Input disabled={loading} className="form-text" placeholder="Entra tu correo electronico"/>
          </Form.Item>
          <span>Se ha enviado una instrucción de restablecimiento de contraseña a la identificación de correo electrónico registrada.</span>
          <div className="modal-buttons">
            <Button
              htmlType="button"
              className="form-submit"
              disabled={loading}
              onClick={onCancel}
            >
              Cancelar
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              className="form-submit"
              disabled={loading}
            >
              Enviar
            </Button>
          </div>

        </Form>
      </Modal>

      {/* phonenumber update */}
      <Modal
        visible={visiblePhone}
        okText="Enviar"
        onCancel={onCancel}
        wrapClassName={'forgetpopup'}
        centered="true"
        closable={false}
        footer={null}
      >
        <Form
          form={form}
          name="phonenumbermanage"
          onFinish={onFinishRequest}
          layout="vertical"
        >
          <Form.Item
            label="Phone Number"
            name="phonenumber"
            className="form-item email"
            rules={[
              {
                required: true,
                message: 'Enter phone number',
              },
            ]}
          >
            <Input disabled={loading} className="form-text" placeholder="Entra tu correo electronico"/>
          </Form.Item>
          <span>You can change it from your profile section later.</span>
          <div className="modal-buttons">
            <Button
              type="primary"
              htmlType="submit"
              className="form-submit"
              disabled={loading}
            >
              Enviar
            </Button>
          </div>

        </Form>
      </Modal>

      {/* Pre Laucn Message */}
      {/* <Modal
        visible={visiblePrelauch}
        okText="ok"
        wrapClassName={'forgetpopup'}
        closable={false}
        keyboard="false"
        centered="true"
        footer={null}
      >
        <div className="successfully-message">
          <p className="heading">Bienvenido,</p>
        <p>Te has registrado correctamente como miembro previo al lanzamiento. Le notificaremos por correo electrónico con el lanzamiento de la plataforma. ¡Gracias por formar parte de nuestro equipo!</p>
        <p className="regards">Atentamente,<br/>
        <span>EnLaCancha Team</span></p>
        </div>
        <div className="modal-buttons">
          <Button
            type="primary"
            htmlType="submit"
            className="form-submit"
            onClick={onOkPrelaunch}
          >
            ok
          </Button>
        </div>

      </Modal> */}

     {/*  Email confirmation Prelauch */}
      <Modal
        visible={VisibleEmailConfirm}
        okText="ok"
        wrapClassName={'forgetpopup'}
        closable={false}
        keyboard="false"
        centered="true"
        footer={null}
      >
        <div className="successfully-message">
          <p className="heading">Hola,</p>
        <p>Se ha enviado un correo electrónico de verificación a tu cuenta. Primero debes verificar tu correo electrónico para iniciar sesión en tu cuenta. Por favor revisa tu carpeta de correo no deseado.
        Si no recibiste el correo electrónico de verificación. Vuelve a enviar el enlace de verificación del correo electrónico.</p>
        <p className="regards">Atentamente,<br/>
        <span>El equipo en la Cancha</span></p>
        </div>
        <div className="modal-buttons">
          <Button
            type="primary"
            htmlType="submit"
            className="form-submit"
            onClick={onOkVerification}
          >
            Vuelve a enviar el enlace de verificación
          </Button>
        </div>

      </Modal>

     {/*  Block Message */}
     <Modal
        visible={visibleBlock}
        okText="ok"
        wrapClassName={'forgetpopup'}
        closable={false}
        keyboard="false"
        centered="true"
        footer={null}
      >
        <div>
          <p>Has sido bloqueado. Comuníquese con el administrador a través de la página Contáctenos.</p>
        </div>
        <div className="modal-buttons">
          <Button
            type="primary"
            htmlType="submit"
            className="form-submit"
            onClick={onOkBlock}
          >
            ok
          </Button>
        </div>

      </Modal>

    </Page>
  );
};

export default Login;
