import React, { Component } from 'react';
import Page from '../../components/Page/Page';
import { Collapse, Modal } from 'antd';
import {PlusOutlined, MinusOutlined} from '@ant-design/icons';
import API from '../../utils/API';
import './css/faq.css';
import './css/searchbox.css';
import './css/leftsidemenu.css';
import '../Forum/css/forumssearchbox.css';

const { Panel } = Collapse;

class FAQ extends Component{
   state ={
      records:[]
   }
   componentDidMount(){
      this.getData();
   }
   getData(){
      API.get('/faqs/contents')
      .then(result => {
        const faqs= result.data.faqs;
        var faqsdata = [];
        faqs.map((faq, index) =>{
          faqsdata[index] = {
            question: faq.question,
            answer: faq.answer,
          }
        });
        this.setState({records: faqsdata});
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
   }
   onSubmit(){
   }
   render(){
      return(
         <Page>
            <div className="faq maincontent">
            <div className="faqsearchbox">
               <h2 className="maintitle">Preguntas Frequentes</h2>
               {/* <div className="forumssearchbox">
                  <div className="userpic"><a href="guestuser.html"><img src="../image/userpic.svg"/></a></div> 
                  <div className="searchbox">
                     <form onSubmit={this.onSubmit}>
                        <div className="form-item search-filter">
                           <input type="text" id="byname" name="byname" className="formtext" placeholder="Escribe tu pregunta"/>
                        </div>
                        <div className="form-action">
                           <input type="submit" value="Submit" className="form-submit"/>
                        </div>
                     </form>
                  </div>
               </div>*/}
            </div>
            <div className="maindiv">
               {/* <div className="leftsidebar">
                  <div className="leftsidemenu">
                     <ul>
                        <li><Link to="/education">Educacion</Link></li>
                        <li><Link to="/comingsoon">Socializa</Link></li>
                        <li><Link to="/comingsoon">Forums</Link></li>
                        <li><Link to="/comingsoon">Membresias</Link></li>
                        <li><Link to="/comingsoon">Perfil</Link></li>
                        <li><Link to="/terms_and_conditions">Teminos y Condiciones</Link></li>
                        <li><Link to="/privacy_policy">Politica de Privacidad</Link></li>
                     </ul>
                  </div>
               </div> */}
               <div className="midcontent">
                  <div className="viewfaqlist">
                     <div className="viewcontent">
                        
                        <Collapse 
                        className="viewcontent"
                        bordered={false}
                        expandIconPosition="right"
                        
                        expandIcon={({ isActive }) => isActive ?
                        (<MinusOutlined/>)
                        :(<PlusOutlined/>)
                        }
                        >
                           {this.state.records.map((record, index) =>
                              
                              <Panel 
                                 header={record.question}
                                 key={index}
                               >
                              <div className="contentbody">
                                 <div dangerouslySetInnerHTML={ { __html:record.answer}} ></div>
                              </div>
                           </Panel>
                           )}
                        </Collapse>
                     </div>
                  </div>
               </div>
            </div>
            </div> 
         </Page>
      );
   }
}
export default FAQ;


      
