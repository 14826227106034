import React, { useContext } from 'react';
import { AuthContext } from '../../App';
import { Link } from 'react-router-dom';
import ProfileImage from '../WidgetBar/ProfileImage';
import Moment from 'moment';
import Linkify from 'react-linkify';

const CommentList = props => {

  const { state } = useContext(AuthContext);
  if (state.isAuthenticated) {
    return (
      <div className='comment-list'>
        {props.commentList.map((item, index) => {
          if(index > 0 && item.author != null){
            return <div className="commentitem" key={index}>
                <div className="userinfomation">
                  <div className="userpic">
                    <Link to={item?.author?.pathalias?.alias}>
                      <ProfileImage id={item.author._id}/>
                    </Link></div>
                  <div className="commentcontent">
                    <div className="commentusername"><Link to={item?.author?.pathalias?.alias}>{item.author.firstname} {item.author.lastname}</Link></div>
                    <div className="commentdesc">
                    <Linkify
                        componentDecorator={(decoratedHref, decoratedText, key) => (
                            <a target="blank" href={decoratedHref} key={key}>
                                {decoratedText}
                            </a>
                        )}
                    ><div dangerouslySetInnerHTML={{ __html: item.message.replace(/\n\r?/g, '<br />') }}/></Linkify>
                    </div>
                    <div className="commentposted">Publicado en: {Moment(item.createdAt).format('MMM D, YYYY')}</div>
                  </div>
                </div>
              </div>
          }
        })}

      </div>
    );
  }

  return (
    <div className="unauthorize">
      Debes iniciar sesión para responder a este tema..
      <br />
      <Link to="/login">Iniciar sesión</Link> | <Link to="/pricing">Suscríbete</Link>
    </div>

  );
};

export default CommentList;
