import React, { useEffect, useContext } from 'react';
import Page from '../../components/Page/Page';
import { MyStepForm } from "./MyStepForm";
import API from '../../utils/API';
import './HelpUs.css'
import { AuthContext } from '../../App';
import { Redirect } from 'react-router-dom';


const HelpUs = props => {
  const { state } = useContext(AuthContext);

   useEffect(()=>{
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
         const user = JSON.parse(localStorage.getItem('user'));
       API.get('/users/' + user.id).then(result => {
         if(result?.data?.user?.pathalias?.pagetype == undefined){
           API.post(`/users/addpath/`,{id:user.id}).then(res => {

            }).catch(err => {
              console.log(err);
            })
         }
       }).catch(err => {
         console.log(err);
       })}
   },[])

   if(state?.user?.usertype.includes('lender') && state?.user?.sub == 'free' && state?.user?.level != 1){
    return <Redirect to="/dashboard/lender"/>;
  }
   return (
    <Page>
      <div className="maincontent helpuspage">
         <MyStepForm />
      </div>
    </Page>
   );
};

export default HelpUs;
