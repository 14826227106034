import React from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props'
import API from '../../utils/API';
import { AuthContext } from '../../App';
import { Modal } from 'antd';
import { useHistory,useLocation } from 'react-router-dom';

const FacebookLoginPage = props => {
  const { dispatch } = React.useContext(AuthContext);
  const history = useHistory();
  const location = useLocation();
  const responseFacebook = (response) => {
    API.post('/users/sociallogin', {
      email: response.email.toLowerCase().trim(),
      firstname: response.first_name,
      lastname: response.last_name,
      username: response.email.toLowerCase().trim(), //assign email as username
      password: response.userID,
      platform: "Facebook"
    })
      .then((result) => {
        dispatch({
          type: 'LOGIN',
          payload: {
            token: result.data.token,
            user: result.data.user,
          },
        });
        if (location.search !== '') {
          let redirectpath = location.search;
          history.push(redirectpath.replace('?=', '')+location.hash);
         }else {
          history.push('/dashboard');
         }
      })
      .catch(e => {
        Modal.error({
          title: 'Registro fallido',
          content: e.response.data.msg,
        });
      });
    }

  return (
    <div >
     <FacebookLogin
  appId="1506934566358928"
  autoLoad = {false}
  fields="first_name,last_name,email,picture"
  callback={responseFacebook}
  render={renderProps => (
    <a onClick = {renderProps.onClick} >Continua con Facebook</a>
  )}
/>
      
    </div>
  );
};

export default FacebookLoginPage;
