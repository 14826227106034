import React, {useEffect, useContext, useState, useRef} from 'react';
import './css//ChatBlock.css';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import ProfileImage from "../WidgetBar/ProfileImage";
import noavtar from '../../assets/no-avatar.png';
import { useHistory } from 'react-router-dom';
import { Skeleton } from 'antd';

const ChatBlock = (props) => {
   const [friends, setFriends] = useState([]);
   const { state } = useContext(AuthContext);
   const [onlineUsers, setOnlineUsers] = useState([]);
   const [Loading, setLoading] = useState(0);
   const [Loadingf, setLoadingf] = useState(0);
   const history = useHistory();
   React.useEffect(() => {
      props?.socket?.current?.emit("addUser", state?.user?.id);
      props?.socket?.current?.on("getUsers", users => {
         setOnlineUsers(users);
      })
      setLoading(1);
      
    }, [props?.socket, Loading, Loadingf]);
  useEffect(() => {
      const getFriends = async () => {
          try {
              const res = await API.get("/users/f/"+state?.user?.id);
              if(res.data?.friend?.friends){
                  setFriends(res.data.friend.friends);
              }
          }catch(err){
              console.log(err);
          }
          setLoadingf(1);
      };
      getFriends();
    }, [state?.user?.id,Loading, Loadingf]);

    const handleClick = async (connecteduser) => {
      try {
        const res = await API.get(
          `/conversations/find/${state?.user?.id}/${connecteduser._id}`
        );
        if(res?.data){
          history.push('/messenger?chat='+res.data._id);
        }else{
            const res = await API.post("/conversations", {
               senderId:state?.user?.id,
               receiverId: connecteduser._id,
            });
            history.push('/messenger?chat='+res.data._id);
            history.go();
        }
  
      } catch (err) {
        console.log(err);
      }
    };
   return (
      <div className="chatblock">
         <h2 className="blocktitle">Mis Conexiones { <span className="count">{friends.length > 0 ? "("+friends.length+")" : null}</span> }</h2>
         {(Loading == 1 && Loadingf == 1)
         ?
         <div className="friendchatlist">
            <div className="viewcontent">
               <div className='topbox'>
                     {friends.map((f, index) => (
                        (onlineUsers?.some(function(o){return o["userId"] === f?.connecteduser?._id}))
                        ?
                        <div className="viewrow status-online" key={index}  onClick={() => handleClick(f?.connecteduser)}>
                           <div className="userpic">
                              <ProfileImage id={f.connecteduser?._id} classtouse="conversationImg"/>
                              <div className="status online"></div>
                           </div>
                           <div className="username">
                              <span>{f?.connecteduser?.firstname}</span> <span> {f?.connecteduser?.lastname}</span>
                           </div>
                        </div>
                        :
                        null
                     ))}            
               </div>
               <div className='bottombox'>
                  {friends.map((f, index) => (
                     (onlineUsers?.some(function(o){return o["userId"] === f?.connecteduser?._id}))
                     ? null
                     :
                     <div className="viewrow status-offline"  key={index}  onClick={() => handleClick(f?.connecteduser)}>
                        <div className="userpic">
                           <ProfileImage id={f.connecteduser?._id} classtouse="conversationImg"/>
                           <div className="status offline"></div>
                        </div>
                        <div className="username">
                           <span>{f?.connecteduser?.firstname}</span> <span> {f?.connecteduser?.lastname}</span>
                        </div>
                     </div>
                  ))}
               </div>
            </div>
         </div>
         
         :<div className=''><Skeleton avatar/></div>
         
      }
      </div>
  );
};

export default ChatBlock;
