import React, { useState, useContext } from 'react';
import { Popover, Button } from 'antd';
import ProfileImage from '../WidgetBar/ProfileImage';
import noavtar from '../../assets/no-avatar.png';
import './NotificationBell.css';
import convertDate from '../../utils/convertDate';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';
import API from '../../utils/API';
import ConnectionNotificationItem from './ConnectionNotificationItem';


const NotificationBell = (props) => {
  var counter = 0;
  const [visible, setispopover] = useState(false);
  const { state } = useContext(AuthContext);
  const [shownotify, setNotify] = useState();
  const handleVisibleChange = (visible) => {
    setispopover(visible);
  }
  React.useEffect(()=>{
    let isMounted = true; 
    if(props.uid != null && isMounted){
      API.get('/users/notify/'+props.uid).then(notify => {
        setNotify(notify);
      }).catch(error => {
        console.log(error);
      });
    }
    return () => { isMounted = false }; 
  },[]);

  React.useEffect(() => {
    props?.socket?.current?.emit("addUser", state?.user?.id);
    props?.socket.current.on("getDesktopNotification", (data) => {
        const getNotification = async () => {
            if(state?.user?.id == data?.receiverId){
                try {
                    const res = await API.get("/users/notify/"+data?.receiverId);
                    setNotify(res);
                  } catch (err) {
                    console.log(err);
                  }
            }
        };
        getNotification();
    });
  }, [props?.socket]);
  React.useEffect(() => {
    props?.socket?.current?.emit("addUser", state?.user?.id);
  }, []);

  const markRead = (sid) =>{
    API.post(
      `/users/api/graphql`,
      {
      notifications_update_seen_or_read: sid
      },
      {
        headers: {
          Authorization: `Bearer ${state.token}`,
        },
      }
      ).then(result =>{
     
      })
      .catch(error => {
        console.log(error);
      })
  }
  const markAllRead = async(readby) =>{
    const resreadAll = await API.post("/users/noti/mallread", {
        b:readby,
    });
    const getNotification = async () => {
      if(readby){
          try {
              const res = await API.get("/users/notify/"+readby);
              setNotify(res);
            } catch (err) {
              console.log(err);
            }
      }
    };
    getNotification();
  }
  const text = <span>Notificacion <div className="seeallnotification">
      <Link to="/notifications" onClick={()=>{setispopover(false)}}>Ve Todo</Link>
      <span className='seperator'> | </span>
      <Button
      className='mark-read'
      onClick={()=>{markAllRead(state?.user?.id)}}>
          Marcar como leídos
      </Button>
    </div>
  </span>;
  const content = (
    <div>
      {shownotify?.data?.notify.map((notifyitem, index) =>{
        if(notifyitem.status == 'false'){
          counter++
        }

        return <div key={index} className={"item-row "+(notifyitem.status == 'false'? 'new': 'old')} onClick={() =>markRead(notifyitem.shortid)}>
            {notifyitem?.notificationcategory == 'connection' 
            ? <ConnectionNotificationItem notifyitem={notifyitem}/>
            : <Link to={notifyitem?.redirecturl} onClick={() => window.location.href = notifyitem?.redirecturl}>
              <div>
                <span className={"picbox "+ (notifyitem?.notificationtype)}><ProfileImage id={notifyitem?.author?._id}/></span>
                
                <span>
                  <div>
                    <strong className='name-s'>{notifyitem?.author?.firstname} {notifyitem?.author?.lastname}</strong>
                    {notifyitem?.notificationtype == 'like'
                    ? 
                      <span> le gusto tu publicacion: <strong>{notifyitem?.entityid?.title}</strong></span>
                    : null
                    }
                    {notifyitem?.notificationtype == 'comment'
                      ? 
                        <span> Comento en tu publicacion: <strong>{notifyitem?.entityid?.title}</strong></span>
                      : null
                    }
                  </div>
                  <div className="timelog">{convertDate(notifyitem.createdAt)}</div>
                </span>
                
              </div>
              </Link>
      }
        </div>
      })}
    </div>
  );

  return (
    <>
      <div className="bell-notification">
      <Popover overlayClassName='onlynotification'
       placement="bottom"
       title={text}
       content={<div className={shownotify?.data?.notify.length?"all-notification": "no-notification"} onClick={()=>{setispopover(false)}}>{shownotify?.data?.notify.length ? content : 'No tienes notificaciones.'}</div>}
       trigger="click"
       visible={visible}
       onVisibleChange={handleVisibleChange}
       >
        <Button>
          {counter > 0 && <span className="counter">{counter}</span>}
          <span className="bell-icon"></span>
        </Button>
      </Popover>
      </div>
    </>
  );
};

export default NotificationBell;
