import React, { useState } from 'react';
import { Form, Input, Modal, Checkbox } from 'antd';
import { Link } from 'react-router-dom';
import API from '../../utils/API';
import './Signuppopup.css';

const Signuppopup = props => {
  const [visible, setVisibile] = useState(false);
  const [form] = Form.useForm();
  
  const onFinish = values => {
    /* setLoading(true); */
    API.post('/users/register', {
      email: values.email.toLowerCase().trim(),
      firstname: values.firstname,
      lastname: values.lastname,
      username: values.email.toLowerCase().trim(), //assign email as username
      password: values.password,
    })
      .then(() => {
        /* setLoading(true); */
        form.resetFields();
        setVisibile(true);
        /* Modal.info({
          title: 'verificacion de email',
          content: 'Se ha enviado un correo de verificación a su correo electrónico. Por favor verifique para disfrutar Nuestros servicios',
        }); */
        //props.form.resetFields();
      })
      .catch(e => {
        /* setLoading(false); */
        Modal.error({
          title: 'Registration Failed',
          content: e.response.data.msg,
        });
      });
  };

  return (
          <div className="registrationformwrap">
            <div className="registrationform">
              <h2>Registracion Gratuita</h2>
              <div className="helptext">Vamos a empezar tu cuenta</div>
              <Form
                form={form}
                name="signup"
                layout="vertical"
                onFinish={onFinish}
                size = {'default'}
              >
                <Form.Item
                  label="Primer Nombre"
                  name="firstname"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'Entra tu primer nombre',
                    },
                    /* {
                      validator(_, value) {
                        if (!/^[a-zA-Z]+$/.test(value)) {
                          return Promise.reject(new Error('Only Alphabet are allowed'));
                        }
                          
                        },
                    }, */
                  ]}
                >
                  <Input  placeholder="Entra tu primer nombre" className="formtext"/>
                </Form.Item>
                <Form.Item
                  label="Apellido"
                  name="lastname"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'Entra tu apellido',
                    },
                    /* {
                      validator(_, value) {
                        if (!/^[a-zA-Z]+$/.test(value)) {
                          return Promise.reject(new Error('Only Alphabet are allowed'));
                        }
                      },
                    }, */
                  ]}
                >
                  <Input  placeholder="Entra tu apellido" className="formtext"/>
                </Form.Item>
                <Form.Item
                  label="Correo Electronico"
                  name="email"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'Por favor introduzca un correo electrónico válido',
                      type: 'email',
                    },
                  ]}
                >
                  <Input  placeholder="Entra tu correo electronico" className="formtext"/>
                </Form.Item>
                <Form.Item
                  label="Contraseña"
                  name="password"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      message: 'La contraseña debe contener letras y numeros con un limite de 8 caracteres',
                      min: 8,
                    },
                  ]}
                >
                  <Input.Password  placeholder="Entra tu contraseña" className="formtext"/>
                </Form.Item>
                {/* <Form.Item
                  name="confirm"
                  label="Confirm Password"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: 'Please confirm your password!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                          return Promise.reject(new Error('The two passwords that you entered do not match!'));
                        },
                      }),
                    ]}
                >
                <Input.Password placeholder="Confirm Password"/>
                </Form.Item> */}
                <Form.Item
                  name="agreement"
                  valuePropName="checked"
                  rules={[
                    {
                      validator: (_, value) =>
                        value ? Promise.resolve() : Promise.reject(new Error('Please accept above Terms and Privacy policy')),
                    },
                  ]}
                >
                <Checkbox
                style={{ fontSize:'12px' }}
                >Registrandote a En la Cancha tu estas de acuerdo con la <a href="/privacy_policy">Política de privacidad</a>, <a href="/terms_and_conditions">Términos y condiciones</a>
                </Checkbox>
                </Form.Item>
                <Form.Item
                  style={{textAlign: 'center' }} 
                  className="formaction"             
                >
                  <input type="submit" value="Registrate" className="formsubmit"></input>
                </Form.Item>
              </Form>
              <div className="formdivider"><span>O</span></div>
              <div className="socialauthlogin">
                {/* <div className="google"><GoogleLoginPage/></div> */}
                {/* <div className="facebook"><a href="javascript:void(0)">Continua con Facebook</a></div> */}
              </div>
              <div className="alreadyaccount">Ya tengo una cuenta?<Link to="/login"> Iniciar sesión</Link></div>
            </div>
          </div>
          
  );
};

export default Signuppopup;
