import React, { Component } from 'react';
import './education.css';
import axios from 'axios';
import { Link } from 'react-router-dom';

class EducationListingItem extends Component {
   state = {
      imgdata: ''
   }
   componentDidMount() {
      axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(this?.props?.datalistitem?.videos[0].videoURL)))
      .then(res => {
        const imageUrl = res.data.thumbnail_url.split('_');
          this.setState({imgdata:imageUrl[0]})
      })
   }
    render(){
       const datalist = this?.props?.datalistitem;
         return (
            <Link to={datalist.pathalias.alias} className="viewrow education-item" key={this.props.key}>
               {/* <div className="type-tag">{datalist.visibilityoption == 'free'? 'GRATIS': (datalist.visibilityoption == 'paid'? 'PAGADO': null) }</div> */}
               <div className={"imagebox player-wrapper"+(this?.state?.imgdata ? ' preview-loaded ': '')}>
               <span>play</span>
               <div> {this.state.imgdata && <img src={this.state.imgdata+'_350'} height="192px" width="352px" alt=""/>}</div>
               </div>
               <div className="titlebox">
                  {String(datalist.title).length >71 
                  ? <h2 className="title">
                        {String(datalist.title).substring(0,70)}...
                  </h2>
                  :
                  <h2 className="title">
                        {datalist.title}
                  </h2>
                  }
               </div>
            </Link>                
         );      
   }

};

export default EducationListingItem;
