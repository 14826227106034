import React, { useState, useEffect, useContext } from 'react';
import { Button } from 'antd';
import { AuthContext } from '../../App';
import API from '../../utils/API';

// antd

const LikeBlock = props => {
  const [loading, setLoading] = useState(false);
  const { state } = useContext(AuthContext);
  const [likecount, setlikecount] = useState(0);
  const [likeaction, setlike] = useState('like');
  useEffect(() => {
    let isMounted = true;
    if(props?.pid){
      API.get('/posts/'+props.pid)
      .then(result=> {
        if(isMounted && result.data != null){
          setlikecount(result.data.post.likepost.length);
          if(result.data.post.likepost.length > 0){
           result.data.post.likepost.map(item =>{
             if(item == state.user.id){
              setlike('dislike');
             }
           })
          }
        }
      })
      .catch(e =>{
        console.log(e.response);
      });
    }

    return () => { isMounted = false }; 
  }, [loading,likeaction]);

  const changeLable = (e) => {
    setLoading(true);
    if(likeaction == 'like'){
      API.post('/posts/like',{
        _id:props.pid,
        uid: props.uid,
        detailPageUrl: props.detailPageUrl
      }).then(result=> {
        setlike('dislike');
        setLoading(false);
        //let receiverId = props.receiverId;
        /* props.socket.current.emit("sendDesktopNotification", {
          receiverId,
        }); */
        //trigger to notifications to devices
        /* var uid = state.user.id+"_"+props.pid;
        API.post(
          `/users/fcm/notifications/web`,
          {
            "title": state?.user?.firstname +' '+state?.user?.lastname,
            "body": 'le gusto tu publicacion',
            "type":"like",
            "senderuid":uid,
            "receiverId": receiverId
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        ) */
      })
      .catch(e =>{
        setLoading(false);
        console.log(e.message);
      })
    }else if(likeaction == 'dislike'){
      setlike('like');
      API.post('/posts/dislike',{
        _id:props.pid,
        uid: props.uid
      }).then(result=> {
        setlike('like');
        setLoading(false);
      })
      .catch(e =>{
        setLoading(false);
        console.log(e.message);

      })
    }
  }
  if (state.isAuthenticated) {
    return (
      <div className={'wrapper'+(likeaction)}>
          <Button
            rows={4}
            className=''
            onClick={(e) => {
              changeLable(e);
            }}
            disabled={loading}
          >
            {likeaction}
          </Button>
          <div>{likecount}</div>
      </div>
    );
  }
};

export default LikeBlock;
