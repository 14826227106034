import React, { Component } from "react";
import API from "../../utils/API";
import { Modal } from "antd";
import { Link, withRouter } from "react-router-dom";
import Moment from "moment";
import { Carousel } from "antd";
import axios from 'axios';
import "../Education/education.css";
import VimeoThumbnail from "../../components/WidgetBar/VimeoThumbnail";

class TipOfTheWeekListing extends Component {
  state = {
    datalists: [],
    counter: 0,
    initial: 0,
    accesscheck: '',
    hideredirectmodal: false,
    membershiprole: 'free',
    hidesubscriptiontmodal: false,
    showRedirectModal:false,
    level:0,
    imgdata:[], 
  };
  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1119,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  componentDidMount() {
    this.getData();
    this.handleRedirect = this.handleRedirect.bind(this);
    this.cancleRedirect = this.cancleRedirect.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleCancel = this.handleCancel.bind(this)
  }

  getData() {
    var that = this;
    API.get("/tipoftheweek/contents")
      .then((result) => {
        const datalists = result.data.tipoftheweek.sort((a, b) => {
          // equal items sort equally
          if (a.tipOfTheWeekOrder === b.tipOfTheWeekOrder) {
            return 0;
          }
          // nulls sort after anything else
          else if (a.tipOfTheWeekOrder === null) {
            return 1;
          } else if (b.tipOfTheWeekOrder === null) {
            return -1;
          }
          // otherwise, if we're ascending, lowest sorts first
          else if (true) {
            return a.tipOfTheWeekOrder < b.tipOfTheWeekOrder ? -1 : 1;
          }
          // if descending, highest sorts first
          else {
            return a.tipOfTheWeekOrder < b.tipOfTheWeekOrder ? 1 : -1;
          }
        });
        this.setState({ datalists });
        //const iddata = [];
        //let Vimeo = require('vimeo').Vimeo;
        //let client = new Vimeo("{client_id}", "{client_secret}", "{access_token}");
        //let client = new Vimeo("721e84fa5be48b9b62e753ae08747a2f6f8d748d", "InC25cSCXjI9NdWvPPvjBX+akYs+qqny30FrRVyiJUPo2KjePXENmNEapS8gj3CVnO5SeOCjTetCiSNWW7GOgm8N99t8GtyaLaTHI0A4bLdDKYE6Dj4/w1yNqKtmdkxc", "36273e305fdd9a218b7b54380e2a1025");
        //datalists.map((id,index,arr)=> {
          /* client.request({
            method: 'GET',
            path: '/videos/'+Number(id.videoURL)
            }, function (name, embed) {
              iddata[index] = embed?.pictures?.base_link;
              if(arr.length - 1 === index){
                  that.setState({imgdata:iddata});
              }
            }) */
        /* axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(id.videoURL)))
        .then(res => {
          const imageUrl = res.data.thumbnail_url.split('_');
          iddata[index] = imageUrl[0];
         
          if(arr.length - 1 === index){
            this.setState({imgdata:iddata})
          }
        }) */
      //})
      })
      .catch((e) => {
        Modal.error({
          title: "Ocurrió un error",
          content: e.message,
        });
      });
 
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
        let id = JSON.parse(localStorage.getItem('user')).id;
        API.get('users/'+id).then(result=>{
          this.setState({level:JSON.parse(localStorage.getItem('user')).level});
        
          if(JSON.parse(localStorage.getItem('user')).level != 1){
          if(result.data != null && result.data.user.membershiprole != 'undefined'){
            this.setState({membershiprole: result.data.user.membershiprole});
            
          }} else {
          this.setState({subscriptionUpgradeModal:false})
          this.setState({membershiprole: 'admin'});
          }
        }).catch(e =>{
         
        });
      
     } else {
        this.setState({hideredirectmodal:true});
     }
  }
  handleRedirect (alias){
    this.setState({hideredirectmodal: false});
    this.setState({hidesubscriptiontmodal: false});
    if(localStorage.getItem('token')){
      let path = '/pricing?='+alias;
      this.props.history.push(path);
    }else{
      let path = '/login?='+alias;
      this.props.history.push(path);
    }
 }
 handleCancel = () => {
  this.setState({showRedirectModal: false});
  this.setState({hidesubscriptiontmodal: false});
  this.props.history.push('/pricing')
  document.body.classList.remove('modalopen');
};
 cancleRedirect(){
   this.setState({showRedirectModal: false});
   this.setState({hidesubscriptiontmodal: false});
 }
 handleNavigation(){
   if(this.state.hideredirectmodal){
     this.setState({showRedirectModal:true})
   }else
 if(this.state.membershiprole == 'free'){
  this.setState({hidesubscriptiontmodal: true});
 }
 }
  render() {
    const redirectModal = (alias) => {
      return <Modal
         visible={this.state.showRedirectModal}
         okText="Iniciar sesión"
         onOk={()=>this.handleRedirect(alias)}
         onCancel={this.handleCancel}
         cancelText="Suscríbete"
         keyboard="false"
         centered="true"
         closable={false}
      >
         <div className="emptymessage" >Debes iniciar sesión para ver este video</div>
      </Modal>
   }
   const subscriptionUpgradeModal = (alias) => {
      return <Modal
         visible={this.state.hidesubscriptiontmodal}
         okText="Actualizar membresía"
         onOk={()=>this.handleRedirect(alias)}
         onCancel={this.cancleRedirect}
         cancelText="Cancelar"
         keyboard="false"
         centered="true"
         closable={false}
      >
         <div>Necesita actualizar su membresía para ver todos los videos</div>
      </Modal>
   }
    if (this.state.datalists.length) {
      Moment.locale("en");
      return (
        <div className="viewvideocourselist tipofweeklist">
          <div className="viewvideocourse">
            <h2>Jugadas en la cancha</h2>
            <div className="viewcontent">
              <Carousel {...this.settings}>
                {this.state.datalists.map((datalist, index) => (
                  <div className="viewrow" key={index}>
                   <div  onClick={this.handleNavigation} className={"imagebox preview-loaded"}>                 
                      {this.state.membershiprole !== 'free' && !this.state.hideredirectmodal ? <Link to={datalist.pathalias.alias}><span>play</span></Link>:<span onClick={this.handleNavigation}>play</span>}
                      {/* <div>{this.state.imgdata[index] && <img src={this.state.imgdata[index]+'_350'} alt="" height="192px" width="352px"/>}</div> */}
                      <div><VimeoThumbnail videoId={datalist?.videoURL} showPlayicon={false}/></div>
                    </div>
                    {this.state.membershiprole !== 'free' && !this.state.hideredirectmodal ?<div className="titlebox">
                      {String(datalist.title).length > 71?<h2 className="title">
                        <Link
                          to={datalist.pathalias.alias}
                          title={datalist.title}
                        >
                          {String(datalist.title).substring(0,70)}...
                        </Link>
                      </h2>:<h2 className="title">
                        <Link
                          to={datalist.pathalias.alias}
                          title={datalist.title}
                        >
                          {String(datalist.title)}
                        </Link>
                      </h2>}
                    </div>:<div className="titlebox">
                      <h2 className="title">
                        {String(datalist.title).length > 71?<div style={{cursor:'pointer'}}
                          onClick={this.handleNavigation}
                        >
                          {String(datalist.title).substring(0,70)}...
                        </div>:<div style={{cursor:'pointer'}}
                          onClick={this.handleNavigation}
                        >
                          {String(datalist.title)}
                        </div>}
                      </h2>
                    </div>}
                    {redirectModal(datalist.pathalias.alias)}
                     {subscriptionUpgradeModal(datalist.pathalias.alias)}
                  </div>
                ))}
              </Carousel>
            </div>
            <div className="viewall">
              <Link to="/education/tipoftheweek">Ver todo</Link>
            </div>
          </div>
       
        </div>
      );
    } else {
      return <div></div>;
    }
  }
}

export default withRouter(TipOfTheWeekListing);
