import React, { useState, useEffect } from 'react';
import {Skeleton} from 'antd';
import API from '../../utils/API';
import defaultProfileimage from "../../assets/images/userdefault.png"
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';

const PodcastImage = props => {

  const [loadings, setLoadings] = useState(true);
  const [profileImage, setProfileImg] = useState('');

  useEffect( ()=> {
    if(props?.id){
        API.get(`/podcasts/getimagecover/?_id=${props.id}`).then(result => {
          if(result.data){
            setProfileImg(
              <LazyLoadImage
              alt="podcast"
              effect="blur"
              src={result?.data?.base64}
             />)
            setLoadings(false);
          }
      }).catch(err => {
        setProfileImg(<img  alt="" className="img" src={defaultProfileimage} />);
        setLoadings(false);
          return
      })
    }
  },[props?.id]);

  return (
    <div>
      {loadings ? (
        <Skeleton.Avatar className="skeletonk" active avatar shape="circle"/>
      ) : (
        <div>{profileImage}</div>
      )}
    </div>
    
    
    
  );
};

export default PodcastImage;
