import React from 'react';
import API from '../../utils/API';
import {Link} from 'react-router-dom';
import './blogdetail.css';
import CategoryName from '../../components/CategoryName/CategoryName';
import BlogImage from '../../components/BlogData/BlogImage';
import SocialShareBlock from '../../components/SocialShareBlock/SocialShareBlock';
const BlogDetails = (props) => {
    const [blogData,setBlogData] = React.useState()
   React.useEffect(()=>{
    API.get('/blogs/contents/node/' + props.pageid).then(result =>{
     setBlogData(result.data.blogs[0])
     createMarkup(result.data.blogs[0].description)
    })
   },[])
   const createMarkup = (data) => {
      return {__html: data};
    }

  return (
   <div className="blogsdetailspage">
      {blogData && <div className = "blogsdetail" >
         <div className="breadcrumbwrapper">
            <div className="breadcrumb">
               <ul>
                  <li><Link to='/education'>Educacion</Link></li>
                  <li><Link to="/education/blogs">Blogs</Link></li>
                  <li>{blogData.blogname}</li>
               </ul>
            </div>
         </div>
         <div className="blogsdetailcontent">
            <div className="imagebox"><BlogImage id = {blogData._id}/></div>
            <div className="courseshare">
               <ul>
                  {/* <li className="bookmark"><a href="#">bookmark</a></li> */}
                  <li className="share">
                     <SocialShareBlock shareTitle={blogData.blogname} shareUrl={window.location.href} />
                  </li>
               </ul>
            </div>
            <div className = "title">{blogData.blogname}</div>
            <div className = "description" dangerouslySetInnerHTML={ { __html:blogData.description}} ></div>
            <div className="createdby">
               <div className="sharelink">
                  <ul>
                     {/* <li className="bookmark"><a href="#">bookmark</a></li>
                     <li className="share"><a href="#">share</a></li> */}
                  </ul>
               </div>
               {blogData.artistname&&<div className="auther"><span>Escrito por {blogData.artistname}</span></div>}
            </div>
         </div>
         <div className="categoriesbox">
            <div className="categories">
               <label>Categoría: {" "}</label>
               <strong>{blogData?.selectedCategory[0].name}</strong>
            </div>    
         </div>                     
      </div>}
      </div> 
  );
};

export default BlogDetails;