import React from 'react';
import Page from '../../components/Page/Page';
import { Modal ,Skeleton} from 'antd'
import API from '../../utils/API';
import { useHistory } from 'react-router-dom'
import CategoryName from '../../components/CategoryName/CategoryName';
import BookImage from '../../components/BooksData/BookImage';
import './bookdetail.css';
const BookDetails = (props) => {
    const [blogData,setBlogData] = React.useState()
    const [bookPdf,setBookPdf]= React.useState()
    const [loadings, setLoadings] = React.useState(true);
    const history = useHistory();
   React.useEffect(()=>{
    API.get('/books/contents/node/' + props.pageid).then(result =>{
     setBlogData(result.data.books[0])
     if(result.data.books[0]?.book){
      API.get('/books/getbook/'+ props.pageid)
      .then(result => {
         setBookPdf( <a href= {`data:application/pdf;base64,${result?.data?.base64}`} download>Descargar PDF</a>)
       
        setLoadings(false)
  })
  .catch(e => {
      Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
      });
  });}
  
    }).catch(error => {
      console.log(error);
    })
  
   },[])
  return (
      <div className="bookdetailwrapper">
        <div className="backbutton">
          <button onClick={() => { history.goBack();}}>Go back</button>
        </div>
        <div className = "bookdetailpage">
          
          {blogData && <div className = "bookdetail">
            <div className = "bookdetailcontent" >
              <div className = "imagebox"><BookImage id = {blogData?._id}/></div>
              <div className = "contentbox">
                <div className = "title">{blogData?.bookname}</div>
                <div className = "auther">por <text>{blogData?.artistname}</text> </div>
                <div className = "category">{blogData?.selectedCategory[0].name} </div>
                <div className = "description"><text>{blogData?.description}</text> </div>
              </div>
            </div>
            {blogData.book&&<div className="downloadbutton">
              {loadings ? (
                <Skeleton.Button className="skeletonk" active avatar shape="square"/>
              ) : (
                <div className="downloadpdf">{bookPdf}</div>
              )}
            </div>}
            {blogData.bookurl&&<div className="downloadbutton">
              
               
              
            <a href={blogData.bookurl} target="_blank" rel="noreferrer noopener">
            obten el libro
</a>
              
            </div>}
          </div>}
        </div>
      </div>
  );
};
export default BookDetails;