import React, { useContext, useState, useRef } from 'react';
import Page from '../../components/Page/Page';
import Forumcategory from '../../components/LeftSidebar/Forumcategory';
/* import AnonymousForumContent from '../../components/AnonymousForum/AnonymousForumContent'; */
import { AuthContext } from '../../App';
import {Button, Modal} from 'antd';
//import Signuppopup from '../../components/Signup/Signup';
import NewForum from './../NewTopic/NewTopic';
import './css/forumsposts.css';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import API from '../../utils/API';
import ForumContentMain from '../../components/ForumBlock/ForumContentMain';
import ChatBlock from '../../components/RightSidebar/ChatBlock';
import SliderChatBlock from '../../components/RightSidebar/SliderChatBlock';
import io from "socket.io-client";
import './css/Shares.css';
import './css/Comments.css';
import './css/AnonymousForum.css';
import './css/SearchBlock.css';

const Forummain = (props) => {

  const { state } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [mainclass, setMainClass] = useState('anonymousforum');
  const [topiclimit, settopiclimit] = useState(0);
  const [membershiprole, setmembershiprole] = useState('free');
  const [username, setusername] = useState();
  const socket = useRef();
  const maincat = props.match.params.maincat;
  const [showActive, setShowActive] = useState(0);
  
  React.useEffect(() => {
    if(state?.isAuthenticated){
      API.get("/users/counter/"+state?.user?.id).then((c) =>{
        if(c?.data?.connected){
          setShowActive(c?.data?.connected);
          setMainClass('forums bothsidebar');
        }else{
          setMainClass("forums sidebarleft")
        }
      });
    }
    return () => {
      return {mainclass}
    }
  }, [state])

  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
  }, [state]);
    React.useEffect( ()=> {
      let isMounted = true;
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
        //setMainClass('forums bothsidebar1 sidebarleft');
        let id = JSON.parse(localStorage.getItem('user')).id;
        API.get('users/'+id).then(result=>{
          if(result.data != null){
            setusername(result.data.user.firstname);
            if(result.data.user.topiclimit != null || result.data.user.topiclimit != 'undefined'){
              settopiclimit(result.data.user.topiclimit);
            }
            if(result.data.user.membershiprole != 'undefined'){
              setmembershiprole(result.data.user.membershiprole);
            }
          }
        }).catch(e =>{
          console.log(e.message);
        });
      }
      return () => { isMounted = false }; 
    },['username']);
  const openAddPost = () => {
    if(state.user.level == 1){
      setVisible(true);
      document.body.classList.add('modalopen');
    }else  if(topiclimit >= 3 && membershiprole=='free'){
      Modal.success({
        content: 'Has alcanzado el límite semanal para publicar temas en el foro.',
      });
    }else if(topiclimit >= 5 && membershiprole=='basic'){
      Modal.success({
        content: 'Has alcanzado el límite diario para publicar temas en el foro.',
      });
    }else{
      setVisible(true);
      document.body.classList.add('modalopen');
    }
    
  }
  const handleCancel = () => {
    setVisible(false)
    document.body.classList.remove('modalopen');
  };
  const getModalResponse = (visiblecheck) =>{
    setVisible(visiblecheck);
    document.body.classList.remove('modalopen');
    Modal.success({
      content: 'Gracias por enviar el tema del foro',
      onOk: () =>  reloadCurrent()
    });
  }

  const reloadCurrent= () =>{
    window.location.reload();
  }
  window.onbeforeunload = function () {
    window.scrollTo(140, 0);
  }
  return (
    <Page>
        <div className={"maincontent " + (mainclass)}>
          <div className="maindiv forum-maincat">
            {(state.isAuthenticated && showActive != 0)
            ?<div className="sliderchatblock"><SliderChatBlock socket={socket}/></div>
            : null
            }
            <div className="leftsidebar">
              {state.isAuthenticated && (
              <div className="categoryfilterwrapper">
                {/* <SearchBlock/> */}
                {/* <Discussionblock/> */}
                <Forumcategory/>
              </div>
              )}
              {!state.isAuthenticated && (
              <div className="categoryfilterwrapper">
                <Forumcategory/>
              </div>
              )}

            </div>
            <div className="midcontent">
              <div className="forums-header">
              {state.isAuthenticated? (
                  <div>
                  <div className="forumssearchbox">
                    <div className="userpic"><ProfileImage id={state.user.id}/></div>
                    <div className="searcharea">
                      <Button
                        className="addpost"
                        onClick={openAddPost}
                      >
                        Cual es tu pregunta {username ? username.charAt(0).toUpperCase() +username.slice(1) : state.user.firstname.charAt(0).toUpperCase()+state.user.firstname.slice(1)}?
                      </Button>
                      <Modal
                        visible={visible}
                        okText="Enviar"
                        onCancel={handleCancel}
                        centered="true"
                        wrapClassName={'addforumspost'}
                        footer={null}
                        keyboard={false}
                        maskClosable={false}
                      >
                        <NewForum getModalResponse={getModalResponse}/>
                      </Modal>
                    </div>
                  </div>
                  <div className="s-title">{maincat.replace(/-/g, ' ')}</div>
                  </div>
              ):
              <div className="s-title public">{maincat.replace(/-/g, ' ')}</div>
              }
              </div>
              {/* {!state.isAuthenticated && (
                <SearchBlock/>
              )} */}
              <ForumContentMain name={maincat}/>
            </div>
            {(state.isAuthenticated && showActive != 0)
            ? <div className="rightsidebar chatwrapper">
                <ChatBlock socket={socket} key={Date.now()}/>
              </div>
              : null
            }
          </div>
        </div>      
    </Page>
  );
};

export default Forummain;
