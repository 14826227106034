import React, { useState } from 'react';
import Page from '../../components/Page/Page';
import { useHistory } from 'react-router-dom'
import connections from "../../assets/images/connectionicons.svg"
import userforumpic from "../../assets/images/userforumpic.svg"
import postvideo from "../../assets/images/postvideo.svg"
import userimg from "../../assets/images/userpic.svg"
import { Row, Col  } from 'antd';
import API from '../.././utils/API';
import GuestUserHeader from '../Guestuser/GuestUserHeader';
import reducer from '../.././utils/reducer';
import './Guestuser.css'


const Guestuser = props => {
    const [visible, setVisible] = useState(false);
    const [primaryCat,setPrimaryCat] = useState()
    const [interest,setInterest] = useState([])
    const [userDetail,setUserDetail] = useState()

    const [data,setData] = useState()
    const initialState = {
      user: null,
      token: null,
      isAuthenticated: false,
    };
    const [state, dispatch] = React.useReducer(reducer, initialState);
    const history = useHistory();
    React.useEffect( ()=> {

         if(props.location?.aboutProps?.id !== undefined){
         fetchData(props.location?.aboutProps?.id)
      } else {
         history.push('/network')
      }
      
    
    },[]);
    const fetchData =  (id) => {
      API.get(`/userinfo/?userid=${id}`).then(
         result => {
       
       setUserDetail(result?.data?.userInfo)
       if(result?.data?.userInfo?.roles !== undefined) {
         const cat = result?.data?.userInfo?.roles
         API.get(`/roles/id?rolesid==${cat}`).then(res => {
            setPrimaryCat(res?.data?.roles?.name)
            }).catch(err => {
              console.log(err);
            })
       }
       if(userDetail?.interests.length !== 0) {
           const arr = []
         result?.data?.userInfo?.interests.map(res => {
            
         API.get(`/interests/id/?interestid=${res}`).then(res => {
            setVisible(true)
          
            interest.push(res?.data?.interets?.name)
            setData(<Row>{interest.map ( int =>{return <Col key = {Math.random()}>
               <div className="subtitle">{int}, </div>
               </Col>})}</Row>)
       
            }).catch(err => {
              console.log(err);
            })})
          
          
    
       setVisible(true)
       }
      
         }
     ).catch(err => {
         console.log(err);
     })
     
 
   
    }
    const titlecase = (word) => {
       if(word !== undefined){
      return  word.split(' ')
      .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ')
      }
    }
   
     

  return (
    <Page>
      <div className="maincontent guestprofileview guestuser">
         <GuestUserHeader id = {props.location?.aboutProps?.id} shortBio = {userDetail?.shortbio}/>

         <div className="guestviewcontent">
            <div className="leftsidebar">
               <div className="guestintroductionview">
                  <h2 className="blocktitle">Introduction</h2>
                  <div className="guestintroductioncontent">
                   { userDetail?.primarycategory && <div className="viewrow">
                        <div className="title">Rol Principal</div>
                        <div className="subtitle">{primaryCat}</div>
                     </div>}
                  
                     {userDetail?.interests && <div className="viewrow">
                        <div className="title">Interes</div>
                       {data}
                     </div>}
                     {userDetail?.currentlocation?.state &&<div className="viewrow">
                     <div className="title">Locacion actual</div>
                        <div className="subtitle">{titlecase(userDetail?.currentlocation?.state)},{" "}{titlecase(userDetail?.currentlocation?.city)} | {userDetail?.currentlocation.zipcode}</div>
                     </div>}
                     {userDetail?.primaryinvestmentlocation?.state &&<div className="viewrow">
                        <div className="title">Locacion de Inversion Principal</div>
                       <div className="subtitle">{titlecase(userDetail?.primaryinvestmentlocation?.state)},{" "}{titlecase(userDetail?.primaryinvestmentlocation?.city)} | {userDetail?.primaryinvestmentlocation.zipcode}</div>
                     </div>}
                  </div>

               </div>
           
               <div className="guestconnectionsview">
                  <div className="heading">
                     <h2 className="blocktitle">Conexiones</h2>
                     <div className="viewall"><a href="#">Ver todo</a></div>
                  </div>
                  <div className="viewcontent">
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                     <div className="viewrow">
                        <div className="usericon"><img src={connections}/></div>
                        <div className="username"><a href="#">Ralph Edward</a></div>
                     </div>
                  </div>
               </div>
              
            </div>

            <div className="midcontent">
               <div className="guestposts">
                  <h2 className="blocktitle">Publicaciones</h2>
                  <div className="posts">
                     <div className="userinfomation">
                        <div className="userpic"><img src={userforumpic}/></div>
                        <div className="username">
                           <div className="title"><h2><a href="#">Jane Cooper</a></h2></div>
                           <div className="category">New to Real Estate</div>
                        </div>
                     </div>
   
                     <div className="content">
                        <div className="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</div>
                        <div className="createdby">
                           <label>Publicado en: </label>
                           <span className="date">May 16, 2021</span>
                        </div>
                     </div>
                     
                     <div className="postsocialshare">
                        <ul>
                           <li className="likecount"><span className="like">like</span><span className="count">0</span></li>
                           <li className="commentcount"><span className="commentcounts">comment</span><span className="count">32</span></li>
                           <li className="share">share</li>
                        </ul>
                     </div>
                     <div className="commentreview">
                        <div className="commentrow">
                           <div className="userpict"><img src={userimg}/></div>
                           <div className="commentcontent">
                              <div className="username">Ralph Edward</div>
                              <div className="description">Lorem ipsum dolor sit amet</div>
                              <div className="commentreply">
                                 <div className="likeandreply">
                                    <ul>
                                       <li className="like"><a href="#">Like</a></li>
                                       <li className="reply"><a href="#">Reply</a></li>
                                    </ul>
                                 </div>
                                 <div className="createdby">
                                    <label>Publicado en: </label>
                                    <span className="date">May 16, 2021</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     <div className="commentformwrapper">
                        <form action="/">
                           <textarea id="commentformwrapper" name="comment" rows="4" cols="50" placeholder="Escribe un comentario"></textarea>
                        </form>
                     </div>
                  </div>
                  
                  <div className="posts">
                     <div className="userinfomation">
                        <div className="userpict"><img src={userforumpic}/></div>
                        <div className="username">
                           <div className="title"><h2><a href="#">Jane Cooper</a></h2></div>
                           <div className="category">New to Real Estate</div>
                        </div>
                     </div>
                     <div className="content">
                        <div className="imagebox"><span>play</span><img src={postvideo}/></div>
                        <div className="contentbox">
                           <div className="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</div>
                           <div className="body">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</div>
                           <div className="createdby">
                              <label>Publicado en: </label>
                              <span className="date">May 16, 2021</span>
                           </div>
                        </div>
                     </div>
                     <div className="postsocialshare">
                        <ul>
                           <li className="likecount"><span className="like">like</span><span className="count">0</span></li>
                           <li className="commentcount"><span className="commentcounts">comment</span><span className="count">32</span></li>
                           <li className="share">share</li>
                        </ul>
                     </div>
                     <div className="commentreview">
                        <div className="commentrow">
                           <div className="userpict"><img src={userimg}/></div>
                           <div className="commentcontent">
                              <div className="username">Ralph Edward</div>
                              <div className="description">Lorem ipsum dolor sit amet</div>
                              <div className="commentreply">
                                 <div className="likeandreply">
                                    <ul>
                                       <li className="like"><a href="#">Like</a></li>
                                       <li className="reply"><a href="#">Reply</a></li>
                                    </ul>
                                 </div>
                                 <div className="createdby">
                                    <label>Publicado en: </label>
                                    <span className="date">May 16, 2021</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     <div className="commentformwrapper">
                        <form action="/">
                           <textarea id="commentformwrapper" name="comment" rows="4" cols="50" placeholder="Escribe un comentario"></textarea>
                        </form>
                     </div>
                  </div>
                 
                  <div className="posts">
                     <div className="userinfomation">
                        <div className="userpict"><img src={userforumpic}/></div>
                        <div className="username">
                           <div className="title"><h2><a href="#">Jane Cooper</a></h2></div>
                           <div className="category">New to Real Estate</div>
                        </div>
                     </div>
                     <div className="content">
                        <div className="title">Lorem ipsum dolor sit amet, consectetur adipiscing elit?</div>
                        <div className="createdby">
                           <label>Publicado en: </label>
                           <span className="date">May 16, 2021</span>
                        </div>
                     </div>
                     <div className="postsocialshare">
                        <ul>
                           <li className="likecount"><span className="like">like</span><span className="count">0</span></li>
                           <li className="commentcount"><span className="commentcounts">comment</span><span className="count">32</span></li>
                           <li className="share">share</li>
                        </ul>
                     </div>
                     <div className="commentreview">
                        <div className="commentrow">
                           <div className="userpict"><img src={userimg}/></div>
                           <div className="commentcontent">
                              <div className="username">Ralph Edward</div>
                              <div className="description">Lorem ipsum dolor sit amet</div>
                              <div className="commentreply">
                                 <div className="likeandreply">
                                    <ul>
                                       <li className="like"><a href="#">Like</a></li>
                                       <li className="reply"><a href="#">Reply</a></li>
                                    </ul>
                                 </div>
                                 <div className="createdby">
                                    <label>Publicado en: </label>
                                    <span className="date">May 16, 2021</span>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>
                     
                     <div className="commentformwrapper">
                        <form action="/">
                           <textarea id="commentformwrapper" name="comment" rows="4" cols="50" placeholder="Escribe un comentario"></textarea>
                        </form>
                     </div>
                  </div>

               </div>
            
            </div>
         </div>
       </div>
       
   
    </Page>
  );
};

export default Guestuser;
