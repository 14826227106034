import React, { useEffect, useState } from 'react';
import { Form, Input, Modal } from 'antd';
import Page from '../../components/Page/Page';
import API from '../../utils/API';
import './css/ContactUs.css';

const ContactUs = (props) => {
   const [form] = Form.useForm();
   const [alias,setAlias] = useState(undefined)
   const emailcheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
   useEffect(()=> {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      const token = JSON.parse(localStorage.getItem('token'));
      const user = JSON.parse(localStorage.getItem('user'));
      fetchData(user.id)
    }
   },[])
   const fetchData = (id) => {
    API.get('/users/'+id).then(async result => {
      setAlias(result.data.user.pathalias.alias);
    }).catch(err=> {
      console.log(err);
    })
   }
   const onFinish = values => {
      API.post('/contacts/add', {
        email: values.email.toLowerCase().trim(),
        fullname: values.fullname,
        reason: values.reason,
        message: values.message,
        profileurl:alias
      })
        .then(() => {
          form.resetFields();
          Modal.success({
            content: 'Gracias por contactar con nosotras.',
          });
        })
        .catch(e => {
          Modal.error({
            title: 'Fallida',
            content: e.message,
          });
        });
    };
  return (
    <Page>
      <div className = "contactus">
          <div className = "viewcontactuscontent">
              <h1 className = "contactustitle">Contactanos</h1>
              <div className="office-hours">
                <p>Numero Telefonico: <a href="tel:(240)913-9549">(240) 913 - 9549</a></p>
                <p>Lunes a Viernes 9:30 am  - 5:00 Eastern time.</p>
              </div>
          <card className ="contactuscard">
          <Form 
            form={form}
            name="contactus"
            layout="vertical"
            onFinish={onFinish}
            size = {'default'}>
               <div className="form-item full-name">
               <Form.Item
                  label="Entra tu nombre completo"
                  name="fullname"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Entra tu nombre completo',
                    },
                  ]}
                >
                  <Input  placeholder="Entra tu nombre completo" className="inputbox" />
                </Form.Item>
               </div>
               <div className="form-item email">
               <Form.Item
                  label="Entra tu correo electronico"
                  name="email"
                  className="form-item"
                  /* rules={[
                    {
                      required: true,
                      message: 'Por favor introduzca un correo electrónico válido',
                      type: 'email',
                    },
                  ]} */
                  rules={[
                    {
                      required: true,
                      validator: (_, value) => {
                        if(typeof(value) == "undefined" || !value){
                         return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                        }else if(emailcheck.test(value.trim())){
                           return Promise.resolve();
                         }
                         return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                       }
                    },
                  ]}
                >
                  <Input  placeholder="Ingrese su ID de correo electrónico" className="inputbox" />
                </Form.Item>
               </div>
               <div className="form-item selectbox">
               <Form.Item
                  label="Razon de contactarnos"
                  name="reason"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Elije la razon',
                    },
                  ]}
                >
                  <select  className="inputbox">
                     <option value="">--Elije la razon--</option>
                     <option value="educacion relacionada">Educacion relacionada</option>
                     <option value="forums relacionados">Forums relacionados</option>
                     <option value="Pagos relacionados">Pagos relacionados</option>
                     <option value="redes relacionadas">Redes relacionadas</option>
                     <option value="otros">Otros</option>
                   </select></Form.Item>
               </div>
               <div className="form-item message">
               <Form.Item
                  label="Envia Mensaje"
                  name="message"
                  className="form-item"
                  rules={[
                    {
                      required: true,
                      message: 'Escribe tu mensaje',
                    },
                  ]}
                >
                  <textarea  className="textareabox" rows="4" cols="50" placeholder="Escribe tu mensaje"></textarea>
                  </Form.Item>
               </div>
               <div className="form-action"><input type="submit" value="Enviar" className="btn form-submit"/></div>
     
            </Form>
          </card>
         </div>
         </div>
    </Page>
  );
};

export default ContactUs;