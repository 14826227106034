
import React, { useState,useEffect,useRef } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Modal} from 'antd';
import { AuthContext } from '../../App';
import Rolename from '../../components/RolesName/RolesName';
import './Network.css';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import noavtar from '../../assets/no-avatar.png';
import MessageButton from '../../components/WidgetBar/MessageButton';
import ConnectButton from '../../components/WidgetBar/ConnectButton';
import io from "socket.io-client"; 

const Networkusers = (props) => {

  const { state } = React.useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [visibletosubscribe, setvisibletosubscribe] = useState(false);
  const [popupmessage, setPopupmessage] = useState(null);
  const history = useHistory();
  const socket = useRef();
 
  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
  }, []);

 const showModal = (type) => {
  if(state.isAuthenticated && type == "paidonly"){
    setvisibletosubscribe(true);
    document.body.classList.add('modalopen');
  }else if(state.isAuthenticated){
    return;
  }else{
    if(type == 'message'){
      setPopupmessage('Debes iniciar sesión para enviar un mensaje a este miembro.');
    }else if( type== 'connect'){
      setPopupmessage('Debes estar conectado para enviar una solicitud de conexion a este miembro');
    }else{
      setPopupmessage('Debes iniciar sesión para realizar esta acción.');
    }
    setVisible(true);
    document.body.classList.add('modalopen');
   }
  }
  const handleRedirect =() => {
    setPopupmessage(null);
    setVisible(false);
    history.push('/login?=/network')
    document.body.classList.remove('modalopen');
  }
  const handleCancel = () => {
    setVisible(false);
    setvisibletosubscribe(false);
    setPopupmessage(null);
    document.body.classList.remove('modalopen');
  };
  const handleRedirectPricing =() => {
    setPopupmessage(null);
    setVisible(false);
    setvisibletosubscribe(false);
    history.push('/pricing?=/network')
    document.body.classList.remove('modalopen');
  }
   
  return (
    <>{props.userlist.map((user, index) => {
      
       return <div className="viewrow" key={index}>
          <div className="userinfomation">

           <div className="userpic">
             {user?.pathalias?.alias
               ?<Link to={user?.pathalias?.alias}>
                  <ProfileImage id={user?._id}/>
               </Link>
               : <ProfileImage id={user?._id}/>
             }
             </div>
           <div className="username">
               <div className="title">
                   {user?.pathalias?.alias
                   ?<h2><Link to={user?.pathalias?.alias}>
                     {user?.firstname} {user?.lastname}
                     </Link></h2>
                     :<h2>{user?.firstname} {user?.lastname}</h2>
                   }
                  </div>
               <div className="category">
                 {user?.userinfoid?.roles && <Rolename id = {user?.userinfoid?.roles}/>}
               </div>
           </div>
           
           {state.isAuthenticated && (
            <div className="userconnect">
                <div className="message-icon-wrapper">
                  <MessageButton forUserId={user?._id}/>
                </div>
                <div className='connect-icon-wrapper'>
                  <ConnectButton forUserId={user?._id} socket={socket}/>
                </div>
             </div>
           )}
           {!state.isAuthenticated && (
             <div className="userconnect">
               <div className="message-icon" onClick={() => showModal('message')}></div>
               <div className='connect-icon' onClick={() => showModal('connect')}></div>
             </div>
           )}
         </div>
       </div>}
    )}
             <Modal
            visible={visible}
            okText="Iniciar sesión"
            cancelText="Cancelar"
            onOk={handleRedirect}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>{popupmessage}</div>
         </Modal>
         <Modal
            visible={visibletosubscribe}
            okText="Actualizar membresía"
            cancelText="Cancelar"
            onOk={handleRedirectPricing}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>Debes actualizar tu membresía para usar la función de búsqueda avanzada.</div>
         </Modal>
    </>
          

  );
};

export default Networkusers;
