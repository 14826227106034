import React, { useContext, useState } from 'react';
import { Redirect } from 'react-router-dom';
import { AuthContext } from '../../App';
import LoanInterested from './LoanInterested';

const LenderDashboard = props => {
  const { state } = useContext(AuthContext);
  if(!state?.user?.usertype.includes('lender')){
    return <Redirect to="/dashboard"/>;
  }
  return (
     <div className="maincontent dashboardpage rightside1">
        <div className="viewuserinformation">
          <div className="viewcontent">
            <div className="viewrow">
              <div className="userpic"></div>
              <div className="username">
                {state.isAuthenticated && (
                  <h2>Bienvenido {state?.user?.firstname},</h2>
                )}
                </div>
            </div>
          </div>
        </div>
        <div className='dashboard-wrapper'>
        {state?.user?.usertype.includes('lender') && <LoanInterested id={state?.user?.id}/>}
        </div>
      </div>
        );
};

export default LenderDashboard;
