import React, { useEffect, useContext, useState } from 'react';
import API from '../../../../utils/API';
import { Modal, Button, Form, Input,InputNumber, Select, DatePicker } from 'antd';


const { TextArea } = Input;
const HardMoneyStep3 = props => {
  const [loantype, setLoantype] = useState('');
  const [stateList,setStateList] = useState([]);
  const [statelistoptiondone,setStateListOptiondone] = useState(false);
  const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
 
  const handleactivehardmoneyChange = (value) => {
      props.formdata.activehardmoney = value;
  };
  const onNext = () => {
    var value = props.form.getFieldsValue()
    console.log('value step3: ',value)
    props.formdata.project = value.project;
    props.formdata.activehardmoney = value.activehardmoney;
    props.next()
  }
  const onPrev = () => {
    props.prev()
  }
   return (
    <>
          <Form.Item
            label="¿ Cuantos proyectos has hecho?"
            name="project"
            >
              <Select
              options={[
                {
                  value: 'Ninguno',
                  label: 'Ninguno',
                },
                {
                  value: '1-3',
                  label: '1-3',
                },
                {
                  value: '3-5',
                  label: '3-5',
                },
                {
                  value: '5-10',
                  label: '5-10',
                },
                {
                  value: '10+',
                  label: '10+',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="¿ Tiene prestamos hard money activos?"
            name="activehardmoney"
            >
              <Select
              options={[
                {
                  value: 'Si',
                  label: 'Si',
                },
                {
                  value: 'No',
                  label: 'No',
                },
              ]}
            />
          </Form.Item>
          <Form.Item className="form-action">
            <Button className="prevstep" onClick={() => onPrev()}>Atrás</Button>
            <Button className="nextstep" type="primary" onClick={() => onNext()}>Siguiente</Button>
          </Form.Item>
      </>
   );
};

export default HardMoneyStep3;
