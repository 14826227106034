import React from "react";
import Page from "../../components/Page/Page";
import { Link } from "react-router-dom";
import { Carousel, Modal } from "antd";
import "../Podcasts/Allpodcasts.css";
import API from "../../utils/API";
import BlogImage from "../../components/BlogData/BlogImage";

const AllBlogs = (props) => {
  const [blogs, setBlogs] = React.useState([]);
  const [category, setCategory] = React.useState([]);
  const [active, setActive] = React.useState();

  React.useEffect(() => {
    API.get(`/blogs/`)
      .then((result) => {
        setBlogs(
          result.data.blogs.sort((a, b) => {
            // equal items sort equally
            if (a.blogOrder === b.blogOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.blogOrder === null) {
              return 1;
            } else if (b.blogOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.blogOrder < b.blogOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.blogOrder < b.blogOrder ? 1 : -1;
            }
          })
        );
        API.get("/categories/getcategories")
          .then((results) => {
            const arr = [];
            results.data.category.map((name) => {
              for (let i = 0; i < result.data.blogs.length; i++) {
                if (result.data.blogs[i].selectedCategory[0] !== undefined) {
                  if (result.data.blogs[i].selectedCategory[0]._id == name._id) {
                    const cat = name.name;
                    const _id = name._id;

                    arr.push({ name: cat, _id: _id });
                  }
                }
              }
            });
            const filteredArr = arr.reduce((acc, current) => {
              const x = acc.find((item) => item._id === current._id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);
            setCategory(filteredArr);
          })
          .catch((e) => {
            Modal.error({
              title: "Ocurrió un error",
              content: e.message,
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const onCatChange = (catId) => {
    setActive(catId);
    const arr = [];
    arr.push(catId);
    API.get("/blogs/bycategory/" + arr)
      .then((result) => {
        setBlogs(
          result.data.blogs.sort((a, b) => {
            // equal items sort equally
            if (a.blogOrder === b.blogOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.blogOrder === null) {
              return 1;
            } else if (b.blogOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.blogOrder < b.blogOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.blogOrder < b.blogOrder ? 1 : -1;
            }
          })
        );
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <Page>
      <div className="allpodcastspage maincontent">
        <div className="vieweducationfilter">
          <div className="vieweducationcontent">
            <div className="searchtabs">
              <ul>
                <li>
                  <Link to="/education/videocourses">ENTRENAMIENTOS</Link>
                </li>
                <li>
                  <Link to="/education/webinar">WEBINARS</Link>
                </li>
                <li>
                  <Link to="/education/tipoftheweek">Jugadas de la semana</Link>
                </li>

                <li>
                  <Link to="/education/podcasts">Podcasts</Link>
                </li>
                <li className="active">
                  <Link to="/education/blogs">Blogs</Link>
                </li>
                <li>
                  <Link to="/education/books">Libros</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="educationlistfilterslider">
          <div className="viewcontent">
            <Carousel {...settings}>
              {category.map((catName) => (
                <div className="viewrow">
                  <a
                    style={{ color: active == catName._id ? "black" : "" }}
                    onClick={() => onCatChange(catName._id)}
                  >
                    {catName.name}
                  </a>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
        <div className="breadcrumbwrapper">
          <div className="breadcrumb">
            <ul>
              <li>
                <Link to="/education">Educacion</Link>
              </li>
              <li>Blogs</li>
            </ul>
          </div>
        </div>
        <div className="viewpodcastslist allpodcastslist allblogs">
          <div className="viewpodcasts">
            {blogs.length ? (
              <div className="viewcontent">
                {blogs.map((blog) => (
                  <Link to={blog.pathalias.alias} className="viewrow">
                    <div className="imagebox">
                      <BlogImage id={blog._id} />
                    </div>
                   {String(blog.blogname).length > 71 ?<h2 className="title">
                      <Link to={blog.pathalias.alias} title={blog.title}>
                        {blog.blogname}...
                      </Link>
                    </h2>:<h2 className="title">
                      <Link to={blog.pathalias.alias} title={blog.title}>
                        {blog.blogname}
                      </Link>
                    </h2>}
                    {/*<div className="category">{blog.artistname}</div>
                        <div className = "categoryname" ><CategoryName id = {blog?.selectedCategory[0]}/></div>
                        <div className="readtime">
                           <div className="readview"><span>10 ? Minimo ( Minimum?) Lee</span></div>
                           <div className="sharelink">
                              <ul>
                                 <li className="bookmark"><a href="#">bookmark</a></li>
                                 <li className="share"><a href="#">share</a></li> 
                              </ul>
                           </div>
                        </div>
                        */}
                  </Link>
                ))}
              </div>
            ) : (
              <div className="catnotfound">
                ¡¡No se han encontrado resultados!!
              </div>
            )}
          </div>
        </div>
      </div>
    </Page>
  );
};
export default AllBlogs;
