import React, { Component} from "react";
import {  Button,Modal, Form, Input } from "antd";
import API from '../../utils/API';
import Page from "../../components/Page/Page";
import './login.css';
import './changepass.css';

class ChangePass extends Component {
  
  state = {
    re:  /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,50}$/,
    email: null,
    loading: false
  }
  componentDidMount(){
   this.getEmail();
  }
  getEmail(){
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let emailid = JSON.parse(localStorage.getItem('user')).email;
      this.setState({email: emailid});
    }
  }
  onFinishRequest = values => {
    this.setState({loading:true});
    API.post('/users/changepassword', {
      password: values.password,
      email:this.state.email
    })
    .then(result => {
      if(result.data != 'null'){
        this.setState({loading:false});
        Modal.success({
          content: "Contraseña actualizada exitosamente.",
          onOk: (e)=> {window.location.reload()}
        });
      }
    })
    .catch(e => {
      this.setState({loading:false});
      Modal.error({
        content: e.response.data.msg,
      });
    });
  };
  render(){
    return (
      <Page>
        <div className="changepassword-wrapper">
          <div className="change-password-form-wrap">
              <h2>Cambia tu Contraseña</h2>
              <Form
                name="Iniciar sesión"
                onFinish={this.onFinishRequest}
                layout="vertical"
              >
              <Form.Item
                  label="Nueva contraseña"
                  name="password"
                  className="formitem"
                  rules={[
                    {
                      required: true,
                      //message: 'La contraseña debe contener letras y numeros con un limite de 8 caracteres',
                      validator: (_, value) => {
                        if(typeof(value) == 'undefined' || !value){
                          return Promise.reject(new Error('La contraseña debe contener letras y numeros con un limite de 8 caracteres'));
                        }else if (!value || this.state.re.test(value)) {
                          return Promise.resolve();
                        }
                          return Promise.reject(new Error('La contraseña debe contener letras y numeros con un limite de 8 caracteres'));
                        },
                    },
                  ]}
                >
                  <Input.Password  placeholder="Introduzca una nueva contraseña" className="formtext"/>
                </Form.Item>
                <Form.Item
                  name="confirm"
                  label="Ingrese y confirme la nueva contraseña"
                  dependencies={['password']}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: '¡Confirme su nueva contraseña!',
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue('password') === value) {
                          return Promise.resolve();
                        }
                          return Promise.reject(new Error('¡Las dos contraseñas que ingresó no coinciden!'));
                        },
                      }),
                    ]}
                >
                <Input.Password placeholder="Ingrese y confirme la nueva contraseña"/>
                </Form.Item>
                <Form.Item
                  className="form-action"
                >
                <Button
                    type="primary"
                    htmlType="submit"
                    className="form-submit"
                    disabled={this.state.loading}
                  >
                  Guardar
                  </Button>
                </Form.Item>
              </Form>
            </div>
          </div>
      </Page>
    );
  }
};

export default ChangePass;
