import React from 'react';
import { NavLink } from 'react-router-dom';
import API from '../../utils/API';
import './VerifySuccessMail.css';


const VerifySuccessMail = props => {
   const [onBoarding,setOnbording] = React.useState(true)
 //  const [id,setId] = React.useState()
  

   React.useEffect(  ()=> {
      
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
     
         const user = JSON.parse(localStorage.getItem('user'));
        
        fetchData(user.id)
      }
  
  
    
      
     
    },[]);
    const fetchData = async (id) => {
      API.get(`/userinfo/?userid=${id}`).then(
         result => {
       const data = result.data.userInfo.roles
         if (data !== undefined){
               setOnbording(false)
         }
         } 
     ).catch(err => {
         console.log(err);
     })
   }
   return (
      <div>
            {onBoarding &&<div className="verifysuccessmail">
         <div className="contentbox"><div className="helpus"><NavLink to="/help-us">Ayúdanos a conocerte mejor</NavLink></div></div>
      </div>}
      </div>
  );
};

export default VerifySuccessMail;
