import { Button, Popover, Skeleton } from 'antd';
import React, { useContext, useState, useEffect,useRef,Suspense } from 'react';
import API from '../../utils/API';
import { Link,Redirect, useHistory } from 'react-router-dom';
import { AuthContext } from '../../App';
import './MessageBell.css';

const MessageNotificationList = React.lazy(() => import("../WidgetBar/MessageNotificationList"));

const MessageBellIcon = (props) => {
    const { state } = useContext(AuthContext);
    var counter = 0;
    const [visible, setispopover] = useState(false);
    const [conversations, setConversations] = useState([]);
    const [countconversation,setCountconversation] = useState(0)
    const handleVisibleChange = (visible) => {
        setispopover(visible);
      }
  
    const markRead = async (c, readby) =>{
        const resread = await API.post("/conversations/mread", {
            c:c,
            b:readby,
        });
        window.location.reload();
    }
    const markAllRead = async(readby) =>{
        const resreadAll = await API.post("/conversations/mallread", {
            b:readby,
        });
        const getUpdatedConversations = async () => {
            if( props.forUserId !== state.user.id){
                try {
                    const res = await API.get("/conversations/"+state?.user?.id+'?limit=8');
                    setConversations(res.data);
                  } catch (err) {
                    console.log(err);
                  }
            }
        };
        getUpdatedConversations();
    }
    useEffect(() => {
        const getConversations = async () => {
            if( props.forUserId !== state.user.id){
                try {
                    API.get("/conversations/"+state?.user?.id+'?limit=1').then(async(c) =>{
                        if(c?.data?.length > 0){
                            setCountconversation(c?.data?.length)
                            const res = await API.get("/conversations/"+state?.user?.id+'?limit=8');
                            setConversations(res.data);
                        }
                    });                    
                  } catch (err) {
                    console.log(err);
                  }
            }
        };
        getConversations();
      }, [state?.user?.id]);

      useEffect(() => {
        props?.socket.current.on("getMessageNotification", (data) => {
            const getNotificationConversations = async () => {
                if( props.forUserId !== state.user.id && state.user.id == data?.receiverId){
                    try {
                        const res = await API.get("/conversations/"+state?.user?.id+'?limit=8');
                        setConversations(res.data);
                      } catch (err) {
                        console.log(err);
                      }
                }
            };
            getNotificationConversations();
        });
      }, [props?.socket]);

        const text = <span>Mesenger <div className="seeallnotification">
            <Link 
                to={conversations.length ?'/messenger?chat='+conversations[0]._id :"/messenger"}
                onClick={() => markRead(conversations[0]?._id, state?.user?.id) }>
                    Ve Todo
            </Link>
            <span className='seperator'> | </span>
            <Button
            className='mark-read'
            onClick={()=>{markAllRead(state?.user?.id)}}>
                Marcar como leídos
            </Button>
            </div>
        </span>;
        const counterin = (
            conversations?.map(conversation =>{
                if(conversation.membersunread.find((m) => m === state?.user?.id)){
                    counter++
                }
            })
        );
        return (
        <>
        {countconversation > 0 ?
        <div className="message-notification">
            <Popover overlayClassName='onlymessage-notification'
                placement="bottom"
                title={text}
                content={<div className="" onClick={()=>{setispopover(false)}}>
                    {conversations?.map((c, index) => {                       
                       return <div key={index} onClick={() => markRead(c._id,state.user.id)}>
                                <Suspense fallback={<Skeleton avatar paragraph={{ rows: 2 }} />}>
                                    <MessageNotificationList conversation={c} currentUser={state}/>
                                </Suspense>
                           </div>
                    })}</div>}
                trigger="click"
                visible={visible}
                onVisibleChange={handleVisibleChange}
            >
                <Button>
                    {counter > 0 && <span className='counter'>{counter}</span>}
                    <span className="bell-icon"></span>
                </Button>
            </Popover>
        </div>
        : null}
        </>
    );
};

export default MessageBellIcon;
