import React from "react";
import './podcasts.css';
import { Carousel } from "antd";
import { Link } from 'react-router-dom';
import API from '../../utils/API';
import PodcastImage from "../../components/PodcastData/PodcastImage";
const PodcastsListing = (props) => {
   const [podcasts, setPodcasts] = React.useState([])
   const settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
         {
            breakpoint: 1119,
            settings: {
               slidesToShow: 2,
               slidesToScroll: 1,
            }
         },
         {
            breakpoint: 767,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         },
         {
            breakpoint: 480,
            settings: {
               slidesToShow: 1,
               slidesToScroll: 1
            }
         }
      ]
   }
   React.useEffect(() => {
      API.get(`/podcasts/`).then(result => {

         setPodcasts(result.data.podcasts.sort((a, b) =>{
  
            // equal items sort equally
            if (a.podcastOrder === b.podcastOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.podcastOrder === null) {
                return 1;
            }
            else if (b.podcastOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
                return a.podcastOrder < b.podcastOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else { 
                return a.podcastOrder < b.podcastOrder ? 1 : -1;
            }
        
          }))

      })
   }, [])
   return (
      <div>
      {podcasts.length ? <div className="viewpodcastslist">
        <div className="viewpodcasts">
            <h2>Podcasts</h2>
            <div className="viewcontent">
               <Carousel {...settings} >
                  {podcasts.map((podcast, index) => <div className="viewrow" key={index}><Link className="podscastlink" to={podcast.pathalias.alias} title={podcast.title}>
                     <div className="imagebox"><PodcastImage id={podcast._id} /></div>
                  {String(podcast.podcastname).length > 71?<h2 className="title">{String(podcast.podcastname).substring(0,70)}...</h2>:<h2 className="title">{String(podcast.podcastname)}</h2>}
                  </Link></div>)}
                
               </Carousel>
            </div>
            <div className="viewall"><Link to="/education/podcasts">Ver todo</Link></div>
         </div>
      </div> : null}</div>
   );
};

export default PodcastsListing;
