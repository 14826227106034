import React, { useContext, useState,useRef } from 'react';
import {  Modal } from 'antd';
import { AuthContext } from '../../App';
import { NavLink, useLocation, useHistory, matchPath } from 'react-router-dom';
import { Link } from 'react-router-dom';
import logo from '../../assets/images/logo.png';
import ProfileImage from '../WidgetBar/ProfileImage';
import API from '../../utils/API';
import NotificationBell from '../../components/WidgetBar/NotificationBell';
import MessageBellIcon from '../WidgetBar/MessageBellIcon';
import io from "socket.io-client";
import { Popover } from 'antd';
import './header.css';

const Header = (props) => {
 
  const { dispatch, state } = useContext(AuthContext);
  const[showDropdown, setDropdown]= useState(false);
  const[showMenuDropdown, setMenuDropdown]= useState(false);
  const [customerId, setcustomer] = useState();
  const [name,setName] = useState(null);
  const history = useHistory();
  const location = useLocation();
  const socket = useRef();
  const match = matchPath(location.pathname, {
    path: ['/comingsoon','/help-us'],
    exact: true,
    strict: false
  })
  React.useEffect(()=>{
      if(state?.user?.id){
        API.get('/users/' + state?.user?.id).then(result => {
          setName(result.data.user.firstname)
        }).catch(err => {
          console.log(err);
        })

        API.get('users/substate/'+ state?.user?.email).then(subresult => {
          if(subresult.data != null){
            setcustomer(subresult?.data?.subpay?.customer_id)
          }
        }).catch(e =>{
          console.log(e.message);
        });
      }
  },[state?.user?.id])

  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
  }, []);
  const comingsoonmatch = matchPath(location.pathname, {
    path: ['/comingsoon'],
    exact: true,
    strict: false
  })
  const headerhide = matchPath(location.search, {
    path: ['?forapp=1'],
    exact: true,
    strict: false
  })
  const isActive = {
    borderColor: "#0072BB",
  };

  const logout = () => {
    setDropdown(!showDropdown)
    Modal.confirm({
      title: '¿Estás seguro de que quieres cerrar la sesión?',
      okText: 'sí',
      cancelText: 'No',
      onOk: () => {
        dispatch({
          type: 'LOGOUT',
        });
        socket.current.emit("logoutUser", state?.user?.id);
        history.push('/');
      },
    });
  };
  
  const showDrop = () => setDropdown(!showDropdown);
  const confirmChangeCard = async() => {
    const res = await API.post('/users/create-customer',
      {'subid': customerId});
    if(res?.data?.change){
      window.location.href = res.data.change;
    }
  }
  const Dropdownmenu = () =>(
    
    <ul className="dropdownmenu">
      
        <li><Link to="/dashboard">Dashboard</Link></li>
        {!state?.user?.usertype?.includes('lender') &&
        <li><Link to="/dashboard/borrower">Portal de préstamo</Link></li>}
        
        {(state?.user?.usertype.includes('lender') && (state?.user?.sub != 'free' || state?.user?.level == 1)) ?
          <><li><Link to="/dashboard/lender">Portal de prestamista</Link></li>
        <li><Link to="/dashboard/lender/profile" onClick={showDrop}>Perfil del prestamista</Link></li></>
        :state?.user?.usertype.includes('lender')
        ?<li><Link to="/dashboard/lender/profile" onClick={showDrop}>Mi perfil</Link></li>
        :''}

        {(state?.user?.sub != 'free' || state?.user?.level == 1)
        && 
        <><li><Link to="/user/connections" onClick={showDrop}>Mis Conexiones</Link></li>
        
        <li><Link to="/aboutuser" onClick={showDrop}>Mi perfil</Link></li></>}
        
        {customerId &&  <li class="a" onClick={confirmChangeCard}>Mi Suscripción</li>}
        <li><Link to="/change-password" onClick={showDrop}>Cambia tu Contraseña</Link></li>
        <li><Link to="/" onClick={logout}>Cerrar sesión</Link></li>
    </ul>
  );

  const showMenuDrop = () => setMenuDropdown(!showMenuDropdown);

  const DropdownMobilemenu = () =>(
    <div className="menuarea">
      <ul className="menu">
        <li className = "educationtourm"><NavLink to="/education" onClick={showMenuDrop} activeStyle={isActive}>Educacion</NavLink></li>
        <li><NavLink to="/network" onClick={showMenuDrop} activeStyle={isActive}>Conexiones</NavLink></li>
        <li><NavLink to="/forums" onClick={showMenuDrop} activeStyle={isActive}>Forums</NavLink></li>
        <li><NavLink to="/deals" onClick={showMenuDrop}>Tratos</NavLink></li>
        <li><NavLink to="/resources" onClick={showMenuDrop}>Recursos y Contratos</NavLink></li>
        <li><NavLink to="/sponsors" onClick={showMenuDrop}>Patrocinadores</NavLink></li>
        
      </ul>
    </div>
  );
  return (
    <header id={(comingsoonmatch) ? "comingsoonheader" : ''} className={ (match || headerhide)? "landing" : "headerwrap"}>
       
      <div className="headerarea">
        <div className="logoarea">
          <div className="LogoContainer">
            <Link to="/"><img src={logo} alt="Enlacancha" width="60" height="60" /></Link>
          </div>
        </div>
        <div className="menuarea">
          { (state?.user?.usertype?.includes('lender') && state?.user?.sub == 'free' && state?.user?.level != 1) ? '':
          <ul className="menu">
              <li className = "educationtour"><NavLink to="/education" activeStyle={isActive}>Educacion</NavLink></li>
              <li className = "networktour"><NavLink to="/network" activeStyle={isActive}>Conexiones</NavLink></li>
              <li className = "forumtour"><NavLink to="/forums" activeStyle={isActive}>Forums</NavLink></li>
              <li><NavLink to="/deals">Tratos</NavLink></li>
              <li className = "doctours"><NavLink to="/resources" activeStyle={isActive}>Recursos y Contratos</NavLink></li>
              <li className = ""><NavLink to="/sponsors" activeStyle={isActive}>Patrocinadores</NavLink></li>
              {!state?.user?.usertype?.includes('lender') &&
              <li className = ""><NavLink to="/financing" activeStyle={isActive}>Financiamiento</NavLink></li>
              }        
          </ul>
          }
        </div>
        
        <div className="signuparea">
          {!state.isAuthenticated && (
            <ul className="menu">
              <li className="signup"><Link to="/pricing">Subscribete</Link></li>
              <li className="signin"><Link to="/login">Iniciar sesión</Link></li>
            </ul>
          )}
        </div>
        {state.isAuthenticated && (
          <div className="useraccountmenu">
            <ul className="useraccount">
            {/* <li className="userinfo}><NavLink to = "/userprofile"> */}
            <li className="userinfo"  key="1"><NavLink to = "/aboutuser">
              <div className = "userprofiletour">
                  <div className="user">
                    <div className="userpic"><ProfileImage id={state?.user?.id}/></div>
                    {name?<div className="username">{name}</div>:<div className="username">{state?.user?.firstname}</div>}
                  </div></div>
                  </NavLink>
              </li>
               <li key="2"><MessageBellIcon socket={socket}/></li>
                <li key="3"><NotificationBell uid={state?.user?.id} socket={socket}/></li>
              {/* <li key="4" className="myaccount}>
                  <div className = "dashboardtour"><span className="icon} onClick={showDrop}>myaccount</span></div>
                  { showDropdown ? <Dropdownmenu/> : null}
              </li> */}
              <li key="4" className="myaccount">
                <Popover
                  content={<Dropdownmenu/>}
                  trigger="click"
                  visible={showDropdown}
                  onVisibleChange={showDrop}
                  overlayClassName="myaccountoverlay"
                >
                  <div className = "dashboardtour"><span className="icon" onClick={showDrop}>myaccount</span></div>
                </Popover>
              </li>
            </ul>
          </div>
        )}
       {/*  <button onClick={showMenuDrop} className="mobilemenubutton" style={{display: 'none'}}></button> */}
        <Popover
                  content={<DropdownMobilemenu/>}
                  trigger="click"
                  visible={showMenuDropdown}
                  onVisibleChange={showMenuDrop}
                  overlayClassName="mainmenuoverlay"
                >
                  <button onClick={showMenuDrop} className="mobilemenubutton" style={{display: 'none'}}></button>
                </Popover>
      </div>
    </header>
    
  );
};

export default Header;
