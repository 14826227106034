import React, { Component } from "react";
import Page from "../../components/Page/Page";
import API from "../../utils/API";
import { Carousel, Modal } from "antd";
import { Link } from "react-router-dom";
import Moment from "moment";
import "../Education/css/allvideocourselist.css";
import "../Education/css/educationlistfilterslider.css";
import "../Education/css/searchbox.css";
import axios from 'axios';
import VimeoThumbnail from "../../components/WidgetBar/VimeoThumbnail";

class AllWebinar extends Component {
  state = {
    datalists: [],
    category: [],
    active: "",
    useruid: "",
    accesscheck: "",
    hideredirectmodal: false,
    membershiprole: "free",
    hidesubscriptiontmodal: false,
    showRedirectModal: false,
    imgdata:[], 
  };

  settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToShow: 7,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1280,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 767,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  onCatChange = (catId) => {
    this.setState({ active: catId });
    const arr = [];
    arr.push(catId);
    API.get("/webinar/bycategory/" + arr)
      .then((result) => {
        this.setState({
          datalists: result.data.webinar.sort((a, b) => {
            // equal items sort equally
            if (a.webinarOrder === b.webinarOrder) {
              return 0;
            }
            // nulls sort after anything else
            else if (a.webinarOrder === null) {
              return 1;
            } else if (b.webinarOrder === null) {
              return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
              return a.webinarOrder < b.webinarOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else {
              return a.webinarOrder < b.webinarOrder ? 1 : -1;
            }
          }),
        });
        const iddata = [];
        result.data.webinar.map((id,index,arr)=> (
          axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(id.videoURL)))
          .then(res => {
            const imageUrl = res.data.thumbnail_url?.split('_');
            iddata[index] = imageUrl[0];
            if(arr.length - 1 === index){
              this.setState({imgdata:iddata})
            }
          })
      ))
      })
      .catch((err) => {
        console.log(err);
      });
  };
  componentDidMount() {
    this.getData();
    this.handleRedirect = this.handleRedirect.bind(this);
    this.cancleRedirect = this.cancleRedirect.bind(this);
    this.handleNavigation = this.handleNavigation.bind(this);
    this.handleCancel = this.handleCancel.bind(this);
  }
  onSubmit() {
  }
  getData() {
    //var that = this;
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      this.setState({ useruid: JSON.parse(localStorage.getItem("user")).id });
    }

    API.get("/webinar/contents")
      .then((result) => {
        const datalists = result.data.webinar.sort((a, b) => {
          // equal items sort equally
          if (a.webinarOrder === b.webinarOrder) {
            return 0;
          }
          // nulls sort after anything else
          else if (a.webinarOrder === null) {
            return 1;
          } else if (b.webinarOrder === null) {
            return -1;
          }
          // otherwise, if we're ascending, lowest sorts first
          else if (true) {
            return a.webinarOrder < b.webinarOrder ? -1 : 1;
          }
          // if descending, highest sorts first
          else {
            return a.webinarOrder < b.webinarOrder ? 1 : -1;
          }
        });
        this.setState({ datalists });
        API.get("/categories/getcategories")
          .then((results) => {
            const arr = [];
            results.data.category.map((name) => {
              for (let i = 0; i < result.data.webinar.length; i++) {
                if (
                  result.data.webinar[i].selectedCategory[0]._id !== undefined
                ) {
                  if (
                    result.data.webinar[i].selectedCategory[0]._id === name._id
                  ) {
                    const cat = name.name;
                    const _id = name._id;

                    arr.push({ name: cat, _id: _id });
                  }
                }
              }
            });
            const filteredArr = arr.reduce((acc, current) => {
              const x = acc.find((item) => item._id === current._id);
              if (!x) {
                return acc.concat([current]);
              } else {
                return acc;
              }
            }, []);

            this.setState({ category: filteredArr });
          })
          .catch((e) => {
            Modal.error({
              title: "Ocurrió un error",
              content: e.message,
            });
          });
      })
      .catch((e) => {
        Modal.error({
          title: "Ocurrió un error",
          content: e.message,
        });
      });
    if (localStorage.getItem("token") && localStorage.getItem("user")) {
      let id = JSON.parse(localStorage.getItem("user")).id;
      API.get("users/" + id)
        .then((result) => {
          this.setState({
            level: JSON.parse(localStorage.getItem("user")).level,
          });
          if(JSON.parse(localStorage.getItem('user')).level !== 1){
            if(result.data !== null && result.data.user.membershiprole !== 'undefined'){
              this.setState({membershiprole: result.data.user.membershiprole});
            }} else {
            this.setState({subscriptionUpgradeModal:false})
            this.setState({membershiprole: 'admin'});
            }
        })
        .catch((e) => {
          console.log(e.message);
        });
    } else {
      this.setState({ hideredirectmodal: true });
    }
  }
  handleRedirect(alias) {
    this.setState({ hideredirectmodal: false });
    this.setState({ hidesubscriptiontmodal: false });
    if (localStorage.getItem("token")) {
      let path = "/pricing?=" + alias;
      this.props.history.push(path);
    } else {
      let path = "/login?=" + alias;
      this.props.history.push(path);
    }
  }
  handleCancel = () => {
    this.setState({showRedirectModal: false});
    this.setState({hidesubscriptiontmodal: false});
    this.props.history.push('/pricing')
    document.body.classList.remove('modalopen');
  };
  cancleRedirect() {
    this.setState({ showRedirectModal: false });
    this.setState({ hidesubscriptiontmodal: false });
  }
  handleNavigation() {
    if (this.state.hideredirectmodal) {
      this.setState({ showRedirectModal: true });
    } else if (this.state.membershiprole === "free") {
      this.setState({ hidesubscriptiontmodal: true });
    }
  }
  render() {
    const redirectModal = (alias) => {
      return (
        <Modal
          visible={this.state.showRedirectModal}
          okText="Iniciar sesión"
          onOk={() => this.handleRedirect(alias)}
          onCancel={this.handleCancel}
          cancelText="Suscríbete"
          keyboard="false"
          centered="true"
          closable={false}
        >
          <div className="emptymessage">
            Debes iniciar sesión para ver este video
          </div>
        </Modal>
      );
    };
    const subscriptionUpgradeModal = (alias) => {
      return (
        <Modal
          visible={this.state.hidesubscriptiontmodal}
          okText="Actualizar membresía"
          onOk={() => this.handleRedirect(alias)}
          onCancel={this.cancleRedirect}
          cancelText="Cancelar"
          keyboard="false"
          centered="true"
          closable={false}
        >
          <div>Necesita actualizar su membresía para ver todos los videos</div>
        </Modal>
      );
    };
    Moment.locale("en");
    return (
      <Page>
        <div className="allCoursespage maincontent">
          <div className="vieweducationfilter">
            <div className="vieweducationcontent">
              <div className="searchtabs">
                <ul>
                  <li>
                    <Link to="/education/videocourses">ENTRENAMIENTOS</Link>
                  </li>
                  <li className="active">
                    <Link to="/education/webinar">WEBINARS</Link>
                  </li>
                  <li>
                    <Link to="/education/tipoftheweek">Jugadas en la cancha</Link>
                  </li>

                  <li>
                    <Link to="/education/podcasts">Podcasts</Link>
                  </li>
                  <li>
                    <Link to="/education/blogs">Blogs</Link>
                  </li>
                  <li>
                    <Link to="/education/books">Libros</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="educationlistfilterslider">
            <div className="viewcontent">
              <Carousel {...this.settings}>
                {this.state.category.map((catName,index) => (
                  <div className="viewrow" key={index}>
                    <span className="aa"
                      style={{
                        color: this.state.active === catName._id ? "black" : "",
                      }}
                      onClick={() => this.onCatChange(catName._id)}
                    >
                      {catName.name}
                    </span>
                  </div>
                ))}
              </Carousel>
            </div>
          </div>
          <div className="breadcrumbwrapper">
            <div className="breadcrumb">
              <ul>
                <li>
                  <Link to="/education">Educacion</Link>
                </li>
                <li>WEBINARS</li>
              </ul>
            </div>
          </div>
          <div className="viewvideocourselist allcourselist allwebinarlist">
            <div className="viewvideocourse">
              {this.state.datalists?.length ? (
                <div className="viewcontent">
                  {this.state.datalists?.map((datalist,index) => (
                    <div to={datalist.pathalias.alias} className="viewrow" key={index}>
                      {/* <div className="type-tag">{datalist.visibilityoption == 'free'? 'GRATIS': (datalist.visibilityoption == 'paid'? 'PAGADO': null) }</div> */}
                      <div className={"imagebox "+(this?.state?.imgdata[index] ? ' preview-loaded ': '')}>
                        {this.state.membershiprole !== "free" &&
                        !this.state.hideredirectmodal ? (
                          <Link to={datalist.pathalias.alias}>
                            <span>play</span>
                          </Link>
                        ) : (
                          <span onClick={this.handleNavigation}>play</span>
                        )}
                            {/* <div>{this.state.imgdata[index] && <img src={this.state.imgdata[index]+'_350'} alt="" height="192px" width="352px"/>}</div> */}
                            <div><VimeoThumbnail videoId={datalist?.videoURL} showPlayicon={false}/></div>
                      </div>
                      {this.state.membershiprole !== "free" &&
                      !this.state.hideredirectmodal ? (
                        <div className="titlebox">
                           {String(datalist.title).length > 71?<h2 className="title">
                        <Link
                          to={datalist.pathalias.alias}
                          title={datalist.title}
                        >
                          {String(datalist.title).substring(0,70)}...
                        </Link>
                      </h2>:<h2 className="title">
                        <Link
                          to={datalist.pathalias.alias}
                          title={datalist.title}
                        >
                          {String(datalist.title)}
                        </Link>
                      </h2>}
                        </div>
                      ) : (
                        <div className="titlebox">
                          <h2 className="title">
                          {String(datalist.title).length > 71?<div style={{cursor:'pointer'}}
                          onClick={this.handleNavigation}
                        >
                          {String(datalist.title).substring(0,70)}...
                        </div>:<div style={{cursor:'pointer'}}
                          onClick={this.handleNavigation}
                        >
                          {String(datalist.title)}
                        </div>}
                          </h2>
                        </div>
                      )}
                      {redirectModal(datalist.pathalias.alias)}
                      {subscriptionUpgradeModal(datalist.pathalias.alias)}
                    </div>
                  ))}
                </div>
              ) : (
                <div className="catnotfound">
                  ¡¡No se han encontrado resultados!!
                </div>
              )}
            </div>
          </div>
        </div>
      </Page>
    );
  }
}

export default AllWebinar;
