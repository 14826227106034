import React, { useContext, useState } from 'react';
import Page from '../../components/Page/Page';
import { AuthContext } from '../../App';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import LoanRequested from './LoanRequested';
import {Row, Col} from 'antd';
import { Redirect } from 'react-router-dom';
import './dashboard.css';
import { Link } from 'react-router-dom/cjs/react-router-dom.min';




const BorrowerDashboard = props => {
  const { state } = useContext(AuthContext);
  if(state?.user?.usertype.includes('lender')){
    return <Redirect to="/dashboard/lender"/>;
  }
  return (
     <div className="maincontent dashboardpage rightside1">
        <div className="viewuserinformation">
          <div className="viewcontent">
            <div className="viewrow">
              <div className="userpic"><ProfileImage id={state?.user?.id}/></div>
              <div className="username">
                {state.isAuthenticated && (
                  <h2>Bienvenido {state?.user?.firstname},</h2>
                )}
               </div>
            </div>
          </div>
        </div>
        <div className='dashboard-wrapper'>
          {state?.user?.id &&
            <LoanRequested id={state?.user?.id}/>}
        </div>

      </div>
  );
};

export default BorrowerDashboard;
