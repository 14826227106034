import React from 'react';
import API from '../../utils/API';
import { Link } from 'react-router-dom';
import BlogImage from '../../components/BlogData/BlogImage';


import './css/dashboardglobal.css';
import './css/dashboardblogslist.css'

const BlogsViewBlock = () => {

  const [blogs,setBlogs] = React.useState([]);

  React.useEffect(()=> {
      API.get('/blogs/contents/user').then(result=>{

      setBlogs(result.data.blogs.sort((a, b)=> {
  
         // equal items sort equally
         if (a.blogOrder === b.blogOrder) {
             return 0;
         }
         // nulls sort after anything else
         else if (a.blogOrder === null) {
             return 1;
         }
         else if (b.blogOrder === null) {
             return -1;
         }
         // otherwise, if we're ascending, lowest sorts first
         else if (true) {
             return a.blogOrder < b.blogOrder ? -1 : 1;
         }
         // if descending, highest sorts first
         else { 
             return a.blogOrder < b.blogOrder ? 1 : -1;
         }
     
       }))

      })
   },[])
  return (
   <div className="blogsviewblock viewblockcontent">
            <h2 className="blocktitle">Blogs</h2>
            <div className="viewcontent">
            {blogs.map((blog, index) => {
               return <div className="viewrow" key={index}>
                  <div className="imagebox"><BlogImage id = {blog._id}/></div>
                  <div className="blogscontent">
                     <div className="title"><h2><Link to={blog.pathalias.alias} title={blog.title}>{blog.blogname.substring(0, 25)}</Link></h2></div>
                     <div className="category">{blog.artistname}</div>
                     <div className="description">
                       <div dangerouslySetInnerHTML={ { __html:blog.description.substring(0, 100)}} ></div>
                     </div>
                  </div>
               </div>
            })
            }
               
            </div>
            <div className="viewall"><Link to="/education/blogs">Ver todo</Link></div>
         </div>
  );
};

export default BlogsViewBlock;
