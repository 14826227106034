import React, { Component } from 'react';
import Page from '../../components/Page/Page';
import API from '../../utils/API';
import { Carousel,Modal} from "antd";
import { Link } from 'react-router-dom';
import ReactPlayer from "react-player";
import Moment from 'moment';
import './css/allvideocourselist.css';
import './css/educationlistfilterslider.css';
import './css/searchbox.css';
import axios from 'axios';
import EducationListingItem from './EducationListingItem';

class CoursesListings extends Component{
   state ={
      datalists: [],
      category:[],
      active:'',
      useruid:'',
      imgdata:[], 
    }
    
    settings = {
      dots: false,
      infinite: false,
      speed: 500,
      autoplay: false,
      arrows: true,
      slidesToShow: 7,
      slidesToScroll: 1,
      responsive: [
         {
             breakpoint: 1280,
             settings: {
                 slidesToShow: 3,
                 slidesToScroll: 1,
             }
         },
         {
             breakpoint: 767,
             settings: {
                 slidesToShow: 2,
                 slidesToScroll: 1
             }
         },
         {
             breakpoint: 480,
             settings: {
                 slidesToShow: 1,
                 slidesToScroll: 1
             }
         }
      ]
   }
   onCatChange = (catId) => {
     this.setState({active:catId})
       const arr = []
       arr.push(catId)
       API.get('/courses/bycategory/' + arr+ '/Intermedio').then(result => {
     
        this.setState({datalists:result.data.courses.sort((a, b) => {
  
         // equal items sort equally
         if (a.courseOrder === b.courseOrder) {
             return 0;
         }
         // nulls sort after anything else
         else if (a.courseOrder === null) {
             return 1;
         }
         else if (b.courseOrder === null) {
             return -1;
         }
         // otherwise, if we're ascending, lowest sorts first
         else if (true) {
             return a.courseOrder < b.courseOrder ? -1 : 1;
         }
         // if descending, highest sorts first
         else { 
             return a.courseOrder < b.courseOrder ? 1 : -1;
         }
     
       })})
       const iddata = [];
       result.data.courses.map((id,index,arr)=> {
         axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(id.videos[0].videoURL)))
         .then(res => {
           const imageUrl = res.data.thumbnail_url.split('_');
           iddata[index] = imageUrl[0];
          
           if(arr.length - 1 === index){
      this.setState({imgdata:iddata})
            //setImgdata(iddata);
           }
         })
       })
     }).catch(err => {
        console.log(err);
     })
        }
    componentDidMount() {
      this.getData();
    };
    onSubmit(){
   }
    getData() {
      var that = this;
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
         this.setState({useruid:JSON.parse(localStorage.getItem('user')).id})
      
      }

      API.get('/courses/contents/all/intermediate')
      .then(result => {
        const datalists= result.data.courses.sort((a, b) => {
  
         // equal items sort equally
         if (a.courseOrder === b.courseOrder) {
             return 0;
         }
         // nulls sort after anything else
         else if (a.courseOrder === null) {
             return 1;
         }
         else if (b.courseOrder === null) {
             return -1;
         }
         // otherwise, if we're ascending, lowest sorts first
         else if (true) {
             return a.courseOrder < b.courseOrder ? -1 : 1;
         }
         // if descending, highest sorts first
         else { 
             return a.courseOrder < b.courseOrder ? 1 : -1;
         }
     
       });
        this.setState({datalists})
        const iddata = [];
        let Vimeo = require('vimeo').Vimeo;
         //let client = new Vimeo("{client_id}", "{client_secret}", "{access_token}");
         let client = new Vimeo("721e84fa5be48b9b62e753ae08747a2f6f8d748d", "InC25cSCXjI9NdWvPPvjBX+akYs+qqny30FrRVyiJUPo2KjePXENmNEapS8gj3CVnO5SeOCjTetCiSNWW7GOgm8N99t8GtyaLaTHI0A4bLdDKYE6Dj4/w1yNqKtmdkxc", "36273e305fdd9a218b7b54380e2a1025");
         /* datalists.map((id,index,arr)=> {
         client.request({
            method: 'GET',
            path: '/videos/'+Number(id.videos[0].videoURL)
            }, function (name, embed) {
               iddata[index] = embed?.pictures?.base_link;
               if(arr.length - 1 === index){
                  that.setState({imgdata:iddata});
               }
            })
            this.setState({imgdata:iddata}); */
        datalists.map((id,index,arr)=> {
          axios.get('https://vimeo.com/api/oembed.json?url=https://vimeo.com/'+(Number(id.videos[0].videoURL)))
          .then(res => {
            const imageUrl = res.data.thumbnail_url.split('_');
            iddata[index] = imageUrl[0];
           
            if(arr.length - 1 === index){
               this.setState({imgdata:iddata})
            }
          })
        })
        API.get('/categories/getcategories')
        .then(results => {
         const arr = []
         results.data.category.map(name => {
            for(let i = 0;i<result.data.courses.length;i++){
               if(result.data.courses[i].selectedCategory[0]._id !== undefined){
               if(result.data.courses[i].selectedCategory[0]._id == name._id){
            const cat = name.name
          const _id = name._id
    
          arr.push({name:cat,_id:_id})
               }}}
         })
         const filteredArr = arr.reduce((acc, current) => {
            const x = acc.find(item => item._id === current._id);
            if (!x) {
              return acc.concat([current]);
            } else {
              return acc;
            }
          }, []);
         
           this.setState({category:filteredArr})
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  
    }
   render(){
   
         Moment.locale('en');
         return(
            <Page>
               <div className="allCoursespage maincontent">
                  <div className="vieweducationfilter">
                     <div className="vieweducationcontent">
                        <div className="searchtabs">
                           <ul>
                              <li className="active"><Link to="/education/videocourses">ENTRENAMIENTOS</Link></li>
                              <li><Link to="/education/webinar">WEBINARS</Link></li>
                              <li><Link to="/education/tipoftheweek">Jugadas en la cancha</Link></li>
                              <li><Link to="/education/podcasts">Podcasts</Link></li>
                              <li><Link to="/education/blogs">Blogs</Link></li>
                              <li><Link to="/education/books">Libros</Link></li>
                           </ul>
                        </div>
                     </div>
                  </div>
                  <div className="educationlistfilterslider">
                     <div className="viewcontent">
                        <Carousel {...this.settings} >
                        {this.state.category.map( (catName, index) => <div className="viewrow" key={index}><a style = {{color:this.state.active == catName._id? 'black':''}} onClick = {()=>this.onCatChange(catName._id)}  >{catName.name}</a></div>)}
                        </Carousel>
                     </div>
                  </div>
                  <div className="breadcrumbwrapper">
                     <div className="breadcrumb">
                        <ul>
                           <li><Link to="/education">Educacion</Link></li>
                           <li><Link to="/education/videocourses">ENTRENAMIENTOS</Link></li>
                           <li>Intermedio</li>
                        </ul>
                     </div>
                  </div>
               <div className="viewvideocourselist allcourselist">
                  <div className="viewvideocourse">
                    {this.state.datalists.length ?<div className="viewcontent">
                        { this.state.datalists.map((datalist,index) =>
                        <EducationListingItem datalistitem={datalist} key={index}/>
                        )}
                     </div>:<div className = "catnotfound">¡¡No se han encontrado resultados!!</div>}
                  </div>
               </div>
            </div>
         </Page>
         );
      
     
   }
}

export default CoursesListings;
         


