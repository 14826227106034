import React from 'react';
import { CardNumberElement, CardExpiryElement, CardCvcElement} from '@stripe/react-stripe-js';
import { Row, Col } from 'antd';

const CARD_ELEMENT_OPTIONS = {
  style: {
    base: {
      'color': '#32325d',
      'fontFamily': '"Helvetica Neue", Helvetica, sans-serif',
      'fontSmoothing': 'antialiased',
      'fontSize': '16px',
      '::placeholder': {
        color: '#aab7c4',
      },
    },
    invalid: {
      color: '#fa755a',
      iconColor: '#fa755a',
    },
  },
};

export default function CardInput() {
  return (
    <div className="card-elements-wrapper">
      {/* <CardElement options={CARD_ELEMENT_OPTIONS} /> */}
        <Row>
          <Col span={24}>
            <label htmlFor="cc-number">Numero de targeta <span className="red">*</span></label>
            <CardNumberElement
              id="cc-number"
              className="ant-input"
              options={CARD_ELEMENT_OPTIONS}
            />
          </Col>
        </Row>
        <Row gutter={[8, 8]}>
          <Col span={12}>
            <label htmlFor="expiry">Fecha de expiracion <span className="red">*</span></label>
            <CardExpiryElement
              id="expiry"
              className="ant-input"
              options={CARD_ELEMENT_OPTIONS}
            />
          </Col>
          <Col span={12}>
            <label htmlFor="cvc">CVC <span className="red">*</span></label>
            <CardCvcElement
              id="cvc"
              className="ant-input"
              options={CARD_ELEMENT_OPTIONS}
            />
          </Col>
        </Row>
      </div>
  );
}