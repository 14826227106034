
import React, { useState,useEffect, useRef } from 'react';
import Page from '../../components/Page/Page';
import {useHistory,Link } from 'react-router-dom';
import {  Modal, Carousel, AutoComplete, Skeleton } from 'antd';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import './Network.css';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import Networkusers from './Networkusers';
import ChatBlock from '../../components/RightSidebar/ChatBlock';
import SliderChatBlock from '../../components/RightSidebar/SliderChatBlock';
import io from "socket.io-client";

const Network = (props) => {

  const [userlist, usersetlist] = React.useState([]);
  const { state } = React.useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [visibletosubscribe, setvisibletosubscribe] = useState(false);
  const [newSearchText, setNewSearchText] = useState('');
  const [roleslist, setRoles] = useState([]);
  const [checkedState, setCheckedState] = useState([]);
  const [popupmessage, setPopupmessage] = useState(null);
  const history = useHistory();
  const queryParams = props.location.search;
  const search = new URLSearchParams(queryParams).get('search');
  const currentPage = new URLSearchParams(queryParams).get('page');
  const defaultchecked = new URLSearchParams(queryParams).get('check');
  const [totalCount, setTotalCount] = useState('');
  const [buttonDisable, setButtonDisable] = useState(false);
  const [membershiprole, setmembershiprole] = useState('free');
  const [autocompleteOption,setAutocopleteOption] = useState([])
  const [isLoading, setisLoading] = useState(true);
  const [mainclass, setMainClass] = useState('anonymousnetwork');
  const socket = useRef();
  const [showActive, setShowActive] = useState(0);
  const [pages, setPages] = useState({
    currentPage,
    totalPages: 1,
  });
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
       {
           breakpoint: 1280,
           settings: {
               slidesToScroll: 1,
           }
       },
       {
           breakpoint: 767,
           settings: {
              slidesToScroll: 1
           }
       },
       {
           breakpoint: 480,
           settings: {
              slidesToScroll: 1
           }
       }
    ]
 }
 const showModal = (type) => {
  if(state.isAuthenticated && type == "paidonly"){
    setvisibletosubscribe(true);
    document.body.classList.add('modalopen');
  }else if(state.isAuthenticated){
    return;
  }else{
    if(type == 'message'){
      setPopupmessage('Debes iniciar sesión para enviar un mensaje a este miembro.');
    }else if( type== 'connect'){
      setPopupmessage('You must be logged in to send connection request to this member.');
    }else{
      setPopupmessage('Debes iniciar sesión para realizar esta acción.');
    }
    setVisible(true);
    document.body.classList.add('modalopen');
   }

  }

  const handleRedirect =() => {
    setPopupmessage(null);
    setVisible(false);
    history.push('/login?=/network')
    document.body.classList.remove('modalopen');
  }
  const handleCancel = () => {
    setVisible(false);
    setvisibletosubscribe(false);
    setPopupmessage(null);
    document.body.classList.remove('modalopen');
  };
  const handleCancelRedirect = () => {
    setVisible(false);
    setvisibletosubscribe(false);
    setPopupmessage(null);
    history.push('/pricing')
    document.body.classList.remove('modalopen');
  };
  const handleRedirectPricing =() => {
    setPopupmessage(null);
    setVisible(false);
    setvisibletosubscribe(false);
    history.push('/pricing?=/network')
    document.body.classList.remove('modalopen');
  }

  
  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
  }, [state?.isAuthenticated]);


   React.useEffect(()=>{
    if(state?.isAuthenticated){
      API.get("/users/counter/"+state?.user?.id).then((c) =>{
        if(c?.data?.connected){
          setShowActive(c?.data?.connected);
          setMainClass('network-both-sidebar');
        }
      });
    }

    setNewSearchText(search);
    if(defaultchecked != null){
      const rolearray = defaultchecked.split(",");
      setCheckedState(rolearray)
    }
    if(state.isAuthenticated){
      setButtonDisable(false);
      API.get('users/'+state.user.id).then(result=>{
        if(result.data != null){
          if(result.data.user.membershiprole != 'undefined'){
            setmembershiprole(result.data.user.membershiprole);
          }
        }
      }).catch(e =>{
        console.log(e.message);
      });
      API.get(`/users/networks${queryParams}`)
      .then(result => {
       if(result.data){
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setisLoading(false)
          setTotalCount(result.data.totalCount);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }else{
      setButtonDisable(true);
      API.get(`/users/networks`)
      .then(result => {
       if(result.data){
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
  },[state]);

  const fetchPage = page => {
    if(newSearchText != null && newSearchText.trim().length > 0 && checkedState.length > 0 ){
      API.get(`/users/networks?search=${newSearchText}&check=${checkedState}&page=${page}`)
      .then(result => {
        if(result.data){
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?search=${newSearchText}&check=${checkedState}&page=${page}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }else if(checkedState.length > 0){
      API.get(`/users/networks?check=${checkedState}&page=${page}`)
      .then(result => {
        if(result.data){
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?check=${checkedState}&page=${page}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }else if(newSearchText != null && newSearchText.trim().length > 0){
      API.get(`/users/networks?search=${newSearchText}&page=${page}`)
      .then(result => {
        if(result.data){
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?search=${newSearchText}&page=${page}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }else{
      API.get(`/users/networks?page=${page}`)
      .then(result => {
        if(result.data){
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?page=${page}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
     
  };
  const getRoles = async () => {
    try {
      const res = await API.get("/roles");
      setRoles(res.data.roles);
    } catch (err) {
      console.log(err);
    }
  };
  React.useEffect(() => {
    getRoles();
  },[state]);

  const prevHandle = (e)=>{
    if(pages.currentPage>1){
      fetchPage(--pages.currentPage);
    }
  }
  const nextHandle = (e)=>{
    if(pages.currentPage < pages.totalPages){
      fetchPage(++pages.currentPage);
    }
  }
  const handleOnChange = (e,id) => {
    getRoles();
    let arr = [];
    if(e.target.checked){
      arr.push(id);
      if(newSearchText != null && newSearchText != ""){
        API.get(`/users/networks?search=${newSearchText}&check=${arr}`)
        .then(result => {
          if(result.data){
            const userdata = result.data.userdata;
            usersetlist(userdata);
            setTotalCount(result.data.totalCount);
            setisLoading(false);
            setPages({
              currentPage: result.data.currentPage,
              totalPages: result.data.totalPages,
            });
          }
          history.push(`/network?search=${newSearchText}&check=${arr}`);
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
      }else{
        API.get(`/users/networks?check=${arr}`)
        .then(result => {
          if(result.data){
            const userdata = result.data.userdata;
            usersetlist(userdata);
            setTotalCount(result.data.totalCount);
            setisLoading(false);
            setPages({
              currentPage: result.data.currentPage,
              totalPages: result.data.totalPages,
            });
          }
          history.push(`/network?check=${arr}`);
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
      }
    }else{
      setCheckedState([]);
      arr = arr.filter(item => item !== id)
      if(arr.length > 0 && newSearchText != null){
        API.get(`/users/networks?search=${newSearchText}&check=${arr}`)
        .then(result => {
          if(result.data){
            const userdata = result.data.userdata;
            usersetlist(userdata);
            setTotalCount(result.data.totalCount);
            setisLoading(false);
            setPages({
              currentPage: result.data.currentPage,
              totalPages: result.data.totalPages,
            });
          }
          history.push(`/network?search=${newSearchText}&check=${arr}`);
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
       
      }else if(arr.length == 0 && newSearchText != null && newSearchText != ''){
        API.get(`/users/networks?search=${newSearchText}`)
        .then(result => {
          if(result.data){
            const userdata = result.data.userdata;
            usersetlist(userdata);
            setTotalCount(result.data.totalCount);
            setisLoading(false);
            setPages({
              currentPage: result.data.currentPage,
              totalPages: result.data.totalPages,
            });
          }
          history.push(`/network?search=${newSearchText}`);
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
       
      }else if(arr.length > 0 && newSearchText == null){
        API.get(`/users/networks?check=${arr}`)
        .then(result => {
          if(result.data){
            const userdata = result.data.userdata;
            usersetlist(userdata);
            setTotalCount(result.data.totalCount);
            setisLoading(false);
            setPages({
              currentPage: result.data.currentPage,
              totalPages: result.data.totalPages,
            });
          }
          history.push(`/network?check=${arr}`);
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });

      }else{
        //fetchPage(1);
        API.get(`/users/networks?page=1`)
        .then(result => {
          if(result.data){
            const userdata = result.data.userdata;
            usersetlist(userdata);
            setTotalCount(result.data.totalCount);
            setisLoading(false);
            setPages({
              currentPage: result.data.currentPage,
              totalPages: result.data.totalPages,
            });
          }
          history.push(`/network?page=1`);
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
      }
    }
    setCheckedState(arr);
  }
  const handelSearchEnter = (e) =>{
    if(e.keyCode == 13 && (newSearchText == null || newSearchText.trim().length <= 0)){
      setCheckedState([]);
      fetchPage(1);
      //return;
    }if(e.keyCode == 13 && newSearchText.trim().length > 0) {
      API.get(`/users/networks?search=${newSearchText}`)
      .then(result => {
        if(result.data){
          const userdata = result.data.userdata;
          setCheckedState([]);
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?search=${newSearchText}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
  }
  const handelSearchSubmit = async (e) =>{
    //e.preventDefault();
    if(newSearchText == null || newSearchText.trim().length <= 0){
      setCheckedState([]);
      fetchPage(1);
      //return;
    }else {
      API.get(`/users/networks?search=${newSearchText}`)
      .then(result => {
        if(result.data){
          setCheckedState([]);
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setisLoading(false);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?search=${newSearchText}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
  }

  const handleSearch = (e) => {
    if(String(e).length >1){
      API.get("/users/networksdrop/"+e)
      .then(result => {
        if(result){
          setCheckedState([]);
          const userdata = result.data.userdata;
          const data = []
          userdata?.map(response=> {
            data.push({value:response.firstname.toLowerCase()})
            data.push({value:response.lastname.toLowerCase()})
            data.push({value:response.firstname.toLowerCase()+" "+response.lastname.toLowerCase()})
            data.push({value:response?.userinfoid?.primaryinvestmentlocation?.city?.toLowerCase()})
            data.push({value:response?.userinfoid?.primaryinvestmentlocation.state?.toLowerCase()})
            data.push({value:response?.userinfoid?.primaryinvestmentlocation?.zipcode?.toLowerCase()})
          })
          const uniqueArr = [...new Map(data.map(item =>[item['value'], item])).values()];
          setAutocopleteOption(uniqueArr.filter((country) => country.value?.includes(e)))
        }
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    } else {
      setAutocopleteOption([])
    }
    setNewSearchText(e?.toLowerCase())
  }

  const handelOnSelect = (value) => {
    API.get(`/users/networks?search=${value}`)
      .then(result => {
        if(result.data){
          setCheckedState([]);
          const userdata = result.data.userdata;
          usersetlist(userdata);
          setTotalCount(result.data.totalCount);
          setPages({
            currentPage: result.data.currentPage,
            totalPages: result.data.totalPages,
          });
        }
        history.push(`/network?search=${value}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  }
  return (
    <Page>
      <div className={"maincontent networkpage fullpage"+ (state.isAuthenticated && membershiprole=='free' ? " forpaid" : '') + ' ' + (mainclass)}>
        <div className="network-search-filter">
          <div className="membersearchbox">
            <div className="membersearch">
              <div className="searchbox" >
                {state.isAuthenticated && <div className='userpicbox'>
                  <ProfileImage id={state?.user?.id}/>
                </div>}
                <div className="formbox" onClick={() => showModal('login')}>
                    {/* <div className="form-item search-filter" >
                      <AutoComplete 
                      type="text"
                      name="search" 
                       className="formtext"
                      placeholder="Búsqueda por nombre, ciudad, estado, código postal"
                      onChange={(e)=>handleSearch(e)}
                      onSelect={(value) => {
                        handelOnSelect(value);
                      }}
                      value={newSearchText != null ? newSearchText : ''}
                      onKeyDown={(e) => {
                        handelSearchEnter(e);
                      }}
                      disabled={buttonDisable}
                      
                      options={autocompleteOption}
                      bordered = {false}
                  
                      filterOption={(inputValue, option) =>
                        option?.vale?.toUpperCase().indexOf(inputValue?.toUpperCase()) !== -1
                      }
                      />
                      </div> */}
                      <div className="form-item search-filter">
                      <input 
                      type="text"
                      name="search" 
                      className="formtext"
                      placeholder="Búsqueda por nombre, ciudad, estado, código postal"
                      onChange={(e) => setNewSearchText(e.target.value)}
                      value={newSearchText != null ? newSearchText : ''}
                      onKeyDown={(e) => {
                        handelSearchEnter(e);
                      }}
                      disabled={buttonDisable}
                      />
                      </div>
                    <div className="form-action"><input type="button" className="form-submit" value="Submit" onClick={(e)=>handelSearchSubmit(e)} disabled={buttonDisable}/></div>
                </div>
              </div>          
            </div>
            <div className="maintitle"><h2>Construye tu equipo</h2></div>
          </div>
          {(state.isAuthenticated && (membershiprole=='basic' || state?.user?.level == 1))
          ?
          <div className='rolefilter'>
            <Carousel {...settings} >
              {roleslist.map(({ name, _id }, index) => {
                return (
                  <div key={index}>
                    <div className="toppings-list-item">
                      <div className="left-section">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          name={name}
                          value={name}
                          checked={checkedState.includes(_id)? true : false}
                          onChange={(e) => handleOnChange(e,_id)}
                        />
                        <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
          :
          (state.isAuthenticated && membershiprole=='free')
          ? <div>
              <div className="upgrademessage"><strong>¡Actualiza tu cuenta! </strong>
              La función de búsqueda avanzada solo está disponible para miembros con una membresia</div>
                <div className='rolefilter forpaid' onClick={() => showModal('paidonly')}>
                <Carousel {...settings} >
                  {roleslist.map(({ name, _id }, index) => {
                    return (
                      <div key={index}>
                        <div className="toppings-list-item">
                          <div className="left-section">
                            <input
                              type="checkbox"
                              id={`custom-checkbox-${index}`}
                              name={name}
                              value={name}
                              /* checked={checkedState.includes(_id)? true : false} */
                              //onChange={(e) => handleOnChange(e,_id)}
                              disabled={true}
                            />
                            <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </Carousel>
              </div>
          </div>

          :
            <div className='rolefilter' onClick={() => showModal('login')}>
              <Carousel {...settings} >
                {roleslist.map(({ name, _id }, index) => {
                  return (
                    <div key={index}>
                      <div className="toppings-list-item">
                        <div className="left-section">
                          <input
                            type="checkbox"
                            id={`custom-checkbox-${index}`}
                            name={name}
                            value={name}
                            /* checked={checkedState.includes(_id)? true : false}
                            onChange={(e) => handleOnChange(e,_id)} */
                            disabled={true}
                          />
                          <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </Carousel>
            </div>
          }
        </div>
        <div className="maindiv">
          {(state.isAuthenticated && showActive != 0)
          ? <div className="sliderchatblock"><SliderChatBlock socket={socket}/></div>
          : null
          }
          <div className="midcontent">
            <div className="ourteamlist">
              {userlist.length > 0 ?
              <div className="viewheader">
                {state.isAuthenticated &&  <div className="viewall"><Link to="/user/connections" className="join">Ver mis conexiones</Link></div>}
                <div className="resultshow">({pages.currentPage} de {pages.totalPages})</div>
                {state.isAuthenticated ?
                  <div className="pagercontrol">
                    {pages.currentPage > 1 && <div className="prev" onClick={(e) =>prevHandle()} >previo</div>}
                    {pages.currentPage < pages.totalPages && <div className="next" onClick={(e) =>nextHandle()}>Siguiente</div>}
                  </div> 
                :
                <div className="pagercontrol">
                  {pages.currentPage > 1 && <div className="prev" onClick={(e) =>showModal('login')} >previo</div>}
                  {pages.currentPage < pages.totalPages && <div className="next" onClick={(e) =>showModal('login')}>Siguiente</div>}
                </div> 
                }
             
              </div>
              : null }
              <div className="viewcontent">
              {isLoading == true
              ? <div className='loading'>
                  <div className="view-row">
                    <Skeleton avatar active={true} paragraph={{ rows: 4 }} />
                  </div>
                  <div className="view-row">
                    <Skeleton avatar active={true} paragraph={{ rows: 4 }} />
                  </div>
                  <div className="view-row">
                    <Skeleton avatar active={true} paragraph={{ rows: 4 }} />
                  </div>
              </div>
              :
                userlist.length >0 ?
                <Networkusers userlist={userlist}/>:
                <div className='no-result'>Ningún miembro encontrado.</div>
              }
              </div>
            </div>
            {(state.isAuthenticated && showActive != 0)
            ? <div className="rightsidebar chatwrapper">
                <ChatBlock socket={socket}/>
              </div>
            : null
            }
          </div>
        </div>
        <Modal
            visible={visible}
            okText="Iniciar sesión"
            cancelText="Suscríbete"
            onOk={handleRedirect}
            onCancel={handleCancelRedirect}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>{popupmessage}</div>
         </Modal>
         <Modal
            visible={visibletosubscribe}
            okText="Actualizar membresía"
            cancelText="Cancelar"
            onOk={handleRedirectPricing}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>Debes actualizar tu membresía para usar la función de búsqueda avanzada.</div>
         </Modal>
      </div>
    </Page>
  );
};

export default Network;