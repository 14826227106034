import React, { useState } from 'react';
import { Form, InputNumber } from "antd";
import Page from "../../components/Page/Page";
import './calculator.css';

const FlipCalculator = () => {
  const [form] = Form.useForm();
  const [selectedDate, setSelectedDate] = useState(Date('8/18/23'));
  const [initialValues, setInitialValues] = useState({
    length_rehab_sell_days: 150,
    agent_fees_when_selling : 5,
    closing_costs_on_sale : 2.75,
    interest: 12,
    points: 2,
    draws: 3,
    draw_fee: 150,
    closing_date_on_purchase: '8/18/23',
    estimated_closing_date_on_selling : '1/15/24',
    processing_fee: 650,
    cma_fee: 250,
    purchase_price: 120000,
    estimated_repairs: 80000,
    arv: 280000,
    ltv: 71.43,
    daily_interest: 66.67,
    insurance_currently_estimated: 1250,
    taxes_currently_estimated: 2800,
    seller_help : 0,
    purchase_closing_costs: 16363.33,
    closing_cost_when_selling: 21700,
    hm_interest_during_loan: 9066.67,
    extension_fees_of_hm: ''
  });
  const updateFormValues = () => {
    // Update the initial values dynamically
    const newInitialValues = {
      purchase_price: 500000,
    };
    setInitialValues(newInitialValues); // Update state with new initial values
    form.setFieldsValue(newInitialValues); // Update form with new initial values
  };
  const handleDateChange = (event) => {
    setSelectedDate(event.target.value);
    initialValues.closing_date_on_purchase = event.target.value
    calculateFlip(initialValues)
  };

  const handleFieldChange = (changedValues, allValues) => {
    calculateFlip(allValues)
  };
  const calculateFlip = (allValues) => {
    var ltv = ((allValues.purchase_price + allValues.estimated_repairs)/allValues.arv)* 100;
    var daily_interest = ((allValues.purchase_price + allValues.estimated_repairs) * allValues.interest/1200)/30
    //calculate EOMonth
    const closing_date = new Date(allValues.closing_date_on_purchase);
    const remainingDays = getEOMONTH(closing_date) + 1;
    var purchase_closing_costs = (allValues.purchase_price + allValues.estimated_repairs)* allValues.points/100 + (allValues.draws * allValues.draw_fee) + allValues.processing_fee + allValues.cma_fee + 30 + (remainingDays * ((allValues.purchase_price + allValues.estimated_repairs)*allValues.interest/1200)/30)+((allValues.purchase_price + allValues.estimated_repairs)*0.03)+allValues.insurance_currently_estimated+allValues.taxes_currently_estimated

    var closing_cost_when_selling = (allValues.arv * allValues.agent_fees_when_selling/100) + (allValues.arv * allValues.closing_costs_on_sale/100)
    //calculate HM Interest during loan
    //const date1 = new Date(closing_date);
    //const date2 = new Date(allValues.estimated_closing_date_on_selling); // Current date
    //const differenceInDays = getDaysDifference(date1, date2);
    var hm_interest_during_loan = allValues.length_rehab_sell_days*daily_interest-(remainingDays * ((allValues.purchase_price + allValues.estimated_repairs)*allValues.interest/1200)/30)
    
    //Calculate Extension Fees of HM
    if(allValues.length_rehab_sell_days > 270){
      allValues.extension_fees_of_hm = (allValues.purchase_price + allValues.estimated_repairs) * 0.03
    }else if(allValues.length_rehab_sell_days > 180){
      allValues.extension_fees_of_hm = (allValues.purchase_price + allValues.estimated_repairs) * 0.01
    }else{
      allValues.extension_fees_of_hm = ''
    }
    allValues.ltv = formatNumberWithTwoDecimals(ltv)
    allValues.daily_interest = formatNumberWithTwoDecimals(daily_interest)
    allValues.purchase_closing_costs = purchase_closing_costs
    allValues.closing_cost_when_selling = closing_cost_when_selling
    allValues.hm_interest_during_loan = hm_interest_during_loan
    setInitialValues(allValues);
  }
  const getEOMONTH = (currentDate) => {
    //const currentDate = new Date(date);
    const currentDay = currentDate.getDate();
    const totalDaysInMonth = new Date(
      currentDate.getFullYear(),
      currentDate.getMonth() + 1,
      0
    ).getDate();
  
    const remainingDays = totalDaysInMonth - currentDay;
    return remainingDays;
  }
  /* const getDaysDifference = (startDate, endDate) => {
    // Calculate the difference in milliseconds
    const differenceInMilliseconds = endDate - startDate;
    // Convert milliseconds to days (1 day = 24 hours * 60 minutes * 60 seconds * 1000 milliseconds)
    const millisecondsInADay = 24 * 60 * 60 * 1000;
    const differenceInDays = Math.floor(differenceInMilliseconds / millisecondsInADay);
    return differenceInDays;
  } */
  const formatNumberWithTwoDecimals = (number) => {
    const roundedNumber = Number(number.toFixed(2)).toString();
    const decimalIndex = roundedNumber.indexOf('.');
  
    if (decimalIndex === -1) {
      // If the number doesn't have a decimal point
      return roundedNumber + '.00';
    } else {
      const decimalPortion = roundedNumber.substring(decimalIndex + 1);
      if (decimalPortion.length < 2) {
        // If there's only one digit after the decimal point
        return roundedNumber + '0';
      }
      return roundedNumber;
    }
  }
    return (
      <Page>
        <div className="changepassword-wrapper calculator-new">
        <div className="change-password-form-wrap calculator-new-wrapper">
              <h2>Flip Analysis</h2>
              <Form
                form={form} // Set the form instance
                name="Flip Analysis"
                layout="vertical"
                initialValues={initialValues}
                onValuesChange={handleFieldChange} // Handle field value changes
              >
                 <Form.Item
                  label="Closing Date on purchase"
                  name="closing_date_on_purchase"
                  className="formitem "
                >
                  <div className='ant-input-number'>
                  <input
                    type="date"
                    id="dateInput"
                    className="ant-input-number-input"
                    value={selectedDate}
                    onChange={handleDateChange}
                  />
                  </div>

                </Form.Item>
                
                <Form.Item
                  label="Length of Rehab/Sell (Days)"
                  name="length_rehab_sell_days"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    placeholder='150'
                  />
                </Form.Item>
                <Form.Item
                  label="Agent Fees when selling"
                  name="agent_fees_when_selling"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    placeholder='5%'
                  />
                </Form.Item>
                <Form.Item
                  label="Closing Costs on Sale"
                  name="closing_costs_on_sale"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    placeholder='2.75%'
                  />
                </Form.Item>
                <Form.Item
                  label="Interest"
                  name="interest"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    placeholder='12%'
                  />
                </Form.Item>
                <Form.Item
                  label="Points"
                  name="points"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    placeholder='2%'
                  />
                </Form.Item>
                <Form.Item
                  label="# of Draws"
                  name="draws"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    placeholder='3'
                  />
                </Form.Item>
                <Form.Item
                  label="Draw Fee"
                  name="draw_fee"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='$150'
                  />
                </Form.Item>
                <Form.Item
                  label="Processing Fee"
                  name="processing_fee"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='$650'
                  />
                </Form.Item>
                <Form.Item
                  label="CMA Fee"
                  name="cma_fee"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='$250'
                  />
                </Form.Item>
                <Form.Item
                  label="Purchase Price"
                  name="purchase_price"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='120,000'
                  />
                </Form.Item>
                <Form.Item
                  label="Estimated Repairs"
                  name="estimated_repairs"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='80,000'
                  />
                </Form.Item>
                <Form.Item
                  label="After Repair Value (ARV)"
                  name="arv"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='280,000'
                  />
                </Form.Item>
                <Form.Item
                  label="Loan to Value (LTV)"
                  name="ltv"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `${value}%`}
                    parser={(value) => value.replace('%', '')}
                    placeholder='71.43'
                    disabled={true}
                  />
                </Form.Item>
                <Form.Item
                  label="Daily Interest"
                  name="daily_interest"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='66,67'
                  />
                </Form.Item>
                <Form.Item
                  label="Insurance (Currently Estimated)"
                  name="insurance_currently_estimated"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='1,250'
                  />
                </Form.Item>
                <Form.Item
                  label="Taxes (Currently Estimated)"
                  name="taxes_currently_estimated"
                  className="formitem"
                >
                  <InputNumber
                    min={0}
                    formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
                    parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
                    placeholder='28,000'
                  />
                </Form.Item>
              </Form>
            </div>
            <div className="cal-output">
              <div className="label-name">Purchase Closing costs</div>
              <div className="label-value">$ {formatNumberWithTwoDecimals(initialValues.purchase_closing_costs)}</div>

              <div className="label-name">Closing costs when selling</div>
              <div className="label-value">$ {formatNumberWithTwoDecimals(initialValues.closing_cost_when_selling)}</div>

              <div className="label-name">HM Interest during loan</div>
              <div className="label-value">$ {formatNumberWithTwoDecimals(initialValues.hm_interest_during_loan)}</div>
              {initialValues.extension_fees_of_hm != ''
              && <>              
                <div className="label-name">Extension Fees of HM</div>
                <div className="label-value">$ {formatNumberWithTwoDecimals(initialValues.extension_fees_of_hm)} <span>(Past 180 Days there is 1 pt extension, Past 270 there is a 2 pt extension)</span></div>
              </>
              }
              
            </div>
          </div>
      </Page>
    );
};

export default FlipCalculator;
