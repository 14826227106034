import React, { useState, useEffect, useContext } from 'react';
import API from '../../utils/API';
import { Link } from 'react-router-dom';
import { AuthContext } from '../../App';


const ConnectionsNav = props => {
    
  const { state } = useContext(AuthContext);
  const [counter, setCounter] = useState([]);
  useEffect(() => {
    getConnections();
  }, [state?.user?.id]);

  const getConnections = async () => {
    if( state?.user?.id){
        try {
            const res = await API.get("/users/counter/"+state?.user?.id);
            setCounter(res?.data);
          } catch (err) {
            console.log(err);
          }
    }
  };


  return (
    <ul>
      <li className={props?.connection}><Link to="/user/connections">Conexiones {counter?.connected ? '('+counter?.connected+')' : null}</Link></li>
      <li className={props?.pending}><Link to="/user/pending-connections">Pendiente {counter?.pending ? '('+counter?.pending+')': null}</Link></li>
      <li className={props?.sent}><Link to="/user/sent-connections">Solicitud enviada {counter?.sent ? '('+counter?.sent+')': null}</Link></li>
    </ul>
  );
};

export default ConnectionsNav;
