import React, { useState, useContext, useRef } from 'react';
import {  Modal, Skeleton } from 'antd';
import API from '../../utils/API';
import { Link, useHistory } from 'react-router-dom';
import AddPostForm from '../AddPostForm/AddPostForm';
import CommentList from '../ForumBlock/CommentList';
import ProfileImage from '../WidgetBar/ProfileImage';
import { AuthContext } from '../../App';
import Rolename from '../../components/RolesName/RolesName';
import LikeBlock from './LikeBlock';
import Moment from 'moment';
import SocialShareBlock from '../../components/SocialShareBlock/SocialShareBlock';
import Linkify from 'react-linkify';
import io from "socket.io-client"; 

const ForumSubcategoryContent = props => {
  const { state } = useContext(AuthContext);
    const [loading, setLoading] = useState(true);
    const [topic, setTopic] = useState([]);
    const history = useHistory();
    const [popupmessage, setPopupmessage] = useState(null);
    const [visible, setVisible] = useState(false);
    const [anchortag, setanchortag] = useState('');
    const [count, setCount] = useState(1);
    const [likeActive, setlikeActive] = useState(false);
    const socket = useRef();
    const [usersList, setusersList] = useState([]);
    const [selectedImage, setSelectedImage] = useState(null);
    React.useEffect(() => {
      let currentURL = window.location.href;
  
      if(currentURL.includes("comunidad.enlacancha")) {
        socket.current = io("wss://comunidad.enlacancha.us");
      }else if(currentURL.includes("enlacancha")) {
          socket.current = io("wss://enlacancha.us");
      }else if(currentURL.includes("hurekadev")) {
          socket.current = io("wss://elc.hurekadev.info");
      }else if (currentURL.includes("localhost")) {
          socket.current = io("ws://localhost:8000");
      }
    }, []);
    const showModal = (type, shortidredirect) => {
      setanchortag('#'+shortidredirect);
      if(type === 'followpost'){
        setPopupmessage('Debes iniciar sesión para seguir este tema.');
      }else if( type === 'comment'){
        setPopupmessage('Debes iniciar sesión para responder a este tema.');
      }else if(type === 'like'){
        setPopupmessage('Debes iniciar sesión para darle like este tema.');
      }else if(type === 'share'){
        setPopupmessage('Debes iniciar sesión para compartir este tema.');
      }else{
        setPopupmessage('Debe iniciar sesión para realizar una acción en este tema.');
      }
     
      setVisible(true);
      document.body.classList.add('modalopen');
    }
    const handleRedirect =() => {
      setPopupmessage(null);
      setVisible(false);
      history.push('/login?=/forums'+anchortag)
      document.body.classList.remove('modalopen');
    }
    const handleCancel = () => {
      setanchortag('');
      setPopupmessage(null);
      setVisible(false);
      history.push('/pricing')
      document.body.classList.remove('modalopen');
    };

    React.useEffect(()=>{
        API.get(`/topics/single/${props.subcategorytopic}`)
        .then(result => {  
         if(result.data){
            setTopic(result.data);
            setLoading(false);
            let userList = []
            if(result?.data){
              let fullName=  result.data?.author?.firstname +' '+result.data?.author?.lastname;
              let ids = result.data?.author?._id;
              let exists = userList.some(el => el.id === ids);
              if(!exists) {
                userList.push({id: ids, display: fullName})
              }
              result.data?.posts.map((item)=>{
                let fullName = item?.author?.firstname+' '+item?.author?.lastname;
                let ids = item?.author?._id;
                let exists = userList.some(el => el.id === ids);
                if(!exists) {
                  userList.push({id: ids, display: fullName})
                }
              })
              setusersList(userList)
            }
         }
           
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
      },[count, likeActive])
    const postReply = (e, message, setMessage, loading, setLoading, topic, detailPageUrl, receiverId) => {
      //if(e.keyCode == 13 && e.shiftKey == false && message.trim().length > 0) {
      if(message.trim().length > 0) {  
        e.preventDefault();
        setLoading(true)
        //get all mentioned users list
        let allmentioneduser = [];
        let aa = message.trim().split('@@@__')
        aa.map((a) =>{
          if (a.indexOf('^^__')>0) {
            let b = a.split('^^__');
            let exists = allmentioneduser.some(el => el === b[0]);
            if(!exists) {
              allmentioneduser.push(b[0]);
            }
          }
        })
        let newComment = message.trim();
        newComment = newComment.split('@@@__everyone').join("<a href=\"javascript:void(0)")
        newComment = newComment.split('@@@__').join("<a href=\"/user/alias/")
        newComment = newComment.split('^^__').join("\">")
        newComment = newComment.split('@@@^^^').join("</a>");
        API.post(
          `/posts/add`,
          {
            message: newComment.trim(),
            author: state.user.id,
            topic: topic,
            detailPageUrl:detailPageUrl,
            allmentioneduser:allmentioneduser
          },
          {
            headers: {
              Authorization: `Bearer ${state.token}`,
            },
          }
        )
          .then(result => {
            setLoading(false);
            setMessage('');
            setCount(count+1);
            socket.current.emit("sendDesktopNotification", {
              receiverId,
            });
          })
          .catch(e => {
            setLoading(false);
            Modal.error({
              title: 'Ocurrió un error',
              content: e.message,
            });
          });
      }
    };

    const openModal = (image) => {
      setSelectedImage(image);
    };
  
    // Handle close modal
    const closeModal = () => {
      setSelectedImage(null);
    };
   return (
        <div className="viewrow-subcat">
        {loading ? (
            <div className="loading">
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
            </div>
          ) : (
                  (topic.author != null && topic.posts[0].author !=null) ? (
                     <div id={topic.shortid} className="viewrow">
                        {/* <div className="discussion-main">{topic.subcategory.name}</div> */}
                        <div className="userinfomation">
                            <div className="userpic">
                              <Link to={topic.author?.pathalias?.alias}>
                                <ProfileImage id={topic.author._id}/>
                                </Link></div>
                            <div className="username">
                                <div className="title">
                                  <h2>
                                    <Link to={topic.author?.pathalias?.alias}>{topic.author.firstname} {topic.author.lastname}</Link>
                                  </h2>
                                </div>
                                <div className="category">{topic.posts[0].author?.userinfoid?.roles && <Rolename id = {topic.posts[0].author?.userinfoid?.roles}/>}</div>
                            </div>
                            {/* <div className="userconnect"><a href="#">connect</a></div> */}
                            <div className="createdby">
                                <div className="created">
                                <label>Publicado por:</label>
                                <span className="authername">{topic.author.firstname} {topic.author.lastname}</span>
                                </div>
                                <div className="date">{Moment(topic.createdAt).format('MMM D, YYYY')}</div>
                            </div>
                        </div>
                        <div className="contentbox">
                            <div className="content">
                            {state.isAuthenticated ? 
                                (
                                  <div className="title"><Link to={"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+topic.shortid}>{topic.title}</Link></div>
                                ) :
                                (
                                  <div className="title">{topic.title}</div>
                                )}
                                <div className="body">
                                  <Linkify
                                    componentDecorator={(decoratedHref, decoratedText, key) => (
                                        <a target="blank" href={decoratedHref} key={key}>
                                            {decoratedText}
                                        </a>
                                    )}
                                >{topic.posts[0]?.message}</Linkify>
                              </div>
                              {topic?.photo?.length > 0 &&
                              <div className="photo-wrapper">
                                {topic?.photo?.map((pitem, index) => (
                                  pitem?.location && <img
                                    key={index}
                                    src={pitem?.location}
                                    alt="Gallery"
                                    className="masonry-image"
                                    onClick={() => openModal(pitem?.location)} // Open modal with clicked image
                                  />
                                ))}
                              </div>
                              }
                              {selectedImage && (
                                <div className="modal-overlay" onClick={closeModal}>
                                  <div className="modal-content" onClick={(e) => e.stopPropagation()}>
                                    <span className="close-button" onClick={closeModal}>&times;</span>
                                    <img src={selectedImage} alt="Enlarged" className="enlarged-image" />
                                  </div>
                                </div>
                              )}
                            </div>
                            {/* {state.isAuthenticated ? (
                            <div className="followpost"><a className="registrationpopupbtn" href="#">Sigue esta Publicacion</a></div>
                            ) : (
                              <div className="followpost" onClick={() => showModal('followpost', topic.shortid)}><a className="registrationpopupbtn" href="#">Sigue esta Publicacion</a></div>
                            )} */}                           
                        </div>
                        <div className="postsocialshare">
                          {state.isAuthenticated ? (
                            <ul>
                              <li className="likecount">
                                <LikeBlock receiverId={topic?.posts[0]?.author?._id} socket={socket} pid={topic.posts[0]._id} uid={state.user.id} detailPageUrl={"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)}/>
                                
                              </li>
                              <li className="commentcount"><span className="commentcounts">comment</span><span className="count">{topic.posts.length > 1 ? topic.posts.length - 1 : 0 }</span></li>
                              <li className="share">
                                <SocialShareBlock shareTitle={topic.title} shareUrl={window.location.origin+"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)} />
                              </li>
                            </ul>
                          ) : (
                            <ul>
                              <li className="likecount" onClick={() => showModal('like', topic.shortid)}><span className="like">like</span><span className="count">{topic.posts[0].likepost.length}</span></li>
                              <li className="commentcount" onClick={() => showModal('comment', topic.shortid)}><span className="commentcounts">comment</span><span className="count">{topic.posts.length > 1 ? topic.posts.length - 1 : 0 }</span></li>
                              <li className="share" onClick={() => showModal('share', topic.shortid)}><div className="share-icon">share</div></li>
                           </ul>
                          )}
                        </div>
                        {state.isAuthenticated ? (
                        <div>
                          <div className="commentlistwrapper">
                            {topic.posts.length > 1 ? <CommentList commentList={topic.posts}/> : null}
                          </div>
                          <div className="commentformwrapper">
                            <AddPostForm postReply={postReply} receiverId={topic?.author?._id} topic= {topic._id} detailPageUrl={"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)} usersList={usersList}/>
                          </div>
                        </div>
                        ) : (
                          <div className="commentformwrapper" onClick={() => showModal('comment', topic.shortid)}>
                            <form action="/" >
                                <textarea id="commentformwrapper" name={"forumscomment"+(topic.shortid)} rows="4" cols="50" placeholder="Escribe un comentario" disabled></textarea>
                            </form>
                          </div>
                        )}
                    </div>)
                    : null
                )
            }
        <Modal
            visible={visible}
            okText="Iniciar sesión"
            cancelText="Suscríbete"
            onOk={handleRedirect}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>{popupmessage}</div>
         </Modal>
        </div>
  );
};

export default ForumSubcategoryContent;
