import React, { useEffect, useContext, useState } from 'react';
import API from '../../../../utils/API';
import { Modal, Button, Form, Input,InputNumber, Select, DatePicker, Checkbox } from 'antd';
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
const libraries = ["places"];

const { TextArea } = Input;
const RefinanciamientoStep4 = props => {
  //const [loantype, setLoantype] = useState('');
  //const [stateList,setStateList] = useState([]);
  //const [statelistoptiondone,setStateListOptiondone] = useState(false);
  //const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  //const [langselected, setLang] = useState({})
  //const [form] = Form.useForm();
  //const [valuepropertyaddress, setValuepropertyaddress] = useState(null)
  const [searchboxRef, setSearchboxRef] = useState(null)
  const handleIsrentedChange = (value) => {
    if(value == 'Si'){
      setIsrentedShow(true);
    }else{
      setIsrentedShow(false)
    }
  };
  const onPrev = () => {
    props.prev()
  }
  const onSearchBoxMounted = ref => {
    setSearchboxRef(ref);
  };
  const changeSearch = (event) => {
    if(event.target.value != '') {
      props.formdata.propertyaddress = event.target.value;
    }
  }
  const onPlacesChanged = () => {
    const places = searchboxRef.getPlaces();
    if(places.length){
      if(places[0]?.business_status){
        props.formdata.propertyaddress = places[0].name+', '+places[0].formatted_address
      }else{
        props.formdata.propertyaddress = places[0].formatted_address
      }
    }
  };
   return (
    <>
          <Form.Item
            label="Direccion del proyecto"
            name="propertyaddress"
            className='addressfield'
          >
            {/* <Input  placeholder='Entra direccion de la propiedad'/> */}
            <LoadScript
              googleMapsApiKey="AIzaSyAxVHisD2iH7zCBu2qLhsIUPX2VOUFaWpA"
              libraries = {libraries}
            >
              <StandaloneSearchBox
                onLoad={onSearchBoxMounted}
                onPlacesChanged={onPlacesChanged}
              >
                
                <input
                  type="text"
                  className='text-input ant-input'
                  placeholder="Dirección de Propiedad"
                  name="modalInputAdd"
                  label="Address"
                  defaultValue = {props?.formdata?.propertyaddress}
                  onBlur={changeSearch} 
                  onChange={changeSearch}
                />
            </StandaloneSearchBox>
          </LoadScript>
          </Form.Item>
          <Form.Item
            label="¿Cuál es la valorización de la propiedad?"
            name="propertyvalue"
          >
            <InputNumber
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Entra valorización de la propiedad'
            />
          </Form.Item>
          <Form.Item
            label="¿Cuáles son los impuestos de la propiedad?"
            name="propertytax"
          >
            <InputNumber 
            min={0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder='Entra impuestos de la propiedad'/>
          </Form.Item>
          <Form.Item
            label="Esta rentado?"
            name="isrented"
            >
              <Select
              onChange={handleIsrentedChange}
              options={[
                {
                  value: 'Si',
                  label: 'Si',
                },
                {
                  value: 'No',
                  label: 'No',
                },
              ]}
            />
          </Form.Item>
          <Form.Item
            label="Cual es la renta actual?"
            name="currentrent"
          >
            <InputNumber 
            min={0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder='Entra renta actual'/>
          </Form.Item>
          {/* <Form.Item
            label="¿Cuándo se compró la propiedad?"
            name="propertypurchased"
          >
            <DatePicker allowClear={false} size='large' format='DD/MM/YYYY' />
          </Form.Item> */}
          <Form.Item
            label="Precio de compra?"
            name="propertypurchasedprice"
          >
            <InputNumber 
            min={0}
            formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
            parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
            placeholder='Entra precio de compra'/>
          </Form.Item>
          <Form.Item
            label="Costo de renovacion"
            name="renovacioncost"
          >
            <InputNumber 
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Entra costo de renovacion'/>
          </Form.Item>
          <Form.Item
            label="Deuda actual?"
            name="currentdebt"
          >
            <InputNumber 
              min={0}
              formatter={(value) => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
              parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
              placeholder='Entra deuda actual'/>
          </Form.Item>
          <Form.Item
          name="acceptterm"
          valuePropName="checked"
          className="term-accept"
          rules={[
            {
              validator: (_, value) =>
                value ? Promise.resolve() : Promise.reject(new Error('Aceptar los términos anteriores')),
            },
          ]}
          >
            <Checkbox>
              Haz click aquí  para aceptar ser contactado por los prestamistas asociados
            </Checkbox>
          </Form.Item>
          <Form.Item className="form-action">
            <Button className="prevstep" onClick={() => onPrev()}>Atrás</Button>
            <Button
                type="primary"
                htmlType="submit"
                className="form-submit"
              >
              Enviar
              </Button>
          </Form.Item>
      </>
   );
};

export default RefinanciamientoStep4;
