import React, { Component } from 'react';
import { Result, Button, Modal } from 'antd';
import Page from '../components/Page/Page';
import { Link } from 'react-router-dom';
import API from '../utils/API';
import CourseDetail from './Education/CourseDetail';
import PodcastDetails from './Podcasts/PodcastDetails';
import BlogDetails from './Blogs/BlogDetails';
import BasicPage from './Basicpage/BasicPage';
import BookDetails from './Books/BookDetails';
import AboutGuestUser from './Guestuser/AboutGuestUser';
import TipOfTheWeekDetails from './TipOfTheWeek/TipOfTheWeekDetails';
import WebinarDetails from './Webinar/WebinarDetails';
import SponsorDetails from './Sponsors/SponsorDetails';
import LoanDetailpage from './Borrower/LoanDetailpage';
import LenderOnboardingForm from './Lender/LenderOnboardingForm';
import BorrowerDashboard from './Borrower/BorrowerDashboard';
import LenderDashboard from './Lender/LenderDashboard';
import LenderProfile from './Lender/LenderProfile';
import Deals from './Deals/Deals';
import DealsDetail from './Deals/DealsDetail';
class Global extends Component {
  state ={
    alias: encodeURIComponent(this.props.match.url),
    pagetype: "",
    pageid: "",
    loadingPage: "",
  }
  componentDidMount() {
    this.getData();
  };
  componentDidUpdate(prevProps){
    if(this.props.match.params!== prevProps.match.params){
      //write code to get new data using new prop
      this.getData();
    }
}
 
  getData(){
    if(this.props.match.url != ''){
      let allparam = this.props.match.url.split('/');
      if(allparam[1] == 'user' && allparam[2] == 'alias'){
        this.setState({pageid: allparam[3]})
        this.setState({loadingPage: 'users'})
        this.setState({pagetype: 'users'})
      }else if(allparam[1] == 'loan' && allparam[2] == 'view'){
        this.setState({pageid: allparam[3]})
        this.setState({loadingPage: 'loandetail'})
        this.setState({pagetype: 'loandetail'})
      }else if(allparam[1] == 'dashboard' && allparam[2] == 'borrower'){
        this.setState({pageid: allparam[2]})
        this.setState({loadingPage: 'borrowerdashboard'})
        this.setState({pagetype: 'borrowerdashboard'})
      }else if(allparam[1] == 'dashboard' && allparam[2] == 'lender' && allparam[3] == 'profile'){
        this.setState({pageid: allparam[3]})
        this.setState({loadingPage: 'lenderprofile'})
        this.setState({pagetype: 'lenderprofile'})
      }else if(allparam[1] == 'dashboard' && allparam[2] == 'lender'){
        this.setState({pageid: allparam[2]})
        this.setState({loadingPage: 'lenderdashboard'})
        this.setState({pagetype: 'lenderdashboard'})
      }else if(allparam[1] == 'activation' && allparam[2] == "emailconfirm" && allparam[3] == "l"  && allparam[4] == "reset"){
        this.setState({pageid: allparam[5]})
        this.setState({loadingPage: 'lenderonboard'})
        this.setState({pagetype: 'lenderonboard'})
      }else if(allparam[1] == 'deals' && typeof allparam[2] != 'undefined'){
        console.log('allparam[2]: ',allparam[2])
        this.setState({loadingPage: 'dealsdetail', pageid: allparam[2]})
      }/* else if(allparam[1] == 'deals'){
        this.setState({loadingPage: 'deals'})
      } */else{
        API.get('/pages/global/pathalias/?alias='+this.state.alias)
        .then(result => {
          if(result.data.p){
            this.setState({pageid: result.data.p.page})
            this.setState({loadingPage: result.data.p.pagetype})
            this.setState({pagetype: result.data.p.pagetype})
          }else{
            this.setState({loadingPage: "notfound"});
          }
        })
        .catch(e => {
          Modal.error({
            title: 'Ocurrió un error',
            content: e.message,
          });
        });
      }
    }
    
  }
  render(){
    const renderPage = () => {
      if(this.state.loadingPage == 'notfound'){
        return <Result
        status="404"
        title="404"
        subTitle="Ooops, la página que visitaste no existe."
        extra={
          <Button type="primary">
            <Link to="/">Volver a la página de inicio</Link>
          </Button>
        }
        style={{ margin: '20px 0' }}
      />
      }else if(this.state.loadingPage == 'course'){
        return <CourseDetail pageid = {this.state.pageid} />
      } else if (this.state.loadingPage == 'podcasts'){
        return <PodcastDetails pageid = {this.state.pageid} />
      } else if (this.state.loadingPage == 'blogs'){
        return <BlogDetails pageid = {this.state.pageid} />
      } else if (this.state.loadingPage == 'books'){
        return <BookDetails pageid = {this.state.pageid} />
      }else if(this.state.loadingPage == 'basic'){
        return <BasicPage pageid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'users'){
        return <AboutGuestUser pageid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'tipoftheweek'){
        return <TipOfTheWeekDetails pageid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'webinar'){
        return <WebinarDetails pageid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'sponsors'){
        return <SponsorDetails pageid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'loandetail'){
        return <LoanDetailpage tokenid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'lenderonboard'){
        return <LenderOnboardingForm tokenid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'borrowerdashboard'){
        return <BorrowerDashboard tokenid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'lenderdashboard'){
        return <LenderDashboard tokenid = {this.state.pageid}/>
      }else if(this.state.loadingPage == 'lenderprofile'){
        return <LenderProfile/>
      }else if(this.state.loadingPage == 'dealsdetail'){
        return <DealsDetail pageid = {this.state.pageid}/>
      }/* else if(this.state.loadingPage == 'deals'){
        return <Deals/>
      } */
    } 

      return (
        <Page>
          {renderPage()}        
        </Page>
      );
  }
};

export default Global;
