import React, { useEffect, useContext, useState } from 'react';
import API from '../../../utils/API';
import { AuthContext } from '../../../App';
import { Redirect, useHistory } from 'react-router-dom';
import './../financeing.css'
import { Modal, Form, Input} from 'antd';
import RefinanciamientoStep1 from './RefinanciamientoSteps/RefinanciamientoStep1';
import RefinanciamientoStep2 from './RefinanciamientoSteps/RefinanciamientoStep2';
import RefinanciamientoStep3 from './RefinanciamientoSteps/RefinanciamientoStep3';
import RefinanciamientoStep4 from './RefinanciamientoSteps/RefinanciamientoStep4';

const { TextArea } = Input;
const Refinanciamiento = props => {
  const [stepForm] = Form.useForm();
  const [current, setCurrent] = React.useState(0);
  const { state } = useContext(AuthContext);
  const [loantype, setLoantype] = useState('');
  const [stateList,setStateList] = useState([]);
  const [statelistoptiondone,setStateListOptiondone] = useState(false);
  const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
  const history = useHistory();
  const [options, setOptions] = useState([]);
  const [formdata, setFormdata] = useState({})
  //const [optionsLang,setsetOptions] = useState(['English']);
  /* const onChangepropertyvalue = (value) => {
    console.log('propertyvalue', value);
  }; */
 
  const onFinish = value =>{
    setLoantype(props.loantype)
    API.post('users/loan/r/add',{
      author: state?.user?.id,
      authorname:state?.user?.firstname,
      loantype:props.loantype,
      activeinvest: formdata?.activeinvest,
      companyname:formdata?.companyname ? formdata?.companyname?.trim() : '',
      credit: formdata?.credit,
      cuantos: formdata?.cuantos,
      currentdebt: value.currentdebt,
      currentrent: value.currentrent,
      employmenttype: formdata?.employmenttype,
      fullname: formdata.fullname ? formdata.fullname.trim(): '',
      income: formdata?.income,
      isrented: value.isrented,
      langcontact: formdata?.langcontact,
      project: formdata?.project,
      propertyaddress: formdata.propertyaddress ? formdata.propertyaddress.trim() : '',
      propertypurchased: value?.propertypurchased,
      propertypurchasedprice: value.propertypurchasedprice,
      propertytax: value.propertytax,
      propertyvalue: value.propertyvalue,
      renovacioncost: value.renovacioncost,
      saving: formdata?.saving,
      stateprovideservice: value.stateprovideservice,
      taxoption: formdata?.taxoption,
      telephonenumber: formdata?.telephonenumber.trim(),
      acceptterm: value.acceptterm,
    }).then(result =>{
      history.push('/dashboard/borrower');
      Modal.success({
        content: 'Se envió su solicitud de préstamos con éxito. Un prestamista se contactará con usted',
      });
      form.resetFields();
      setLoantype();
      console.log(result)
    })
  }

   useEffect(()=>{
    async function getStateList () {
      await API.get('/location/details/state').then(async result => {
        setStateList(result?.data?.statelist)
        let temp = [];
        result?.data?.statelist.map((s)=>{
          temp.push({
            value: s.name,
            label: s.name,
          });
        })
        setOptions(temp);
        setStateListOptiondone(true);
       }).catch(err => {
         console.log(err);
       })
    }
    getStateList();
  },[statelistoptiondone])

  const handleActiveinvestChange = (value) => {
    if(value == 'Si'){
      setCuantosShow(true);
    }else{
      setCuantosShow(false);
    }
  };

 if(state?.user?.usertype.includes('lender')){
    return <Redirect to="/dashboard"/>;
  }
  const next = () => {
    window.scrollTo({
      top: 0
    });
    setCurrent(current+1)
  }
  const prev = () => {
    window.scrollTo({
      top: 0
    });
    setCurrent(current-1);
  };

  const steps = [
    {
      step: 1,
      title: "INFORMACION GENERAL",
      content: <RefinanciamientoStep1 next={next} form = {stepForm} formdata={formdata}/>
    },
    {
      step: 2,
      title: "INFORMACION FINANCIERA",
      content: <RefinanciamientoStep2 next={next} prev={prev} form = {stepForm} formdata={formdata}/>
    },
    {
        step: 3,
        title: "INFORMACION DE EXPERIENCIA",
        content: <RefinanciamientoStep3 next={next} prev={prev} form = {stepForm} formdata={formdata} cc={formdata?.activeinvest}/>
    },
    {
        step: 4,
        title: "INFORMACION DE LA PROPIEDAD",
        content: <RefinanciamientoStep4 prev={prev} formdata={formdata}/>
    }
  ];
   return (
    <Form onFinish = {onFinish} form = {stepForm} layout="vertical"
    name="">
      <div className='step-wrapper'>
        <h2>{steps[current]?.title}</h2>
        <div id="l-requestforms">
          {steps[current]?.content}
        </div>
        
      </div>
    </Form>
        
      
   );
};

export default Refinanciamiento;
