import React, { createContext, useReducer, useEffect } from 'react';
import { BrowserRouter, Switch, Route } from 'react-router-dom';
import { Layout } from 'antd';
import Header from './components/Header/Header';
import Footer from './components/Footer/Footer';
import reducer from './utils/reducer';
import PrivateRoute from './components/PrivateRoute/PrivateRoute';
import ScrollToTop from './components/Page/ScrollToTop';
// Pages
import Front from './pages/Front/Front';
import Dashboard from './pages/Dashboard/Dashboard';
import Education from './pages/Education/Education';
import CoursesListingsBeginners from './pages/Education/CoursesListingsBeginners';
import CoursesListingsIntermediate from './pages/Education/CoursesListingsIntermediate';
import CoursesListingsExpert from './pages/Education/CoursesListingsExpert';
import Network from './pages/Network/Network';
import Pricing from './pages/Pricing/Pricing';
import Login from './pages/Login/Login';
import FlipCalculator from './pages/Calculator/FlipCalculator';
/* import Register from './pages/Register/Register'; */
/* import Page404 from './pages/Page404/Page404'; */
import Policy from './pages/Basicpage/Policy';
import TermsCondition from './pages/Basicpage/TermsCondition';
import Aboutus from './pages/Basicpage/AboutUs';
import Contactus from './pages/Basicpage/ContactUs';
import Comingsoon from './pages/Basicpage/Comingsoon';
/* import Forum from './pages/Forum/Forum'; */
/* import AnonymousForum from './pages/Forum/AnonymousForum'; */
/* import AnonymousForumFilter from './pages/Forum/AnonymousForumFilter'; */
import CoursesListings from './pages/Education/CoursesListings';
import FAQ from './pages/Basicpage/FAQ';
import AllPodcasts from './pages/Podcasts/AllPodcasts';
import AllBooks from './pages/Books/AllBooks';
import AllSponsors from './pages/Sponsors/AllSponsors';
import AllBlogs from './pages/Blogs/AllBlogs';
/* import AboutGuestUser from './pages/Guestuser/AboutGuestUser'; */
import ForgetPass from './pages/Login/ForgetPass';
import GlobalPage from './pages/Global';
import AboutUser from './pages/UserProfile/AboutUser.js/AboutUser';
import HelpUs from './pages/HelpUs/HelpUs';
import Guestuser from './pages/Guestuser/Guestuser';
import './app.css';
import ChangePass from './pages/Login/ChangePass';
/* import ResetPass from './pages/Login/ResetPass'; */
import Topic from './pages/Topic/Topic';
import Subcategory from './pages/Subcategory/Subcategory';
import Forummain from './pages/Forum/Forummain';
/* import AllNotifications from './components/WidgetBar/AllNotifications'; */
import AsyncNotifications from './pages/Async/AsyncNotifications';
import AsyncForum from './pages/Async/AsyncForum';
import Messenger from './pages/Messenger/Messenger';
import AllTipOfTheWeek from './pages/TipOfTheWeek/AllTipOfTheWeek';
import AllWebinar from './pages/Webinar/AllWebinar';
import Myconnections from './pages/MyNetwork/Connections';
import PendingConnections from './pages/MyNetwork/PendingConnections';
import SentConnections from './pages/MyNetwork/SentConnections';
import Resource from './pages/Resource/Resource';
/* import BorrowerOnboard from './pages/Borrower/BorrowerOnboard'; */
import Financing from './pages/Borrower/Financing';
import Deals from './pages/Deals/Deals'
// Auth context
export const AuthContext = createContext();
const initialState = {
  user: null,
  token: null,
  isAuthenticated: false,
};

const App = () => {
  const [state, dispatch] = useReducer(reducer, initialState);

  useEffect(() => {
    // Check if there's a token on localStorage and authenticate with it
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      const token = JSON.parse(localStorage.getItem('token'));
      const user = JSON.parse(localStorage.getItem('user'));
      dispatch({
        type: 'LOGIN',
        payload: {
          user,
          token,
        },
      });
    }
  }, []);

  return (
    <AuthContext.Provider value={{ state, dispatch }}>
      <BrowserRouter>
        <div className="App">
          <Layout
          style= {{ 
            backgroundColor: '#ffffff',
          }}
          >
            <Header />
            <ScrollToTop/>
            <Switch>
              <Route path="/" exact component={Front} />
              <Route path="/activation/emailconfirm/:token" exact component={Front}/>
              <Route path="/activation/emailconfirm/dealer/reset/:token" exact component={Front}/>
              {/* <Route path="/activation/emailconfirm/l/reset/:token" exact component={ResetPass}/> */}
              <Route path="/user/password/reset/:token" exact component={ForgetPass}/>    
              <Route path="/education/beginners" exact component={CoursesListingsBeginners} />
              <Route path="/education/intermediate" exact component={CoursesListingsIntermediate} />
              <Route path="/education/expert" exact component={CoursesListingsExpert} />
              <Route path="/education" exact component={Education} />  
              <Route path="/network" exact component={Network} />
             {/*  <Route path="/forums" exact render={(props) => <Forum {...props} key={Date.now()}/>}/> */}
              {/* <Route path="/forums/:maincat" exact component={Forummain}/> */}
               <Route path="/forums" exact render={(props) => <AsyncForum {...props} key={Date.now()}/>}/>
              <Route path="/forums/:maincat" exact render={(props) => <Forummain {...props} key={Date.now()}/>} />

              <Route path="/forums/:subcat/:sid" exact component={Subcategory}/>
              <Route path="/forums/:subcat/:sid/:topictitle" exact component={Topic}/>
              {/* <Route path="/forums/anonymousforum" exact component={AnonymousForum}/>
              <Route path="/forums/new-to-real-estate" exact component={AnonymousForumFilter}/> */}
              <Route path="/pricing" exact component={Pricing} />      
              <Route path="/login" exact component={Login} />
              <Route path="/calculator/flip" exact component={FlipCalculator} />
              {/* <Route path="/borrower/onboard" exact component={BorrowerOnboard} /> */}
              <Route path="/terms_and_conditions" exact component={TermsCondition} />
              <Route path="/privacy_policy" exact component={Policy} />
              <Route path="/aboutus" exact component={Aboutus} />
              <Route path="/contactus" exact component={Contactus}/>
              <Route path="/comingsoon" exact component={Comingsoon}/>
              <Route path="/education/videocourses" exact component={CoursesListings}/>
              <Route path="/education/tipoftheweek" exact component={AllTipOfTheWeek}/>
              <Route path="/education/webinar" exact component={AllWebinar}/>
              <Route path="/faq" exact component={FAQ}/>
              <Route path="/education/podcasts" exact component={AllPodcasts}/>
              <Route path="/education/blogs" exact component={AllBlogs}/>
              <Route path="/education/books" exact component={AllBooks}/>
              <Route path="/sponsors" exact component={AllSponsors}/>
              <Route path="/financing" exact component={Financing} />
              {/* <Route path="/userprofile/aboutuser" exact component={AboutUser}/>
              <Route path="/guestuser/aboutuser" exact component={AboutGuestUser}/> */}
              <Route path="/aboutuser" exact component={AboutUser}/>
              {/* <Route path="/aboutguestuser" exact component={AboutGuestUser}/> */}
              <Route path="/guestuser" exact component={Guestuser}/>
              <PrivateRoute path="/dashboard" exact component={Dashboard} />
              <PrivateRoute path="/help-us" exact component={HelpUs} />
              
              <PrivateRoute path="/change-password" exact component={ChangePass} />
              <PrivateRoute path="/notifications" exact component={AsyncNotifications} />
              <PrivateRoute path="/messenger" exact component={Messenger} />
              <PrivateRoute path="/user/connections" exact component={Myconnections}/>
              <PrivateRoute path="/user/pending-connections" exact component={PendingConnections}/>
              <PrivateRoute path="/user/sent-connections" exact component={SentConnections}/>
              <PrivateRoute path="/resources" exact component={Resource}/>
              <Route path="/public/resources" exact component={Resource}/>
              <Route path="/deals" exact component={Deals} />
              <Route path="*" exact component={GlobalPage}/>
            </Switch>         
              
   
            <Footer />
          </Layout>
        </div>
      </BrowserRouter>
    </AuthContext.Provider>
  );
};

export default App;
