import React, { useEffect, useState } from 'react';
import './message.css';
import convertDate from '../../utils/convertDate';

import API from '../../utils/API';
import ProfileImage from '../WidgetBar/ProfileImage';
import Linkify from 'react-linkify';

const Message = (props) => {
    const [user,setUser] = useState(null);
    useEffect(() => {
        if(props?.message?.sender){
            const getUser = async () => {
              try {
                const res = await  API.get('/users/' + props.message.sender);
                setUser(res.data.user);
              } catch (err) {
                console.log(err);
              }
            };
            getUser();
        }
        
      }, [props?.message]);

    return (
        <div className={props.own ? "message own" : "message"}>
            <div className="messageTop">
              {(!props.own && user)
              ?<ProfileImage id={user._id} classtouse="messageImg"/>
              :null
               }
            
            <div className="messageText">
              <Linkify
                  componentDecorator={(decoratedHref, decoratedText, key) => (
                      <a target="blank" href={decoratedHref} key={key}>
                          {decoratedText}
                      </a>
                  )}
              >{props.message.text.split('\n').map(str => <p>{str}</p>)}
              </Linkify>
            </div>
            </div>
            <div className="messageBottom">{convertDate(props.message.createdAt)}</div>
      </div>
    )
}

export default Message;