import React, { useState, useEffect } from 'react';
import API from '../../utils/API';


const CategoryName = props => {
  const [categoryName, setCategoryName] = useState('');

  useEffect( ()=> {
    let isMounted = true;
    if(props?.id){
        API.get(`/categories/id/?categoryid=${props.id}`).then(res => {
          if(isMounted){
            setCategoryName(res?.data?.category?.name)         
          }
          }).catch(err => {
            console.log(err);
          })
    }
    return () => { isMounted = false }; 
  },[props?.id]);

  return (
    <div>
      {categoryName}
    </div>
    
    
    
  );
};

export default CategoryName;