import React, { useEffect, useState, useContext, useRef } from 'react';
import Page from '../../components/Page/Page';
import API from '../../utils/API';
import { Button, Pagination, Skeleton, Modal } from 'antd';
import Post from '../../components/Post/Post';
import { useHistory, Link } from 'react-router-dom';
import AddPostForm from '../../components/AddPostForm/AddPostForm';
import { AuthContext } from '../../App';
import LikeBlock from '../../components/ForumBlock/LikeBlock';
import './topicdetail.css';
import NewForum from '../../pages/NewTopic/NewTopic';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import noavtar from '../../assets/no-avatar.png';
import Forumcategory from '../../components/LeftSidebar/Forumcategory';
import SocialShareBlock from '../../components/SocialShareBlock/SocialShareBlock';
import MetaTags from 'react-meta-tags';
import LogoImage from '../../assets/images/logo.png';
import io from "socket.io-client"; 
import ChatBlock from '../../components/RightSidebar/ChatBlock';
import SliderChatBlock from '../../components/RightSidebar/SliderChatBlock';

const Topic = props => {
  const { state } = useContext(AuthContext);
  const [loading, setLoading] = useState(true);
  const [pageLoading, setPageLoading] = useState(false);
  const [topic, setTopic] = useState({});
  const queryParams = props.location.search;
  const shortid = new URLSearchParams(queryParams).get('sid');
  const currentPage = new URLSearchParams(queryParams).get('page');
  const subcat = props.match.params.subcat;
  const sid = props.match.params.sid;
  const topictitle = props.match.params.topictitle;
  const [popupmessage, setPopupmessage] = useState(null);
  const [visible, setVisible] = useState(false);
  const [anchortag, setanchortag] = useState('');
  const [topiclimit, settopiclimit] = useState(0);
  const [membershiprole, setmembershiprole] = useState('free');
  const [username, setusername] = useState();
  const [newPostvisible, setnewPostvisible] = useState(false);
  const [mainclass, setMainClass] = useState('anonymousforum');
  const [mainclass1, setMainClass1] = useState('subcat-topiclist');
  const socket = useRef();
  const [showActive, setShowActive] = useState(0);
  const [usersList, setusersList] = useState([]);
  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
  }, [state]);

  React.useEffect(() => {
    if(state?.isAuthenticated){
      setMainClass1('midcontent');
      API.get("/users/counter/"+state?.user?.id).then((c) =>{
        if(c?.data?.connected){
          setShowActive(c?.data?.connected);
          setMainClass('maincontent forums bothsidebar');
        }else{
          setMainClass("maincontent forums sidebarleft")
        }
      });
      
    }
    return () => {
      return {
        mainclass,
        mainclass1
      }
    }
  }, [state])
  
  const [pages, setPages] = useState({
    currentPage,
    totalPages: 1,
  });
  const history = useHistory();

  if (!queryParams) {
    history.goBack();
  }

  const openAddPost = () => {
    if(state.user.level == 1){
      setnewPostvisible(true);
      document.body.classList.add('modalopen');
    }else if(topiclimit >= 3 && membershiprole=='free'){
      Modal.success({
        content: 'Has alcanzado el límite semanal para publicar temas en el foro.',
      });
    }else if(topiclimit >= 5 && membershiprole=='basic'){
      Modal.success({
        content: 'Has alcanzado el límite diario para publicar temas en el foro.',
      });
    }else{
      setnewPostvisible(true);
      document.body.classList.add('modalopen');
    }
    
  }
  const handlenewpostCancel = () => {
    setnewPostvisible(false)
    document.body.classList.remove('modalopen');
  };
  const getModalResponse = (visiblecheck) =>{
    setnewPostvisible(visiblecheck);
    document.body.classList.remove('modalopen');
    Modal.success({
      content: 'Gracias por enviar el tema del foro',
      onOk: () =>  reloadCurrent()
    });
  }

  const reloadCurrent= () =>{
    window.location.reload();
  }
  // Initial load
  useEffect(() => {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      let id = JSON.parse(localStorage.getItem('user')).id;
      API.get('users/'+id).then(result=>{
        if(result.data != null){
          setusername(result.data.user.firstname);
          if(result.data.user.topiclimit != null || result.data.user.topiclimit != 'undefined'){
            settopiclimit(result.data.user.topiclimit);
          }
          if(result.data.user.membershiprole != 'undefined'){
            setmembershiprole(result.data.user.membershiprole);
          }
        }
      }).catch(e =>{
        console.log(e.message);
      });
    }
    API.get(`/topics${queryParams}`)
      .then(result => {
        setTopic(result.data);
        setPages({
          currentPage: result.data.currentPage,
          totalPages: result.data.totalPages,
        });
        let userList = []
        if(result?.data){
          result.data?.posts.map((item)=>{
            let fullName = item?.author?.firstname+' '+item?.author?.lastname;
            let ids = item?.author?._id;
            let exists = userList.some(el => el.id === ids);
            if(!exists) {
              userList.push({id: ids, display: fullName})
            }
          })
          setusersList(userList)
        }
        setLoading(false);
      })
      .catch(e => {
        history.push('/404');
        console.log(e.message);
      });
  }, [queryParams, username]);

  const postReply = (e, message, setMessage, loading, setLoading, topic,detailPageUrl) => {
    //if(e.keyCode == 13 && e.shiftKey == false && message.trim().length > 0) {
    if(message.trim().length > 0) {    
      e.preventDefault();
      setLoading(true)
      //get all mentioned users list
      let allmentioneduser = [];
      let aa = message.trim().split('@@@__')
      aa.map((a) =>{
        if (a.indexOf('^^__')>0) {
          let b = a.split('^^__');
          let exists = allmentioneduser.some(el => el === b[0]);
          if(!exists) {
            allmentioneduser.push(b[0]);
          }
        }
      })
      let newComment = message.trim();
      newComment = newComment.split('@@@__everyone').join("<a href=\"javascript:void(0)")
      newComment = newComment.split('@@@__').join("<a href=\"/user/alias/")
      newComment = newComment.split('^^__').join("\">")
      newComment = newComment.split('@@@^^^').join("</a>");
      API.post(
        `/posts/add`,
        {
          message: newComment.trim(),
          author: state.user.id,
          topic: topic,
          detailPageUrl:detailPageUrl,
          allmentioneduser:allmentioneduser
        },
        {
          headers: {
            Authorization: `Bearer ${state.token}`,
          },
        }
      )
      .then(result => {
        setLoading(false);
        setMessage('');

        const recentPageNumber = result.data.post.topicTotalPages;
        if (recentPageNumber == currentPage) {
          setTopic(prevTopicState => {
            return {
              ...prevTopicState,
              posts: [...prevTopicState.posts, result.data.post],
            };
          });
        } else {
          history.push(`/forums/${subcat}/${sid}/${topictitle}?sid=${shortid}&page=${recentPageNumber}`);
        }
      })
      .catch(e => {
        setLoading(false);
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
    }
  };

  const fetchPage = page => {
    setPageLoading(true);

    API.get(`/topics?sid=${shortid}&page=${page}`)
      .then(result => {
        setTopic(result.data);
        setPages({
          currentPage: result.data.currentPage,
          totalPages: result.data.totalPages,
        });
        setPageLoading(false);
        history.push(`/forums/${subcat}/${sid}/${topictitle}?sid=${shortid}&page=${page}`);
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });
  };

  const pagination = (
    <div className="topic-pagination">
      <Pagination
        current={parseInt(pages.currentPage, 10)}
        total={parseInt(pages.totalPages, 10) * 10}
        showSizeChanger={false}
        onChange={fetchPage}
      />
    </div>
  );
  const postsocialshare = () => (<div className="postsocialshare">
        {state.isAuthenticated ? (
          <ul>
            <li className="likecount">
              <LikeBlock receiverId={topic?.posts[0]?.author?._id} socket={socket} pid={topic.posts[0]._id} uid={state.user.id} detailPageUrl={"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)} />
              
            </li>
            <li className="commentcount"><span className="commentcounts">comment</span><span className="count">{topic?.posts?.length > 1 ? topic.posts.length - 1 : 0 }</span></li>
            <li className="share">
            <SocialShareBlock shareTitle={topic.title} shareUrl={window.location.origin+"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)} />
            </li>
          </ul>
        ) : (
          <ul>
            <li className="likecount" onClick={() => showModal('like', topic.shortid)}><span className="like">like</span><span className="count">{topic?.posts[0]?.likepost.length}</span></li>
            <li className="commentcount" onClick={() => showModal('comment', topic.shortid)}><span className="commentcounts">comment</span><span className="count">{topic?.posts?.length > 1 ? topic?.posts?.length - 1 : 0 }</span></li>
            {/* <li className="share" onClick={() => showModal('share', topic.shortid)}>share</li> */}
          </ul>
        )}
      </div>);

    const showModal = (type, shortidredirect) => {
      setanchortag('#'+shortidredirect);
      if(type == 'followpost'){
        setPopupmessage('Debes iniciar sesión para seguir este tema.');
      }else if( type== 'comment'){
        setPopupmessage('Debes iniciar sesión para responder a este tema.');
      }else if(type == 'like'){
        setPopupmessage('Debes iniciar sesión para darle like este tema.');
      }else if(type == 'share'){
        setPopupmessage('Debes iniciar sesión para compartir este tema.');
      }else{
        setPopupmessage('Debe iniciar sesión para realizar una acción en este tema.');
      }
    
      setVisible(true);
      document.body.classList.add('modalopen');
    }
    const handleCancel = () => {
      setanchortag('');
      setPopupmessage(null);
      setVisible(false);
      document.body.classList.remove('modalopen');
    };
  return (
    <Page>
      <div className={"forum " + (mainclass)}>
        <div className="maindiv">
          {(state.isAuthenticated && showActive != 0)
          ?<div className="sliderchatblock"><SliderChatBlock socket={socket}/></div>
          : null
          }
          <div className="leftsidebar">
            {state.isAuthenticated && (
            <div className="categoryfilterwrapper">
              {/* <SearchBlock/> */}
              {/* <Discussionblock/> */}
              <Forumcategory/>
            </div>
            )}
            {!state.isAuthenticated && (
            <div className="categoryfilterwrapper">
              <Forumcategory/>
            </div>
            )}

          </div>
          <div className={mainclass1}>
            <div className="content-top-sub">
              {state.isAuthenticated && (
                <div className="forumssearchbox">
                  <div className="userpic"><ProfileImage id={state.user.id}/></div>
                  <div className="searcharea">
                    <Button
                      className="addpost"
                      onClick={openAddPost}
                    >
                      Cual es tu pregunta {username ? username.charAt(0).toUpperCase() +username.slice(1) : state.user.firstname.charAt(0).toUpperCase()+state.user.firstname.slice(1)}?
                    </Button>
                    <Modal
                      visible={newPostvisible}
                      okText="Enviar"
                      onCancel={handlenewpostCancel}
                      centered="true"
                      wrapClassName={'addforumspost'}
                      footer={null}
                      keyboard={false}
                      maskClosable={false}
                    >
                      <NewForum getModalResponse={getModalResponse}/>
                    </Modal>
                  </div>
                </div>
              )}
            </div>
            <div className="forumspostscontent">
              <div className="forumsposts">
                <div className="viewrow">
                  {loading ? (
                    <div className="topic-loading">
                      <Skeleton className="skeleton" active avatar />
                      <Skeleton className="skeleton" active avatar />
                      <Skeleton className="skeleton" active avatar />
                      <Skeleton className="skeleton" active avatar />
                      <Skeleton className="skeleton" active avatar />
                      <Skeleton className="skeleton" active avatar />
                    </div>
                  ) : (
                    <div className="topic-wrapper">
                        <div className="discussion-main">
                          <Link to={`/forums/${subcat}/${topic.subcategory.shortid}?sid=${topic.subcategory.shortid}`}>
                            {topic.subcategory.name}
                          </Link>
                        </div>
                      {/* {pages.totalPages > 1 && pagination} */}
                      {pageLoading ? (
                        <div className="topic-pageloading">
                          <Skeleton className="skeleton" active avatar />
                          <Skeleton className="skeleton" active avatar />
                          <Skeleton className="skeleton" active avatar />
                          <Skeleton className="skeleton" active avatar />
                          <Skeleton className="skeleton" active avatar />
                          <Skeleton className="skeleton" active avatar />
                        </div>
                      ) : (

                            <div>
                            <MetaTags>
                              <title>{topic.title+(' | En La Cancha Social')}</title>
                              <meta name="description" content="En La Cancha Social" />
                              <meta property="og:title" content="En La Cancha Social" />
                              <meta property="og:url" content={window.location.origin+"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)} />
                              <meta property="og:type" content="article" />
                              <meta property="og:image" content={LogoImage} />
                              <meta property="og:description" content={'En La Cancha Forum'} />
                              <meta property="og:title" content={topic.title} />
                            </MetaTags>
                            {topic.posts.map((post, index) => {
                              if(post.author != null){
                                if(index == 0){
                                  return <Post key={post._id} data={post} positionname="top-post" topictitle={topic.title} topicid={topic._id} counterbox={postsocialshare()} topicphoto={topic.photo}/>;
                                }else{
                                  return <Post key={post._id} data={post} positionname="lower-post"/>;
                                }
                              }                             
                            })}
                            </div>
                      )}
                      {pages.totalPages > 1 && pagination}
                      <AddPostForm postReply={postReply} topic= {topic._id} detailPageUrl={"/forums/"+(topic.subcategory.name).replace(/[^a-zA-Z]/g,"-")+"/"+ (topic.shortid)+'/'+(topic.title).replace(/[^a-zA-Z]/g,"-")+'?sid='+(topic.shortid)} usersList={usersList}/>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
          {(state.isAuthenticated && showActive != 0)
            ?<div className="rightsidebar chatwrapper">
              <ChatBlock socket={socket} key={Date.now()}/>
            </div>
            :null
          }
        </div>
      </div>
    </Page>
  );
};

export default Topic;
