import React, { useState } from 'react';
import API from '../../utils/API';
import BookImage from '../../components/BooksData/BookImage';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import './css/dashboardglobal.css';
import './css/dashboardbooklist.css'

const BooksViewBlock = () => {
  const [books,setBooks] = React.useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(()=> {
   API.get(`/books/`).then(result=>{
      if(result.data){
         setBooks(result.data.books.sort((a, b) => {
  
            // equal items sort equally
            if (a.bookOrder === b.bookOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.bookOrder === null) {
                return 1;
            }
            else if (b.bookOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
                return a.bookOrder < b.bookOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else { 
                return a.bookOrder < b.bookOrder ? 1 : -1;
            }
        
          }));
         setLoading(false);
      }
   }).catch(err => {
      console.log(err);
   })
},[])

  return (
   <div className="booksviewblock viewblockcontent">
            <h2 className="blocktitle">Libros</h2>
            <div className="viewcontent">
            {loading ? (
            <div className="loading">
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active  />
              <Skeleton className="skeleton" active  />
              <Skeleton className="skeleton" active  />
            </div>
          ) : 
            (books.map( (book, index) => {
               return <div className="viewrow" key={index}>
                  <div className="imagebox"><BookImage id ={book._id}/></div>
                  <div className="bookscontent">
                     <div className="title"><Link to={book.pathalias.alias} title={book.title}>{book.bookname.substring(0, 15)}</Link></div>
                     <div className="category">{book.artistname}</div>
                     <div className="description">
                        {book.description.substring(0, 120)}
                     </div>
                  </div>
               </div>
            })
            )}
               
            </div>
            <div className="viewall"><Link to="/education/books">Ver todo</Link></div>
         </div>
  );
};

export default BooksViewBlock;
