import React, { useState, useContext, useRef } from 'react';
import { useHistory, Link } from 'react-router-dom';
import { Form, Input, Modal, Radio,Space, Result, Button, Checkbox } from 'antd';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import loadingimage from '../../assets/images/loading.gif'
import io from "socket.io-client";
import './onboarding.css';
import PhoneInput from 'react-phone-input-2'
import { LoadScript, StandaloneSearchBox } from '@react-google-maps/api';
const libraries = ["places"];

const CheckboxGroup = Checkbox.Group;
const loantypeOptions = ['Hard Money', 'Refinanciamiento'];
const LenderOnboardingForm = props => {
  const { dispatch, state } = useContext(AuthContext);
  const socket = useRef();
  const [loading, setLoading] = useState(false);
  const [serviceprovidevalue, setServiceProvideValue] = useState([]);
  const [servicenotprovidevalue, setServiceNotProvideValue] = useState([]);
  const [minimumloanvalue, setMinimumLoanValue] = useState(1);
  const [makeloan, setMakeloan] = useState('');
  const [stateListOptions,setStateListOptions] = useState([])
  const [enablereset, setEnablereset] = useState(false)
  const [emailtoken, setEmailtoken] = useState(false)
  const [id,setStateuid] = useState(null)
  const history = useHistory();
  const [loantypevalue, setLoantypevalue] = useState([]);
  const [indeterminate, setIndeterminate] = useState(false);
  const [checkAll, setCheckAll] = useState(false);
  const re = /^(?=.*[A-Za-z])(?=.*\d)[a-zA-Z0-9!@#$%^&*()~¥=_+}{":;'?/>.<,`\-\|\[\]]{8,50}$/;
  const alpha = /^(?=.*[A-Za-z ])[a-zA-Z ]{1,50}$/;
  const emailcheck = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  const [form] = Form.useForm();
  const [linfoid, setLinfoId]= useState('');
  const [existingmember, setExistingmember] = useState('no')
  const [phonenumber, setPhoneNumber] = useState('')
  const [officephonenumber, setOfficePhoneNumber] = useState('')
  const [searchboxRef, setSearchboxRef] = useState(null)
  const [companyaddress, setCompanyaddress] = useState('')
  const handlePhoneOnChange = (value, data, event, formattedValue) => {
    if(formattedValue.length > 10){
      setPhoneNumber(formattedValue);
    }else {
      setPhoneNumber('');
    }
  }
  const handleOfficePhoneOnChange = (value, data, event, formattedValue) => {
    if(formattedValue.length > 10){
      setOfficePhoneNumber(formattedValue);
    }else {
      setOfficePhoneNumber('');
    }
  }
  const onFinish = values => {
    API.post('users/lender/info/add',{
      cname: values.cname,
      companyname: values.companyname,
      //cemail: values.cemail,
      companyaddress: companyaddress,
      makeloan,
      officeemail: values.officeemail,
      officephone: officephonenumber,
      telefono: phonenumber,
      serviceprovidevalue,
      servicenotprovidevalue,
      loantypevalue,
      minimumloanvalue,
      id: id,
      linfoid: linfoid,
      existingmember: existingmember,
    }).then(result =>{
      history.push('/login');
      if(existingmember == 'yes'){
        Modal.success({
          content: 'Informacion exitosamente guardada.',
        });
      }else{
        Modal.success({
          content: 'Informacion exitosamente guardada. Le hemos enviado un email  para establecer su contraseña de inicio de sesión.',
        });
      }
    })
  };
  React.useEffect(() => {
    let currentURL = window.location.href;

    if(currentURL.includes("comunidad.enlacancha")) {
      socket.current = io("wss://comunidad.enlacancha.us");
    }else if(currentURL.includes("enlacancha")) {
        socket.current = io("wss://enlacancha.us");
    }else if(currentURL.includes("hurekadev")) {
        socket.current = io("wss://elc.hurekadev.info");
    }else if (currentURL.includes("localhost")) {
        socket.current = io("ws://localhost:8000");
    }
    if(state.isAuthenticated){
      dispatch({
        type: 'LOGOUT',
      });
      socket.current.emit("logoutUser", state?.user?.id);
    }
    async function checkforvalidation(){
      await API.post('/users/authentication/activate/rest', {
        token: props.tokenid,
      })
        .then((response) => {
          if(response?.data?.decodedToken?.email){
            setEnablereset(true)
            setEmailtoken(response.data.decodedToken.email)
            setStateuid(response.data.decodedToken.objId)
            setLinfoId(response.data.decodedToken.linfoid)
            setExistingmember(response.data.decodedToken.existingmember)
          }
        })
      await API.get('/location/details/state/name').then(async result => {
        setStateListOptions(result?.data?.statelist)
        //setStateListNotOptions(result?.data?.statelist)
      }).catch(err => {
        console.log(err);
      })
      setLoading(true)
    }
    checkforvalidation();

  }, [id]);

  const onChangeMakeloanTax = (e) => {
    setMakeloan(e.target.value)
  }
  const onStateServiceChange = (list) => {
    setServiceProvideValue(list)
    setCheckAll(list.length === stateListOptions.length);
    if(list.length === stateListOptions.length){
      setServiceNotProvideValue([]) 
    }
  };

  const onCheckAllServiceChange = (e) => {
    setServiceProvideValue(e.target.checked ? stateListOptions : []);
    setCheckAll(e.target.checked);
    if(e.target.checked){
      setServiceNotProvideValue([]) 
    }
    
  };

  const onChangeMinimumLoad = (e) => {
    setMinimumLoanValue(e.target.value);
  };
  const onSearchBoxMounted = ref => {
    setSearchboxRef(ref);
  };
  const onPlacesChanged = () => {
    const places = searchboxRef.getPlaces();
    if(places.length){
      if(places[0]?.business_status){
        setCompanyaddress(places[0].name+', '+places[0].formatted_address)
      }else{
        setCompanyaddress(places[0].formatted_address)
      }
    }
  };
  return (
          <>
            {(loading == true && enablereset == true) ?
              <div className="l-onboarding-form-wrap">
                <div className="l-onboarding-form">
                  <div className="helptext">Fill this form to complete your registration</div>
                  <Form
                    form={form}
                    name="lenderinfo"
                    layout="vertical"
                    onFinish={onFinish}
                    size = {'default'}
                  >
                    <Form.Item
                      label="Nombre de la compañía"
                      name="companyname"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if(typeof(value) == "undefined" || !value){
                            return Promise.reject(new Error('Enter Company Name'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <Input  placeholder="Nombre de la compañía" className="form-text" />
                    </Form.Item>
                    <Form.Item
                      label="Nombre y apellido de persona de contacto"
                      name="cname"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          //message: 'Entra tu primer nombre',
                          validator: (_, value) => {
                            if(typeof(value) == "undefined" || !value){
                            return Promise.reject(new Error('Entra Nombre y apellido de persona de contacto'));
                            }else if(alpha.test(value.trim())){
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Entra Nombre y apellido de persona de contacto'));
                          },
                        },
                      ]}
                    >
                      <Input  placeholder="Nombre y apellido de persona de contacto" className="form-text" />
                    </Form.Item>
                    <Form.Item
                      label="Telefono"
                      name="telefono"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if(typeof(value) == "undefined" || !value){
                            return Promise.reject(new Error('Entra Número telefónico'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      <PhoneInput
                        country={'us'}
                        value={phonenumber}
                        enableSearch={true}
                        onChange={handlePhoneOnChange}
                      />
                    </Form.Item>
                    {/* <Form.Item
                      label="Email"
                      name="cemail"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if(typeof(value) == "undefined" || !value){
                            return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                            }else if(emailcheck.test(value.trim())){
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                          }
                        },
                      ]}
                    >
                      <Input  placeholder="Entra tu correo electronico" className="form-text" />
                    </Form.Item> */}
                    <Form.Item
                      label="Direccion de su  compañía"
                      name="companyaddress"
                      className="form-item"
                    >
                      {/* <Input  placeholder="Direccion de su  compañía" className="form-text" /> */}
                      <LoadScript
                        googleMapsApiKey="AIzaSyAxVHisD2iH7zCBu2qLhsIUPX2VOUFaWpA"
                        libraries = {libraries}
                      >
                        <StandaloneSearchBox
                          onLoad={onSearchBoxMounted}
                          onPlacesChanged={onPlacesChanged}
                        >
                          
                          <input
                            type="text"
                            className='text-input ant-input'
                            placeholder="Direccion de su  compañía"
                            name="modalInputAdd"
                            label="Address"
                          />
                      </StandaloneSearchBox>
                    </LoadScript>
                    </Form.Item>
                    <Form.Item
                      label="Numero telefonico de oficina"
                      name="officephone"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          //message: 'Entra tu primer nombre',
                          validator: (_, value) => {
                            if(typeof(value) == "undefined" || !value){
                              return Promise.reject(new Error('Office phone is required'));
                            }
                            return Promise.resolve();
                          },
                        },
                      ]}
                    >
                      {/* <Input  placeholder="Numero telefonico de oficina" className="form-text" /> */}
                      <PhoneInput
                        country={'us'}
                        value={officephonenumber}
                        enableSearch={true}
                        onChange={handleOfficePhoneOnChange}
                      />
                    </Form.Item>
                    <Form.Item
                      label="Email de oficina"
                      name="officeemail"
                      className="form-item"
                      rules={[
                        {
                          required: true,
                          validator: (_, value) => {
                            if(typeof(value) == "undefined" || !value){
                            return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                            }else if(emailcheck.test(value.trim())){
                              return Promise.resolve();
                            }
                            return Promise.reject(new Error('Por favor introduzca un correo electrónico válido'));
                          }
                        },
                      ]}
                    >
                      <Input  placeholder="Email de oficina" className="form-text" />
                    </Form.Item>

                    <Form.Item
                    label="¿Estados en los  que provee sus servicios?"
                    name="radio"
                    rules={[
                      { required: true, 
                        validator: (_, value) =>{
                          if(serviceprovidevalue.length > 0){
                            return Promise.resolve();
                          }
                          return Promise.reject(new Error('Please select an option'));
                        }
                      }
                    ]}>
                      <div className="checkall-wrapper">
                        <Checkbox indeterminate={indeterminate} onChange={onCheckAllServiceChange} checked={checkAll}>
                          Todos los Estados
                        </Checkbox>
                      </div>

                      <CheckboxGroup options={stateListOptions} value={serviceprovidevalue} onChange={onStateServiceChange} />
                    </Form.Item>
                    {/* <Form.Item
                      label="Realiza prestamos con Tax ID?"
                      name="makeloan"
                      className="form-item"
                    >
                      <Input  placeholder="Realiza prestamos con Tax ID?" className="form-text" />
                    </Form.Item> */}
                    <Form.Item
                      label="Realiza prestamos con Tax ID?">
                      <Radio.Group onChange={onChangeMakeloanTax} value={makeloan}>
                          <Radio value="Si">Si</Radio>
                          <Radio value="No">No</Radio>
                      </Radio.Group>
                    </Form.Item>
                    <Form.Item
                    label="Tipos de prestamos"
                    name="radiotypeofloan"
                    rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
                      {/* <Radio.Group onChange={onChangeLoanType} value={loantypevalue}>
                        <Space direction="vertical">
                          <label></label>
                          <Radio value="hard_money">Hard Money</Radio>
                          <Radio value="refinanciamiento">Refinanciamiento</Radio>
                        </Space>
                      </Radio.Group> */}
                      <CheckboxGroup options={loantypeOptions} value={loantypevalue} onChange={(list)=> {setLoantypevalue(list)}} />
                    </Form.Item>
                    <Form.Item
                    label="Cual es el prestamo minimo que puede originar"
                    name="radiominimum"
                    rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
                    <Radio.Group onChange={onChangeMinimumLoad} value={minimumloanvalue}>
                      <Space direction="vertical">
                        <label></label>
                        <Radio value="nominimum">No tenemos prestamo minimo</Radio>
                        <Radio value="50000">50,000 +</Radio>
                        <Radio value="100000">100,000 +</Radio>
                      </Space>
                    </Radio.Group>
                    </Form.Item>
                    <Form.Item
                      style={{textAlign: 'center' }} 
                      className="form-action"           
                    >
                      <input type="submit" value="Save" className="form-submit"></input>
                    </Form.Item>
                  </Form>
                </div>
              </div>
            : loading == true ? <Result
            status="404"
            title="404"
            subTitle="Ooops, la página que visitaste no existe."
            extra={
              <Button type="primary">
                <Link to="/">Volver a la página de inicio</Link>
              </Button>
            }
            style={{ margin: '20px 0' }}
          />:<div className='loading-wrapper'><img src={loadingimage}/></div>}
          </>
  );
};

export default LenderOnboardingForm;
