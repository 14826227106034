import React, {  useState } from 'react';
import API from '../../utils/API';
import PodcastImage from "../../components/PodcastData/PodcastImage";
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';

import './css/dashboardglobal.css';
import './css/dashboardpodcasts.css'

const PodcastsViewBlock = () => {
  const [podcasts, setPodcasts] = React.useState([]);
  const [loading, setLoading] = useState(true);

  React.useEffect(() => {
      API.get(`/podcasts/contents/user`).then(result => {
         if(result.data){
         setPodcasts(result.data.podcasts.sort((a, b) =>{
  
            // equal items sort equally
            if (a.podcastOrder === b.podcastOrder) {
                return 0;
            }
            // nulls sort after anything else
            else if (a.podcastOrder === null) {
                return 1;
            }
            else if (b.podcastOrder === null) {
                return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
                return a.podcastOrder < b.podcastOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else { 
                return a.podcastOrder < b.podcastOrder ? 1 : -1;
            }
        
          }));
         setLoading(false);
         }
      })
   }, [])
  return (
   <div className="podcastsviewblock viewblockcontent">
            <h2 className="blocktitle">Podcasts</h2>
            <div className="viewcontent">
            {loading ? (
            <div className="loading">
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
              <Skeleton className="skeleton" active avatar />
            </div>
          ) : (podcasts.map((podcast, index) => {
               return <div className="viewrow" key={index}>
                  <div className="imagebox"><PodcastImage id={podcast._id} /></div>
                  <div className="podcastscontent">
                     <div className="title"><h2><Link to={podcast.pathalias.alias} title={podcast.title}>{podcast.podcastname.substring(0, 25)}</Link></h2></div>
                     <div className="category">{podcast.artistname}</div>
                     <div className="description">
                        {podcast?.description}
                     </div>
                  </div>
               </div>
               })
            )}
            </div>
            <div className="viewall"><Link to="/education/podcasts">Ver todo</Link></div>
         </div>
  );
};

export default PodcastsViewBlock;
