import { Button, Modal, Input } from 'antd';
import React, { useContext, useState, useEffect,useRef } from 'react';
import API from '../../utils/API';
import { Link,Redirect, useHistory } from 'react-router-dom';
import { AuthContext } from '../../App';
const { TextArea } = Input;

const MessageButton = (props) => {
    const { state } = useContext(AuthContext);
    const [conversations, setConversations] = useState([]);
    const history = useHistory();
    const [membershiprole, setmembershiprole] = useState();
    const [visibletosubscribe, setVisibletosubscribe] = useState(false);

    React.useEffect(() => {
      if(state?.user?.id){
        API.get(`/users/${state?.user?.id}`).then(u=>{
          setmembershiprole(u?.data?.user?.membershiprole);
        })
      }
    },[]);
    const handleRedirectPricing =() => {
        setVisibletosubscribe(false);
        history.push('/pricing?=/network')
        document.body.classList.remove('modalopen');
    }
    const handleCancel = () => {
        setVisibletosubscribe(false);
        document.body.classList.remove('modalopen');
    };
    const openMessageBox = async (e) => {
        //setVisible(true);
        const res = await API.get("/conversations/"+state?.user?.id+'/count');
        if( state?.user?.level == 1 || membershiprole == 'basic' || (res?.data < 5 && membershiprole == 'free')){
            try {
                const res = await API.post("/conversations", {
                    senderId:state?.user?.id,
                    receiverId: props.forUserId,
                });
                history.push('/messenger?chat='+res.data._id);
            }catch (err) {
                console.log(err);
            }
        }else{
            setVisibletosubscribe(true);
            document.body.classList.add('modalopen');
        }
    }
    useEffect(() => {
        const getConversations = async () => {
            if( props.forUserId !== state?.user?.id){
                try {
                    const res = await API.get("/conversations/find/"+state?.user?.id+"/"+ props.forUserId);
                    setConversations(res.data);
                  } catch (err) {
                    console.log(err);
                  }
            }
        };
        getConversations();
      }, [state?.user?.id, props.forUserId]);
    return (
        <>
            {
                props.forUserId !== state?.user?.id
                ? (conversations!= null ? <Link to={"/messenger?chat="+conversations._id}>Envia Mensaje</Link> : <Button onClick={openMessageBox}>Envia Mensaje</Button>)
                : null
            }
            <Modal
            visible={visibletosubscribe}
            okText="Actualizar membresía"
            cancelText="Cancelar"
            onOk={handleRedirectPricing}
            onCancel={handleCancel}
            keyboard="false"
            centered="true"
            closable={false}
         >
            <div>Actualiza tu membresía para comenzar una nueva conversión ya que agotaste el límite de mensajes</div>
         </Modal>
        </>
    );
};

export default MessageButton;
