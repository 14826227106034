import React, { useContext, useState, useRef } from 'react';
import { useHistory, NavLink } from 'react-router-dom';
import ProfileImage from '../../components/WidgetBar/ProfileImage';
import API from '../../utils/API';
import {Button, Modal} from 'antd';
import { AuthContext } from '../../App';
import ImageGallery from "react-image-gallery";
import NewDeal from './NewProperty.js';
import UpdateDeal from './UpdateProperty';
import NewEnquiry from './NewEnquiry.js';
import Moment from 'moment';
import './css/deals.css';
import "react-image-gallery/styles/css/image-gallery.css";
const DealsDetail = (props) => {

  const { state } = useContext(AuthContext);
  const [visible, setVisible] = useState(false);
  const [visibleUpdate, setVisibleUpdate] = useState(false);
  const [visibleEnquiry, setVisibleEnquiry] = useState(false)
  const [dealsdata, setDeals] = useState([]);
  const [visibleMsg, setVisibleMsg] = useState(false);
  const [images, setImages] = useState([]);
  const [newSearchText, setNewSearchText] = useState('');
  const history = useHistory();
  /* const images = [
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
    {
      original: "https://picsum.photos/id/1018/1000/600/",
      thumbnail: "https://picsum.photos/id/1018/250/150/",
    },
    {
      original: "https://picsum.photos/id/1015/1000/600/",
      thumbnail: "https://picsum.photos/id/1015/250/150/",
    },
    {
      original: "https://picsum.photos/id/1019/1000/600/",
      thumbnail: "https://picsum.photos/id/1019/250/150/",
    },
  ]; */
  React.useEffect(() => {
    getDealDetail()
  }, [])
  function getDealDetail () {
    API.get('deals/contents/node/'+ props.pageid).then(result => {
      console.log('result?.data?.deal: ',result?.data?.deal )
      if(result?.data?.deal){
        setDeals(result.data.deal[0])
        let photolist = [];
        result.data.deal[0].photo.map(item => {
          photolist.push({
            original: item.location,
            thumbnail: item.location,
          })
        })
        setImages(photolist);
      }
    })
  }
  const addProperty = () => {
    setVisible(true)
  }
  const openEnquiry = () => {
    setVisibleEnquiry(true)
  }
  const handleCancel = () => {
    setVisible(false)
    setVisibleEnquiry(false)
    setVisibleMsg(false)
    setVisibleUpdate(false)
  };
  const getModalResponse = (visiblecheck) =>{
    setVisible(visiblecheck)
  }
  const getModalResponseEnquiry = (visiblecheck) =>{
    setVisibleEnquiry(visiblecheck)
    setVisibleMsg(true)
  }
  const getModalResponseUpdate = (visiblecheck) => {
    setVisibleUpdate(visiblecheck)
  }
  const handelSearchSubmit = (e) =>{
    if(e.keyCode == 13 && (newSearchText == null || newSearchText.trim().length <= 0)){
      return
    }
    e.preventDefault();
    history.push(`/deals?search=${newSearchText}`);
  }
  return (
    <div className={"maincontent dealerpage"}>
      <div className="maindiv">
        <div className="deals-midcontent dealsdetailspage">
          <div className='membersearchbox dealersearchbox'>
            <div className='membersearch'>
            <h1> TRATOS </h1>
              <div className="searchbox" >
                {state.isAuthenticated && <div className='userpicbox'>
                  <ProfileImage id={state?.user?.id}/>
                </div>}
                <div className="formbox">
                  <div className="form-item search-filter">
                    <input 
                    type="text"
                    name="search" 
                    className="formtext"
                    placeholder="Search by City, State, Zipcode"
                    onChange={(e) => setNewSearchText(e.target.value)}
                      value={newSearchText != null ? newSearchText : ''}
                    />
                    </div>
                  <div className="form-action"><input type="button" className="form-submit" value="Submit" onClick={(e)=> handelSearchSubmit(e)}/></div>
                </div>
                {state?.user?.usertype?.includes('dealer') &&
                  <div className='add-property'>
                    <Button onClick={()=> addProperty()}>AGREGAR PROPIEDAD</Button>
                  </div>
                }
              </div>
            </div>
          </div>

          <div className='deals-wrapper'>
            <div className='deals-full-content'>
              <div className='deals-content-box'>
                <div className='deals-title'>{dealsdata?.title}</div>
                <div className='image-slider'>
                  <ImageGallery items={images} lazyLoad={true} showPlayButton={false} autoPlay={true} showNav={false} showFullscreenButton={false}/>
                </div>
                <div className='deals-detail'>
                {(state?.user?.usertype?.includes('dealer') && dealsdata?.author?._id === state?.user?.id) &&
                  <div className=''>
                  <Button onClick={()=> setVisibleUpdate(true)}>EDIT</Button>
                  </div>
                }
                  <div className='ap-bar'>
                    <div className='at-group'>
                    <div className='deals-address'>{/* <span className='adbg'></span> */}<span>{dealsdata?.address1},{dealsdata?.address2}</span>,<br /> {dealsdata?.state}, {dealsdata?.city}, {dealsdata?.zipcode}</div>
                    <div className='ap'>
                    {/* <div className='dt'>
                      <div className="commentposted">Listed on: <span>{Moment(dealsdata.createdAt).format('MMM D, YYYY')}</span></div>
                    </div> */}
                    </div>
                    </div>
                    {dealsdata?.price && <div className='deal-price'> <span>${dealsdata?.price.toLocaleString()}</span></div>}
                  </div>

                  <div className='deals-re-avr'>
                     
                      {dealsdata?.renovationestimate && <div className='deal-re'>
                      Renovation Estimate: <span>${dealsdata?.renovationestimate.toLocaleString()}</span>
                      
                    </div>
                    }
                      {dealsdata?.avr && <div className='deal-avr'>AVR: <span>${dealsdata?.avr.toLocaleString()}</span></div>}
                    </div>

                    <div className='deal-other'>
                    <div className='bedroom'>
                      <span className='icon'></span>
                      <span>Bedrooms</span>
                      {dealsdata?.bedroom}
                    </div>
                    <div className='bathroom'>
                      <span className='icon'></span>
                      <span>Bathrooms</span>
                      {dealsdata?.bathrooms}
                    </div>
                    <div className='type'>
                      <span className='icon'></span>
                      <span>Type</span>
                      {dealsdata?.type}
                    </div>
                    <div className='footage'>
                      <span className='icon'></span>
                      <span>Sq.footage</span>
                      {dealsdata?.footage}
                    </div>
                    <div className='lotsize'>
                      <span className='icon'></span>
                      <span>Lot size</span>
                      {dealsdata?.lotsize}
                    </div>
                    <div className='build'>
                      <span className='icon'></span>
                      <span>Year build</span>
                      {dealsdata?.yearbuild}
                    </div>
                    <div className='deal-availablefor'> 
                      <span>Vacant</span>
                      {dealsdata?.availablefor}
                    </div>
                    
                    
                    {/* <div className='parking'>
                      <span className='icon'></span>
                      <span>Parking</span>
                      {dealsdata?.parking}
                    </div> */}
                    
                    
                    
                    {/* {dealsdata?.halfbathrooms && <div className='halfbathroom'>
                      <span className='icon'></span>
                      <span>Half Bathrooms</span>
                      {dealsdata?.halfbathrooms}
                    </div>
                    } */}
                    {/* {dealsdata?.basement && <div className='basement'>
                      <span className='icon'></span>
                      <span>Basement</span>
                      {dealsdata?.basement}
                    </div>
                    }
                    {dealsdata?.hoa && <div className='hoa'>
                      <span className='icon'></span>
                      <span>HoA</span>
                      {dealsdata?.hoa}
                    </div>
                    } */}
                  </div>

                  
                  <div className='deal-detail-desktop'>
                    <div className='title'>Descripción</div>
                    <div dangerouslySetInnerHTML={{ __html: dealsdata?.body?.replace(/\n\r?/g, '<br />', {target: '_blank'}) }}/>
                  </div>
                  
                  {/* <div className='deal-action-wrapper'>
                    <Button className='enquiry' onClick={()=> openEnquiry()}>Preguntas</Button>
                  </div> */}
                  
                </div>
              </div>
              
              <div className='seller-information'>
                <div className="seller-text-info">
                  <div className='title'>Seller Information</div>
                  <div className='seller-name'>{dealsdata?.author?.firstname} {dealsdata?.author?.lastname}</div>
                  </div>
                <div className="userpic"><ProfileImage id={dealsdata?.author?._id}/></div>
                <div className='seller-ctas'>
                    <a className='enquiry btn' href={dealsdata?.author?.pathalias?.alias}>Contactar Wholesaler</a>
                    <Button className='financing'><NavLink to="/financing">Financiamiento</NavLink></Button>
                </div>
              </div>
          </div>
          </div>
          <Modal
          visible={visible}
          onCancel={handleCancel}
          centered="true"
          wrapClassName={'addproperty'}
          footer={null}
          keyboard={false}
          maskClosable={false}
          width={1000}
        >
          <NewDeal getModalResponse={getModalResponse} userdata={state} dealid={dealsdata.dealid}/>
        </Modal>
        <Modal
          visible={visibleUpdate}
          onCancel={handleCancel}
          centered="true"
          wrapClassName={'addproperty'}
          footer={null}
          keyboard={false}
          maskClosable={false}
          width={1000}
        >
          <UpdateDeal getModalResponse={getModalResponseUpdate} userdata={state} dealsdata={dealsdata} getDealDetail={getDealDetail}/>
        </Modal>
        <Modal
          visible={visibleEnquiry}
          onCancel={handleCancel}
          centered="true"
          wrapClassName={'enquiry'}
          footer={null}
          keyboard={false}
          maskClosable={false}
          width={500}
        >
          <NewEnquiry getModalResponse={getModalResponseEnquiry} userdata={state} dealid={dealsdata.dealid}/>
        </Modal>
        <Modal
          visible={visibleMsg}
          onCancel={handleCancel}
          centered="true"
          wrapClassName={'enquiry'}
          footer={null}
          keyboard={false}
          maskClosable={false}
          width={500}
        >
          <h2>Gracias por enviar tu solicitud.</h2>
        </Modal>
      </div>
    </div>
  </div>      
  );
};

export default DealsDetail;
