
import React, { useState,useEffect, useRef } from 'react';
import Page from '../../components/Page/Page';
import {useHistory,useLocation, matchPath } from 'react-router-dom';
import {  Modal, Carousel, AutoComplete, Skeleton } from 'antd';
import API from '../../utils/API';
import { AuthContext } from '../../App';
import ReactPlayer from "react-player";
import './Resource.css';

const Resource = (props) => {

  const { state } = React.useContext(AuthContext);
  const [documentlists, setDocumentlists] = useState([]);
  const [calculatorlists, setCalculatorlists] = useState([]);
  const [importantlists, setImportantlists] = useState([]);
  const history = useHistory();
  const [roleslist, setRoles] = useState([]);
  const [hidesubscribemodal,sethidesubscrubemodal] = useState(false)
  const [defaultresource, setdefaultresource] = useState(0);
  const [defaultDocumentListItem, setdocumentlistdefaultitem] = useState(0);
  const [defaultCalculatorListItem, setcalculatorlistdefaultitem] = useState(0);
  const [defaultImportanttListItem, setimportantlistdefaultitem] = useState(0);
  const [documentLight, setDocumentLight] = useState(true);
  const [calculatorLight, setCalculatorLight] = useState(true);
  const [otherLight, setOtherLight] = useState(true);
  const [sections, setSections] = useState(0)
  const location = useLocation();
  
 /*  if(state?.user?.level == 1){
    
  }else{
    history.push('/pricing');
  } */
  const headerhide = matchPath(location.search, {
    path: ['?forapp=1'],
    exact: true,
    strict: false
  })
  React.useEffect(()=>{
      if(state?.user?.id){
        API.get('/users/' + state?.user?.id).then(result => {
        if(result?.data?.user?.membershiprole == 'basic' || state?.user?.level == 1 || headerhide){
        }else{
          sethidesubscrubemodal(true)
        }
        }).catch(err => {
          console.log(err);
        })
      }
    //}
  },[state?.user?.id])
  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    autoplay: false,
    arrows: true,
    slidesToScroll: 1,
    variableWidth: true,
    responsive: [
       {
           breakpoint: 1280,
           settings: {
               slidesToScroll: 1,
           }
       },
       {
           breakpoint: 767,
           settings: {
              slidesToScroll: 1
           }
       },
       {
           breakpoint: 480,
           settings: {
              slidesToScroll: 1
           }
       }
    ]
 }

  
   React.useEffect(()=>{
      API.get(`/courses/contents/resources`)
      .then(result => {
       if(result.data){
         let filterCat = [];
         let documentlist = [];
         let calculatorlist = []
         let importantlist = [];
         let section = [];
         let temp = 0;
          result.data.resources.map((item,index)=>{
            if(0 == temp){
              temp = item.resourceOrder;
            }else if(temp > item.resourceOrder){
              temp = item.resourceOrder;
            }
            if(item.checkDoc == 'true' || item.checkCal == 'true' || item.checkOther == 'true'){
              filterCat[item.resourceOrder] = item.selectedCategory[0].name
            }
            section[item.resourceOrder] = {
              checkDoc:item.checkDoc,
              checkCal:item.checkCal,
              checkOther:item.checkOther
            }

            documentlist[item.resourceOrder] = item.documents.sort(function (a, b) {
              return a.dposition - b.dposition
            });
            calculatorlist[item.resourceOrder] = item.calculations.sort(function (a, b) {
              return a.cPosition - b.cPosition
            });
            importantlist[item.resourceOrder] = item.importantlist.sort(function (a, b) {
              return a.otherPosition - b.otherPosition
            });
         })
          setRoles(filterCat);
          setDocumentlists(documentlist);
          setCalculatorlists(calculatorlist);
          setImportantlists(importantlist);
          setdefaultresource(temp);
          setSections(section)
        }
      })
      .catch(e => {
        Modal.error({
          title: 'Ocurrió un error',
          content: e.message,
        });
      });

  },[state]);

  
  const handleOnChange = (e,id) => {
    setdefaultresource(id)
    setdocumentlistdefaultitem(0);
    setDocumentLight(true);
    setcalculatorlistdefaultitem(0);
    setCalculatorLight(true);
    setimportantlistdefaultitem(0)
    setOtherLight(true)
  }
  const handleDocumentItemClick = async (e,item, key) =>{
    e.preventDefault();
    setdocumentlistdefaultitem(key);
    setDocumentLight(false);
    setCalculatorLight(true);
    setOtherLight(true);
    if(item?.dpdf?.key){
      var fileExt = item?.dpdf?.key.split('.').pop();
      const response = await API.get( //here use axios interceptors
        `/courses/contents/resource/download?keyName=${item?.dpdf?.key}`,{
          responseType:'blob', //very very important dont miss (if not downloaded file unsupported to view)
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item?.documentTitle+"."+fileExt); //change "file.pdf" according to saved name you want, give extension according to filetype
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    if(item?.dlink){
      window.open(item.dlink,"_blank","toolbar=yes,scrollbars=yes, resizable=yes, top=500, left=500, width=500, height=500");
    }
  }

  const handleCalculatorItemClick = async(e,item, key) =>{
    e.preventDefault();
    setDocumentLight(true);
    setOtherLight(true);
    setCalculatorLight(false);
    setcalculatorlistdefaultitem(key);
    if(item?.calcpdf?.key){
      var fileExt = item?.calcpdf?.key.split('.').pop();
      const response = await API.get( //here use axios interceptors
        `/courses/contents/resource/download?keyName=${item?.calcpdf?.key}`,{
          responseType:'blob', //very very important dont miss (if not downloaded file unsupported to view)
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item?.cTitle+"."+fileExt); //change "file.pdf" according to saved name you want, give extension according to filetype
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    if(item?.clink){
      window.open(item.clink,"_blank","toolbar=yes,scrollbars=yes, resizable=yes, top=500, left=500, width=500, height=500");
    }
  }
  const handleOtherItemClick = async(e,item, key) =>{
    e.preventDefault();
    setDocumentLight(true);
    setCalculatorLight(true);
    setOtherLight(false)
    setimportantlistdefaultitem(key)
    //download the file
    if(item?.otherpdf?.key){
      var fileExt = item?.otherpdf?.key.split('.').pop();
      const response = await API.get( //here use axios interceptors
        `/courses/contents/resource/download?keyName=${item?.otherpdf?.key}`,{
          responseType:'blob', //very very important dont miss (if not downloaded file unsupported to view)
        }
      );
      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", item?.otherTitle+"."+fileExt); //change "file.pdf" according to saved name you want, give extension according to filetype
      document.body.appendChild(link);
      link.click();
      link.remove();
    }
    //close download code
    if(item?.otherlink){
      window.open(item.otherlink,"_blank","toolbar=yes,scrollbars=yes, resizable=yes, top=500, left=500, width=500, height=500");   
    }
    }
  const handleRedirect =  () =>{
    
    sethidesubscrubemodal(false)
     if(localStorage.getItem('token')){
       let path = '/pricing';
       history.push(path);
     } else{
      let path = '/login';
     history.push(path);
     }
     
  }
  const cancleRedirect = () =>{
    history.push('/')
   sethidesubscrubemodal(false)
 }
  return (
    <Page>
      <div className={"maincontent networkpage fullpage"}>
        <div className="network-search-filter">
          <div className="membersearchbox">
            <div className="maintitle"><h2>Documentos y Calculadoras</h2></div>
          </div>
          <div className='rolefilter'>
            <Carousel {...settings} >
              {roleslist.map((name, index) => {
                return (
                  <div key={index}>
                    <div className="toppings-list-item">
                      <div className="left-section">
                        <input
                          type="checkbox"
                          id={`custom-checkbox-${index}`}
                          name={name}
                          value={index}
                          checked={defaultresource == index ? true : false}
                          onChange={(e) => handleOnChange(e,index)}
                        />
                        <label htmlFor={`custom-checkbox-${index}`}>{name}</label>
                      </div>
                    </div>
                  </div>
                );
              })}
            </Carousel>
          </div>
          
        </div>
        <div className="maindiv">
          <div className="midcontent resource-document">
              {sections[defaultresource]?.checkDoc == 'true' &&
              <><div className='document-wrapper'>
                <div className='document-wrapper-left wrapper-left'>
                  <h3>Documentos</h3>
                  {hidesubscribemodal
                    ? <><Skeleton /></>
                    :
                    <ol className='item-wrapper'>
                      {documentlists[defaultresource]?.map((item,key) =>{
                        return <li onClick={(e) => handleDocumentItemClick(e,item,key)}>{item?.documentTitle}</li>
                      })}
                    </ol>
                    }
                </div>
                {(defaultresource > 0 && documentlists[defaultresource][defaultDocumentListItem]?.dvideoURL != "")
                ?
                <div className='document-wrapper-right wrapper-right'>
                  {hidesubscribemodal
                    ? <><Skeleton.Image /></>
                    :<>
                  <div className='video-title'>{documentlists[defaultresource][defaultDocumentListItem]?.documentVideoTitle}</div>
                  <div className="coursevideo">
                    <ReactPlayer
                    url={'https://vimeo.com/'+documentlists[defaultresource][defaultDocumentListItem]?.dvideoURL}
                        width="100%"
                        height="400px"
                        playing
                        light={documentLight}
                        controls={true}
                        />
                  </div>
                  </>}
                </div>
                :null}
              </div>
              <div className='seprator-line'></div>
              </>}
              {sections[defaultresource]?.checkCal == 'true' &&
              <><div className='calculator-wrapper'>
                <div className='calculator-wrapper-left wrapper-left'>
                  <h3>Calculadoras</h3>
                  {hidesubscribemodal
                    ? <><Skeleton /></>
                    :
                  <ol className='item-wrapper'>
                    {calculatorlists[defaultresource]?.map((citem,key) =>{
                      return <li onClick={(e) => handleCalculatorItemClick(e,citem,key)} >{citem?.cTitle}</li>
                    })}
                  </ol>
                  }
                </div>
                {(defaultresource > 0 && calculatorlists[defaultresource][defaultCalculatorListItem]?.cvideoURL != "")
                ?
                <div className='calculator-wrapper-right wrapper-right'>
                  {hidesubscribemodal
                    ? <><Skeleton.Image /></>
                    :<>
                  <div className='video-title'>{calculatorlists[defaultresource][defaultCalculatorListItem]?.cvideoTitle}</div>
                  <div className="coursevideo">
                    <ReactPlayer
                    url={'https://vimeo.com/'+calculatorlists[defaultresource][defaultCalculatorListItem]?.cvideoURL}
                        width="100%"
                        height="400px"
                        playing
                        light={calculatorLight}
                        controls={true}
                        />
                  </div>
                  </>}
                </div>
                :null}
              </div>
              <div className='seprator-line'></div>
              </>}
              {sections[defaultresource]?.checkOther == 'true' &&
              <><div className='important-wrapper'>
                <div className='important-wrapper-left wrapper-left'>
                  <h3>Otros Recursos</h3>
                  {hidesubscribemodal
                    ? <><Skeleton /></>
                    :
                  <ol className='item-wrapper'>
                    {importantlists[defaultresource]?.map((oitem,key) =>{
                      return <li onClick={(e) => handleOtherItemClick(e,oitem,key)}>{oitem?.otherTitle} </li>
                    })}
                  </ol>
                  }
                </div>
                {(defaultresource > 0 && importantlists[defaultresource][defaultImportanttListItem]?.othervideolink != "")
                ?
                <div className='important-wrapper-right wrapper-right'>
                  {hidesubscribemodal
                    ? <><Skeleton.Image /></>
                    :<>
                  <div className='video-title'>{importantlists[defaultresource][defaultImportanttListItem]?.othervideoTitle}</div>
                  <div className="coursevideo">
                    <ReactPlayer
                    url={'https://vimeo.com/'+importantlists[defaultresource][defaultImportanttListItem]?.othervideolink}
                        width="100%"
                        height="400px"
                        playing
                        light={otherLight}
                        controls={true}
                        />
                  </div>
                  </>}
                </div>
                :null}
              </div>
              </>}
            </div>
          <Modal
         visible={hidesubscribemodal}
         okText="Actualizar membresía"
         onOk={handleRedirect}
         onCancel={cancleRedirect}
         cancelText="Cancelar"
         keyboard="false"
         centered="true"
         closable={false}
      >
         <div>Solo miembros subscritos pueden acceder esta pagina</div>
      </Modal>
        </div>
      </div>
    </Page>
  );
};

export default Resource;