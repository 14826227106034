import React, { useEffect, useContext, useState } from 'react';
import API from '../../../../utils/API';
import { Button, Form, Input, Select } from 'antd';
import PhoneInput from 'react-phone-input-2'

const { TextArea } = Input;
const RefinanciamientoStep1 = props => {
  const [loantype, setLoantype] = useState('');
  const [stateList,setStateList] = useState([]);
  const [statelistoptiondone,setStateListOptiondone] = useState(false);
  const [cuantosShow, setCuantosShow] = useState(false)
  const [isrentedShow, setIsrentedShow] = useState(false);
  const [langselected, setLang] = useState({})
  const [form] = Form.useForm();
  const [phonenumber, setPhoneNumber] = useState('')
  
  const optionsLang = [
    {value: 'Español',label: 'Español'},
    {value: 'English', label: 'English'}
  ];
  const handleLangChange = (value) => {
    console.log(`selected ${value}`);
    setLang(value)
  };
  const handlePhoneOnChange = (value, data, event, formattedValue) => {
    if(formattedValue.length > 10){
      setPhoneNumber(formattedValue);
    }else {
      setPhoneNumber('');
    }
  }
  const onNext = () => {
    props.form.validateFields(["fullname", "telephonenumber", "langcontact"])
    .then(() =>{
      var value = props.form.getFieldsValue()
      props.formdata.fullname = value.fullname;
      props.formdata.telephonenumber = phonenumber ? phonenumber : props.formdata.telephonenumber;
      props.formdata.langcontact = value.langcontact;
      props.next()
    })
    .catch((e) =>{
      console.log()
    })

  }
   return (
      <>
          <Form.Item
            label="Nombre completo"
            name="fullname"
            className="form-item"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if(typeof(value) == "undefined" || !value){
                  return Promise.reject(new Error('Entra tu nombre completo'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            <Input  placeholder="Entra tu nombre completo" className="form-text" />
          </Form.Item>
          <Form.Item
            label="Número telefónico"
            name="telephonenumber"
            className="form-item"
            rules={[
              {
                required: true,
                validator: (_, value) => {
                  if(typeof(value) == "undefined" || !value){
                  return Promise.reject(new Error('Entra Número telefónico'));
                  }
                  return Promise.resolve();
                },
              },
            ]}
          >
            {/* <Input  placeholder="Entra Número telefónico" className="form-text" /> */}
            <PhoneInput
              country={'us'}
              value={props?.formdata?.telephonenumber}
              enableSearch={true}
              onChange={handlePhoneOnChange}
            />
          </Form.Item>
          
          <Form.Item
            label="¿En que idioma quiere ser contactado?"
            name="langcontact"
            rules={[{ required: true, message: "Favor de seleccionar una opción" }]}>
              <Select
                placeholder="Entra idioma"
                onChange={handleLangChange}
                options={optionsLang}
              />
          </Form.Item>
          <Form.Item className="form-action">
              <Button className="nextstep" type="primary" onClick={() => onNext()}>Siguiente</Button>
            </Form.Item>
      </>
   );
};

export default RefinanciamientoStep1;
