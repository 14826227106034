import React, { useState, useContext } from 'react';
import Page from '../../../components/Page/Page';
import API from '../../.././utils/API';
import { AuthContext } from '../../../App';
import './aboutuser.css'
import UserHeader from '../UserHeader';
import { useHistory, Redirect } from 'react-router-dom'
const AboutUser = props => {
    const { state } = useContext(AuthContext);
    const [userDetail,setUserDetail] = useState()
    let data = {}
    const [primaryCat,setPrimaryCat] = useState("")
    const [secondaryCat,setSecondaryCat] = useState()
    const [otherCat,setOtherCat] = useState([])
    const [interest,setInterest] = useState([])
    const [intData,setData] = useState()
    const [roles,setRoles] = useState([])
    const [loader,setLoader] = useState(true)

    const history = useHistory();

   React.useEffect( ()=> {
    if (localStorage.getItem('token') && localStorage.getItem('user')) {
      const user = JSON.parse(localStorage.getItem('user'));
      fetchData(user.id)
    }
    },[]);

      const fetchData = (id) => {
        API.get(`/userinfo/?userid=${id}`).then(
          result => {
         data = result.data.userInfo
        setUserDetail(result.data.userInfo)
        // if(data?.primarycategory !== undefined)
        // categoryName(data?.primarycategory)
        // if(data?.secondarycategory !== undefined)
        
        // secCatName(data?.secondarycategory)
        if(data?.roles !== undefined){
        rolesName(data?.roles)}
        // if(data?.othercategory?.length !== 0){
        
        //   const arr = []
        //   data?.othercategory?.map(res => {
        //   API.get(`/categories/id/?categoryid=${res}`).then(res => {
        //   otherCat.push(res?.data?.category?.name)
        //  setCatData( <ul>
        //   {otherCat?.map( cat => <li>{cat}</li>)}
           
        //  </ul>)
           
        //   }).catch(err => {
        //     console.log(err);
        //   })
        // })

        //   }

          if(userDetail?.interests.length !== 0) {
            const arr = []
          result?.data?.userInfo?.interests.map(res => {
             
          API.get(`/interests/id/?interestid=${res}`).then(res => {
            
           
             interest.push(res?.data?.interets?.name)
             setData( <ul>
              { interest?.map( cat => <li>{cat}</li>)}
              </ul>)
        
             }).catch(err => {
               console.log(err);
             })})
           
            
     
   
        }
        setLoader(true)
          }
      ).catch(err => {
          console.log(err);
          setLoader(false)
      })
      
     
      }
      if(state?.user?.usertype.includes('lender') && state?.user?.sub == 'free' && state?.user?.level != 1){
        return <Redirect to="/dashboard/lender"/>;
      }
      const categoryName = (id) => {
       
        API.get(`/categories/id/?categoryid=${id}`).then(res => {
          setPrimaryCat(res?.data?.category?.name)
        }).catch(err => {
          console.log(err);
        })
      }
      const rolesName = (id) => {
        API.get(`/roles/id?rolesid=${id}`).then(res => {
          setRoles(res?.data?.roles?.name)
        }).catch(err => {
          console.log(err);
        })
      }
      const secCatName = (id) => {
       
        API.get(`/categories/id/?categoryid=${id}`).then(res => {
          setSecondaryCat(res?.data?.category?.name)
       
        }).catch(err => {
          console.log(err);
        })
      }

      const otherCatName = (id) => {
       
        API.get(`/categories/id/?categoryid=${id}`).then(res => {
          setOtherCat(res?.data?.category?.name)
        }).catch(err => {
          console.log(err);
        })
      }

    const back =  {
        background: "#0072BB",
        color:"#ffff"
      }

/*       const titlecase = (word) => {
          if(word !== undefined){
      return  word.split(' ')
      .map(w => w[0].toUpperCase() + w.substr(1).toLowerCase())
      .join(' ')
    }
      } */
    

  return (
    <Page>
           {
      <div className="maincontent aboutuser">
 
        <div className="backbutton">
          <button onClick={() => { history.goBack();}}>Go back</button>
        </div>
        <div className="aboutsection">
          <UserHeader btn = {back} shortBio = {userDetail?.shortbio} otherCat = {otherCat}/>
          <div className = "aboutcontainer">
            {userDetail?.roles &&<div className="viewrow">
              <h1 className = "blocktitle" >Rol principal</h1>
              <div className="value">{roles}</div>
            </div>}
            {/* {userDetail?.primarycategory &&<div className="viewrow">
              <h1 className = "blocktitle" >Primary Category</h1>
              <div className="value">{primaryCat}</div>
            </div>}
            {userDetail?.secondarycategory&& <div className="viewrow">
              <h1 className = "blocktitle" >Secondory Category</h1>
              <div className="value">{secondaryCat}</div>
            </div>}
            {userDetail?.othercategory[0] && <div className="viewrow">
              <h1 className = "blocktitle" >Otras categorias</h1>
              <div className="value">
                {catData}
              </div>
            </div>} */}
          {userDetail?.interests[0]&& <div className="viewrow">
              <h1 className = "blocktitle" >Intereses</h1>
              <div className="value">
                {intData}
              </div>
            </div>}
            {userDetail?.shortbio && <div className="viewrow description"> 
              <h1 className = "blocktitle" >biografía Breve</h1>
              <div className="value">{userDetail?.shortbio}</div>
            </div>}
          {userDetail?.currentlocation?.state && <div className="viewrow addresslocation">
              <h1 className = "blocktitle" >Locacion actual</h1>
              <div className="value">
                <ul>
                  <li>{userDetail?.currentlocation?.state}</li>
                  <li>{userDetail?.currentlocation?.city} </li>
                  <li>{userDetail?.currentlocation.zipcode}</li>
                </ul>
              </div>
            </div>}
            {userDetail?.fulladdress &&<div className="viewrow description">
              <h1 className = "blocktitle" >Dirección</h1>
              <div className="value">{userDetail?.fulladdress}</div>
            </div>}
            {userDetail?.primaryinvestmentlocation && <div className="viewrow addresslocation">
              <h1 className = "blocktitle" >Locacion de Inversion Principal</h1>
              <div className="value">
                <ul>
                  <li>{userDetail?.primaryinvestmentlocation?.state}</li>
                  <li>{userDetail?.primaryinvestmentlocation?.city} </li>
                  <li>{userDetail?.primaryinvestmentlocation.zipcode} </li>
                </ul>
              </div>
            </div>}
            {userDetail?.secondaryinvestmentlocation[0]?.state && <div className="viewrow addresslocation">
              <h1 className = "blocktitle" >Ubicación de inversión secundaria</h1>
              <div className="value">
                <ul>
                  <li>{userDetail?.secondaryinvestmentlocation[0]?.state}</li>
                  <li>{userDetail?.secondaryinvestmentlocation[0]?.city} </li>
                  <li>{userDetail?.secondaryinvestmentlocation[0]?.zipcode} </li>
                </ul>
              </div>
            </div>}
            {userDetail?.secondaryinvestmentlocation[1]?.state && <div className="viewrow addresslocation">
              <h1 className = "blocktitle" >Otra ubicación de inversión</h1>
              <div className="value">
              { userDetail?.secondaryinvestmentlocation?.map( cat => <ul>
                  <li>{cat?.state}</li>
                  <li>{cat?.city}</li>
                  <li>{cat.zipcode}</li>
                </ul>).splice(1,userDetail.secondaryinvestmentlocation.length)}
              
              </div>
            </div>}
            {userDetail?.phonenumber && <div className="viewrow">
              <h1 className = "blocktitle" >Número de contacto</h1>
              <div className="value">{userDetail?.phonenumber}</div>
            </div>}
          {userDetail?.userid?.email && <div className="viewrow">
              <h1 className = "blocktitle" >Correo Electronico</h1>
              <div className="value">{userDetail?.userid?.email}</div>
            </div>}
            {userDetail?.website && <div className="viewrow">
              <h1 className = "blocktitle" >Sitio web</h1>
              <div className="value">{userDetail?.website} </div>
            </div>}
          {userDetail?.aboutyourself && <div className="viewrow description">
              <h1 className = "blocktitle" >Acerca de usted / Descripción completa</h1>
              <div className="value">{userDetail?.aboutyourself}</div>
            
            </div>}
          {userDetail?.companydetails && <div className="viewrow description">
              <h1 className = "blocktitle" >Detalles de la compañía</h1>
              <div className="value">{userDetail?.companydetails} </div>
            
            </div>}
          </div>
        </div>
      </div>}
    </Page>
  );
};

export default AboutUser;