import React, { Component } from 'react';
import API from '../../utils/API';
import {Modal, Skeleton} from 'antd';
import './basic.css';
/* import styles from './Policy.module.css'; */

class BasicPage extends Component {

  state ={
    pagedata: [],
    pageid : this.props.pageid
  }
  componentDidMount() {
    this.getData()
  };
  getData(){
    API.get('/pages/contents/node/'+this.props.pageid)
    .then(result => {
      const basicpage= result.data.basicpages;
      this.setState({ pagedata:basicpage });
    })
    .catch(e => {
      Modal.error({
        title: 'Ocurrió un error',
        content: e.message,
      });
    });
  }

  render(){
  return (
    <div>
      {this.state.pagedata.length == 0 &&
      <div className="maincontent basicpage">
        <Skeleton active/>
        <Skeleton active/>
      </div>
      }
      {this.state.pagedata.length > 0 && 
        <div className="maincontent privacypolicy termsandconditions">
          <h1>{this.state.pagedata[0].title}</h1>
          <div dangerouslySetInnerHTML={ { __html:this.state.pagedata[0].body}} ></div>
        </div>
      }
    </div>
  );
  }
};

export default BasicPage;
