import React, { useEffect, useState } from 'react';
import './conversation.css';
import API from '../../utils/API';
import ProfileImage from '../WidgetBar/ProfileImage';
import { Link } from 'react-router-dom';
const ConversationHead = (props) => {
    const [user,setUser] = useState(null);
    useEffect(() => {
        if(props?.convesationhead?.members.length > 0 && props?.currentUser?.user != null){
            const friendId = props.convesationhead.members.find((m) => m !== props.currentUser.user.id);
            const getUser = async () => {
              try {
                const res = await  API.get('/users/' + friendId);
                setUser(res.data.user);
              } catch (err) {
                console.log(err);
              }
            };
            getUser();
        }
      }, [props.currentUser, props.convesationhead]);

    return (
      <>
      {user?
       <div><Link to={user?.pathalias?.alias}>
          <div className="conversationhead">
            {<ProfileImage id={user._id} classtouse="conversationImg"/>}
            <span className="conversationName">{user?.firstname} {user?.lastname}</span>
        </div>
       </Link></div>
      : null}
      </>
    )
}

export default ConversationHead;