import React,{useState} from 'react';
import API from '../../utils/API';
import {Link, useHistory} from 'react-router-dom';
import '../Blogs/blogdetail.css';
import CategoryName from '../../components/CategoryName/CategoryName';
import SocialShareBlock from '../../components/SocialShareBlock/SocialShareBlock';
import '../Education/education.css';
import ReactPlayer from "react-player";
import { Button,Modal } from "antd";
import esLocale from "moment/locale/es";
import moment from 'moment'
const WebinarDetails = (props) => {
   const history = useHistory();
    const [blogData,setBlogData] = React.useState()
    const [dataList,setDataList] = useState([])
    const [category,setCategory] = useState()
    const [index,setIndex] = useState(0)
    const [hidesubscribemodal,sethidesubscrubemodal] = useState(false)
    const [redirectUrl,setRedirectUrl] = useState(false)
   React.useEffect(()=>{
      moment.locale('es', [esLocale]);
      if (localStorage.getItem('token') && localStorage.getItem('user')) {
         let id = JSON.parse(localStorage.getItem('user')).id;
         API.get('users/'+id).then(result=>{
         if(JSON.parse(localStorage.getItem('user')).level !== 1){
            if(result.data !== null && result.data.user.membershiprole !== 'undefined'){
               if( result.data.user.membershiprole === 'free'){
                  history.push('/education')
                  sethidesubscrubemodal(true)
               }
            }           
         } else{
              sethidesubscrubemodal(false)
            }
         }).catch(e =>{
           console.log(e.message);
         });
      } else{
         history.push('/login')
         setRedirectUrl(true)
      }
      API.get('/webinar/contents/node/' + props.pageid).then(result =>{
         setBlogData(result.data.webinar[0])
         setCategory(<CategoryName id={result.data.webinar[0]?.selectedCategory[0]._id}/>)
         createMarkup(result.data.webinar[0].description)
         API.get('/webinar/contents')
         .then(resulto => {
            const datalists= resulto.data.webinar.sort((a, b) => {
            // equal items sort equally
            if (a.webinarOrder === b.webinarOrder) {
                  return 0;
            }
            // nulls sort after anything else
            else if (a.webinarOrder === null) {
                  return 1;
            }
            else if (b.webinarOrder === null) {
                  return -1;
            }
            // otherwise, if we're ascending, lowest sorts first
            else if (true) {
                  return a.webinarOrder < b.webinarOrder ? -1 : 1;
            }
            // if descending, highest sorts first
            else { 
                  return a.webinarOrder < b.webinarOrder ? 1 : -1;
            }  
            });
      
            //const index = datalists.findIndex( (element) => element?._id === result?.data?.webinar[0]?._id);
            setDataList(datalists)       
         }).catch(e => {
            console.log(e);
         });
      })
   },[props?.pageid, history])

   const handleRedirect =  () =>{
     sethidesubscrubemodal(false)
      if(localStorage.getItem('token')){
        let path = '/pricing?='+blogData.pathalias.alias;
        history.push(path);
      }else{
         let path = '/login?='+blogData.pathalias.alias;
         history.push(path);
      }
   }
   const handleCancel = () => {
      sethidesubscrubemodal(false)
      history.push('/education')
      document.body.classList.remove('modalopen');
    };
   const cancleRedirect = () =>{
      history.push('/education')
      sethidesubscrubemodal(false)
   }
   const createMarkup = (data) => {
      return {__html: data};
    }
    const onSelectTip = (id) => {

      setBlogData(undefined)
      setCategory(undefined)
      API.get("/webinar/contents/node/" + id).then((result) => {
   
        setBlogData(result.data.webinar[0]);
        createMarkup(result.data.webinar[0].description);
        setCategory(<CategoryName id={result.data.webinar[0]?.selectedCategory[0]._id}/>)
        const index = dataList.findIndex( (element) => element?._id === result?.data?.webinar[0]?._id);
        setIndex(index)
      });
    
   }
  return (
   <div className="blogsdetailspage webinardetailspage">
      {blogData && <div className = "blogsdetail webinardetails" >
         <div className="breadcrumbwrapper">
            <div  className="breadcrumb">
               <ul>
                  <li><Link to='/education'>Educacion</Link></li>
                  <li><Link to="/education/webinar">WEBINARS</Link></li>
               </ul>
            </div>
            <div className="webinartitle">
               <div className="title">{blogData.title}</div>
            </div>
         </div>
         <div className="blogsdetailcontent">
         <div className="tipvideo">
                                    <ReactPlayer
                                    url={'https://vimeo.com/'+blogData.videoURL}
                                       width="100%"
                                       height="558px"
                                       playing
                                       light={true}
                                       controls={true}
                                       />
                                 </div>
                                 {blogData?.publishedDate&& <div className="created" style={{marginTop:5}} >Publicado en {moment(blogData?.publishedDate).format('MMM D, YYYY')}</div>}
              {blogData?.artistname&& <div className="created" style={{marginTop:5}}>Publicado por: {String(blogData?.artistname).charAt(0).toUpperCase() + String(blogData?.artistname).slice(1)}</div>}
            <div className="courseshare">
               <ul>
                  {/* <li className="bookmark"><a href="#">bookmark</a></li> */}
                  <li className="share">
                     <SocialShareBlock shareTitle={blogData.title} shareUrl={window.location.href} />
                  </li>
               </ul>
            </div>
            <div className="categoriesbox">
            <div className="categories">
               <label>Categoría: {" "}</label>
               <strong>{category}</strong>
            </div>    
         </div>  
            {blogData?.description &&<div className = "title" >Descripcion de la clase intensiva</div>}
            <div className = "description" dangerouslySetInnerHTML={ { __html:blogData.description}} ></div>
            <div className="createdby">
               <div className="sharelink">
                  <ul>
                     {/* <li className="bookmark"><a href="#">bookmark</a></li>
                     <li className="share"><a href="#">share</a></li> */}
                  </ul>
               </div>
               {/* {blogData.artistname&&<div className="auther"><span>Escrito por {blogData.artistname}</span></div>} */}
            </div>
            <Modal
         visible={hidesubscribemodal}
         okText="Actualizar membresía"
         onOk={handleRedirect}
         onCancel={cancleRedirect}
         cancelText="Cancelar"
         keyboard="false"
         centered="true"
         mask
         closable={false}
      >
         <div>Debe actualizar su membresía para ver los detalles de la clase intensiva</div>
      </Modal>
      <Modal
           visible={redirectUrl}
           okText="Iniciar sesión"
           onOk={handleRedirect}
           onCancel={handleCancel}
           cancelText="Suscríbete"
           keyboard="false"
           centered="true"
           closable={false}
        >
           <div className="emptymessage" >Debes iniciar sesión para ver este video</div>
        </Modal>
            {dataList.length > 0 &&<div className="actionbutton"><div className="actionbuttoninner">
          {index > 0&&<div className="button previous"> <Link onClick={()=> onSelectTip(dataList[index-1]?._id)} to={dataList[index+1]?.pathalias?.alias} ><Button type="primary">Clase intensiva anterior</Button></Link></div>}
          {index+1 !== dataList.length&&<div className="button next"> <Link onClick={()=> onSelectTip(dataList[index+1]?._id)} to={dataList[index+1]?.pathalias?.alias} ><Button type="primary">Siguiente clase intensiva</Button></Link></div>}
          </div></div>}
         </div>                   
      </div>}
      </div> 
  );
};

export default WebinarDetails;